import axios from "../../utils/axiosInstance";

export const processCreationListReactive = async (count, pageCount, filters, multiConditionFilters, sortModel, showAllTelemetry) => {
    const response = await axios.post("/processCreationListReactive", {
        page: pageCount,
        count: count,
        filters: filters,
        multiConditionFilters: multiConditionFilters,
        sortModel: sortModel,
        showAllTelemetry: showAllTelemetry
    });
    return response.data;
};

export const findByProcessCreationIdListReactive = async (ids) => {
    const response = await axios.post("/findByProcessCreationIdListReactive",{
        ids: ids
    })
    return response.data
}
export const getProcessCreationsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getProcessCreationsGridFilterStateReactive", {});
    return response.data;
};

export const updateProcessCreationsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateProcessCreationsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateProcessCreationsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateProcessCreationsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getProcessCreationsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getProcessCreationsGridColumnStateReactive", {});
    return response.data;
};
export const updateProcessCreationsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateProcessCreationsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateProcessCreationsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateProcessCreationsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};
export const updateProcessCreationsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateProcessCreationsGridColumnModeReactive", {
        columnMode: columnMode
    });
};
export const updateShowAllCreationsTelemetryReactive = async (visible) => {
    return await axios.post("/updateShowAllCreationsTelemetryReactive", {
        visible: visible
    });
};