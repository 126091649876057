import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import {AgChartsReact} from "ag-charts-react";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";

export default function Collectipede() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Collectipede</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col">
                    <div className="flex flex-row items-center justify-center flex-wrap mt-8 gap-x-5 gap-y-2">
                        <div className="text-4xl ml-10 mb-5 ">Collectipede</div>
                        <img className="" src="/images/logo/collectipedeLogo.png" width="80" alt="Collectipede Logo" />
                    </div>
                    <hr className="ml-10 mr-10 mb-20 bg-black h-0.5" />
                    <div className={"flex flex-row flex-wrap gap-x-20 justify-center gap-y-5"}>
                        <div style={{height: "300px", width: ""}} className={``}>
                            <AgChartsReact options={
                                {
                                    autoSize: true,
                                    data: [
                                        { label: 'Free Licenses', value: 100 },
                                        { label: 'Used Licenses', value: 56 }
                                    ],
                                    title: {
                                        text: 'License Capacity Appliance 1',
                                        fontSize: 18,
                                    },
                                    series: [
                                        {
                                            type: 'pie',
                                            angleKey: 'value',
                                            calloutLabelKey: 'label',
                                            fills: ['#E8E8E8', '#e76a24'],
                                            strokes: ['#989898', '#989898'],
                                            highlightStyle: {
                                                item: {
                                                    fill: "#4194a5",
                                                    stroke: "#181818",
                                                    strokeWidth: 1
                                                },
                                            },
                                        },
                                    ],
                                }
                            } />
                        </div>
                        <div style={{height: "300px", width: ""}} className={``}>
                            <AgChartsReact options={
                                {
                                    autoSize: true,
                                    data: [
                                        { label: 'Free Licenses', value: 400 },
                                        { label: 'Used Licenses', value: 1000 }
                                    ],
                                    title: {
                                        text: 'License Capacity Appliance 2',
                                        fontSize: 18,
                                    },
                                    series: [
                                        {
                                            type: 'pie',
                                            angleKey: 'value',
                                            calloutLabelKey: 'label',
                                            fills: ['#E8E8E8', '#e76a24'],
                                            strokes: ['#989898', '#989898'],
                                            highlightStyle: {
                                                item: {
                                                    fill: "#4194a5",
                                                    stroke: "#181818",
                                                    strokeWidth: 1
                                                },
                                            },
                                        },
                                    ],
                                }
                            } />
                        </div>
                    </div>
                    <div className={"flex flex-row flex-wrap gap-x-20 justify-center gap-y-5 mt-10"}>
                        <div style={{height: "300px", width: ""}} className={``}>
                            <AgChartsReact options={
                                {
                                    autoSize: true,
                                    data: [
                                        { label: 'Free Licenses', value: 50 },
                                        { label: 'Used Licenses', value: 3 }
                                    ],
                                    title: {
                                        text: 'License Capacity Appliance 3',
                                        fontSize: 18,
                                    },
                                    series: [
                                        {
                                            type: 'pie',
                                            angleKey: 'value',
                                            calloutLabelKey: 'label',
                                            fills: ['#E8E8E8', '#e76a24'],
                                            strokes: ['#989898', '#989898'],
                                            highlightStyle: {
                                                item: {
                                                    fill: "#4194a5",
                                                    stroke: "#181818",
                                                    strokeWidth: 1
                                                },
                                            },
                                        },
                                    ],
                                }
                            } />
                        </div>
                        <div style={{height: "300px", width: ""}} className={`mb-10`}>
                            <AgChartsReact options={
                                {
                                    autoSize: true,
                                    data: [
                                        { label: 'Free Licenses', value: 300 },
                                        { label: 'Used Licenses', value: 350 }
                                    ],
                                    title: {
                                        text: 'License Capacity Appliance 4',
                                        fontSize: 18,
                                    },
                                    series: [
                                        {
                                            type: 'pie',
                                            angleKey: 'value',
                                            calloutLabelKey: 'label',
                                            fills: ['#E8E8E8', '#e76a24'],
                                            strokes: ['#989898', '#989898'],
                                            highlightStyle: {
                                                item: {
                                                    fill: "#4194a5",
                                                    stroke: "#181818",
                                                    strokeWidth: 1
                                                },
                                            },
                                        },
                                    ],
                                }
                            } />
                        </div>
                    </div>
                    <NonGridPageFooter/>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
}

