import {getValidatedStagedUpdatesReactive} from "../pages/api/agentsApi";


export function agentVersionFormatter(params){
    //agentVersionFormatted is used for column field now so we can avoid the getValidatedStagedUpdatesReactive getting called endlessly. If we want to get rid of the spinner for loading for this column we can just
    // do params.node.data.agentVersionFormatted = params.node.data.agentVersion instead of params.node.data.agentVersionFormatted = params.node.data.agentId and then remove cell renderers for this column
    if(params.node.data.agentVersionFormatted){
        return params.node.data.agentVersionFormatted
    }

    if(params.node.data.agentId){
        let agentId = params.node.data.agentId;
        getValidatedStagedUpdatesReactive(agentId).then(function(stagedUpdateList){
            if(stagedUpdateList && params.node.data.agentVersion !== stagedUpdateList.stagedAgentVersion){
                let alpha = (stagedUpdateList.currentAgentVersion+" (Staged: " + stagedUpdateList.stagedAgentVersion + ")")
                params.node.data.agentVersionFormatted = alpha
                params.api.refreshCells({columns: ["agentVersionFormatted"], rowNodes: [params.node], suppressFlash: false, force: false})
                //params.node.setDataValue("agentVersionFormatted",alpha)
                //return alpha;
            }
            else
            { // making sure this branch is covered
                if(params.node.data.agentVersion){
                    params.node.data.agentVersionFormatted = params.node.data.agentVersion
                    params.api.refreshCells({columns: ["agentVersionFormatted"], rowNodes: [params.node], suppressFlash: false, force: false})
                    //params.node.setDataValue("agentVersionFormatted", params.node.data.agentVersion)
                }
                else{
                    //spinning circle in agentVersion col will keep showing if params.node.data.agentVersion is null, so have to account for that
                    params.node.data.agentVersionFormatted = " "
                    params.api.refreshCells({columns: ["agentVersionFormatted"], rowNodes: [params.node], suppressFlash: false, force: false})
                    //params.node.setDataValue("agentVersionFormatted", " ")
                }
            }
            // some of the agentId does not exist
        }).catch(function(error){
            console.log(error);
            //spinning circle in agentVersion col will keep showing if params.node.data.agentVersion is null, so have to account for that
            params.node.data.agentVersionFormatted = " "
            params.api.refreshCells({columns: ["agentVersionFormatted"], rowNodes: [params.node], suppressFlash: false, force: false})
            //params.node.setDataValue("agentVersionFormatted", " ")
        })
        params.node.data.agentVersionFormatted = params.node.data.agentId
        return params.node.data.agentId
    }

}