import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {Auth} from "aws-amplify";
import Modal from "react-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import {initializeGroupsAgentTelemetrySettingReactive, updateAccountInfoReactive} from "../pages/api/loginApi";
import {changeUserTimeZoneReactive} from "../pages/api/settingsApi";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import {encryptAndStoreSessionVariable} from "../utils/storageHelper";
import {buttonTheme, roundButtonTheme, switchTheme} from "../utils/muiStyling";
import {Button, FormControlLabel, IconButton, Switch, ThemeProvider} from "@mui/material";
import {MuiCloseIconButton, MuiIconWithTooltip} from "./muiComponents";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export const NewPasswordModal = ({ onClose, onSuccess, user, opened }) => {
    const { register, handleSubmit, watch, reset } = useForm();
    const [showPassword, setShowPassword] = useState(false);
    const completeNewPassWord = async () => {
        const pass = watch("newPassword");
        const passConfirm = watch("newPasswordConfirm");
        if(pass !== passConfirm){
            NotificationManager.error(
                `Passwords must match.`
            );
            return;
        }
        try {
            await Auth.completeNewPassword(
                user,
                pass
            ).then((newPassUser) => {
                //alert("You have successfully made a new password! You will be asked to login again with your new password.")
                onSuccess();
            }).catch( e => {
                if(e.message){
                    NotificationManager.error(e.message);
                }
                else{
                    NotificationManager.error(
                        `Error setting new password, please try again.`
                    );
                }
            });
        } catch (error) {
            NotificationManager.error(
                `Error setting new password, please try again.`
            );
        }
    };
    return (
        <Modal
            onRequestClose={() => {
                reset({})
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Setup New Password"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-lg lg:w-lg md:w-lg sm:w-lg xs:w-11/12 max-w-lg inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(completeNewPassWord)}>
                <div className="flex flex-1 flex-col gap-y-3 w-full">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Setup New Password</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                reset({})
                                onClose()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5"/>
                    {/*Form content*/}
                    <label className="ml-1 ">A new password is required, please enter your new password below:</label>
                    <div className="ml-1 w-full">
                        <small>Password</small>
                        <input
                            type={showPassword ? 'text' : 'password'}
                            autoComplete="off"
                            required
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            name="newPassword"
                            {...register("newPassword")}
                            className="focus:outline-none h-10 p-2 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid pr-10"
                        />
                        <span className="passwordIcon">
                                            {showPassword
                                                ?
                                                <IconButton sx={{width: 25, height: 25}}
                                                            className={`self-center object-contain`}>
                                                    <Visibility className={"cursor-pointer"} onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}/>
                                                </IconButton>
                                                :
                                                <IconButton sx={{width: 25, height: 25}}
                                                            className={`self-center object-contain`}>
                                                    <VisibilityOff className={"cursor-pointer"} onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}/>
                                                </IconButton>
                                            }
                                        </span>
                    </div>
                    <div className="ml-1 mb-1 w-full">
                        <small>Confirm Password</small>
                        <br/>
                        <input
                            name="newPasswordConfirm"
                            type={showPassword ? 'text' : 'password'}
                            required
                            onKeyPress={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            {...register("newPasswordConfirm")}
                            className="focus:outline-none h-10 p-2 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <ThemeProvider theme={roundButtonTheme}>
                        <Button variant={"contained"} color={"primary"} type={"submit"}>
                            Submit New Password
                        </Button>
                    </ThemeProvider>
                </div>

            </form>
        </Modal>
    );
};

export const MFAModal = ({onClose, onSuccess, isSetup, user, opened, setIsLoadingMethod}) => {
    const {register, handleSubmit} = useForm();
    const [qrCode, setQrCode] = useState();

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if (isSetup && user) {
                try {
                    const qrCode = await Auth.setupTOTP(user);
                    const generatedQrCode =
                        "otpauth://totp/" + user.username + "?secret=" + qrCode + "&issuer=Cyber%20Crucible";
                    setQrCode(generatedQrCode);
                } catch (error) {
                    console.log(error)
                }
            }
        })()
        return () => controller?.abort();
    }, [isSetup, user]);

    const verifyTOTPCode = async (data) => {
        setIsLoadingMethod(true)
        try {
            const totpCode = data.mfa
            const auth = qrCode
                ? await Auth.verifyTotpToken(user, totpCode)
                : await Auth.confirmSignIn(user, totpCode, "SOFTWARE_TOKEN_MFA");
            if (auth) {
                onSuccess();
            }
        } catch (error) {
            if (error.message) {
                NotificationManager.error(error.message)
            } else {
                NotificationManager.error("Wrong code, please try again.")
            }
            setIsLoadingMethod(false)
        }
    };

    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={isSetup ? "Setup your MFA" : "Enter your MFA"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white ${isSetup ? "max-w-xl" : "max-w-sm"} xl:w-xl lg:w-xl md:w-xl sm:w-xl xs:w-11/12 inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(verifyTOTPCode)}>
                <div className="flex flex-1 flex-col w-full">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between items-center">
                        <h1 className="font-bold text-3xl">{isSetup ? "Setup your MFA" : "Enter your MFA"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                onClose()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    {qrCode && (
                        <div className="w-full flex flex-1 items-center flex-col justify-center">
                            <small className="leading-6">
                                Here are the next steps, to fully secure your Cyber Crucible
                                account: <br />
                                1. Install Google Authenticator on your phone{" "}
                                <a
                                    className="text-color-orange-100"
                                    href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en&oco=1"
                                    target="_blank" rel="noreferrer"
                                >
                                    (instructions here)
                                </a>
                                <br />
                                2. Scan the barcode on this screen. <br />
                                3. Enter the code you see on your phone.
                            </small>
                            <QRCode value={qrCode} className="mt-4 self-center" />
                        </div>
                    )}
                    <div className="my-6 flex justify-center items-center">
                        <input
                            autoComplete="off"
                            maxLength={6}
                            minLength={6}
                            name="mfa"
                            type="text"
                            autoFocus={true}
                            required
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g,'');
                            }}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    if(document.getElementById("submitCodeButton")){
                                        if(e.target && e.target.value && e.target.value.length === 6){
                                            document.getElementById("submitCodeButton").click()
                                        }
                                    }
                                    e.preventDefault();
                                }}}
                            {...register("mfa")}
                            className="text-center focus:outline-none h-10 p-1 xl:w-50 lg:w-50 md:w-50 sm:w-50 xs:w-full max-w-xs mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex justify-center items-center">
                        <ThemeProvider theme={roundButtonTheme}>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                style={{ fontSize: 20, textTransform: "none", padding: "10px 30px" }}
                                id={"submitCodeButton"}
                                type={"submit"}
                            >
                                Submit Code
                            </Button>
                        </ThemeProvider>
                    </div>

                </div>

            </form>
        </Modal>
    );
};

export const AcceptEulaModal = ({ opened, onClose, onAccept, infoOnly }) => (
    <Modal
        onRequestClose={() => {
            onClose()
        }}
        isOpen={opened}
        contentLabel={"End User License Agreement"}
        className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white h-5/6 xl:w-3/4 lg:w-3/4 md:w-11/12 sm:w-11/12 xs:w-11/12 inset-y-10 mx-auto rounded-2xl overflow-auto`}
        overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
    >
        <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 pb-8">
            {/*Title with exit button*/}
            <div className="flex flex-row justify-center">
                <h1 className="font-bold text-3xl">End User License Agreement</h1>
            </div>
            <hr className="mt-3 h-0.5" />
            {/*Form content*/}
            <div className="flex-1 mb-2">
                <small>{eulaContent}</small>
            </div>
            <div className="pb-8">
            <ThemeProvider theme={roundButtonTheme}>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={() => {
                    if(infoOnly){
                        onClose();
                    }
                    else{
                        onAccept()
                    }
                }}>
                {infoOnly ? "Close" : "Accept"}
            </Button>
            </ThemeProvider>
            </div>
        </div>
    </Modal>
);

export const UserInfoModal = ({ onClose, onSuccess, awsUser, opened, onError }) => {
    const { register, handleSubmit} = useForm();
    const updateUserInAws = async (data) => {
        const firstName = data.firstName;
        const lastName = data.lastName;
        const companyName = data.companyName;
        if(firstName.trim().length > 0 && lastName.trim().length > 0){
            //TODO: Make this confirm into a modal, use the <ConfirmationModal>
            let confirmResponse = window.confirm(`You are about to update your account info, would you like to continue?`);
            if(confirmResponse === true) {
                try{
                    await Auth.updateUserAttributes(awsUser, {
                        name: `${firstName.trim()} ${lastName.trim()}`,
                        given_name: firstName.trim(),
                        family_name: lastName.trim()
                    }).then(async () => {
                        //update in mongo
                        await updateAccountInfoReactive(firstName.trim(), lastName.trim(), companyName);
                        onSuccess();
                    }).catch((error) => {
                        onError();
                    })
                } catch (error) {
                    onError();
                }
            }
        }
    }
    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Update Account Info Before Continuing"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-md lg:w-md md:w-md sm:w-md xs:w-11/12 max-w-md inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(updateUserInAws)}>
                <div className="flex flex-1 flex-col w-full">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-center">
                        <h1 className="font-bold text-3xl">Update Account Info Before Continuing</h1>
                    </div>
                    <hr className="mt-3 h-0.5" />
                    {/*Form content*/}
                    <div className="ml-1 mt-5 w-full">
                        <small>First Name</small>
                        <br />
                        <input
                            autoComplete="off"
                            name="firstName"
                            type="text"
                            required
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("firstName")}
                            className="focus:outline-none h-10 p-1 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="ml-1 mt-5 w-full">
                        <small>Last Name</small>
                        <br />
                        <input
                            autoComplete="off"
                            name="lastName"
                            type="text"
                            required
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("lastName")}
                            className="focus:outline-none h-10 p-1 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="ml-1 mt-5 w-full">
                        <small>Company Name (Optional)</small>
                        <br />
                        <input
                            autoComplete="off"
                            name="companyName"
                            type="text"
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("companyName")}
                            className="focus:outline-none h-10 p-1 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="flex flex-col mt-5 w-full">

                    <ThemeProvider theme={roundButtonTheme}>
                    <Button type={"submit"} variant={"contained"} color={"primary"}>
                        Update Account
                    </Button>
                    </ThemeProvider>
                    </div>
                </div>

            </form>
        </Modal>
    );
};

export const UserTimeZoneSetupModal = ({ onClose, onSuccess, opened, setIsLoading }) => {
    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Update Default TimeZone Setting Before Continuing"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-1 flex-col p-5 w-full ml-4 mr-4">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-center">
                    <h1 className="font-bold text-3xl">Update Default TimeZone Setting Before Continuing</h1>
                </div>
                <hr className="mt-3 h-0.5" />
                {/*Form content*/}
                <div className="m-5 flex flex-col gap-y-3">
                    <label>Change Default Timezone Setting</label>
                    <TimezonePicker
                        absolute={false}
                        placeholder="Select timezone..."
                        className="max-w-md w-full rounded-sm border border-black border-opacity-5 border-solid"
                        onChange={async (value) => {
                            if(value){
                                try{
                                    setIsLoading(true)
                                    await changeUserTimeZoneReactive(value)
                                    NotificationManager.success("Default Timezone successfully updated");
                                    encryptAndStoreSessionVariable("timezone", value)
                                    onSuccess()
                                }
                                catch(error){
                                    NotificationManager.error("Error updating your default timezone setting");
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export const GroupCollectAgentTelemetrySetupModal = ({ onClose, onSuccess, opened, setIsLoading, groupsToInitialize, onError }) => {
    const [approveAllChecked, setApproveAllChecked] = useState(true);
    const [groupsChecked, setGroupsChecked] = useState([]);

    useEffect(() =>{
        let controller = new AbortController();
        (async () => {
            let defaultState = []
            groupsToInitialize.forEach(zenGroup => {
                defaultState.push(zenGroup.zenGroupId)
            })
            setGroupsChecked(defaultState)
        })()
        return () => controller?.abort();
    }, [groupsToInitialize]);

    async function submit(){
        let groupIdsOffList = []
        //Gather group ids that we are going to turn this setting off for, we know which ones are on because of the groupsChecked list
        groupsToInitialize.forEach(group => {
            if(!groupsChecked.includes(group.zenGroupId)){
                //if the groups checked does not include the group.zenGroupId then we are turning this setting off for this group, add its id to groupIdsOffList
                groupIdsOffList.push(group.zenGroupId)
            }
        })

        setIsLoading(true)
        try{
            await initializeGroupsAgentTelemetrySettingReactive(groupsChecked, groupIdsOffList)
            setIsLoading(false)
            onSuccess()
        }
        catch (error){
            setIsLoading(false)
            onError()
        }
    }

    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Initialize Group Settings for Collecting Agent Telemetry Before Continuing"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-1 flex-col p-5 w-full ml-4 mr-4 gap-y-3">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-between">
                    <h1 className="font-bold text-3xl text-center"> (Optional) Additional Telemetry Collection </h1>
                    <div>
                        <MuiIconWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    className="ml-1 object-contain"
                                    icon="fa-light fa-circle-info"
                                    size="2xl"
                                />
                            }
                            tooltipTitle={"We have created a new group setting to enable additional telemetry collection that is useful for identifying possible new types of attacks or program behaviors."}
                            tooltipPlacement={"bottom-start"}
                        />
                    </div>
                </div>
                <hr className="h-0.5" />
                {/*Form content*/}
                <div className={`flex flex-row items-center ml-1 mb-1`}>
                    <ThemeProvider theme = {switchTheme}>
                        <FormControlLabel control={
                            <Switch
                                checked={approveAllChecked}
                                name="selectAllGroupsToggle"
                                onChange={e => {
                                    setApproveAllChecked(e.target.checked)
                                    if(e.target.checked){
                                        //set all toggles to true/on
                                        let allGroupIds = []
                                        groupsToInitialize.forEach(group => {
                                            allGroupIds.push(group.zenGroupId)
                                        })
                                        setGroupsChecked(allGroupIds)
                                    }
                                    else{
                                        //set all toggles to false/off
                                        setGroupsChecked([])
                                    }
                                }}
                            />
                        } label={approveAllChecked ? "Approve All" : "Decline All"}/>
                    </ThemeProvider>
                </div>
                <hr className="h-0.5" />
                {groupsToInitialize && groupsToInitialize.map((group) => {
                    return (
                    <div key={group.zenGroupId} className={`flex flex-row items-center ml-1 mb-1`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    value={group.zenGroupId}
                                    checked={groupsChecked.includes(group.zenGroupId)} //default to true
                                    name={`initializeToggle${group.zenGroupId}`}
                                    onChange={(e) => {
                                        let newSetting = e.target.checked
                                        if(newSetting){
                                            //should be checked
                                            if(!groupsChecked.includes(group.zenGroupId)){
                                                setGroupsChecked(groupCheckedBefore => [...groupCheckedBefore, group.zenGroupId])
                                            }
                                        }
                                        else{
                                            //should not be checked
                                            setGroupsChecked((current) =>
                                                current.filter((groupId) => groupId !== group.zenGroupId)
                                            );
                                        }
                                    }}
                                />
                            } label={group.name}/>
                        </ThemeProvider>
                    </div>
                    );
                })}

                <ThemeProvider theme={buttonTheme}>
                    <Button variant={"contained"} color={"primary"} type={"submit"} onClick={submit}>
                        Submit
                    </Button>
                </ThemeProvider>
            </div>
        </Modal>
    );
};



export const ModuleTitle = ({
                                title,
                                borderless = true,
                                color = "blue",
                            }) => (
    <div
        className="flex border-l-0 text-left text-2xl"
    >
        {title}
    </div>
);

export const Paragraph = ({ children, align = "left", mb="0" }) => (
    <div className={`text-${align} mb-${mb} font-slab font-light text-xl leading-10`}>{children}</div>
);


export const eulaContent = (
    <div className="flex flex-col items-start mt-6">
        <Paragraph>
            This copy of Cyber Crucible ("the Software Product") and accompanying
            documentation is licensed and not sold. This Software Product is protected
            by copyright laws and treaties, as well as laws and treaties related to
            other forms of intellectual property. Cyber Crucible, Inc. or its
            subsidiaries, affiliates, and suppliers (collectively "Cyber Crucible")
            own intellectual property rights in the Software Product. The Licensee's
            ("you" or "your") license to download, use, copy, or change the Software
            Product is subject to these rights and to all the terms and conditions of
            this End User License Agreement ("Agreement").
        </Paragraph>
        <ModuleTitle title="Acceptance" />
        <Paragraph>
            YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY
            SELECTING THE "ACCEPT" OPTION AND DOWNLOADING THE SOFTWARE PRODUCT OR BY
            INSTALLING, USING, OR COPYING THE SOFTWARE PRODUCT. YOU MUST AGREE TO ALL
            OF THE TERMS OF THIS AGREEMENT BEFORE YOU WILL BE ALLOWED TO DOWNLOAD THE
            SOFTWARE PRODUCT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS
            AGREEMENT, YOU MUST SELECT "DECLINE" AND YOU MUST NOT INSTALL, USE, OR
            COPY THE SOFTWARE PRODUCT.
        </Paragraph>
        <ModuleTitle title="License Grant" />
        <Paragraph>
            This Agreement entitles you to install and use one copy of the Software
            Product. In addition, you may make one archival copy of the Software
            Product. The archival copy must be on a storage medium other than a hard
            drive, and may only be used for the reinstallation of the Software
            Product. This Agreement does not permit the installation or use of
            multiple copies of the Software Product, or the installation of the
            Software Product on more than one computer at any given time, on a system
            that allows shared used of applications, on a multi-user network, or on
            any configuration or system of computers that allows multiple users.
            Multiple copy use or installation is only allowed if you obtain an
            appropriate licensing agreement for each user and each copy of the
            Software Product. For further information regarding multiple copy
            licensing of the Software Product, please contact:
            <br />
            <br />
            Representative: CEO Address: 550M Ritchie Hwy #135 Severna Park, Maryland
            21146 Phone Number: 410.216.0369 E-mail Address: support@cybercrucible.com
        </Paragraph>
        <ModuleTitle title="Restrictions on Transfer" />
        <Paragraph>
            Without first obtaining the express written consent of Cyber Crucible, you
            may not assign your rights and obligations under this Agreement, or
            redistribute, encumber, sell, rent, lease, sublicense, or otherwise
            transfer your rights to the Software Product.
        </Paragraph>
        <ModuleTitle title="Restrictions on Use" />
        <Paragraph>
            You may not use, copy, or install the Software Product on any system with
            more than one computer, or permit the use, copying, or installation of the
            Software Product by more than one user or on more than one computer. If
            you hold multiple, validly licensed copies, you may not use, copy, or
            install the Software Product on any system with more than the number of
            computers permitted by license, or permit the use, copying, or
            installation by more users, or on more computers than the number permitted
            by license.
            <br />
            <br />
            You may not decompile, "reverse-engineer", disassemble, or otherwise
            attempt to derive the source code for the Software Product.
            <br />
            <br />
            You may not use the database portion of the Software Product in connection
            with any software other than the Software Product.
        </Paragraph>
        <ModuleTitle title="Restrictions on Alteration" />
        <Paragraph>
            You may not modify the Software Product or create any derivative work of
            the Software Product or its accompanying documentation. Derivative works
            include but are not limited to translations. You may not alter any files
            or libraries in any portion of the Software Product. You may not reproduce
            the database portion or create any tables or reports relating to the
            database portion.
        </Paragraph>
        <ModuleTitle title="Restrictions on Copying" />
        <Paragraph>
            You may not copy any part of the Software Product except to the extent
            that licensed use inherently demands the creation of a temporary copy
            stored in computer memory and not permanently affixed on storage medium.
            You may make one archival copy which must be stored on a medium other than
            a computer hard drive.
        </Paragraph>
        <ModuleTitle title="Disclaimer of Warranties and Limitation of Liability" />
        <Paragraph>
            UNLESS OTHERWISE EXPLICITLY AGREED TO IN WRITING BY CYBER CRUCIBLE, CYBER
            CRUCIBLE MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, IN FACT OR IN LAW,
            INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY
            OR FITNESS FOR A PARTICULAR PURPOSE OTHER THAN AS SET FORTH IN THIS
            AGREEMENT OR IN THE LIMITED WARRANTY DOCUMENTS PROVIDED WITH THE SOFTWARE
            PRODUCT.
            <br />
            <br />
            Cyber Crucible makes no warranty that the Software Product will meet your
            requirements or operate under your specific conditions of use. Cyber
            Crucible makes no warranty that operation of the Software Product will be
            secure, error free, or free from interruption. YOU MUST DETERMINE WHETHER
            THE SOFTWARE PRODUCT SUFFICIENTLY MEETS YOUR REQUIREMENTS FOR SECURITY AND
            UNINTERRUPTABILITY. YOU BEAR SOLE RESPONSIBILITY AND ALL LIABILITY FOR ANY
            LOSS INCURRED DUE TO FAILURE OF THE SOFTWARE PRODUCT TO MEET YOUR
            REQUIREMENTS. CYBER CRUCIBLE WILL NOT, UNDER ANY CIRCUMSTANCES, BE
            RESPONSIBLE OR LIABLE FOR THE LOSS OF DATA ON ANY COMPUTER OR INFORMATION
            STORAGE DEVICE.
            <br />
            <br />
            UNDER NO CIRCUMSTANCES SHALL CYBER CRUCIBLE, ITS DIRECTORS, OFFICERS,
            EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY OTHER PARTY FOR INDIRECT,
            CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY
            KIND (INCLUDING LOST REVENUES OR PROFITS OR LOSS OF BUSINESS) RESULTING
            FROM THIS AGREEMENT, OR FROM THE FURNISHING, PERFORMANCE, INSTALLATION, OR
            USE OF THE SOFTWARE PRODUCT, WHETHER DUE TO A BREACH OF CONTRACT, BREACH
            OF WARRANTY, OR THE NEGLIGENCE OF CYBER CRUCIBLE OR ANY OTHER PARTY, EVEN
            IF CYBER CRUCIBLE IS ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH
            DAMAGES. TO THE EXTENT THAT THE APPLICABLE JURISDICTION LIMITS CYBER
            CRUCIBLE'S ABILITY TO DISCLAIM ANY IMPLIED WARRANTIES, THIS DISCLAIMER
            SHALL BE EFFECTIVE TO THE MAXIMUM EXTENT PERMITTED.
        </Paragraph>
        <ModuleTitle title="Limitation of Remedies and Damages" />
        <Paragraph>
            Your remedy for a breach of this Agreement or of any warranty included in
            this Agreement is the correction or replacement of the Software Product.
            Selection of whether to correct or replace shall be solely at the
            discretion of Cyber Crucible. Cyber Crucible reserves the right to
            substitute a functionally equivalent copy of the Software Product as a
            replacement. If Cyber Crucible is unable to provide a replacement or
            substitute Software Product or corrections to the Software Product, your
            sole alternate remedy shall be a refund of the purchase price for the
            Software Product exclusive of any costs for shipping and handling.
            <br />
            <br />
            Any claim must be made within the applicable warranty period. All
            warranties cover only defects arising under normal use and do not include
            malfunctions or failure resulting from misuse, abuse, neglect, alteration,
            problems with electrical power, acts of nature, unusual temperatures or
            humidity, improper installation, or damage determined by Cyber Crucible to
            have been caused by you. All limited warranties on the Software Product
            are granted only to you and are non-transferable. You agree to indemnify
            and hold Cyber Crucible harmless from all claims, judgments, liabilities,
            expenses, or costs arising from your breach of this Agreement and/or acts
            or omissions.
        </Paragraph>
        <ModuleTitle title="Governing Law, Jurisdiction and Costs" />
        <Paragraph>
            This Agreement is governed by the laws of Delaware, without regard to
            Delaware's conflict or choice of law provisions.
        </Paragraph>
        <ModuleTitle title="Severability" />
        <Paragraph>
            If any provision of this Agreement shall be held to be invalid or
            unenforceable, the remainder of this Agreement shall remain in full force
            and effect. To the extent any express or implied restrictions are not
            permitted by applicable laws, these express or implied restrictions shall
            remain in force and effect to the maximum extent permitted by such
            applicable laws.
        </Paragraph>
    </div>
);
