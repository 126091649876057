import NotificationManager from "react-notifications/lib/NotificationManager";
import {
    changeAgentNameReactive,
    editAgentManagedUpdateLatestAgentVersionApprovedSettingReactive,
    singleChangeAgentGroupReactive
} from "../pages/api/agentsApi";
import {changeLicenseGroupReactive, changeLicenseNameReactive} from "../pages/api/licensesApi";
import {findZenGroupByFriendlyName, updateGroupNameInSession} from "./zenGroupSessionStorageManager";
import {changeIncidentNameReactive} from "../pages/api/incidentsApi";
import {changeZenGroupGroupNameReactive} from "../pages/api/groupsApi";
import {updateObjectInSessionStorage} from "./clientSideDataSessionStorageHelper";
import {changeBrowserDataIncidentNameReactive} from "../pages/api/browserDataIncidentsApi";

/*
    Assumes the agent name colId is 'agentDisplayName', the zenGroup id colId is 'zenGroupId', and the
    agent id colId is 'agentId'
 */

//handles deciding to change group name or change object's group, then calls to those individual helper functions
function handleGroupColumnEditedForBothOptions(event, changeGroupFunction, objectId, sessionStorageVariableName=null, idKey=null, idValue=null){
    //Refer to the custom attribute called useChangeGroupNameEditor to see what code to run: change group name or change the object's group
    if(event.data.useChangeGroupNameEditor === true){
        //change the group name
        handleGroupColumnChangeNameOnly(event)
    }
    else{
        //change the object's group
        handleGroupColumnChangeObjectsGroupOnly(event, changeGroupFunction, objectId, sessionStorageVariableName, idKey, idValue)
    }
}

function handleGroupColumnChangeObjectsGroupOnly(event, changeGroupFunction, objectId, sessionStorageVariableName=null, idKey=null, idValue=null){
    let gridApi = event.api
    //else use logic to change the object's group
    if(changeGroupFunction && objectId){
        let newGroup = findZenGroupByFriendlyName(event.newValue)
        if(newGroup && newGroup.id){
            changeGroupFunction(newGroup.id, objectId).then(() => {
                NotificationManager.success(
                    "Group successfully updated"
                );
                event.node.setDataValue("zenGroupId",newGroup.id);
                event.node.setDataValue("zenGroupDisplayName",newGroup.friendlyName);
                if(sessionStorageVariableName && idKey && idValue){
                    //if these fields are not null, then update zenGroupId in session
                    updateObjectInSessionStorage(sessionStorageVariableName, idKey, idValue, "zenGroupId", newGroup.id)
                }
            }).catch(function(error){
                if(error.message) {
                    NotificationManager.error("Group change failed due to "+error.message);
                }else{
                    NotificationManager.error("Group change failed");
                }
                event.node.data.zenGroupDisplayName = event.oldValue;
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
            })
        }
        else{
            //Didn't find group
            event.node.data.zenGroupDisplayName = event.oldValue;
            gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
        }
    }
}

export function handleGroupColumnChangeNameOnly(event){
    //change the group name
    let gridApi = event.api
    if(event.newValue === event.oldValue){
        event.node.data.zenGroupDisplayName = event.oldValue;
        gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
        return;
    }
    if(!event.data.zenGroupId || !event.newValue || event.newValue.trim().length < 1){
        event.node.data.zenGroupDisplayName = event.oldValue;
        gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
        return;
    }

    changeZenGroupGroupNameReactive(event.newValue.trim(), event.data.zenGroupId).then(() => {
        //success
        NotificationManager.success("Successfully changed this group's name")
        //We have to update the group name in session storage for the correct name to appear in value formatter, or else it will use the old name since the group in
        // session storage is not updated
        updateGroupNameInSession(event.data.zenGroupId, event.newValue.trim())
        //we should refresh the filter so the filter has the new name as well, it pulls groups from the session storage so it should get the new group name after refreshing
        let zenGroupFilter = gridApi.getFilterInstance('zenGroupDisplayName');
        if(zenGroupFilter){
            zenGroupFilter.refreshFilterValues();
        }
        //force refresh on the cells to get new group name for all objects in the same group
        gridApi.refreshCells({columns: ["zenGroupDisplayName"], suppressFlash: true, force: true})
    }).catch(function(error){
        if(error.message){
            NotificationManager.error(error.message);
        }
        else{
            NotificationManager.error("Error changing group name")
        }
        event.node.data.zenGroupDisplayName = event.oldValue;
        gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
    })
}

export function agentDisplayNameOnlyCellEditingStopped(event){
    let gridApi = event.api
    if(event.column.colId === "agentDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.zenGroupId || !event.data.agentId){
                NotificationManager.error("Error updating agent name")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                if(response){
                    NotificationManager.success("Successfully changed this agent's name")
                    event.node.setDataValue("agentDisplayName", event.newValue.trim());
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing agent name")
                }
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            event.data.agentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
        }
    }
}

export function agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped(event){
    let gridApi = event.api
    if(event.column.colId === "agentDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.zenGroupId || !event.data.agentId){
                NotificationManager.error("Error updating agent name")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                if(response){
                    NotificationManager.success("Successfully changed this agent's name")
                    event.node.setDataValue("agentDisplayName", event.newValue.trim());
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing agent name")
                }
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            event.data.agentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
        }
    }
    else if(event.column.colId === "zenGroupDisplayName"){
        handleGroupColumnChangeNameOnly(event)
    }
}

export function agentPageCellEditingStopped(event){
    let gridApi = event.api
    if(event.column.colId === "agentDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.zenGroupId || !event.data.agentId){
                NotificationManager.error("Error updating agent name")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                if(response){
                    NotificationManager.success("Successfully changed this agent's name")
                    event.node.setDataValue("agentDisplayName", event.newValue.trim());
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing agent name")
                }
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            //NotificationManager.error("Agent Names Cannot Be Blank")
            event.data.agentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
        }
    }
    else if(event.column.colId === "licenseDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.licenseDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.licenseId){
                NotificationManager.error("This agent is not assigned a license")
                event.data.licenseDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.zenGroupId){
                NotificationManager.error("Error updating license name")
                event.data.licenseDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeLicenseNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.licenseId).then(function(){
                NotificationManager.success("Successfully changed this license's name")
                event.node.setDataValue("licenseDisplayName", event.newValue.trim());

            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing license name")
                }
                event.data.licenseDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            //NotificationManager.error("License names cannot be empty");
            event.data.licenseDisplayName = event.oldValue;
            gridApi.refreshCells({suppressFlash: true})
        }
    }
    else if(event.column.colId === "zenGroupDisplayName"){
        if(event.newValue === undefined || event.newValue === null || event.newValue === event.oldValue){
            event.data.zenGroupDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
            return;
        }
        handleGroupColumnEditedForBothOptions(event, singleChangeAgentGroupReactive, event.data.agentId)
        /*event.api.getFilterInstance(
            event.column.colId,
            function(filter){
                //console.log(filter.valueModel.availableValues);
                if (filter.valueModel.availableValues.size > 0) {
                    let filterValuesArray = Array.from(filter.valueModel.availableValues);
                    filterValuesArray.forEach(function(zenGroup, index) {
                        this[index] = findZenGroupByFriendlyName(zenGroup);
                        if(this[index] && this[index].friendlyName === event.newValue){
                            changeAgentGroup(this[index].id,event.data.agentId).then(() => {
                                NotificationManager.success(
                                    "Group successfully updated for "+event.data.agentDisplayName
                                );
                                // console.log("old zenGroupId:"+event.data.zenGroupId);
                                // console.log("new zenGroupId:"+this[index].id);
                                event.node.setDataValue("zenGroupId",this[index].id);
                                event.node.setDataValue("zenGroupDisplayName",this[index].friendlyName);
                                //event.data.zenGroupId = this[index].id;
                                //event.data.zenGroupId = this[index].id;
                                //console.log("about to refresh cells for changeAgentGroup response");
                                //gridApi.refreshCells({suppressFlash: false})
                            }).catch(function(error){
                                if(error.message) {
                                    NotificationManager.error("Group change failed due to "+error.message);
                                }else{
                                    NotificationManager.error("Group change failed for agent");
                                }
                                event.data.zenGroupDisplayName = event.oldValue;
                                gridApi.refreshCells({suppressFlash: true})
                            })
                        }
                    },filterValuesArray);
                } else {
                    console.log("ZenGroup filter has 0 groups to pull from");
                    event.data.zenGroupDisplayName = event.oldValue;
                    gridApi.refreshCells({suppressFlash: true})
                }
            }
        )*/
    }
    else if(event.column.colId === "latestAgentVersionApproved"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.latestAgentVersionApproved = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            if(!event.data.agentId){
                NotificationManager.error("Error updating this agent's latest agent version approved for updates")
                event.data.latestAgentVersionApproved = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            if(event.node.data.updatePolicy === "Following Group Policy"){
                //should not have gotten through to be able to toggle in this instance since we disable in this case, but double check
                event.data.latestAgentVersionApproved = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            editAgentManagedUpdateLatestAgentVersionApprovedSettingReactive(event.data.agentId, event.newValue.trim()).then(function(response){
                NotificationManager.success("Successfully changed this agent's latest agent version approved for updates")
                event.node.setDataValue("latestAgentVersionApproved", event.newValue.trim());
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error updating this agent's latest agent version approved for updates")
                }
                event.data.latestAgentVersionApproved = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            })
        }
        else{
            //NotificationManager.error("Agent Names Cannot Be Blank")
            event.data.latestAgentVersionApproved = event.oldValue
            gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
        }
    }
    else{
        //console.log(event.column.colId);
    }
}

export function licensePageCellEditingStopped(event){
    let gridApi = event.api
    if(event.column.colId === "agentDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            if(!event.data.agentId){ //check to see if there is an agent associated with the license first.
                NotificationManager.error("There is no agent associated with this license yet.")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            if(!event.data.zenGroupId){
                NotificationManager.error("Error updating agent name")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                NotificationManager.success("Successfully changed this agent's name")
                event.node.setDataValue("agentDisplayName", event.newValue.trim());
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing agent name")
                }
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
            })
        }
        else{
            //NotificationManager.error("Agent Names Cannot Be Blank")
            event.data.agentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
        }
    }
    else if(event.column.colId === "licenseName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.licenseName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            if(!event.data.licenseId || !event.data.zenGroupId){
                NotificationManager.error("Error updating license name")
                event.data.licenseName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
                return;
            }
            changeLicenseNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.licenseId).then(function(){
                NotificationManager.success("Successfully changed this license's name")
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing license name")
                }
                event.data.licenseName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
            })
        }
        else{
            event.data.licenseName = event.oldValue;
            gridApi.refreshCells({suppressFlash: true, force: true, rowNodes: [event.node]})
        }
    }
    else if(event.column.colId === "zenGroupDisplayName"){
        if(event.newValue === event.oldValue){
            event.data.zenGroupDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
            return;
        }
        handleGroupColumnEditedForBothOptions(event, changeLicenseGroupReactive, event.data.licenseId)
    }
    else{
        //console.log(event.column.colId);
    }
}

export function zenGroupOnlyCellEditingStopped(event, changeGroupFunction, objectId, sessionStorageVariableName=null, idKey=null, idValue=null){
    let gridApi = event.api
    if(event.column.colId === "zenGroupDisplayName"){
        if(event.newValue === event.oldValue){
            event.data.zenGroupDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
            return;
        }

        //let handleGroupColumnEdited handle the rest of the code for editing zenGroupDisplayName column (change group name vs change object's group)
        handleGroupColumnEditedForBothOptions(event, changeGroupFunction, objectId, sessionStorageVariableName, idKey, idValue)
    }
}


export function WhiteListCellEditingStopped(event, changeNameFunction, fieldName){
    let gridApi = event.api
    if(event.column.colId === fieldName){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data[fieldName] = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            if(!event.data.whitelistId){
                NotificationManager.error("Error updating tailored behavior name")
                event.data[fieldName] = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            changeNameFunction(event.data.whitelistId, event.newValue.trim())
                .then(function(){
                    NotificationManager.success("Successfully changed tailored behavior name")
                    event.node.setDataValue(fieldName, event.newValue.trim());
                }).catch(function(error){
                if (error.message){
                    NotificationManager.error(error.message)
                } else {
                    NotificationManager.error("Error changing the tailored behavior name")
                }
                event.data[fieldName] = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            })
        }
        else{
            event.data[fieldName] = event.oldValue
            gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
        }
    }

}

export function SilentIncidentCellEditingStopped(event, changeNameFunction, fieldName){
    let gridApi = event.api
    if(event.column.colId === fieldName){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data[fieldName] = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            if(!event.data.id){
                NotificationManager.error("Error updating name")
                event.data[fieldName] = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                return;
            }
            changeNameFunction(event.data.id, event.newValue.trim())
                .then(function(){
                    NotificationManager.success("Successfully changed name")
                    event.node.setDataValue(fieldName, event.newValue.trim());
                    /*updateObjectInSessionStorage("silentIncidentsGridList", "id",
                        event.data.id, fieldName, event.newValue.trim())*/
                }).catch(function(error){
                if (error.message){
                    NotificationManager.error(error.message)
                } else {
                    NotificationManager.error("Error changing the name")
                }
                event.data[fieldName] = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            })
        }
        else{
            event.data[fieldName] = event.oldValue
            gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
        }
    }
}

export function incidentPageCellEditingStopped(event){
    let gridApi = event.api
    //console.log(event.column.colId)
    if(event.column.colId === "incidentDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.incidentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.incidentId || !event.data.zenGroupId){
                NotificationManager.error("Error updating incident name")
                event.data.incidentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeIncidentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.incidentId).then(function(response){
                if(response){
                    NotificationManager.success("Successfully changed this incident's name")
                    event.node.setDataValue("incidentDisplayName", event.newValue.trim());
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing incident name")
                }
                event.data.incidentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            event.data.incidentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
        }
    } else if(event.column.colId === "zenGroupDisplayName"){
        handleGroupColumnChangeNameOnly(event)
    } else if(event.column.colId === "agentDisplayName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.agentId){ //check to see if there is an agent associated with the license first.
                NotificationManager.error("There is no agent associated with this incident.")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.zenGroupId){
                NotificationManager.error("There is no zenGroup associated with this agent.")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                NotificationManager.success("Successfully changed this agent's name")
                event.node.setDataValue("agentDisplayName", event.newValue.trim());
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing agent name")
                }
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            //NotificationManager.error("Agent Names Cannot Be Blank")
            event.data.agentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
        }
    }else if(event.column.colId === "whitelistName"){
        if(event.newValue && event.newValue.trim().length > 0){
            if(event.newValue === event.oldValue){
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.agentId){ //check to see if there is an agent associated with the license first.
                NotificationManager.error("There is no agent associated with this incident.")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            if(!event.data.zenGroupId){
                NotificationManager.error("There is no zenGroup associated with this agent.")
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
                return;
            }
            changeAgentNameReactive(event.newValue.trim(), event.data.zenGroupId, event.data.agentId).then(function(response){
                NotificationManager.success("Successfully changed this agent's name")
                event.node.setDataValue("agentDisplayName", event.newValue.trim());
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Error changing agent name")
                }
                event.data.agentDisplayName = event.oldValue
                gridApi.refreshCells({suppressFlash: true})
            })
        }
        else{
            //NotificationManager.error("Agent Names Cannot Be Blank")
            event.data.agentDisplayName = event.oldValue
            gridApi.refreshCells({suppressFlash: true})
        }
    }
}