import axios from "../../utils/axiosInstance";

export const getSessionExpirationsReactive = async () => {
    const response = await axios.post("/getSessionExpirationsReactive", {
    });
    return response.data;
};

export const changeSessionExpirationNameReactive = async (newUserSetFriendlyName, id) => {
    return await axios.post("/changeSessionExpirationNameReactive", {
        "newUserSetFriendlyName": newUserSetFriendlyName,
        "id": id
    });
};

export const changeSessionExpirationDurationReactive = async (duration, id) => {
    return await axios.post("/changeSessionExpirationDurationReactive", {
        "duration": duration,
        "id": id
    });
};

export const findBySessionExpirationIdListReactive = async (ids) => {
    const response = await axios.post("/findBySessionExpirationIdListReactive",{
        ids
    })
    return response.data
};

export const getShortestSessionExpirationReactive = async () => {
    const response = await axios.post("/getShortestSessionExpirationReactive",{})
    return response.data
};

export const getSessionDurationsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getSessionDurationsGridFilterStateReactive", {});
    return response.data;
};

export const updateSessionDurationsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateSessionDurationsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateSessionDurationsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateSessionDurationsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getSessionDurationsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getSessionDurationsGridColumnStateReactive", {});
    return response.data;
};
export const updateSessionDurationsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateSessionDurationsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateSessionDurationsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateSessionDurationsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const updateSessionDurationsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateSessionDurationsGridColumnModeReactive", {
        columnMode: columnMode
    });
};