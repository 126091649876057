import {getAgentsGridColumnStateReactive, getAgentsGridFilterStateReactive} from "../pages/api/agentsApi";
import {getDealsGridColumnStateReactive, getDealsGridFilterStateReactive} from "../pages/api/dealsApi";
import {getDecryptorsGridColumnStateReactive, getDecryptorsGridFilterStateReactive} from "../pages/api/decryptorsApi";
import {getGroupsGridColumnStateReactive, getGroupsGridFilterStateReactive} from "../pages/api/groupsApi";
import {getIncidentsGridColumnStateReactive, getIncidentsGridFilterStateReactive} from "../pages/api/incidentsApi";
import {
    getBrowserDataIncidentsGridColumnStateReactive,
    getBrowserDataIncidentsGridFilterStateReactive
} from "../pages/api/browserDataIncidentsApi";
import {getLicensesGridColumnStateReactive, getLicensesGridFilterStateReactive} from "../pages/api/licensesApi";
import {
    getSecurityNotificationsGridColumnStateReactive,
    getSecurityNotificationsGridFilterStateReactive
} from "../pages/api/notificationsApi";
import {
    getProcessCreationsGridColumnStateReactive,
    getProcessCreationsGridFilterStateReactive
} from "../pages/api/processCreationsApi";
import {
    getProcessInjectionsGridColumnStateReactive,
    getProcessInjectionsGridFilterStateReactive
} from "../pages/api/processInjectionsApi";
import {getRolesGridColumnStateReactive, getRolesGridFilterStateReactive} from "../pages/api/rolesApi";
import {
    getSalesNotificationsGridColumnStateReactive,
    getSalesNotificationsGridFilterStateReactive
} from "../pages/api/salesNotificationsApi";
import {
    getSessionDurationsGridColumnStateReactive,
    getSessionDurationsGridFilterStateReactive
} from "../pages/api/sessionExpirationApi";
import {getTasksGridColumnStateReactive, getTasksGridFilterStateReactive} from "../pages/api/tasksApi";
import {getWhitelistsGridColumnStateReactive, getWhitelistsGridFilterStateReactive} from "../pages/api/tailoredBehaviorsApi";
import {
    decryptAndGetSessionVariable,
    encryptAndStoreSessionVariable,
    removeItemFromStorage
} from "./storageHelper";
import {
    getSilentIncidentsGridColumnStateReactive,
    getSilentIncidentsGridFilterStateReactive
} from "../pages/api/silentResponsesApi";
import {getDemoModeSetting, getTrainingModeSetting} from "./trainingAndDemoModeHelper";
import {
    getTrainingLicensesGridColumnStateReactive,
    getTrainingLicensesGridFilterStateReactive
} from "../pages/api/trainingLicensesApi";
import {customColumnModeText, maxColumnModeText} from "../components/clickToShowButtons";
import {getChartVisibilitySettingsReactive} from "../pages/api/loginApi";
import {
    getDistributorManagementGridColumnStateReactive,
    getDistributorManagementGridFilterStateReactive
} from "../pages/api/distributorGroupManagementApi";
import {
    agentChart30DayValue,
    agentChart60DayValue,
    agentChart90DayValue,
    agentChartAllAgentsValue
} from "../pages/private/agents";
import {
    getCustomerDeploymentHealthGridColumnStateReactive,
    getCustomerDeploymentHealthGridFilterStateReactive
} from "../pages/api/customerDeploymentHealthApi";
//since the agents page is the page that users go to after successful login, we should call to the api for the agent filter and column states first before other pages.
export function getAgentsGridFilterAndColumnStateAndStoreInSession(){
    getAgentsGridFilterStateReactive().then(response => {
        encryptAndStoreSessionVariable("agentsGridFilterState", JSON.stringify(response));
    }).catch(error => {
        console.log(error);
    })
    getAgentsGridColumnStateReactive().then(response => {
        encryptAndStoreSessionVariable("agentsGridColumnState", JSON.stringify(response));
    }).catch(error => {
        console.log(error);
    })
}
export const agentChartLastActiveDaysSessionVariable = "agentChartLastActiveDays"
export const agentChartVisibleSessionVariable = "agentChartVisible"
export const whitelistChartVisibleSessionVariable = "whitelistChartVisible"
export const dataIncidentChartVisibleSessionVariable = "dataIncidentChartVisible"
export const identityIncidentChartVisibleSessionVariable = "identityIncidentChartVisible"
export const agentLicenseChartVisibleSessionVariable = "agentLicenseChartVisible"
export const partnerDealsPageVolumePricingTableVisibleSessionVariable = "partnerDealsPageVolumePricingTableVisible"
export const showGroupInstallerConfigIconToggledSessionVariable = "showGroupInstallerConfigIconToggled"
export const showAllInjectionsTelemetryVariable = "showAllInjectionsTelemetry"
export const showAllCreationsTelemetryVariable = "showAllCreationsTelemetry"
export const showAllIdentityIncidentsTelemetryVariable = "showAllIdentityIncidentsTelemetry"
export const filterExpiredLicensesByDefaultVariable = "filterExpiredLicensesByDefault"
export function getAllChartVisibilitySettingsAndStoreInSession(){
    getChartVisibilitySettingsReactive().then(response => {
        let agentChartLastActiveDays = response.agentChartLastActiveDays
        let agentChartVisible = response.agentChartVisible
        let whitelistChartVisible = response.whitelistChartVisible
        let dataIncidentChartVisible = response.dataIncidentChartVisible
        let identityIncidentChartVisible = response.identityIncidentChartVisible
        let agentLicenseChartVisible = response.agentLicenseChartVisible
        let partnerDealsPageVolumePricingTableVisible = response.partnerDealsPageVolumePricingTableVisible
        let showGroupInstallerConfigIconToggled = response.showGroupInstallerConfigIconToggled
        let showAllInjectionsTelemetry = response.showAllInjectionsTelemetry
        let showAllCreationsTelemetry = response.showAllCreationsTelemetry
        let showAllIdentityIncidentsTelemetry = response.showAllIdentityIncidentsTelemetry
        let filterExpiredLicensesByDefault = response.filterExpiredLicensesByDefault
        encryptAndStoreSessionVariable(agentChartLastActiveDaysSessionVariable, JSON.stringify(agentChartLastActiveDays));
        encryptAndStoreSessionVariable(agentChartVisibleSessionVariable, JSON.stringify(agentChartVisible));
        encryptAndStoreSessionVariable(whitelistChartVisibleSessionVariable, JSON.stringify(whitelistChartVisible));
        encryptAndStoreSessionVariable(dataIncidentChartVisibleSessionVariable, JSON.stringify(dataIncidentChartVisible));
        encryptAndStoreSessionVariable(identityIncidentChartVisibleSessionVariable, JSON.stringify(identityIncidentChartVisible));
        encryptAndStoreSessionVariable(agentLicenseChartVisibleSessionVariable, JSON.stringify(agentLicenseChartVisible));
        encryptAndStoreSessionVariable(partnerDealsPageVolumePricingTableVisibleSessionVariable, JSON.stringify(partnerDealsPageVolumePricingTableVisible));
        encryptAndStoreSessionVariable(showGroupInstallerConfigIconToggledSessionVariable, JSON.stringify(showGroupInstallerConfigIconToggled));
        encryptAndStoreSessionVariable(showAllInjectionsTelemetryVariable, JSON.stringify(showAllInjectionsTelemetry));
        encryptAndStoreSessionVariable(showAllCreationsTelemetryVariable, JSON.stringify(showAllCreationsTelemetry));
        encryptAndStoreSessionVariable(showAllIdentityIncidentsTelemetryVariable, JSON.stringify(showAllIdentityIncidentsTelemetry));
        encryptAndStoreSessionVariable(filterExpiredLicensesByDefaultVariable, JSON.stringify(filterExpiredLicensesByDefault));
    }).catch(error => {
        console.log(error);
    })
}

export function getChartVisibilitySettingInSession(sessionVariable){
    try{
        let sessionValue = decryptAndGetSessionVariable(sessionVariable)
        if(sessionValue){
            let visible = JSON.parse(sessionValue)
            if(visible !== null && visible !== undefined){
                return visible
            }
        }
    }
    catch (e) {}
    //Return false (not visible) by default
    return false
}

export function updateChartVisibilitySettingInSession(sessionVariable, visible, updateChartVisibilityFunction){
    encryptAndStoreSessionVariable(sessionVariable, JSON.stringify(visible))
    //call to api
    if(updateChartVisibilityFunction){
        updateChartVisibilityFunction(visible).then(response => {})
            .catch(error => {
                console.log(error);
            })
    }
}

export function getTelemetryDataVisibilitySettingInSession(sessionVariable, gridFilterStateName){
    try{
        //If use filter state is disabled for this grid, use the default value of true to show all telemetry data.
        //If use filter state is enabled for this grid, then return whatever is in storage for this setting
        let useFilterStateInSession = getUseFilterStateInSession(gridFilterStateName)
        if(!useFilterStateInSession){
            return true //Return true by default (show all telemetry data)
        }
        //else return value in session
        let sessionValue = decryptAndGetSessionVariable(sessionVariable)
        if(sessionValue){
            let visible = JSON.parse(sessionValue)
            if(visible !== null && visible !== undefined){
                return visible
            }
        }
    }
    catch (e) {}
    return true //Return true by default (show all telemetry data)
}

//clearSessionStorageValues is used for toggling training/demo mode for clearing session storage variables or retriving them
export function getAndStoreAllFilterStatesInSession(clearSessionStorageValues){
    if(clearSessionStorageValues){
        removeItemFromStorage("agentsGridFilterState")
        removeItemFromStorage("dealsGridFilterState")
        removeItemFromStorage("decryptorsGridFilterState")
        removeItemFromStorage("groupsGridFilterState")
        removeItemFromStorage("incidentsGridFilterState")
        removeItemFromStorage("licensesGridFilterState")
        removeItemFromStorage("securityNotificationsGridFilterState")
        removeItemFromStorage("processCreationsGridFilterState")
        removeItemFromStorage("processInjectionsGridFilterState")
        removeItemFromStorage("rolesGridFilterState")
        removeItemFromStorage("salesNotificationsGridFilterState")
        removeItemFromStorage("schedulesGridFilterState")
        removeItemFromStorage("sessionDurationsGridFilterState")
        removeItemFromStorage("tasksGridFilterState")
        removeItemFromStorage("whitelistsGridFilterState")
        removeItemFromStorage("silentIncidentsGridFilterState")
        removeItemFromStorage("browserDataIncidentsGridFilterState")
        removeItemFromStorage("trainingLicensesGridFilterState")
        removeItemFromStorage("distributorManagementGridFilterState")
        removeItemFromStorage("customerDeploymentHealthGridFilterState")
    }
    else{
        getAgentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("agentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getDealsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("dealsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getDecryptorsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("decryptorsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getGroupsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("groupsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getIncidentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("incidentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getLicensesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("licensesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSecurityNotificationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("securityNotificationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getProcessCreationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("processCreationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getProcessInjectionsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("processInjectionsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getRolesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("rolesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSalesNotificationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("salesNotificationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSessionDurationsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("sessionDurationsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getTasksGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("tasksGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getWhitelistsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("whitelistsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })

        getSilentIncidentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("silentIncidentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getBrowserDataIncidentsGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("browserDataIncidentsGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getTrainingLicensesGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("trainingLicensesGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getDistributorManagementGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("distributorManagementGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getCustomerDeploymentHealthGridFilterStateReactive().then(response => {
            encryptAndStoreSessionVariable("customerDeploymentHealthGridFilterState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
    }
}

export function onFilterChangedHelper(params, gridFilterState, updateGridFilterModel){
    let filterModel = params.api.getFilterModel();
    let currentFilterState = decryptAndGetSessionVariable(gridFilterState);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if (currentFilterState){
        try{
            let parsedFilterState = JSON.parse(currentFilterState);
            if (parsedFilterState.useFilterState === true) {
                //session storage
                parsedFilterState.filterModel = JSON.stringify(filterModel);
                encryptAndStoreSessionVariable(gridFilterState, JSON.stringify(parsedFilterState));
                //api call to update filter model for user only if we are not in training or demo mode
                if(!trainingMode && !demoMode){
                    updateGridFilterModel(JSON.stringify(filterModel)).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }
        } catch (e) {
            console.log(e);
        }

    }
    else{
        //session storage
        const currentFilterState = {
            "filterModel": JSON.stringify(filterModel),
            "useFilterState": true
        };
        encryptAndStoreSessionVariable(gridFilterState, JSON.stringify(currentFilterState));
        //api call to update filter model for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridFilterModel(JSON.stringify(filterModel)).then(response => {
            }).catch(error => {
                console.log(error);
            })
        }
    }
}

export function onShowAllTelemetryDataChangedHelper(showTelemetryDataVariableName, newShowTelemetryDataValue, gridFilterStateName, updateShowTelemetryDataFunction){
    let currentFilterState = decryptAndGetSessionVariable(gridFilterStateName);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    let updateInStorage = false
    //Only want to update the show telemetry data value in storage if the useFilterState is true for the grid
    if(currentFilterState){
        try{
            let parsedFilterState = JSON.parse(currentFilterState);
            if (parsedFilterState.useFilterState === true) {
                //If useFilterState is true, we can update the telemetry setting
                updateInStorage = true
            }
        } catch (e) {
            console.log(e);
        }
    }
    else{
        //else no filter found for some reason, treat this as useFilterState is true since that is the default setting
        updateInStorage = true
    }

    if(updateInStorage){
        //Update telemetry setting in storage
        encryptAndStoreSessionVariable(showTelemetryDataVariableName, JSON.stringify(newShowTelemetryDataValue))
        //api call to update telemetry setting for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateShowTelemetryDataFunction && updateShowTelemetryDataFunction(newShowTelemetryDataValue)
                .then(response => {})
                .catch(error => {
                    console.log(error);
                })
        }
    }
}

export function onGridReadyHelper(params, gridFilterState, defaultFilterObject=null){
    let currentFilterState = decryptAndGetSessionVariable(gridFilterState);
    if (currentFilterState){
        try{
            let filterStateParsed = JSON.parse(currentFilterState);
            if (filterStateParsed.useFilterState){
                let parsedFilterModelVariable = JSON.parse(filterStateParsed.filterModel);
                //Check if parsedFilterModelVariable is null or undefined since you can't pass that to Object.assign, set to {} if it is
                if(parsedFilterModelVariable === null || parsedFilterModelVariable === undefined){
                    parsedFilterModelVariable = {}
                }
                //If defaultFilterObject is not null and undefined call Object.assign to add each key/value pair in defaultFilterObject to parsedFilterModelVariable
                if(defaultFilterObject !== null && defaultFilterObject !== undefined){
                    parsedFilterModelVariable = Object.assign(parsedFilterModelVariable, defaultFilterObject) //Object.assign returns the new object with default filters included with saved user filters
                    /*
                        Docs for Object.assign say "The properties are overwritten by other objects that have the same properties later in the parameters order.".
                        So if both parsedFilterModelVariable and defaultFilterObject have the same key, the defaultFilterObject will take precedence which is what we want
                     */
                }
                params.api.setFilterModel(parsedFilterModelVariable);
            }
            else if(defaultFilterObject !== null && defaultFilterObject !== undefined){ //we still need to apply default filter if useFilterState is false
                params.api.setFilterModel(defaultFilterObject)
            }
        } catch (error){
            console.log(error);
        }
    }
    else{
        if(defaultFilterObject !== null && defaultFilterObject !== undefined){ //we still need to apply default filter if currentFilterState is null
            try{
                params.api.setFilterModel(defaultFilterObject)
            } catch (e) {}
        }
    }
}

export function updateUseFilterStateHelper(newUseFilterState, gridUseFilterState, updateGridUseFilterState){
    let currentUseFilterState = decryptAndGetSessionVariable(gridUseFilterState);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if (currentUseFilterState){
        try{
            let parsedUseFilterState = JSON.parse(currentUseFilterState);
            //session storage
            parsedUseFilterState.useFilterState = newUseFilterState;
            encryptAndStoreSessionVariable(gridUseFilterState, JSON.stringify(parsedUseFilterState));
        }catch (error) {
            console.log(error);
        }
        //api call to update filter model for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseFilterState(newUseFilterState).then(response => {

            }).catch(error => {
                console.log(error);
            })
        }
    }
    else{
        //api call to update filter model for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseFilterState(newUseFilterState).then(response => {
                //sessionStorage
                const newFilterState = {
                    "filterModel": null,
                    "useFilterState": newUseFilterState
                };
                encryptAndStoreSessionVariable(gridUseFilterState, JSON.stringify(newFilterState));
            }).catch(error => {
                console.log(error);
            })
        }
        else{
            //else only update in session
            const newFilterState = {
                "filterModel": null,
                "useFilterState": newUseFilterState
            };
            encryptAndStoreSessionVariable(gridUseFilterState, JSON.stringify(newFilterState));
        }
    }
}

export function getUseFilterStateInSession(gridUseFilterState) {
    let currentUseFilterState = decryptAndGetSessionVariable(gridUseFilterState);
    if (currentUseFilterState) {
        try {
            let currentUseFilterStateParsed = JSON.parse(currentUseFilterState);
            return currentUseFilterStateParsed.useFilterState;
        } catch (error) {
            console.log(error);
        }
    }
    return true;
}

//clearSessionStorageValues is used for toggling training/demo mode for clearing session storage variables or retriving them
export function getAndStoreAllGridColumnStatesInSession(clearSessionStorageValues=false){
    if(clearSessionStorageValues){
        removeItemFromStorage("agentsGridColumnState")
        removeItemFromStorage("whitelistsGridColumnState")
        removeItemFromStorage("decryptorsGridColumnState")
        removeItemFromStorage("incidentsGridColumnState")
        removeItemFromStorage("tasksGridColumnState")
        removeItemFromStorage("processInjectionsGridColumnState")
        removeItemFromStorage("processCreationsGridColumnState")
        removeItemFromStorage("securityNotificationsGridColumnState")
        removeItemFromStorage("salesNotificationsGridColumnState")
        removeItemFromStorage("schedulesGridColumnState")
        removeItemFromStorage("sessionDurationsGridColumnState")
        removeItemFromStorage("groupsGridColumnState")
        removeItemFromStorage("rolesGridColumnState")
        removeItemFromStorage("licensesGridColumnState")
        removeItemFromStorage("partnerDealsGridColumnState")
        removeItemFromStorage("silentIncidentsGridColumnState")
        removeItemFromStorage("browserDataIncidentsGridColumnState")
        removeItemFromStorage("trainingLicensesGridColumnState")
        removeItemFromStorage("distributorManagementGridColumnState")
        removeItemFromStorage("customerDeploymentHealthGridColumnState")
    }
    else{
        getAgentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("agentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getWhitelistsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("whitelistsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getDecryptorsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("decryptorsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getIncidentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("incidentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getTasksGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("tasksGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getProcessInjectionsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("processInjectionsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getProcessCreationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("processCreationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSecurityNotificationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("securityNotificationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSalesNotificationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("salesNotificationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSessionDurationsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("sessionDurationsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getGroupsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("groupsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getRolesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("rolesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getLicensesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("licensesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getDealsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("partnerDealsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getSilentIncidentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("silentIncidentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getBrowserDataIncidentsGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("browserDataIncidentsGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getTrainingLicensesGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("trainingLicensesGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getDistributorManagementGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("distributorManagementGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
        getCustomerDeploymentHealthGridColumnStateReactive().then(response => {
            encryptAndStoreSessionVariable("customerDeploymentHealthGridColumnState", JSON.stringify(response));
        }).catch(error => {
            console.log(error);
        })
    }
}

export function onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateGridColumnStateFunction){
    let columnState = params.api.getColumnState()
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName)
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if(currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState)
            if(parsedColumnState.useColumnState === true){
                //update in sessionStorage
                parsedColumnState.columnStatesList = columnState
                encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(parsedColumnState))
                //api call to update column state for user only if we are not in training or demo mode
                if(!trainingMode && !demoMode){
                    updateGridColumnStateFunction(columnState).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }

        } catch (e) {
            console.log(e);
        }
    }
    else{
        const currentColumnState = {
            "useColumnState": true,
            "columnStatesList": columnState,
            "columnMode": customColumnModeText //default to custom
        }

        encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(currentColumnState))
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridColumnStateFunction(columnState).then(response => {
            }).catch(error => {
                console.log(error);
            })
        }
    }
}

export function onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            if (parsedColumnState.useColumnState){
                params.api.applyColumnState({applyOrder: true, state: parsedColumnState.columnStatesList})
            }
        } catch (error){
            console.log(error);
        }
    }
}

//just for incidents grid, making sure number of incidents column is always visible and pinned
export function onGridReadyHelperForColumnStateForIncidentsGrid(params, gridColumnStateSessionVariableName){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            if (parsedColumnState.useColumnState){
                if(parsedColumnState.columnStatesList){
                    parsedColumnState.columnStatesList.forEach(function(columnState){
                        if(columnState && columnState.colId === "incidentsCount"){
                            columnState.hide = false
                            columnState.pinned = "left"
                        }
                    })
                    params.api.applyColumnState({applyOrder: true, state: parsedColumnState.columnStatesList})
                }
            }
        } catch (error){
            console.log(error);
        }
    }
}

export function getUseColumnStateInSession(gridColumnStateSessionVariableName) {
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState) {
        try {
            let parsedColumnState = JSON.parse(currentColumnState);
            return parsedColumnState.useColumnState;
        } catch (error) {
            console.log(error);
        }
    }
    return true;
}

export function updateUseColumnStateHelper(newUseColumnState, gridColumnStateSessionVariableName, updateGridUseColumnStateFunction){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            //session storage
            parsedColumnState.useColumnState = newUseColumnState;
            encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(parsedColumnState));
        }catch (error) {
            console.log(error);
        }
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseColumnStateFunction(newUseColumnState).then(response => {

            }).catch(error => {
                console.log(error);
            })
        }
    }
    else{
        //sessionStorage
        const newFilterState = {
            "columnStatesList": null,
            "useColumnState": newUseColumnState,
            "columnMode": customColumnModeText //default to custom
        };
        encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(newFilterState));
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridUseColumnStateFunction(newUseColumnState).then(response => {

            }).catch(error => {
                console.log(error);
            })
        }
    }
}

export function getColumnModeInSession(gridColumnStateSessionVariableName) {
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
    if (currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState);
            //if useColumnState is true then return the saved columnMode
            if (parsedColumnState.useColumnState){
                return parsedColumnState.columnMode
            }
            else{
                //When useColumnState is false then show the default/max amount of columns
                return maxColumnModeText
            }
        } catch (error){
            console.log(error);
        }
    }
    //Return maxColumnModeText by default if error or currentColumnState was not found in session
    return maxColumnModeText
}

export function getAgentChartLastActiveDaysValueInSession() {
    let defaultValue = agentChart90DayValue
    let sessionValue = decryptAndGetSessionVariable(agentChartLastActiveDaysSessionVariable);
    if (sessionValue){
        try{
            let parsedValue = JSON.parse(sessionValue);
            if(parsedValue !== null && parsedValue !== undefined){
                if(parsedValue !== agentChart30DayValue && parsedValue !== agentChart60DayValue && parsedValue !== agentChart90DayValue){
                    //if value is not 30/60/90 then show all agents in chart
                    return agentChartAllAgentsValue
                }
                else{ //else value was valid
                    return parsedValue
                }
            }
        } catch (error){
            console.log(error);
        }
    }
    //Return defaultValue if not found in session
    return defaultValue
}
export function updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateGridColumnModeFunction, newColumnMode){
    let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName)
    let trainingMode = getTrainingModeSetting()
    let demoMode = getDemoModeSetting()
    if(currentColumnState){
        try{
            let parsedColumnState = JSON.parse(currentColumnState)
            if(parsedColumnState.useColumnState === true){
                //update in sessionStorage
                parsedColumnState.columnMode = newColumnMode
                encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(parsedColumnState))
                //api call to update column state for user only if we are not in training or demo mode
                if(!trainingMode && !demoMode){
                    updateGridColumnModeFunction(newColumnMode).then(response => {
                    }).catch(error => {
                        console.log(error);
                    })
                }
            }

        } catch (e) {
            console.log(e);
        }
    }
    else{
        const currentColumnState = {
            "useColumnState": true,
            "columnStatesList": null,
            "columnMode": newColumnMode
        }

        encryptAndStoreSessionVariable(gridColumnStateSessionVariableName, JSON.stringify(currentColumnState))
        //api call to update column state for user only if we are not in training or demo mode
        if(!trainingMode && !demoMode){
            updateGridColumnModeFunction(newColumnMode).then(response => {
            }).catch(error => {
                console.log(error);
            })
        }
    }
}

export function getDefaultAgGridSidebarProps(columnsPanelWidth=null) {
    return {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
                toolPanelParams: {
                    suppressSyncLayoutWithGrid: false, //sidebar column order will match with whats on the grid
                    suppressColumnMove: false, //lets users re-order columns on the sidebar
                    suppressRowGroups: true,
                    suppressValues: true,
                    suppressPivotMode: true

                },
                width: columnsPanelWidth
            },
        ],
        position: "right"
    };
}