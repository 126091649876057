import axios from "../../utils/axiosInstance";

export const getCustomerDeploymentHealthGridFilterStateReactive = async () => {
    const response =  await axios.post("/getCustomerDeploymentHealthGridFilterStateReactive", {});
    return response.data;
};

export const updateCustomerDeploymentHealthGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateCustomerDeploymentHealthGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateCustomerDeploymentHealthGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateCustomerDeploymentHealthGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getCustomerDeploymentHealthGridColumnStateReactive = async () => {
    const response =  await axios.post("/getCustomerDeploymentHealthGridColumnStateReactive", {});
    return response.data;
};
export const updateCustomerDeploymentHealthGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateCustomerDeploymentHealthGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateCustomerDeploymentHealthGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateCustomerDeploymentHealthGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};
export const updateCustomerDeploymentHealthGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateCustomerDeploymentHealthGridColumnModeReactive", {
        columnMode: columnMode
    });
};