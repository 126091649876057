import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandlePopulateGrid
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {useForm} from "react-hook-form";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, Switch, ThemeProvider} from "@mui/material";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import {ccGetAllNonChannelPartnerGroupsReactive, ccGetUsersInGroupReactive} from "../api/groupsApi";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import Modal from "react-modal";
import {ccRetrieveUsernamesReactive} from "../api/notificationsApi";
import {
    addSelfToGroupsCCOnly,
    listAllGroupsCCOnly,
    listAllUserGroupsCCOnly,
    removeSelfFromGroupsCCOnly
} from "../api/ccOnlyAddToGroupApi";
import NotificationManager from "react-notifications/lib/NotificationManager";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import {checkPermission} from "../../utils/permissionCheckHelper";
import AddIcon from '@mui/icons-material/Add';
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";
export default function CcOnlyAddToGroup() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [enableButtons, setEnableButtons] = useState(false);
    const [addToGroupAllGroupsShownModalIsOpen, setAddToGroupAllGroupsShownModalIsOpen] = useState(false);
    const [addToGroupUserSelectModalIsOpen, setAddToGroupUserSelectModalIsOpen] = useState(false);
    const [allGroupsList, setAllGroupsList] = useState([]);
    const [allUsernamesList, setAllUsernamesList] = useState([]);
    const [usernameSelected, setUsernameSelected] = useState(null);
    const [groupsSelected, setGroupsSelected] = useState([]);
    const [userGroupOptions, setUserGroupOptions] = useState([]);
    const [columnDefs, setColumnDefs] = useState([
        { field: "friendlyName", headerName: "Group", initialWidth: 400,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: true,
            },
            sortable: true,
            editable: false,
            cellRenderer: 'agGroupCellRenderer',
            comparator: function (valueA, valueB) {
                if((valueA === null || valueA === undefined) && (valueB === null || valueB === undefined)){ //if both null
                    return 0
                }
                else if(valueA === null || valueA === undefined){ //both are not null, but valueA is null, return 1 to sort valueA after valueB
                    return 1
                }
                else if(valueB === null || valueB === undefined){ //both are not null, but valueB is null, return -1 to sort valueA before valueB
                    return -1
                }
                else{
                    //else compare both lowercase values
                    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
                }
            }
        },
        { field: "organizationName", headerName: "Organization Name", sortable: true,
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            editable: false
        },
        { field: "channelPartner", headerName: "Channel Partner",
            initialWidth: 290,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Channel Partner', 'Direct Customer'],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.channelPartner){
                        return "Channel Partner";
                    }else{
                        return "Direct Customer";
                    }
                }
            },
            valueGetter: (params) => { //need valueGetter or else true/false will show for this column in excel export because only valueGetters are used to get values for export
                if(params.node.data.channelPartner){
                    return "Channel Partner";
                }else{
                    return "Direct Customer";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                                <Switch
                                    checked={params.node.data.channelPartner}
                                    name={`cellToggleChannelPartner${params.node.data.zenGroupId}`}
                                    disabled={true}
                                    className={"cursor-pointer"}
                                />
                            </ThemeProvider>
                        </div>
                    )
                },
            sortable: true
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);

    function resetAddToGroupModal(){
        setAddToGroupUserSelectModalIsOpen(false)
        setUsernameSelected(null)
        setGroupsSelected([])
        setUserGroupOptions([])

        setAddToGroupAllGroupsShownModalIsOpen(false)
    }

    function getGroupsForUser(username){
        //Need to reset group options and selected group since new username is selected
        setGroupsSelected([])
        setUserGroupOptions([])
        if(username !== null && username !== undefined){
            listAllUserGroupsCCOnly(username).then(results => {
                let groupsFormattedForOptions = []
                results.forEach(group => {
                    let objToPush = {}
                    objToPush.value = group.id
                    objToPush.label = group.userSetFriendlyName || group.friendlyName || group.id
                    groupsFormattedForOptions.push(objToPush)
                })
                groupsFormattedForOptions.sort(function (a, b) {
                    return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                });
                setUserGroupOptions(groupsFormattedForOptions)
            }).catch(function (error) {})
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Cyber Crucible Only Add to Group</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Add Yourself to Group Modal User Select Option" isOpen={addToGroupUserSelectModalIsOpen}
                   onRequestClose={() => {
                       resetAddToGroupModal()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Add Yourself to Group</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetAddToGroupModal()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div>
                        <label>Select User to get Group Options</label>
                        <MuiAutocompleteNonGroupOptions
                            options={allUsernamesList}
                            value={usernameSelected}
                            onChange={(event, value) => {
                                setUsernameSelected(value)
                                //call to get group options
                                getGroupsForUser(value)
                            }}
                        />
                    </div>
                    <div>
                        <label>Select the Group(s) to Add Yourself to</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={userGroupOptions}
                            value={groupsSelected} multiple={true}
                            onChange={( event, valueList ) => {
                                setGroupsSelected(valueList)
                            }}
                        />
                    </div>
                    <div className="flex flex-col">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"} type={"submit"} color={"primary"}
                                    onClick={(e) => {
                                        if(groupsSelected !== null && groupsSelected !== undefined && groupsSelected.length > 0){
                                            setIsLoading(true)
                                            let groupIdsToSend = []
                                            groupsSelected.forEach(groupOption => {
                                                groupIdsToSend.push(groupOption.value)
                                            })
                                            addSelfToGroupsCCOnly(groupIdsToSend).then(result => {
                                                setIsLoading(false)
                                                NotificationManager.success("Successfully added yourself to group(s). Refresh the page to see the changes");
                                                resetAddToGroupModal()
                                            }).catch(function (error) {
                                                setIsLoading(false)
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Unexpected error making this request");
                                                }
                                            })
                                        }
                                    }}
                            >
                                Add Yourself to Group(s)
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <Modal contentLabel="Add Yourself to Group Modal All Groups Option" isOpen={addToGroupAllGroupsShownModalIsOpen}
                   onRequestClose={() => {
                       resetAddToGroupModal()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Add Yourself to Group</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetAddToGroupModal()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div>
                        <label>Select the Group(s) to Add Yourself to</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={allGroupsList}
                            value={groupsSelected} multiple={true}
                            onChange={( event, valueList ) => {
                                setGroupsSelected(valueList)
                            }}
                        />
                    </div>
                    <div className="flex flex-col">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"} type={"submit"} color={"primary"}
                                    onClick={(e) => {
                                        if(groupsSelected !== null && groupsSelected !== undefined && groupsSelected.length > 0){
                                            setIsLoading(true)
                                            let groupIdsToSend = []
                                            groupsSelected.forEach(groupOption => {
                                                groupIdsToSend.push(groupOption.value)
                                            })
                                            addSelfToGroupsCCOnly(groupIdsToSend).then(result => {
                                                setIsLoading(false)
                                                NotificationManager.success("Successfully added yourself to group(s). Refresh the page to see the changes");
                                                resetAddToGroupModal()
                                            }).catch(function (error) {
                                                setIsLoading(false)
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Unexpected error making this request");
                                                }
                                            })
                                        }
                                    }}
                            >
                                Add Yourself to Group(s)
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Cyber Crucible Only Add to Group")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            <MuiIconButtonWithTooltipAndBox
                                icon={<AddIcon className={"cursor-pointer"}/>} tooltipTitle={"Add yourself to Group(s) with all groups in database shown"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetAddToGroupModal()
                                    setAddToGroupAllGroupsShownModalIsOpen(true)
                                    //if we have not populated allGroupsList yet then do so now
                                    if(allGroupsList.length === 0) {
                                        listAllGroupsCCOnly().then(response => {
                                            let groupsFormattedForOptions = []
                                            response.forEach(group => {
                                                let objToPush = {}
                                                objToPush.value = group.id
                                                objToPush.label = group.userSetFriendlyName || group.friendlyName || group.id
                                                groupsFormattedForOptions.push(objToPush)
                                            })
                                            groupsFormattedForOptions.sort(function (a, b) {
                                                return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                                            });
                                            setAllGroupsList(groupsFormattedForOptions)
                                        }).catch(error => {})
                                    }
                                }}/>
                            <MuiIconButtonWithTooltipAndBox
                                icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Add yourself to Group(s) with user select option to get groups"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetAddToGroupModal()
                                    setAddToGroupUserSelectModalIsOpen(true)
                                    //if we have not populated allUsernamesList yet then do so now
                                    if(allUsernamesList.length === 0){
                                        ccRetrieveUsernamesReactive().then(response => {
                                            setAllUsernamesList(response)
                                        }).catch(function (error) {
                                            setAllUsernamesList([])
                                        })
                                    }

                                }}/>
                            <MuiIconButtonWithTooltipAndBox
                                icon={<PersonRemoveIcon className={"cursor-pointer"}/>} tooltipTitle={"Remove Yourself From All Selected Groups"}
                                tooltipPlacement={"top"} disabled={!enableButtons}
                                onClick={() => {
                                    if(gridApi) {
                                        let groupIdsToSend = []
                                        for (const rowNode of gridApi.getSelectedNodes()) {
                                            if(rowNode && rowNode.data && rowNode.data.zenGroupId){
                                                groupIdsToSend.push(rowNode.data.zenGroupId)
                                            }
                                        }
                                        if(groupIdsToSend.length > 0){
                                            setIsLoading(true)
                                            removeSelfFromGroupsCCOnly(groupIdsToSend).then(response => {
                                                setIsLoading(false)
                                                NotificationManager.success("Successfully removed yourself from selected groups. Refresh the page to see the changes")
                                            }).catch(function (error) {
                                                setIsLoading(false)
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Unexpected error making this request");
                                                }
                                            })
                                        }
                                    }
                                }}/>
                        </div>
                        <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} />
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setEnableButtons={setEnableButtons}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                        />
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    getRowId = (params) => {
        return params.data.zenGroupId
    }

    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };

    detailCellRendererParams = (detailParams) => {
        let groupId = detailParams.node.data.zenGroupId
        let detailGridColumnDefs = [
            {
                field: "username", headerName: `Username`, sortable: true, width: 600,
                filter: 'agSetColumnFilter',
                filterParams: {
                    suppressSorting: false,
                    buttons: ["reset", "apply", "cancel"],
                }
            }
        ]
        return {
            detailGridOptions: {
                getRowId: (params) => {
                    return params.data.username;
                },
                columnDefs: detailGridColumnDefs,
                defaultColDef: {
                    resizable: true,
                    floatingFilter: true,
                    cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                },
                suppressExcelExport: true,
                suppressCsvExport: true,
                enableCellTextSelection : true,
                getContextMenuItems: (params => {return ["resetColumns", "autoSizeAll"]})
            },
            getDetailRowData: async (detailRowDataParams) => {
                let detailRowData = []
                try{
                    detailRowData = await ccGetUsersInGroupReactive(groupId)
                } catch (e) {}
                detailRowDataParams.successCallback(detailRowData)
            },
        }

    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Default sort to sort on username asc.
        if(gridReadyParams && gridReadyParams.api){
            gridReadyParams.api.applyColumnState({
                state: [{colId: "friendlyName", sort: "asc"}]
            })
        }

        await loadDataWithSSEAndStartChangeStreamListener("/groupManagementListReactive", null,
            this.populateGrid, null, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, MasterDetailModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        maintainColumnOrder={true}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        detailRowHeight={400}
                        keepDetailRows={true} //keeps detail rows
                        masterDetail={true}
                        detailCellRendererParams={this.detailCellRendererParams}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
