import axios, {axiosNoRequestTokenInterceptorInstance} from "../../utils/axiosInstance";

export const userEulaAcceptedCheckReactive = async () => {
    const response = await axios.post("/userEulaAcceptedCheckReactive", {});
    return response.data;
};

export const userAcceptEulaReactive = async () => {
    const response = await axios.post("/userAcceptEulaReactive", {});
    return response.data;
};

export const userInfoCheckReactive = async () => {
    const response = await axios.post("/userInfoCheckReactive", {});
    return response.data;
};

export const updateAccountInfoReactive = async (firstName, lastName, companyName) => {
    let data = {};
    if(companyName){
        data = { "firstName": firstName, "lastName": lastName, "companyName": companyName.trim()};
    }
    else{
        data = { "firstName": firstName, "lastName": lastName};
    }
    const response = await axios.post("/updateAccountInfoReactive", data);
    return response;
};

export const userTimeZoneCheckReactive = async () => {
    const response = await axios.post("/userTimeZoneCheckReactive", {});
    return response.data.timeZone;
};

export const groupAgentTelemetryInitialSetupCheckReactive = async () => {
    const response = await axios.post("/groupAgentTelemetryInitialSetupCheckReactive", {});
    return response.data;
};

export const initializeGroupsAgentTelemetrySettingReactive = async (zenGroupIdsToTurnTelemetryOn, zenGroupIdsToTurnTelemetryOff) => {
    const response = await axios.post("/initializeGroupsAgentTelemetrySettingReactive", {
        zenGroupIdsToTurnTelemetryOn: zenGroupIdsToTurnTelemetryOn,
        zenGroupIdsToTurnTelemetryOff: zenGroupIdsToTurnTelemetryOff
    });
    return response.data;
};

export const checkUserLastPasswordResetDateReactive = async (updateUserLastPasswordResetDateTime, accessToken=null) => {
    if(accessToken !== null && accessToken !== undefined){
        /*
            If the user is signed out in another window due to this endpoint returning true, the token interceptor for default axios variable will not be able to
            use the refresh token to get more access tokens due to the user being in the RESET_REQUIRED status in cognito.
            The best we can do for this edge case is get the current access token from Auth.currentSession() and use it in the Authorization header. Auth.currentSession() automatically refreshes expired
            tokens, but won't be able to unless user is out of the RESET_REQUIRED status.

            If we don't do it this way then the call to this endpoint will error out due to token interceptor not being able to get new access tokens, and user will not know they have to reset their password.
            Once they do reset their password user will be out of RESET_REQUIRED status and new tokens are able to be retrieved.

         */
        const response = await axiosNoRequestTokenInterceptorInstance.post("/checkUserLastPasswordResetDateReactive", {
            updateUserLastPasswordResetDateTime: updateUserLastPasswordResetDateTime
        }, {transformRequest: (data, headers) => {
                headers["Authorization"] = `Bearer ${accessToken}`
                return JSON.stringify(data)
            }});
        return response.data;
    }
    else{
        const response = await axios.post("/checkUserLastPasswordResetDateReactive", {
            updateUserLastPasswordResetDateTime: updateUserLastPasswordResetDateTime
        });
        return response.data;
    }
};

export const getChartVisibilitySettingsReactive = async () => {
    const response = await axios.post("/getChartVisibilitySettingsReactive", {});
    return response.data;
};

export const updateAgentChartLastActiveDaysReactive = async (newAgentChartLastActiveDays) => {
    return await axios.post("/updateAgentChartLastActiveDaysReactive", {newAgentChartLastActiveDays: newAgentChartLastActiveDays})
};






