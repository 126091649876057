import axios from "../../utils/axiosInstance";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {Auth} from "aws-amplify";
import {encryptAndStoreSessionVariable} from "../../utils/storageHelper";
import {getDemoModeSetting, getTrainingModeSetting} from "../../utils/trainingAndDemoModeHelper";

export const groupListReactive = async () => {
    let response = await axios.post("/groupListReactive", {});
    return response.data;
};
export const downloadAgentGroupOptionsListReactive = async () => {
    let response = await axios.post("/downloadAgentGroupOptionsListReactive", {});
    return response.data;
};
export const groupListForCCUsersOnDealsPageReactive = async () => {
    let response = await axios.post("/groupListForCCUsersOnDealsPageReactive", {});
    return response.data
};
export async function storeGroupListForCCUsersOnDealsPageInSessionSSE(){
    Auth.currentSession().then(response => {
        let accessToken = response.getAccessToken().getJwtToken()
        let groups = []
        fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL +"/sse/groupListForCCUsersOnDealsPageReactive", {
            method: "POST",
            headers: {
                "Accept": "text/event-stream",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken,
            },
            //Get trainingMode and demoMode settings dynamically from session
            body: JSON.stringify({
                trainingMode: getTrainingModeSetting(),
                demoMode: getDemoModeSetting()
            }),
            openWhenHidden: true,
            onopen(res) {
                if (res.ok && res.status === 200) {
                    //console.log("Connection made ", res);
                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.error("Client side error ", res);
                }
            },
            onmessage(event) {
                //while this is open it will continually push the event data into the groups array and update the zenGroupSessionStorage, it is not a change stream
                const value = JSON.parse(event.data)
                groups.push(value)
            },
            onclose() {
                //console.log("Connection closed by the server");
                encryptAndStoreSessionVariable("ccUsersDealsZenGroupSessionStorage", JSON.stringify(groups))
            },
            onerror(err) {
                console.error("There was an error from server", err);
            },
        })
    }).catch(error => {
        console.error(error)
    })
}

export const channelAndDistributionGroupListReactive = async () => {
    let response = await axios.post("/channelAndDistributionGroupListReactive", {});
    return response.data
};
export const userGroupListWithChannelInfoReactive = async () => {
    let response = await axios.post("/userGroupListWithChannelInfoReactive", {});
    return response.data;
};


export const distributionGroupListReactive = async () => {
    let response = await axios.post("/distributionGroupListReactive", {});
    return response.data.zenGroups;
};

export const getGroupManagementListReactive = async () => {
    let response = await axios.post("/groupManagementListReactive", {
    });
    return response.data;
};

export const getUsersInGroupListReactive = async (zenGroup) => {
    let response = await axios.post("/usersInGroupListReactive", {
        zenGroupId: zenGroup
    });
    return response.data;
};
export const ccGetUsersInGroupReactive = async (zenGroupId) => {
    let response = await axios.post("/ccGetUsersInGroupReactive", {
        zenGroupId: zenGroupId
    });
    return response.data;
};

export const ccGetAllNonChannelPartnerGroupsReactive = async () => {
    let response = await axios.post("/ccGetAllNonChannelPartnerGroupsReactive", {});
    return response.data;
};

export const createNewGroupReactive = async (groupName, channelPartnerCustomer, distributionGroup, assignedDistributionGroupId,
                                             zenGroupIdToCopyUser, zenGroupIdToCopyWhitelist, collectAgentTelemetry, whcpOnly, sslPinningMode,
                                             latestAgentVersionApproved, passwordExpirationPolicyInDays, agentInactiveDaysSetting, runAgentsInSafeMode,
                                             hideAgentsAutomatically, dmzMode, enableSystemTray) => {
    return await axios.post("/createNewGroupReactive", {
        groupName,
        channelPartnerCustomer: channelPartnerCustomer,
        distributionGroup: distributionGroup,
        assignedDistributionGroupId: assignedDistributionGroupId,
        zenGroupIdToCopyUser: zenGroupIdToCopyUser,
        zenGroupIdToCopyWhitelist: zenGroupIdToCopyWhitelist,
        collectAgentTelemetry: collectAgentTelemetry,
        whcpOnly : whcpOnly,
        sslPinningMode: sslPinningMode,
        latestAgentVersionApproved: latestAgentVersionApproved,
        passwordExpirationPolicyInDays: passwordExpirationPolicyInDays,
        agentInactiveDaysSetting: agentInactiveDaysSetting,
        runAgentsInSafeMode: runAgentsInSafeMode,
        hideAgentsAutomatically: hideAgentsAutomatically,
        dmzMode: dmzMode,
        enableSystemTray: enableSystemTray
    });
};

export const addToGroupReactive = async (username, groupId) => {
    const response = await axios.post("/addToGroupReactive", {
        usernameToAdd: username,
        zenGroupToAddTo: groupId
    });
    return response.data;
};

export const createUserFromGMReactive = async (username, groupId) => {
    const response = await axios.post("/createUserFromGMReactive", {
        usernameToCreate: username,
        zenGroupToAddTo: groupId
    });
    return response.data;
};

export const removeFromGroupReactive = async (username, groupId) => {
    return await axios.post("/removeFromGroupReactive", {
        userToRemove: username.trim(),
        zenGroupToRemoveFrom: groupId
    });
};

export const updateUserRolesReactive = async (username, groupId, roles) => {
    return await axios.post("/updateUserRolesReactive", {
        usernameToUpdateRoles: username,
        zenGroupToUpdateRoles: groupId,
        newSelectedRolesList: roles
    });
};

export const changeZenGroupGroupNameReactive = async (newGroupName, groupId) => {
    return await axios.post("/changeZenGroupGroupNameReactive", {
        "newGroupName": newGroupName,
        "zenGroupIdToChangeNameIn": groupId
    });
};

export const changeDefaultGroupReactive = async (zenGroup) => {
    return await axios.post("/changeDefaultGroupReactive", {
        newDefaultZenGroup: zenGroup,
    });
};
export const changeZenGroupDistributionGroupFieldReactive = async (zenGroup, value) => {
    const response = await axios.post("/changeZenGroupDistributionGroupFieldReactive", {
        zenGroupId: zenGroup,
        distributionGroupValue: value
    });
    return response.data;
};
export const changeZenGroupOrganizationNameReactive = async (newGroupName, groupId) => {
    return await axios.post("/changeZenGroupOrganizationNameReactive", {
        "newGroupOrganizationName": newGroupName,
        "zenGroupId": groupId
    });
};
export const updateGroupsAssignedDistributionGroupIdReactive = async (newAssignedDistributionGroupId, groupsToUpdateList, zenGroupIdToMakeNotDistributionGroup) => {
    const response = await axios.post("/updateGroupsAssignedDistributionGroupIdReactive", {
        newAssignedDistributionGroupId: newAssignedDistributionGroupId,
        groupsToUpdateList: groupsToUpdateList,
        zenGroupIdToMakeNotDistributionGroup: zenGroupIdToMakeNotDistributionGroup
    });
    return response.data;
}
export const changeZenGroupAssignedDistributionGroupIdReactive = async (zenGroupId, newAssignedDistributionGroupId) => {
    return await axios.post("/changeZenGroupAssignedDistributionGroupIdReactive", {
        zenGroupIdToUpdate: zenGroupId,
        newAssignedDistributionGroupId: newAssignedDistributionGroupId
    });
};
export const singleRemoveZenGroupAssignedDistributionGroupIdReactive = async (zenGroupId) => {
    return await axios.post("/singleRemoveZenGroupAssignedDistributionGroupIdReactive", {
        zenGroupIdToUpdate: zenGroupId
    });
};

export const editGroupWhcpReactive = async(zenGroupId, updatedWhcpOnly) =>{
    return await axios.post("/editGroupWhcpReactive", {
        zenGroupId :zenGroupId,
        updatedWhcpOnly : updatedWhcpOnly
    
    });

}
export const changeGroupCollectAgentTelemetrySettingReactive = async (zenGroupId, collectAgentTelemetry) => {
    return await axios.post("/changeGroupCollectAgentTelemetrySettingReactive", {
        zenGroupId: zenGroupId,
        collectAgentTelemetry: collectAgentTelemetry
    });
};

export const getGroupsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getGroupsGridFilterStateReactive", {});
    return response.data;
};

export const updateGroupsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateGroupsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateGroupsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateGroupsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};
export const uploadSSLCertReactive = async (sslFile, zenGroupId) => {
    //have to take some extra steps in this request since we are sending a file along with zenGroupId
    const formData = new FormData()
    formData.append('file', sslFile)
    formData.append('zenGroupId', zenGroupId)
    const config  = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await axios.post("/uploadSSLCertReactive", formData, config);
};
export const editGroupSSLPinningModeReactive = async (zenGroupId, sslPinningModeUpdated) => {
    return await axios.post("/editGroupSSLPinningModeReactive", {
        zenGroupId: zenGroupId,
        sslPinningModeUpdated: sslPinningModeUpdated
    });
};
export const deleteSSLCertFromGroupReactive = async (zenGroupId, sha256Base64) => {
    let response =await axios.post("/deleteSSLCertFromGroupReactive", {
        zenGroupId: zenGroupId,
        sha256Base64: sha256Base64
    });
    return response.data;
};

export const getZenGroupFriendlyNamesReactive = async (zenGroupIds) => {
    const response = await axios.post("/getZenGroupFriendlyNamesReactive",{
        zenGroupIds
    })
    return response.data
}

export const getGroupsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getGroupsGridColumnStateReactive", {});
    return response.data;
};
export const updateGroupsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateGroupsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateGroupsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateGroupsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const editGroupAutoUpdateAgentsSettingReactive = async (zenGroupId, newAutoUpdateSetting) => {
    return await axios.post("/editGroupAutoUpdateAgentsSettingReactive", {
        zenGroupId: zenGroupId,
        newAutoUpdateSetting: newAutoUpdateSetting
    });
};
export const editGroupLatestAgentVersionApprovedSettingReactive = async (zenGroupId, newLatestAgentVersionApproved) => {
    return await axios.post("/editGroupLatestAgentVersionApprovedSettingReactive", {
        zenGroupId: zenGroupId,
        newLatestAgentVersionApproved: newLatestAgentVersionApproved
    });
};
export const editGroupPasswordExpirationPolicyReactive = async (zenGroupId, newPasswordExpirationPolicyInDays) => {
    return await axios.post("/editGroupPasswordExpirationPolicyReactive", {
        zenGroupId: zenGroupId,
        newPasswordExpirationPolicyInDays: newPasswordExpirationPolicyInDays
    });
};

export const updateGroupsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateGroupsGridColumnModeReactive", {
        columnMode: columnMode
    });
};

export const getInstallScriptClientAuthReactive = async (groupId) => {
    const response =  await axios.post("/getInstallScriptClientAuthReactive", {groupId: groupId});
    return response.data;
};

export const updateShowGroupInstallerConfigIconToggledReactive = async (visible) => {
    return await axios.post("/updateShowGroupInstallerConfigIconToggledReactive", {
        visible: visible
    });
};
export const getPartnerGroupSettingsReactive = async () => {
    let response = await axios.post("/getPartnerGroupSettingsReactive", {});
    return response.data;
};
export const ccGetPartnerGroupsReactive = async () => {
    let response = await axios.post("/ccGetPartnerGroupsReactive", {});
    return response.data;
};

export const ccChangeGroupIsDistributorReactive = async (zenGroupId, newSetting, overrideIfDealExists) => {
    return await axios.post("/ccChangeGroupIsDistributorReactive", {
        zenGroupId: zenGroupId,
        newSetting: newSetting,
        overrideIfDealExists: overrideIfDealExists
    });
};
export const ccChangeGroupIsResellerReactive = async (zenGroupId, newSetting, overrideIfDealExists) => {
    return await axios.post("/ccChangeGroupIsResellerReactive", {
        zenGroupId: zenGroupId,
        newSetting: newSetting,
        overrideIfDealExists: overrideIfDealExists
    });
};
export const ccChangeGroupIsReferralAgentReactive = async (zenGroupId, newSetting, overrideIfDealExists) => {
    return await axios.post("/ccChangeGroupIsReferralAgentReactive", {
        zenGroupId: zenGroupId,
        newSetting: newSetting,
        overrideIfDealExists: overrideIfDealExists
    });
};
export const ccTurnOffChannelPartnerSettingForGroupReactive = async (zenGroupId, overrideIfDealExists) => {
    return await axios.post("/ccTurnOffChannelPartnerSettingForGroupReactive", {
        zenGroupId: zenGroupId,
        overrideIfDealExists: overrideIfDealExists
    });
};

export const ccPromoteGroupToPartnerReactive = async (zenGroupId, isDistributor, isReseller, isReferralAgent, distributorDiscount, resellerDiscount, referralDiscount, daysUntilInvoiceDue, canCreatePOC,
                                                      partnerMaxDaysForPOCTrialLicensesToExpire) => {
    return await axios.post("/ccPromoteGroupToPartnerReactive", {
        zenGroupId: zenGroupId,
        isDistributor: isDistributor,
        isReseller: isReseller,
        isReferralAgent: isReferralAgent,
        distributorDiscount: distributorDiscount,
        resellerDiscount: resellerDiscount,
        referralDiscount: referralDiscount,
        daysUntilInvoiceDue: daysUntilInvoiceDue,
        canCreatePOC: canCreatePOC,
        partnerMaxDaysForPOCTrialLicensesToExpire: partnerMaxDaysForPOCTrialLicensesToExpire
    });
};


export const ccChangeGroupDistributorDiscountReactive = async (zenGroupId, newDiscount, overrideIfDealExists) => {
    return await axios.post("/ccChangeGroupDistributorDiscountReactive", {
        zenGroupId: zenGroupId,
        newDiscount: newDiscount,
        overrideIfDealExists: overrideIfDealExists
    });
};


export const ccChangeGroupResellerDiscountReactive = async (zenGroupId, newDiscount, overrideIfDealExists) => {
    return await axios.post("/ccChangeGroupResellerDiscountReactive", {
        zenGroupId: zenGroupId,
        newDiscount: newDiscount,
        overrideIfDealExists: overrideIfDealExists
    });
};

export const ccChangeGroupReferralDiscountReactive = async (zenGroupId, newDiscount, overrideIfDealExists) => {
    return await axios.post("/ccChangeGroupReferralDiscountReactive", {
        zenGroupId: zenGroupId,
        newDiscount: newDiscount,
        overrideIfDealExists: overrideIfDealExists
    });
};

export const ccChangeGroupDaysUntilInvoiceDueReactive = async (zenGroupId, newDaysUntilDue) => {
    return await axios.post("/ccChangeGroupDaysUntilInvoiceDueReactive", {
        zenGroupId: zenGroupId,
        newDaysUntilDue: newDaysUntilDue
    });
};

export const ccChangeGroupMaxDaysUntilTrialLicensesExpireReactive = async (zenGroupId, partnerMaxDaysForPOCTrialLicensesToExpire) => {
    return await axios.post("/ccChangeGroupMaxDaysUntilTrialLicensesExpireReactive", {
        zenGroupId: zenGroupId,
        partnerMaxDaysForPOCTrialLicensesToExpire: partnerMaxDaysForPOCTrialLicensesToExpire
    });
};


export const editGroupCanaryExtensionModeReactive = async (zenGroupId, canaryExtensionMode) => {
    return await axios.post("/editGroupCanaryExtensionModeReactive", {
        zenGroupId: zenGroupId,
        canaryExtensionMode: canaryExtensionMode
    });
};

export const ccGetPartnerGroupDaysUntilInvoiceDueSettingsReactive = async (zenGroupId) => {
    let response = await axios.post("/ccGetPartnerGroupDaysUntilInvoiceDueSettingsReactive", {
        zenGroupId: zenGroupId
    })
    return response.data;
};

export const resendUserInvitationReactive = async (username, zenGroupId) => {
    return await axios.post("/resendUserInvitationReactive", {
        username: username,
        zenGroupId: zenGroupId
    });
};

export const ccCreateNewPartnerGroupReactive = async (newPartnerGroupName, groupOwnerUsername, isDistributor, isReseller, isReferralAgent, distributorDiscount, resellerDiscount, referralDiscount, daysUntilInvoiceDue, canCreatePOC,
                                                      partnerMaxDaysForPOCTrialLicensesToExpire) => {
    return await axios.post("/ccCreateNewPartnerGroupReactive", {
        newPartnerGroupName: newPartnerGroupName,
        groupOwnerUsername: groupOwnerUsername,
        isDistributor: isDistributor,
        isReseller: isReseller,
        isReferralAgent: isReferralAgent,
        distributorDiscount: distributorDiscount,
        resellerDiscount: resellerDiscount,
        referralDiscount: referralDiscount,
        daysUntilInvoiceDue: daysUntilInvoiceDue,
        canCreatePOC: canCreatePOC,
        partnerMaxDaysForPOCTrialLicensesToExpire: partnerMaxDaysForPOCTrialLicensesToExpire
    });
};

export const ccChangeGroupCanCreatePOCReactive = async (zenGroupId, canCreatePOC) => {
    return await axios.post("/ccChangeGroupCanCreatePOCReactive", {
        zenGroupId: zenGroupId,
        canCreatePOC: canCreatePOC
    });
};

export const updateGroupAgentInactiveDaysReactive = async (zenGroupId, newAgentInactiveDaysSetting) => {
    return await axios.post("/updateGroupAgentInactiveDaysReactive", {
        zenGroupId: zenGroupId,
        newAgentInactiveDaysSetting: newAgentInactiveDaysSetting
    });
};

export const updateGroupRunAgentsInSafeModeReactive = async (zenGroupId, runAgentsInSafeMode) => {
    return await axios.post("/updateGroupRunAgentsInSafeModeReactive", {
        zenGroupId: zenGroupId,
        runAgentsInSafeMode: runAgentsInSafeMode
    });
};

export const addGroupProxyConfigReactive = async (zenGroupId, url, username, password) => {
    return await axios.post("/addGroupProxyConfigReactive", {
        zenGroupId: zenGroupId,
        url: url,
        username: username,
        password: password
    });
};
export const removeGroupProxyConfigReactive = async (zenGroupId, url, username) => {
    return await axios.post("/removeGroupProxyConfigReactive", {
        zenGroupId: zenGroupId,
        url: url,
        username: username
    });
};
export const editGroupProxyConfigReactive = async (zenGroupId, url, username) => {
    return await axios.post("/editGroupProxyConfigReactive", {
        zenGroupId: zenGroupId,
        url: url,
        username: username
    });
};
export const updateGroupToNotUseProxyServerReactive = async (zenGroupId) => {
    return await axios.post("/updateGroupToNotUseProxyServerReactive", {
        zenGroupId: zenGroupId,
    });
};

export const updateGroupAutomaticallyHideAgentsReactive = async (zenGroupId, hideAgentsAutomatically) => {
    return await axios.post("/updateGroupAutomaticallyHideAgentsReactive", {
        zenGroupId: zenGroupId,
        hideAgentsAutomatically: hideAgentsAutomatically
    });
};
export const updateGroupDmzModeReactive = async (zenGroupId, dmzMode) => {
    return await axios.post("/updateGroupDmzModeReactive", {
        zenGroupId: zenGroupId,
        dmzMode: dmzMode
    });
};
export const updateGroupEnableIncidentAutoDeleteReactive = async (zenGroupId, enableIncidentAutoDelete) => {
    return await axios.post("/updateGroupEnableIncidentAutoDeleteReactive", {
        zenGroupId: zenGroupId,
        enableIncidentAutoDelete: enableIncidentAutoDelete
    });
};
export const updateGroupEnableSystemTrayReactive = async (zenGroupId, enableSystemTray) => {
    return await axios.post("/updateGroupEnableSystemTrayReactive", {
        zenGroupId: zenGroupId,
        enableSystemTray: enableSystemTray
    });
};
export const editGroupIdentityHidesVisibleCCOnly = async (zenGroupId, newSetting) => {
    return await axios.post("/editGroupIdentityHidesVisibleCCOnly", {
        zenGroupId: zenGroupId,
        newSetting: newSetting
    });
};
export const editGroupIdentityBlocksVisibleCCOnly = async (zenGroupId, newSetting) => {
    return await axios.post("/editGroupIdentityBlocksVisibleCCOnly", {
        zenGroupId: zenGroupId,
        newSetting: newSetting
    });
};
export const bulkEditGroupsIdentityVisibilityCCOnly = async (zenGroupIds, identityHidesVisible, identityBlocksVisible) => {
    return await axios.post("/bulkEditGroupsIdentityVisibilityCCOnly", {
        zenGroupIds: zenGroupIds,
        identityHidesVisible: identityHidesVisible,
        identityBlocksVisible: identityBlocksVisible,
    })
};