import React, {Component, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import "../../css/private-web-pages.css";
import {AgGridReact} from "@ag-grid-community/react";
import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {NotificationContainer} from 'react-notifications';
import Modal from "react-modal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import 'react-notifications/lib/notifications.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    addRoleToUserRoleMapReactive,
    createRoleReactive,
    deleteRoleReactive,
    getGroupAndUserInfoReactive,
    removeRoleFromUserRoleMapReactive,
    updateRoleReactive,
    updateRolesGridColumnModeReactive,
    updateRolesGridColumnStateReactive,
    updateRolesGridFilterModelReactive,
    updateRolesGridUseColumnStateReactive,
    updateRolesGridUseFilterStateReactive
} from "../api/rolesApi";
import {useForm} from "react-hook-form";
import SidebarMenu from "../../components/sideBarComponent";
import {
    zenGroupCellRenderFramework,
    zenGroupFilterParamsCellRenderer,
    zenGroupFilterParamsValues
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import {getZenGroupDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {
    decryptAndGetSessionVariable,
    encryptAndStoreSessionVariable,
    getItemFromStorageWithoutDecrypting
} from "../../utils/storageHelper";
import {
    getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid,
    reviverForMapStringify
} from "../../utils/roleNamesOfCurrentUserHelper";
import {useLocation} from "react-router-dom";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {Box, Button, Checkbox, FormControlLabel, Switch, ThemeProvider, Tooltip} from "@mui/material";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";

Modal.setAppElement('#root')

const PERMISSIONS = [
    "Add User To Group",
    "Remove User From Group",
    "Change User Role",
    "Change Group Name",
    "Create Group",
    "Edit Session Duration",
    "View Session Duration",
    "Edit Group Use WHCP Drivers Only",
    "Upload SSL Certificate",
    "Remove SSL Certificate",
    "Edit Group SSL Pinning Mode"
];
const INCIDENTS = [
    "View Tailored Behaviors",
    "View Silent Responses",
    "Edit Tailored Behaviors",
    "Edit Silent Responses",
    "Delete Extortion Response Program",
    "Edit Auto-Delete Extortion Response Program"
];
const PURCHASE = [
    "Purchase Licenses",
    "Edit Partner Deal Name",
    "Remove Partner Deal Name"
];

const NOTIFICATIONS = [
    "Create Notification",
    "Edit Notification",
    "Delete Notification",
];

const MANAGE_AGENTS = [
    "Change Agent Group",
    "Uninstall Agent",
    "Change Agent Auto-Upgrade",
    "Change Agent Name",
    "Release Agent License",
    "Change Collect Agent Telemetry",
    "Change Agent Grid Visibility"
];

const APPLIANCE = [
    "Create Appliance",
    "View Appliance",
    "Create Appliance Job",
    "Cancel Appliance Job",
    "View Appliance Job"
];

const toChecklistFormat = (plain) =>
    plain.map((opt) => ({
        id: opt,
        label: opt,
    }));

//let keysToRefresh = ["roleName"]
let gridColumnStateSessionVariableName = "rolesGridColumnState"
let adminRoleReservedName = "Admin"
let readOnlyRoleReservedName = "Read Only"
let guestRoleReservedName = "Guest"
let deleteResponseRoleReservedName = "Delete Extortion Response"
let minColumnIds = ["zenGroupDisplayName", "username", adminRoleReservedName]
let medColumnIds = ["zenGroupDisplayName", "username", adminRoleReservedName] //The medium option is disabled for this grid so just re-using same columns to avoid a npe

export default function Roles() {
    const { register, handleSubmit, reset } = useForm();
    const [activeRole, setActiveRole] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [gridApi, setGridApi] = useState();
    const [zenGroup, setZenGroup] = useState();

    const permissions = toChecklistFormat(PERMISSIONS);
    const [checkedPermissions, setCheckedPermissions] = useState(new Set())

    const incidents = toChecklistFormat(INCIDENTS);
    const [checkedIncidents, setCheckedIncidents] = useState(new Set())

    const purchase = toChecklistFormat(PURCHASE);
    const [checkedPurchases, setCheckedPurchases] = useState(new Set())

    const notifications = toChecklistFormat(NOTIFICATIONS);
    const [checkedNotifications, setCheckedNotifications] = useState(new Set())

    const agents = toChecklistFormat(MANAGE_AGENTS)
    const [checkedAgents, setCheckedAgents] = useState(new Set())

    const appliances = toChecklistFormat(APPLIANCE);
    const [checkedAppliances, setCheckedAppliances] = useState(new Set())

    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("rolesGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const zenGroupDisplayNameAndUsernameLocation = useLocation();
    const [showCreateOrEditRoleModal, setShowCreateOrEditRoleModal] = useState(false);
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(300)
    }, []);
    const handleChecklist = (values, checkedSet, setCheckedSet, index, checked) => {
        let newSet = new Set(checkedSet)
        if (checked) {
            newSet.add(values[index].id)
            setCheckedSet(newSet)
        } else {
            newSet.delete(values[index].id)
            setCheckedSet(newSet)
        }
    };

    const handleCheckAll = (setCheckedSet, values, checked) => {
        setCheckedSet(new Set())

        if (checked) {
            let newSet = new Set()
            for (let index in values){
                newSet.add(values[index].id)
            }
            setCheckedSet(newSet)
        }
    }

    const clearForm = () => {
        setZenGroup(null);
        reset({ roleName: null });
        setIsEditing(false);
        setActiveRole(null);
        setCheckedPermissions(new Set());
        setCheckedIncidents(new Set());
        setCheckedPurchases(new Set());
        setCheckedNotifications(new Set());
        setCheckedAgents(new Set());
        setCheckedAppliances(new Set());
    };

    const populateRole = (roleToEdit) => {
        const { zenGroupId, roleName } = roleToEdit;
        setIsEditing(true);
        setZenGroup(zenGroupId);
        reset({ roleName: roleName });
        let currentSetPermissions = getCurrentSetPermissions(roleToEdit);
        const plainPermissions = [...currentSetPermissions];
        setCheckedPermissions(
            new Set(PERMISSIONS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedIncidents(
            new Set(INCIDENTS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedPurchases(
            new Set(PURCHASE.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedNotifications(
            new Set(NOTIFICATIONS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedAgents(
            new Set(MANAGE_AGENTS.filter((p) => plainPermissions.includes(p)))
        );
        setCheckedAppliances(
            new Set(APPLIANCE.filter((p) => plainPermissions.includes(p)))
        );
    };

    const onSubmit = async ({ roleName }) => {
        try {
            setIsLoading(true);
            const checkedItems = [
                ...checkedPermissions,
                ...checkedIncidents,
                ...checkedPurchases,
                ...checkedNotifications,
                ...checkedAgents,
                ...checkedAppliances
            ];
            if(!isEditing){ //creating a role
                if(roleName){
                    if(roleName.trim().length < 1){
                        NotificationManager.info("Please make sure the Role Name field is not empty before continuing.");
                        setIsLoading(false);
                        return;
                    }
                }
                else{
                    NotificationManager.info("Please make sure the Role Name field is not empty before continuing.");
                    setIsLoading(false);
                    return;
                }
            }
            let editRoleName = null;
            if(isEditing){ //editing a role
                if(roleName){
                    if(roleName.trim().length > 0){
                        if(activeRole && roleName.trim() !== activeRole.roleName){ //make sure they aren't the same name
                            editRoleName = roleName.trim(); //rest will take care of checking for reserved role names
                        }
                    }
                }
            }
            let response;
            if(isEditing){ //editing a role
                if(activeRole){
                    if(activeRole.roleName === adminRoleReservedName || activeRole.roleName === readOnlyRoleReservedName || activeRole.roleName === guestRoleReservedName || activeRole.roleName === deleteResponseRoleReservedName){
                        NotificationManager.error("You cannot edit this default role.");
                        setIsLoading(false);
                        return;
                    }
                    response = await updateRoleReactive(
                        editRoleName,
                        checkedItems,
                        activeRole.id
                    );
                }
                else{
                    setIsLoading(false);
                    return;
                }
            }
            else{ //creating role
                if(zenGroup){ //make sure a zenGroup is selected
                    //rest will take care of checking reserved role names
                    response = await createRoleReactive(roleName.trim(), checkedItems, zenGroup);
                }
                else{
                    NotificationManager.info("Please make sure a Group is selected before continuing.");
                    setIsLoading(false);
                    return;
                }
            }
            //if we get to here, the api call should have been made and the response stored in the 'response' variable
            if(response.roleNameExistsAlready === true){
                //the role name exists already
                NotificationManager.error("A role in this group exists with this name already, enter a different name");
                setIsLoading(false);
                return
            }
            else if(response.roleNameExistsAlready === false){
                //success for both create and updating a role
                getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid(resetGrid, refreshGridCells)
                /*getAndStoreAllRoleNamesOfCurrentUserInSession()
                resetGrid();*/
                if(isEditing){
                    NotificationManager.success(`Successfully updated this role`);
                }
                else{
                    NotificationManager.success(`Successfully created this role`);
                }
            }
        } catch (error) {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Error completing the request");
            }
            setIsLoading(false);
            return
        }
        setShowCreateOrEditRoleModal(false)
        setIsLoading(false);
    };

    const MuiChecklist = (checkedSet, values, setCheckedSet, formLabel) => (
        <Box boxShadow={3} sx={{ borderRadius: 2, display: 'inline-flex', flexDirection: 'column', ml: 0, paddingBlock: 1, paddingLeft: 2}}>
            <FormControlLabel
                label={formLabel}
                componentsProps={{ typography: { fontFamily: ['Open Sans', 'sans-serif'].join(','), fontSize: 19, fontWeight: 'bold'} }}
                control={
                    <Checkbox
                        checked={checkedSet.size === values.length}
                        indeterminate={checkedSet.size > 0 && checkedSet.size !== values.length}
                        onChange={(e) => {
                            handleCheckAll(setCheckedSet, values, e.target.checked)
                        }}
                    />
                }
            />
            <Box sx={{ display: 'inline-flex', flexDirection: 'column', ml: 0.5, mr: 0.5, maxWidth: "315px"}}>
                {values.map(
                    ({ label, id }, i) => {
                        if(label === "Delete Extortion Response Program" || label === "Edit Auto-Delete Extortion Response Program"){
                            let checked = activeRole && activeRole.roleName === deleteResponseRoleReservedName
                            return (
                                <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                         title={<div className={"text-sm"}>{checked ? "You must contact support in order to disable access to this privilege" : "You must contact support in order to gain access to this privilege"}</div>} placement={"top"}>
                                    <FormControlLabel
                                        componentsProps={{ typography: { fontFamily: ['Open Sans', 'sans-serif'].join(',') } }}
                                        key={id}
                                        label={label}
                                        disabled={true}
                                        control={
                                            <Checkbox
                                                checked={checked} name={id} id={id}
                                                style={{position: "relative", paddingTop: 1, paddingBottom: 1}}
                                                onChange={(e)=>{handleChecklist(values, checkedSet, setCheckedSet, i, e.target.checked)}}
                                            />
                                        }
                                    />
                                </Tooltip>
                            )
                        }
                        else{
                            return (
                                <FormControlLabel
                                    componentsProps={{ typography: { fontFamily: ['Open Sans', 'sans-serif'].join(',') } }}
                                    key={id}
                                    label={label}
                                    control={
                                        <Checkbox
                                            checked={checkedSet.has(label)} name={id} id={id}
                                            style={{position: "relative", paddingTop: 1, paddingBottom: 1}}
                                            onChange={(e)=>{handleChecklist(values, checkedSet, setCheckedSet, i, e.target.checked)}}
                                        />
                                    }
                                />
                            )
                        }
                    }
                )}
            </Box>
        </Box>
    )
    const [columnDefs, setColumnDefs] = useState(() => {
        return getColumnDefs()
    })

    function getColumnDefs(){
        let loggedInUsername = getItemFromStorageWithoutDecrypting("username")
        //Get set of roleNames to use for column headers and make map of zenGroupId to list of roleNames for the group
        let roleNameSet = new Set()
        //let validRoleNameMap = new Map()
        JSON.parse(decryptAndGetSessionVariable("MasterRoleList"))?.forEach((value) => {
            roleNameSet.add(value.roleName)
        })

        let headers = [
            {
                field: "zenGroupDisplayName",
                headerName: "Group",
                initialWidth: 450,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    valueFormatter: zenGroupFilterParamsCellRenderer,
                    values: zenGroupFilterParamsValues,
                    refreshValuesOnOpen: true,
                    suppressSorting: true,
                    showTooltips: true
                },
                sortable: true,
                cellRenderer: zenGroupCellRenderFramework,
                editable: true,
                cellEditor: "customNameCellEditor"
            },
            {
                field: "username", headerName: "User Name", initialWidth: 400,
                filter: 'agSetColumnFilter',
                filterParams: {
                    suppressSorting: false,
                    buttons: ["reset", "apply", "cancel"],
                },
                valueFormatter: function (params) {
                    if(loggedInUsername){
                        if (params.data.username === loggedInUsername) {
                            //for the current logged-in user, add a (me) to end of string
                            return params.data.username + " (me)"
                        }
                    }
                    return params.data.username
                },
                sortable: true
            },
            { field: "roleMap", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        ]
        //get sessionStorage values from getAndStoreAllRoleNamesOfCurrentUserInSession that will be used in valueGetters below for figuring out if user is assigned a role or not
        let zenGroupToRoleNameAndIdMapSessionStorage = decryptAndGetSessionVariable("zenGroupIdToRoleNameAndIdMap")
        let globalRoleMap = new Map()
        if(zenGroupToRoleNameAndIdMapSessionStorage){
            globalRoleMap = JSON.parse(decryptAndGetSessionVariable("zenGroupIdToRoleNameAndIdMap"), reviverForMapStringify)
        }
        //add a column for each role name in the roleNameSet
        roleNameSet.forEach((roleName) => {
            headers.push({
                field: roleName,
                headerName: roleName,
                initialWidth: 400,
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ["reset", "apply", "cancel"],
                    values: ["Assigned", "Not Assigned", "Role Does Not Exist For Group"],
                    suppressSorting: true
                },
                sortable: true,
                valueGetter: function (params) {
                    /*
                        See if a role exists in this group (params.data.zenGroupId):
                            -It does exist:
                                -check this user's roleMap for this group and see if they have this roleId
                                -They are assigned this role then return Assigned
                                -They are not assigned this role then return Not Assigned
                            -It does not exist for this group
                                -Return blank/null or does not exist
                     */
                    if(params && params.data && params.data.zenGroupId){
                        let zenGroupId = params.data.zenGroupId
                        let groupPossibleRolesMap = globalRoleMap.get(zenGroupId)
                        if(groupPossibleRolesMap && groupPossibleRolesMap.has(roleName)){
                            //there are possible roles for this group and a role with this roleName exists for this group. Check to see if user is assigned this role
                            if(params.data.roleMap && params.data.roleMap.hasOwnProperty(zenGroupId)){
                                //user has a roleMap and a key in the roleMap for zenGroupId
                                let userAssignedRoleIdsList = params.data.roleMap[zenGroupId]
                                let roleIdToCheck = groupPossibleRolesMap.get(roleName)
                                if(userAssignedRoleIdsList && userAssignedRoleIdsList.includes(roleIdToCheck)){
                                    params.data[roleName] = "Assigned"
                                    return "Assigned"
                                }
                                else{
                                    params.data[roleName] = "Not Assigned"
                                    return "Not Assigned"
                                }
                            }
                            else{
                                //else user does not have a roleMap or does not have a key in their roleMap for this zenGroupId
                                params.data[roleName] = "Not Assigned"
                                return "Not Assigned"
                            }
                        }
                        else{
                            //no existing roles for this group or group does not have this roleName
                            params.data[roleName] = "Role Does Not Exist For Group"
                            return "Role Does Not Exist For Group"
                        }
                    }
                },
                cellRenderer:
                    function (params) {
                        let data = params.node.data
                        /*if (!validRoleNameMap.get(data.zenGroupId)?.has(roleName)) {
                            return (<div className={`flex flex-row items-center`}></div>)
                        }*/
                        if (data[roleName] !== "Assigned" && data[roleName] !== "Not Assigned") {
                            //then does not exist
                            return (<div className={`flex flex-row items-center`}></div>)
                        }
                        //  produce info icon information
                        let disableToggle = false
                        if (data.username === loggedInUsername) {
                            disableToggle = true
                        }
                        let disableIconForDefaultRoles = false
                        let editTooltip = "Click to view/edit this role"
                        let deleteTooltip = "Click to delete this role inside this row's group"
                        if(roleName === adminRoleReservedName || roleName === readOnlyRoleReservedName || roleName === guestRoleReservedName || roleName === deleteResponseRoleReservedName){
                            editTooltip = "You cannot edit this default role, you may only view the permissions for this role"
                            deleteTooltip = "You cannot delete this default role"
                            disableIconForDefaultRoles = true
                        }
                        return (
                            <div className={`flex flex-row items-center gap-x-1`}>
                                <ThemeProvider theme={switchTheme}>
                                    <Switch
                                        checked={params.node.data[roleName] === "Assigned"}
                                        name={data.zenGroupId + data.userId + roleName} //  to create a unique name/input id
                                        disabled={disableToggle} //  disable the toggle for current user
                                        onChange={(e) => {
                                            //  prevent current logged-in user from changing their own roles
                                            if (data.username === loggedInUsername) {
                                                NotificationManager.error(`You can not change your own role`);
                                            } else {
                                                //else get the roleId for this roleName in this row's group
                                                let zenGroupId = data.zenGroupId
                                                let groupPossibleRolesMap = globalRoleMap.get(zenGroupId)
                                                if(groupPossibleRolesMap && groupPossibleRolesMap.has(roleName)){
                                                    let roleIdToUpdate = groupPossibleRolesMap.get(roleName)
                                                    if (!e.target.checked) {
                                                        // Remove roleId from roleMap, remove this role from user
                                                        removeRoleFromUserRoleMapReactive(roleIdToUpdate, data.username, zenGroupId).then(() => {
                                                            //NotificationManager.success(`Successfully updated user`);
                                                            e.target.checked = false
                                                            //need to remove this roleId from user roleMap
                                                            if(data.roleMap && data.roleMap.hasOwnProperty(zenGroupId)){
                                                                let userAssignedRoleIdsList = data.roleMap[zenGroupId]
                                                                if(userAssignedRoleIdsList && userAssignedRoleIdsList.includes(roleIdToUpdate)){
                                                                    data.roleMap[zenGroupId] = userAssignedRoleIdsList.filter(function (value, index, arr) {
                                                                        return value !== roleIdToUpdate;
                                                                    })
                                                                    updateUserRoleMapForRolesGridInSessionStorage(data.username, data.roleMap, zenGroupId)
                                                                    params.node.setDataValue("roleMap", data.roleMap)
                                                                }
                                                            }
                                                            params.node.setDataValue(roleName, "Not Assigned")
                                                            params.api.refreshCells({columns: [roleName], rowNodes: [params.node], suppressFlash: true, force: true})
                                                        }).catch(error => {
                                                            if (error.message) {
                                                                NotificationManager.error(error.message)
                                                            } else {
                                                                NotificationManager.error("Unexpected error sending request")
                                                            }
                                                        })
                                                    } else {
                                                        // Add roleId to roleMap for this group, assign user this role
                                                        addRoleToUserRoleMapReactive(roleIdToUpdate, data.username, zenGroupId).then(() => {
                                                            //NotificationManager.success(`Successfully updated user`);
                                                            e.target.checked = true
                                                            //need to add this roleId to user roleMap
                                                            //If roleMap is null then set it to {}
                                                            if(data.roleMap === null || data.roleMap === undefined){
                                                                data.roleMap = {}
                                                            }
                                                            //Check if roleMap does not have the key for zenGroupId yet, if no set it to empty list
                                                            if(!data.roleMap.hasOwnProperty(zenGroupId)){
                                                                data.roleMap[zenGroupId] = []
                                                            }

                                                            let userAssignedRoleIdsList = data.roleMap[zenGroupId]
                                                            //make sure userAssignedRoleIdsList is not null first
                                                            if(!userAssignedRoleIdsList){
                                                                userAssignedRoleIdsList = []
                                                            }
                                                            if(!userAssignedRoleIdsList.includes(roleIdToUpdate)){
                                                                //add roleIdToUpdate to  userAssignedRoleIdsList
                                                                userAssignedRoleIdsList.push(roleIdToUpdate)
                                                                data.roleMap[zenGroupId] = userAssignedRoleIdsList
                                                                updateUserRoleMapForRolesGridInSessionStorage(data.username, data.roleMap, zenGroupId)
                                                                params.node.setDataValue("roleMap", data.roleMap)
                                                            }
                                                            params.node.setDataValue(roleName, "Assigned")
                                                            params.api.refreshCells({columns: [roleName], rowNodes: [params.node], suppressFlash: true, force: true})
                                                        }).catch(error => {
                                                            if (error.message) {
                                                                NotificationManager.error(error.message)
                                                            } else {
                                                                NotificationManager.error("Unexpected error sending request")
                                                            }
                                                        })
                                                    }
                                                }
                                                else{
                                                    //error, this should not happen since cell renderer toggle should not be visible if role does not exist for this group
                                                }
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                                <MuiIconButtonWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="object-contain"
                                            icon="fa-duotone fa-pen-to-square"
                                            size="xs"
                                        />
                                    }
                                    onClick={() => {
                                        //open edit/view role modal
                                        let zenGroupId = data.zenGroupId
                                        let groupPossibleRolesMap = globalRoleMap.get(zenGroupId)
                                        if(groupPossibleRolesMap && groupPossibleRolesMap.has(roleName)){
                                            let roleIdToEdit = groupPossibleRolesMap.get(roleName)
                                            if(roleIdToEdit){
                                                let masterRolesList = JSON.parse(decryptAndGetSessionVariable("MasterRoleList"))
                                                if(masterRolesList){
                                                    let roleFound = masterRolesList.find((role) => role.id === roleIdToEdit)
                                                    if(roleFound){
                                                        clearForm()
                                                        setActiveRole(roleFound)
                                                        populateRole(roleFound)
                                                        setShowCreateOrEditRoleModal(true)
                                                    }
                                                }
                                            }
                                        }
                                    }}
                                    tooltipTitle={editTooltip}
                                    tooltipPlacement={"bottom-start"}
                                />
                                <MuiIconButtonWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            icon="fa-duotone fa-trash-can"
                                            size="xs"
                                            className="object-contain mr-0"
                                            color={`${disableIconForDefaultRoles ? "#C1c1c1" : ""}`}
                                        />
                                    }
                                    onClick={() => {
                                        //delete role from this group
                                        if(disableIconForDefaultRoles){
                                            return
                                        }
                                        //call to delete role
                                        let zenGroupId = data.zenGroupId
                                        let groupPossibleRolesMap = globalRoleMap.get(zenGroupId)
                                        if(groupPossibleRolesMap && groupPossibleRolesMap.has(roleName)){
                                            let roleIdToDelete = groupPossibleRolesMap.get(roleName)
                                            if(roleIdToDelete){
                                                setIsLoading(true)
                                                deleteRoleReactive(roleIdToDelete).then(response => {
                                                    setIsLoading(false)
                                                    NotificationManager.success(`Successfully deleted role: ${roleName}`);
                                                    refreshGrid().then() //this function will remove toggles for this role for this group or remove the column from grid if no more groups have this role
                                                }).catch(error => {
                                                    setIsLoading(false)
                                                    if (error.message) {
                                                        NotificationManager.error(error.message)
                                                    } else {
                                                        NotificationManager.error("Unexpected error sending request")
                                                    }
                                                })
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error sending request")
                                            }
                                        }
                                    }}
                                    tooltipTitle={deleteTooltip}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        )
                    }
            })
        })
        return headers
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Roles</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap mt-8 ml-5 mr-10 gap-y-2 h-full">
                    {privatePageHeaderHelper("Roles")}
                    <hr className="bg-black h-0.5" />
                    <Modal contentLabel="Confirm"
                           isOpen={showCreateOrEditRoleModal}
                           onRequestClose={() => {
                               setShowCreateOrEditRoleModal(false)
                               clearForm()
                           }}
                           shouldCloseOnOverlayClick={true}
                           className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-4xl max-w-6xl inset-y-10 mx-auto rounded-2xl`}
                           overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-auto"
                    >
                        <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                            {/*Title with exit button*/}
                            <div className="flex flex-row justify-between">
                                <h1 className="font-bold text-3xl">{isEditing ? `Edit Role: ${activeRole?.roleName}` : "Create New Role"}</h1>
                                <MuiCloseIconButton
                                    onClick={() => {
                                        setShowCreateOrEditRoleModal(false);
                                        clearForm()
                                    }}
                                />
                            </div>
                            <hr className="mt-3 h-0.5" />
                            {/*Form content*/}
                            <div className="flex flex-row flex-wrap w-full ">
                                <div className="ml-1 mr-1 mt-5 w-full xl:w-1/3">
                                    <label>{isEditing ? "Change Role Name" : "Role Name"}</label>
                                    <input
                                        onKeyPress={(e) => {
                                            if(e.key === 'Enter'){
                                                e.preventDefault();
                                            }}}
                                        name="roleName"
                                        type="text"
                                        required
                                        {...register("roleName")}
                                        className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                                    />
                                </div>
                                <div className="ml-1 mt-5 w-full xl:w-3/5">
                                    <label>Group</label>
                                    <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                        zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                        disabled={isEditing}
                                        value={zenGroup}
                                        onChange={( event, value ) => {
                                            setZenGroup(value?.value)
                                        }}
                                    />
                                </div>
                            </div>
                            <label className="text-xl">Permissions</label>
                            <div className="flex flex-col w-full flex-wrap gap-x-2 gap-y-5 justify-center xl:flex-row">
                                {MuiChecklist(checkedAgents, agents, setCheckedAgents, "Agent Manager")}
                                {MuiChecklist(checkedPermissions, permissions, setCheckedPermissions, "Group Manager")}
                                {MuiChecklist(checkedIncidents, incidents, setCheckedIncidents, "Incident Manager")}
                                {MuiChecklist(checkedAppliances, appliances, setCheckedAppliances, "Appliance Manager")}
                                {MuiChecklist(checkedNotifications, notifications, setCheckedNotifications, "Notification Manager")}
                                {MuiChecklist(checkedPurchases, purchase, setCheckedPurchases, "Purchase Manager")}
                            </div>
                            <div className="flex flex-row flex-wrap justify-around mb-2">
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button
                                        variant={"contained"}
                                        color={"secondary"}
                                        onClick={() => {
                                            if(isEditing){
                                                setShowCreateOrEditRoleModal(false)
                                            }
                                            clearForm();
                                        }}
                                    > {isEditing ? "Cancel" : "Clear Form"}
                                    </Button>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={handleSubmit(onSubmit)}
                                    > {isEditing ? "Update" : "Create"}
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </Modal>
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Role"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setShowCreateOrEditRoleModal(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} disableMediumOption={true} updateGridColumnModeFunction={updateRolesGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'rolesGridFilterState', updateRolesGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateRolesGridUseColumnStateReactive);
    }

    function getCurrentSetPermissions(roleToEdit){

        if (roleToEdit === null || roleToEdit === undefined) {
            return []; //return empty list
        }

        let allPermissionsList = [];
        if(roleToEdit.groupManager){
            let gm = roleToEdit.groupManager
            if(gm.addUserToGroup === true){
                allPermissionsList.push("Add User To Group")
            }
            if(gm.removeUserFromGroup === true){
                allPermissionsList.push("Remove User From Group")
            }
            if(gm.changeUserRole === true){
                allPermissionsList.push("Change User Role")
            }
            if(gm.changeGroupName === true){
                allPermissionsList.push("Change Group Name")
            }
            if(gm.createGroup === true){
                allPermissionsList.push("Create Group")
            }
            if(gm.editSessionExpiration === true){
                allPermissionsList.push("Edit Session Duration")
            }
            if(gm.listSessionExpiration === true){
                allPermissionsList.push("View Session Duration")
            }
            if(gm.editGroupWhcp === true){
                allPermissionsList.push("Edit Group Use WHCP Drivers Only")
            }
            if(gm.uploadSSLCert === true){
                allPermissionsList.push("Upload SSL Certificate")
            }
            if(gm.deleteSSLCert === true){
                allPermissionsList.push("Remove SSL Certificate")
            }
            if(gm.editGroupSSLPinningMode === true){
                allPermissionsList.push("Edit Group SSL Pinning Mode")
            }
        }
        if(roleToEdit.incidentManager){
            let im = roleToEdit.incidentManager
            if(im.viewWhitelists === true){
                allPermissionsList.push("View Tailored Behaviors")
            }
            if(im.viewSilentResponses === true){
                allPermissionsList.push("View Silent Responses")
            }
            if(im.editWhitelists === true){
                allPermissionsList.push("Edit Tailored Behaviors")
            }
            if(im.editSilentResponses === true){
                allPermissionsList.push("Edit Silent Responses")
            }
            if(im.editAutoDeleteIncident === true){
                allPermissionsList.push("Edit Auto-Delete Extortion Response Program")
            }
            if(im.deleteIncident === true){
                allPermissionsList.push("Delete Extortion Response Program")
            }
        }
        if(roleToEdit.purchaseManager){
            let pm = roleToEdit.purchaseManager
            if(pm.purchaseLicenses === true){
                allPermissionsList.push("Purchase Licenses")
            }
            if(pm.changePartnerDealName === true){
                allPermissionsList.push("Edit Partner Deal Name")
            }
            if(pm.removePartnerDealName === true){
                allPermissionsList.push("Remove Partner Deal Name")
            }
        }
        if(roleToEdit.notificationManager){
            let nm = roleToEdit.notificationManager
            if(nm.createNotification === true){
                allPermissionsList.push("Create Notification")
            }
            if(nm.editNotification === true){
                allPermissionsList.push("Edit Notification")
            }
            if(nm.deleteNotification === true){
                allPermissionsList.push("Delete Notification")
            }
        }
        if(roleToEdit.agentManager){
            let am = roleToEdit.agentManager
            if(am.changeAgentGroup === true){
                allPermissionsList.push("Change Agent Group")
            }
            if(am.uninstallAgent === true){
                allPermissionsList.push("Uninstall Agent")
            }
            if(am.changeAgentAutoUpgrade === true){
                allPermissionsList.push("Change Agent Auto-Upgrade")
            }
            if(am.changeAgentName === true){
                allPermissionsList.push("Change Agent Name")
            }
            if(am.releaseAgentLicense === true){
                allPermissionsList.push("Release Agent License")
            }
            if(am.changeAgentTelemetrySetting === true){
                allPermissionsList.push("Change Collect Agent Telemetry")
            }
            if(am.changeAgentVisibility === true){
                allPermissionsList.push("Change Agent Grid Visibility")
            }
        }
        if(roleToEdit.applianceManager){
            let am = roleToEdit.applianceManager
            if(am.createAppliance === true){
                allPermissionsList.push("Create Appliance")
            }
            if(am.queryAppliance === true){
                allPermissionsList.push("View Appliance")
            }
            if(am.createApplianceJob === true){
                allPermissionsList.push("Create Appliance Job")
            }
            if(am.queryApplianceJob === true){
                allPermissionsList.push("View Appliance Job")
            }
            if(am.cancelApplianceJob === true){
                allPermissionsList.push("Cancel Appliance Job")
            }
        }
        return allPermissionsList;
    }

    function getGrid(){
        return (
            <Grid
                headers={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                setGridApi={setGridApi}
                zenGroupDisplayNameAndUsernameLocation={zenGroupDisplayNameAndUsernameLocation}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    function updateUserRoleMapForRolesGridInSessionStorage(username, newRoleMap, zenGroupId){
        let sessionStorageVariableName = "roleGridData"
        let gridDataList = JSON.parse(decryptAndGetSessionVariable(sessionStorageVariableName))
        if(gridDataList){
            for(let i = 0; i < gridDataList.length; i++){
                //for extra precaution surround in try catch when accessing sessionStorage variables and current iterations
                try{
                    let currentObject = gridDataList[i]
                    //update this user's roleMap for this group only
                    if(currentObject["username"] === username && currentObject["zenGroupId"] === zenGroupId){
                        currentObject["roleMap"] = newRoleMap
                    }
                } catch (e) {}
            }
            encryptAndStoreSessionVariable(sessionStorageVariableName, JSON.stringify(gridDataList))
        }
    }

    async function refreshGrid(){
        //commenting out for now since this refresh code has not been updated for the new role man client side grid
        //await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "roleId", "id", findByRoleIdList, keysToRefresh)
        getAndStoreAllRoleNamesOfCurrentUserInSessionAndResetRolesGrid(resetGrid, refreshGridCells)
        //this only is checking for new/deleted roles for the columns, does not refresh user permissions yet.
    }

    function resetGrid(){
        clearForm();
        //this function is used when a new notification was created or a notification was deleted since refreshGrid() doesn't cover this yet as of July 30th
        gridApi.onFilterChanged() //preserves filter and sort models
    }
    function refreshGridCells(){
        gridApi.refreshCells()
        let newColumnDefs = getColumnDefs()
        setColumnDefs(newColumnDefs)
    }

    function excelExport(){
        //roles currently loads all data at once with no sse so pass false here
        standardExcelExportHelper(gridApi, false, "rolesGridExport")
    }
}


let saveFilterChanges = true //used for if user clicks link to come to agents page and we auto filter grid to show that specific agent, in that case we don't want to save
// the filters because that would mess with their previous filters they still may want.

class Grid extends Component {
    rowData = []

    constructor(props, onClickRow, filterVals, headers) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };

    onCellEditingStopped = (event) => {
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
    }
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateRolesGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateRolesGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateRolesGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateRolesGridColumnModeReactive, customColumnModeText)
        }
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateRolesGridColumnModeReactive)
        }
        //Medium not allowed for this grid, only min/max/custom
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update


        let roleGridData = JSON.parse(decryptAndGetSessionVariable("roleGridData"))
        if(roleGridData){
            //found roleGridData in session
            gridReadyParams.api.setGridOption("rowData", roleGridData);
        }
        else{
            const data = await getGroupAndUserInfoReactive();
            gridReadyParams.api.setGridOption("rowData", data);
        }

        //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
        if(this.props.zenGroupDisplayNameAndUsernameLocation && this.props.zenGroupDisplayNameAndUsernameLocation.state
            && this.props.zenGroupDisplayNameAndUsernameLocation.state.zenGroupDisplayName && this.props.zenGroupDisplayNameAndUsernameLocation.state.user){
            let zenGroupDisplayName = this.props.zenGroupDisplayNameAndUsernameLocation.state.zenGroupDisplayName
            let username = this.props.zenGroupDisplayNameAndUsernameLocation.state.user.username
            let locationFilterModel = {"zenGroupDisplayName": {filterType: "set", values: [zenGroupDisplayName]}}
            if(username) {
                locationFilterModel["username"] = {
                    filterType: "set",
                    values: [username]
                }
            }
            // console.log(locationFilterModel)
            //we don't want to save filter changes for the user if we are coming from a page where they clicked the agent link
            saveFilterChanges = false
            gridReadyParams.api.setFilterModel(locationFilterModel)
            //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
            //window.scroll({behavior: "smooth", top: 100, left: 0})
        } else {
            saveFilterChanges = true
            onGridReadyHelper(gridReadyParams, "rolesGridFilterState");
        }

        gridReadyParams.api.hideOverlay()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        rowData={this.rowData}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.headers}
                        components={{customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        maintainColumnOrder={true}
                        onGridReady={this.onGridReady}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onCellEditingStopped={this.onCellEditingStopped}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            //only update session and user saved filters if saveFilterChanges is true. We added this in to not save filter changes if user clicked a link to agents page because
                            // this filtering out for one agent would mess with their saved filters that they may still want.
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'rolesGridFilterState', updateRolesGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}












