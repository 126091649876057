import React, {Component, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    createEmailNotificationObjectReactive,
    deleteSingleSecurityNotificationReactive,
    findBySecurityNotificationIdListReactive,
    singleUpdateSecurityNotificationSendHiddenAgentAlertsReactive,
    singleUpdateSecurityNotificationSendRansomwareActivityAlertsForSilentIncidentsReactive,
    updateNotificationTimeIntervalReactive,
    updateNotificationTypeReactive,
    updateSecurityNotificationNameReactive,
    updateSecurityNotificationsGridColumnModeReactive,
    updateSecurityNotificationsGridColumnStateReactive,
    updateSecurityNotificationsGridFilterModelReactive,
    updateSecurityNotificationsGridUseColumnStateReactive,
    updateSecurityNotificationsGridUseFilterStateReactive
} from "../api/notificationsApi";
import {ConfirmationModal} from "../../components/confirmationModal";
import SidebarMenu from "../../components/sideBarComponent";
import * as EmailValidator from "email-validator";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";

import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import {handleGroupColumnChangeNameOnly} from "../../utils/gridCellEditing";
import {getZenGroupDropDownContents} from "../../utils/zenGroupSessionStorageManager";

import {checkPermissionAndDeleteRowNodeFromGrid,} from "../../utils/clientSideDataSessionStorageHelper";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

const INTERVAL_OPTIONS = [
    //{
    //  value: "300",
    //  label: "5 Minutes",
    //},
    {
        value: 900,
        label: "15 Minutes",
    },
    {
        value: 1800,
        label: "30 Minutes",
    },
    {
        value: 3600,
        label: "1 Hour",
    },
    {
        value: 43200,
        label: "12 Hours",
    },
    {
        value: 86400,
        label: "24 Hours",
    },
    {
        value: 604800,
        label: "1 Week",
    },
    {
        value: 2419200,
        label: "1 Month",
    },
];

//const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NOTIFICATION_TYPES = [
    //values line up with EmailNotification model. Will need to update if model is changed
    {
        value: "ransomwareActivity",
        label: "Ransomware Activity",
    },
    {
        value: "offlineAgent",
        label: "Fully Offline Agent",
    },
    {
        value: "partiallyOfflineAgent",
        label: "Partially Offline Agent",
    },
    {
        value: "non_RansomwareIntrusionActivity",
        label: "Abnormal Identity Access",
    },
    {
        value: "autoGeneratedAVWhitelist",
        label: "Behavioral Model Tuned",
    },
    {
        value: "newAgentVersion",
        label: "New Agent Version (one time alert)",
    }
]
let keysToRefresh = ["lastMessageSent", "notificationName", "emailAddress", "userCreatedItUsername", "userCreatedItUsername","timeIntervalInSeconds", "ransomwareActivity"
    , "offlineAgent", "non_RansomwareIntrusionActivity", "zenGroupId", "sendOfflineAlertsForHiddenAgents", "sendRansomwareActivityAlertsForSilentIncidents", "autoGeneratedAVWhitelist", "newAgentVersion"]
let gridColumnStateSessionVariableName = "securityNotificationsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "lastMessageSent", "ransomwareActivity", "offlineAgent", "partiallyOfflineAgent", "non_RansomwareIntrusionActivity", "autoGeneratedAVWhitelist", "newAgentVersion"]
let medColumnIds = ["zenGroupDisplayName", "lastMessageSent", "emailAddress", "timeIntervalInSeconds", "ransomwareActivity", "offlineAgent", "partiallyOfflineAgent", "non_RansomwareIntrusionActivity", "autoGeneratedAVWhitelist", "newAgentVersion"]
export default function Notifications() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    //const [zenGroup, setZenGroup] = useState();
    const [newNotificationObj, setNewNotificationObj] = useState({});
    const [newNotificationObjTypesChecked, setNewNotificationObjTypesChecked] = useState([]);
    const [enableButtons, setEnableButtons] = useState(false);
    const [createNotificationModalOpen, setCreateNotificationModalOpen] = useState(false);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const [notifyAllGroupMembersToggled, setNotifyAllGroupMembersToggled] = useState(true);
    const [sendOfflineAgentAlertsToHiddenAgentsToggled, setSendOfflineAgentAlertsToHiddenAgentsToggled] = useState(true);
    const [sendRansomwareActivityAlertsForSilentIncidentsToggled, setSendRansomwareActivityAlertsForSilentIncidentsToggled] = useState(false);
    const [gridApi, setGridApi] = useState();
    // eslint-disable-next-line no-unused-vars
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("securityNotificationsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        { field: "lastMessageSent", headerName: "Date of Last Message Sent", initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "notificationName", headerName: "Notification Name",  filter: 'agTextColumnFilter', initialWidth: 330,
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Notification's Name", "notificationName")
            }
        },
        { field: "emailAddress", headerName: "Email Address to Notify", initialWidth: 300,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "timeIntervalInSeconds", headerName: "Time Interval", initialWidth: 250,
            keyCreator: function (params){
                if(params && params.node && params.node.data){
                    return convertTimeIntervalToFormattedString(params.node.data.timeIntervalInSeconds)
                }
            },
            valueGetter: function (params){
                return convertTimeIntervalToFormattedString(params.node.data.timeIntervalInSeconds)
            },
            comparator: function(valueA, valueB, nodeA, nodeB, isDescending){
                return (nodeA.data.timeIntervalInSeconds === nodeB.data.timeIntervalInSeconds) ? 0 : (nodeA.data.timeIntervalInSeconds > nodeB.data.timeIntervalInSeconds) ? 1 : -1;
            },
            cellEditor: "agSelectCellEditor",
            editable: true,
            cellEditorParams: function (params) {
                return { cellHeight: 50, values: INTERVAL_OPTIONS.map(({ value, label}) => label)}
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["15 Minutes", "30 Minutes", "1 Hour", "12 Hours", "24 Hours", "1 Week", "1 Month"],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            sortable: true
        },
        { field: "ransomwareActivity", headerName: "Ransomware Activity", initialWidth: 290,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                    <ThemeProvider theme={switchTheme}>
                        <Switch
                            checked={params.node.data.ransomwareActivity}
                            name={`cellToggleRansomwareActivity${params.node.data.notificationId}`}
                            onChange={(changeEvent) => {
                                if(params.node.data.zenGroupId && params.node.data.notificationId){
                                    updateNotificationTypeReactive(params.node.data.zenGroupId, params.node.data.notificationId, "ransomwareActivity", !params.node.data.ransomwareActivity).then(response => {
                                        params.node.setDataValue("ransomwareActivity", !params.node.data.ransomwareActivity);
                                    }).catch(function (error) {
                                        if (error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Error updating this notification");
                                        }
                                    })
                                }
                                else{
                                    NotificationManager.error("Error updating this notification");
                                }
                            }}
                        />
                    </ThemeProvider>
                    </div>
                )
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.ransomwareActivity){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.ransomwareActivity){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true
        },
        { field: "offlineAgent", headerName: "Fully Offline Agent", initialWidth: 230,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                        <Switch
                            checked={params.node.data.offlineAgent}
                            name={`cellToggleofflineAgent${params.node.data.notificationId}`}
                            onChange={(changeEvent) => {
                                if(params.node.data.zenGroupId && params.node.data.notificationId){
                                    updateNotificationTypeReactive(params.node.data.zenGroupId, params.node.data.notificationId, "offlineAgent", !params.node.data.offlineAgent).then(response => {
                                        params.node.setDataValue("offlineAgent", !params.node.data.offlineAgent);
                                    }).catch(function (error) {
                                        if (error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Error updating this notification");
                                        }
                                    })
                                }
                                else{
                                    NotificationManager.error("Error updating this notification");
                                }
                            }}
                        />
                        </ThemeProvider>
                    </div>
                )
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.offlineAgent){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.offlineAgent){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true
        },
        { field: "partiallyOfflineAgent", headerName: "Partially Offline Agent", initialWidth: 270,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                checked={params.node.data.partiallyOfflineAgent}
                                name={`cellTogglePartiallyOfflineAgent${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.zenGroupId && params.node.data.notificationId){
                                        updateNotificationTypeReactive(params.node.data.zenGroupId, params.node.data.notificationId, "partiallyOfflineAgent", !params.node.data.partiallyOfflineAgent).then(response => {
                                            params.node.setDataValue("partiallyOfflineAgent", !params.node.data.partiallyOfflineAgent);
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                        })
                                    }
                                    else{
                                        NotificationManager.error("Error updating this notification");
                                    }
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.partiallyOfflineAgent){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.partiallyOfflineAgent){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true
        },
        { field: "non_RansomwareIntrusionActivity", headerName: "Abnormal Identity Access", initialWidth: 300,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                            checked={params.node.data.non_RansomwareIntrusionActivity}
                            name={`cellToggleAbnormalIdentityAccess${params.node.data.notificationId}`}
                            onChange={(changeEvent) => {
                                if(params.node.data.zenGroupId && params.node.data.notificationId){
                                    updateNotificationTypeReactive(params.node.data.zenGroupId, params.node.data.notificationId, "non_RansomwareIntrusionActivity", !params.node.data.non_RansomwareIntrusionActivity).then(response => {
                                        params.node.setDataValue("non_RansomwareIntrusionActivity", !params.node.data.non_RansomwareIntrusionActivity);
                                    }).catch(function (error) {
                                        if (error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Error updating this notification");
                                        }
                                    })
                                }
                                else{
                                    NotificationManager.error("Error updating this notification");
                                }
                            }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.non_RansomwareIntrusionActivity){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.non_RansomwareIntrusionActivity){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true
        },
        { field: "autoGeneratedAVWhitelist", headerName: "Behavioral Model Tuned", initialWidth: 300,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                            checked={params.node.data.autoGeneratedAVWhitelist}
                            name={`cellToggleAutoGeneratedAVWhitelist${params.node.data.notificationId}`}
                            onChange={(changeEvent) => {
                                if(params.node.data.zenGroupId && params.node.data.notificationId){
                                    updateNotificationTypeReactive(params.node.data.zenGroupId, params.node.data.notificationId, "autoGeneratedAVWhitelist", !params.node.data.autoGeneratedAVWhitelist).then(response => {
                                        params.node.setDataValue("autoGeneratedAVWhitelist", !params.node.data.autoGeneratedAVWhitelist);
                                    }).catch(function (error) {
                                        if (error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Error updating this notification");
                                        }
                                    })
                                }
                                else{
                                    NotificationManager.error("Error updating this notification");
                                }
                            }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.autoGeneratedAVWhitelist){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.autoGeneratedAVWhitelist){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true
        },
        { field: "newAgentVersion", headerName: "New Agent Version", initialWidth: 300,
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <Switch
                            checked={params.node.data.newAgentVersion}
                            name={`cellToggleNewAgentVersion${params.node.data.notificationId}`}
                            onChange={(changeEvent) => {
                                if(params.node.data.zenGroupId && params.node.data.notificationId){
                                    updateNotificationTypeReactive(params.node.data.zenGroupId, params.node.data.notificationId, "newAgentVersion", !params.node.data.newAgentVersion).then(response => {
                                        params.node.setDataValue("newAgentVersion", !params.node.data.newAgentVersion);
                                    }).catch(function (error) {
                                        if (error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Error updating this notification");
                                        }
                                    })
                                }
                                else{
                                    NotificationManager.error("Error updating this notification");
                                }
                            }}
                            />
                        </ThemeProvider>
                    </div>
                )
            },
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.newAgentVersion){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.newAgentVersion){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true
        },
        { field: "sendOfflineAlertsForHiddenAgents", headerName: "Send Offline Alerts For Hidden Agents", initialWidth: 400,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.sendOfflineAlertsForHiddenAgents){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.sendOfflineAlertsForHiddenAgents){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.sendOfflineAlertsForHiddenAgents}
                                name={`cellToggleSendOfflineAlertsForHiddenAgents${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        //setIsLoading(true)
                                        singleUpdateSecurityNotificationSendHiddenAgentAlertsReactive(params.node.data.notificationId, !params.node.data.sendOfflineAlertsForHiddenAgents).then(result => {
                                            //NotificationManager.success(`Successfully updated this notification`);
                                            params.node.setDataValue("sendOfflineAlertsForHiddenAgents",!params.node.data.sendOfflineAlertsForHiddenAgents);
                                            //Send params.node.data.sendOfflineAlertsForHiddenAgents instead of !params.node.data.sendOfflineAlertsForHiddenAgents since the setDataValue will change the sendOfflineAlertsForHiddenAgents
                                            // value to the new value
                                            /*updateObjectInSessionStorage("securityNotificationsGridList", "notificationId",
                                                params.node.data.notificationId, "sendOfflineAlertsForHiddenAgents", params.node.data.sendOfflineAlertsForHiddenAgents)*/
                                            setIsLoading(false)
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                            setIsLoading(false)

                                        })
                                    }
                                    else{
                                        NotificationManager.error("Unexpected error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "sendRansomwareActivityAlertsForSilentIncidents", headerName: "Send Ransomware Activity Alerts For Silent Responses", initialWidth: 550,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ['Send Alerts', 'Do Not Send Alerts'],
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.sendRansomwareActivityAlertsForSilentIncidents){
                        return "Send Alerts"
                    }
                    else{
                        return "Do Not Send Alerts"
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.sendRansomwareActivityAlertsForSilentIncidents){
                    return "Send Alerts"
                }
                else{
                    return "Do Not Send Alerts"
                }
            },
            sortable: true,
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme={switchTheme}>
                            <Switch
                                checked={params.node.data.sendRansomwareActivityAlertsForSilentIncidents}
                                name={`sendRansomwareActivityAlertsForSilentIncidents${params.node.data.notificationId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.notificationId){
                                        //setIsLoading(true)
                                        singleUpdateSecurityNotificationSendRansomwareActivityAlertsForSilentIncidentsReactive(params.node.data.notificationId, !params.node.data.sendRansomwareActivityAlertsForSilentIncidents).then(result => {
                                            //NotificationManager.success(`Successfully updated this notification`);
                                            params.node.setDataValue("sendRansomwareActivityAlertsForSilentIncidents",!params.node.data.sendRansomwareActivityAlertsForSilentIncidents);
                                            //Send params.node.data.sendRansomwareActivityAlertsForSilentIncidents instead of !params.node.data.sendRansomwareActivityAlertsForSilentIncidents since the setDataValue will
                                            // change the sendRansomwareActivityAlertsForSilentIncidents value to the new value
                                            /*updateObjectInSessionStorage("securityNotificationsGridList", "notificationId",
                                                params.node.data.notificationId, "sendRansomwareActivityAlertsForSilentIncidents", params.node.data.sendRansomwareActivityAlertsForSilentIncidents)*/
                                            setIsLoading(false)
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this notification");
                                            }
                                            setIsLoading(false)

                                        })
                                    }
                                    else{
                                        NotificationManager.error("Unexpected error updating this notification");
                                    }
                                }}
                            />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "userCreatedItUsername", headerName: "Created By", initialWidth: 275,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(400)
    }, []);

    const createNewNotificationSubmit = async (data) => {
        if(data && newNotificationObj){
            if(newNotificationObj.group && newNotificationObj.timeInterval){
                let emailAddressValueToSend = null
                if(!notifyAllGroupMembersToggled){
                    //validate email if notifyAllGroupMembers is not toggled
                    if(!data.emailAddress || data.emailAddress.trim().length < 1){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    data.emailAddress = data.emailAddress.trim();
                    if(!EmailValidator.validate(data.emailAddress)){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    //valid email if we get to here
                    emailAddressValueToSend = data.emailAddress;
                }
                if(newNotificationObjTypesChecked && newNotificationObjTypesChecked.length > 0){
                    let ransomwareActivity = newNotificationObjTypesChecked.includes("ransomwareActivity")
                    let non_RansomwareIntrusionActivity = newNotificationObjTypesChecked.includes("non_RansomwareIntrusionActivity")
                    let offlineAgent = newNotificationObjTypesChecked.includes("offlineAgent")
                    let partiallyOfflineAgent = newNotificationObjTypesChecked.includes("partiallyOfflineAgent")
                    let autoGeneratedAVWhitelist = newNotificationObjTypesChecked.includes("autoGeneratedAVWhitelist")
                    let newAgentVersion = newNotificationObjTypesChecked.includes("newAgentVersion")
                    let nameToSend = null
                    if(data.name && data.name.trim().length > 0){
                        nameToSend = data.name.trim()
                    }
                    setIsLoading(true)
                    try{
                        await createEmailNotificationObjectReactive(newNotificationObj.group, notifyAllGroupMembersToggled, emailAddressValueToSend, nameToSend, newNotificationObj.timeInterval,
                            ransomwareActivity, non_RansomwareIntrusionActivity, offlineAgent, autoGeneratedAVWhitelist, sendOfflineAgentAlertsToHiddenAgentsToggled, sendRansomwareActivityAlertsForSilentIncidentsToggled,
                            newAgentVersion, partiallyOfflineAgent)
                        NotificationManager.success("Successfully created email notification")
                        setNewNotificationObj({})
                        setNewNotificationObjTypesChecked([])
                        setCreateNotificationModalOpen(false)
                        setNotifyAllGroupMembersToggled(true)
                        setSendOfflineAgentAlertsToHiddenAgentsToggled(true)
                        setSendRansomwareActivityAlertsForSilentIncidentsToggled(false)
                        resetGrid();
                        reset({})
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating this email notification");
                        }
                    }
                }
                else{
                    NotificationManager.error("Please select at least one notification type, you will be able to edit this in the grid below later")
                }
            }
            else{
                NotificationManager.error("Please fill out all of the fields")
            }
        }
        setIsLoading(false)
    };

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Security Notifications</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Creat Notification Modal*/}
            <Modal contentLabel="Create New Security Notification" isOpen={createNotificationModalOpen}
                   onRequestClose={() => {
                       setCreateNotificationModalOpen(false)
                       reset({})
                       setNewNotificationObj({})
                       setNewNotificationObjTypesChecked([])
                       setNotifyAllGroupMembersToggled(true)
                       setSendOfflineAgentAlertsToHiddenAgentsToggled(true)
                       setSendRansomwareActivityAlertsForSilentIncidentsToggled(false)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-3xl max-w-3xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createNewNotificationSubmit)}>
                    <div className="flex flex-1 flex-col">
                        <div className="flex flex-row justify-center">
                            <h1 className="font-bold text-3xl">Create New Security Notification</h1>
                        </div>
                        <hr className="mt-3 h-0.5" />
                        <div className="ml-1 mt-5">
                            <label>Group</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                value={newNotificationObj.group}
                                onChange={( event, value ) => {
                                    setNewNotificationObj({ ...newNotificationObj, group: value?.value })

                                }}
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={sendOfflineAgentAlertsToHiddenAgentsToggled}
                                        name="toggleSendOfflineAgentAlertsToHiddenAgentsToggled"
                                        onChange={e => setSendOfflineAgentAlertsToHiddenAgentsToggled(e.target.checked)}
                                    />
                                } label={sendOfflineAgentAlertsToHiddenAgentsToggled ? "Send Offline Agent Alerts for Hidden Agents" : "Do Not Send Offline Agent Alerts for Hidden Agents"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={sendRansomwareActivityAlertsForSilentIncidentsToggled}
                                        name="sendRansomwareActivityAlertsForSilentIncidentsToggle"
                                        onChange={e => setSendRansomwareActivityAlertsForSilentIncidentsToggled(e.target.checked)}
                                    />
                                } label={sendRansomwareActivityAlertsForSilentIncidentsToggled ? "Send Ransomware Activity Alerts for Silent Responses" : "Do Not Send Ransomware Activity Alerts for Silent Responses"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={notifyAllGroupMembersToggled}
                                        name="toggle3"
                                        onChange={e => setNotifyAllGroupMembersToggled(e.target.checked)}
                                    />
                                } label={notifyAllGroupMembersToggled ? "Notify All Group Members" : "Notify Single Email Address"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${notifyAllGroupMembersToggled ? "hidden" : "block"} ml-1 mt-5`}>
                            <label>Email Address to Notify</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                required={!notifyAllGroupMembersToggled}
                                name="emailAddress"
                                {...register("emailAddress")}
                                placeholder="Required"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Name for Notification</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                type="text"
                                name="name"
                                {...register("name")}
                                placeholder="Optional"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>

                        <div className="ml-1 mt-5">
                            <label>Notification type:</label>
                            {NOTIFICATION_TYPES.map(({value, label}) => {
                                return (
                                    <div key={value} className="ml-1 mt-5 flex flex-row">
                                        <input
                                            className="mr-2"
                                            type="checkbox"
                                            defaultValue={newNotificationObjTypesChecked.includes(value)}
                                            onChange={(val) => {
                                                let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                if(selectedTypesArray.includes(value)){
                                                    let index = selectedTypesArray.indexOf(value);
                                                    selectedTypesArray.splice(index, 1);
                                                    setNewNotificationObjTypesChecked(selectedTypesArray);
                                                }
                                                else{
                                                    selectedTypesArray.push(value);
                                                    setNewNotificationObjTypesChecked(selectedTypesArray);
                                                }
                                            }}

                                        />
                                        <label>{label}</label>

                                    </div>

                                );
                            })}
                        </div>

                        <div className="ml-1 mt-5">
                            <label>Time Interval</label>
                            <MuiAutocompleteNonGroupOptions
                                options={INTERVAL_OPTIONS}
                                value={newNotificationObj.timeInterval}
                                onChange={(event, value) => {
                                    setNewNotificationObj({ ...newNotificationObj, timeInterval: value?.value })
                                }}
                            />
                            <div className="flex flex-1 flex-col mt-3">
                                <ThemeProvider theme = {buttonTheme}>
                                    <Button variant={"contained"}
                                            color={"primary"}
                                            type={"submit"}
                                    >
                                        Create
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            {/*Delete Notification Modal*/}
            <ConfirmationModal
                text={`You are about to delete this notification, would you like to continue?`}
                onConfirm={() => deleteNotifications()}
                onClose={() => {
                    setShowRemoveConfirmation(false);
                }}
                opened={showRemoveConfirmation}
            />
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col ml-5 mr-10 mt-8 flex-nowrap gap-y-3 h-full">
                    {privatePageHeaderHelper("Security Notifications")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create New Security Notification"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setNewNotificationObj({});
                                        reset({})
                                        setCreateNotificationModalOpen(!createNotificationModalOpen);
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Delete Security Notification"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        deleteNotifications()
                                    }}>
                                </MuiIconButtonWithTooltipAndBox>
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateSecurityNotificationsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false}
                                          refreshGridFunction = {refreshGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport}/>
                        </div>
                    </div>

                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'securityNotificationsGridFilterState', updateSecurityNotificationsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSecurityNotificationsGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    async function deleteNotifications() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let notificationRowNodeToDelete = gridApi.getSelectedNodes()[0]
            if(!notificationRowNodeToDelete){
                NotificationManager.error("Error, no rows are selected")
                return
            }
            if(!notificationRowNodeToDelete.data || !notificationRowNodeToDelete.data.notificationId){
                return
            }
            let notificationId = notificationRowNodeToDelete.data.notificationId
            deleteSingleSecurityNotificationReactive(notificationId).then(response => {
                NotificationManager.success(`Successfully deleted this notification`)
                checkPermissionAndDeleteRowNodeFromGrid(gridApi, notificationId, 'notificationId', notificationRowNodeToDelete.data.zenGroupId,
                    "notificationManager", "deleteNotification")
                setShowRemoveConfirmation(false)
                resetGrid();
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Error deleting notification");
                }
            })
        }
    }

    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "notificationId", "notificationId", findBySecurityNotificationIdListReactive, keysToRefresh)
    }

    function resetGrid(){
        gridApi && gridApi.deselectAll();
        setEnableButtons(false)
        setNotifyAllGroupMembersToggled(true)
        setSendOfflineAgentAlertsToHiddenAgentsToggled(true)
        setSendRansomwareActivityAlertsForSilentIncidentsToggled(false)
    }

    function convertTimeIntervalToFormattedString(interval){
        switch(interval) {
            case 300:
                return "5 Minutes"
            case 900:
                return "15 Minutes"
            case 1800:
                return "30 Minutes"
            case 3600:
                return "1 Hour"
            case 43200:
                return "12 Hours"
            case 86400:
                return "24 Hours"
            case 604800:
                return "1 Week"
            case 2419200 :
                return "1 Month"
            default:
                return interval
        }
    }

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "securityNotificationsGridExport")
    }
}

function updateNotificationTimeIntervalOnChange(params, newTimeInterval, gridApi){
    //setIsLoading(true);
    let oldValue = params.oldValue
    if(params.newValue === params.oldValue){
        params.node.data.timeIntervalInSeconds = oldValue
        gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
        return;
    }
    if(params.node.data.zenGroupId && params.node.data.notificationId && newTimeInterval){
        updateNotificationTimeIntervalReactive(params.node.data.zenGroupId, newTimeInterval, params.node.data.notificationId).then(() => {
            //params.node.setDataValue("timeIntervalInSeconds", newTimeInterval);
            NotificationManager.success(`Successfully updated notification: ${params.node.data.notificationName}`)
            /*updateObjectInSessionStorage("securityNotificationsGridList", "notificationId",
                params.node.data.notificationId, "timeIntervalInSeconds", newTimeInterval)*/
        }).catch(function(error){
            if(error.message){
                NotificationManager.error(error.message)
            }
            else{
                NotificationManager.error("Error updating notification")
            }
            params.node.data.timeIntervalInSeconds = oldValue
            gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
        })

    }
    else{
        params.node.data.timeIntervalInSeconds = oldValue
        gridApi.refreshCells({suppressFlash: true, rowNodes: [params.node]})
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSecurityNotificationsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateSecurityNotificationsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSecurityNotificationsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateSecurityNotificationsGridColumnModeReactive, customColumnModeText)
        }
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else if(event.column.colId === "notificationName"){
            if(event.newValue && event.newValue.trim().length > 0){
                if(event.newValue === event.oldValue){
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                if(!event.data.notificationId){
                    NotificationManager.error("Error updating name")
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                    return;
                }
                updateSecurityNotificationNameReactive(event.newValue.trim(), event.data.notificationId).then(function(response){
                    NotificationManager.success("Successfully changed the name")
                    event.node.setDataValue("notificationName", event.newValue.trim());
                    /*updateObjectInSessionStorage("securityNotificationsGridList", "notificationId",
                        event.data.notificationId, "notificationName", event.newValue.trim())*/
                }).catch(function(error){
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error updating name")
                    }
                    event.data.notificationName = event.oldValue
                    gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
                })
            }
            else{
                event.data.notificationName = event.oldValue
                gridApi.refreshCells({suppressFlash: true, rowNodes: [event.node]})
            }
        }
        else if(event.column.colId === "timeIntervalInSeconds"){
            let newTimeInterval
            INTERVAL_OPTIONS.forEach(element => {
                if(element.label === event.newValue){
                    newTimeInterval = element.value
                }
            })
            updateNotificationTimeIntervalOnChange(event, newTimeInterval, gridApi)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["notificationId"] = objectBody["id"]
        objectBody["emailAddress"] = objectBody["notifyAllGroupMembers"] ? "All Group Members" : objectBody["emailAddress"]
        objectBody["notificationName"] = objectBody["notificationUserSetName"] ? objectBody["notificationUserSetName"] : objectBody["notificationRandomName"]
        objectBody["notificationGroupName"] = objectBody["notificationGroupUserSetName"] ? objectBody["notificationGroupUserSetName"] : objectBody["notificationGroupRandomName"]
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.notificationId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateSecurityNotificationsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateSecurityNotificationsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        onGridReadyHelper(gridReadyParams, "securityNotificationsGridFilterState");
        await loadDataWithSSEAndStartChangeStreamListener("/sse/notificationsListReactive", "/sse/listenToSecurityNotificationEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)

        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        rowData={this.rowData}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={this.onCellEditingStopped}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'securityNotificationsGridFilterState', updateSecurityNotificationsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
