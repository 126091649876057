import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./storageHelper";
import {getGroupAndUserInfoReactive} from "../pages/api/rolesApi";
import {checkPermission} from "./permissionCheckHelper";
import {distinctAgentVersionsReactive} from "../pages/api/agentsApi";

//function to fire off all async calls for client side grid data and store in session
export function getAndStoreAllClientSideDataInSession(){
    //commenting out api calls for grids that we converted to sse instead of storing in session
    /*getWhitelistsReactive().then(whitelists => {
        encryptAndStoreSessionVariable("whitelistGridList", JSON.stringify(whitelists))
    }).catch(error => {})
    getSilentIncidentsReactive().then(data => {
        encryptAndStoreSessionVariable("silentIncidentsGridList", JSON.stringify(data))
    }).catch(error => {})
    getSalesNotificationsReactive().then(data => {
        encryptAndStoreSessionVariable("salesNotificationsGridList", JSON.stringify(data))
    }).catch(error => {})
    getNotificationsReactive().then(data => {
        encryptAndStoreSessionVariable("securityNotificationsGridList", JSON.stringify(data))
    }).catch(error => {})
    getSessionExpirationsReactive().then(data => {
        encryptAndStoreSessionVariable("sessionExpirationsGridList", JSON.stringify(data))
    }).catch(error => {})*/
    /*getGroupManagementListReactive().then(data => {
        encryptAndStoreSessionVariable("groupManagementGridList", JSON.stringify(data))
    }).catch(error => {})*/
    getGroupAndUserInfoReactive().then(response => {
        encryptAndStoreSessionVariable("roleGridData", JSON.stringify(response))
    }).catch(error => {})
   /* getDealsReactive().then(data => {
        encryptAndStoreSessionVariable("partnerDealsGridList", JSON.stringify(data))
    }).catch(error => {})
    getDecrytorsReactive().then(data => {
        encryptAndStoreSessionVariable("decryptorsGridList", JSON.stringify(data))
    }).catch(error => {})
    getSchedulesReactive().then(data => {
        encryptAndStoreSessionVariable("schedulesGridList", JSON.stringify(data))
    }).catch(error => {})*/
}

export function updateObjectInSessionStorage(sessionStorageVariableName, idKey, idValue, variableToChangeKey, variableToChangeValue){
    let gridDataList = JSON.parse(decryptAndGetSessionVariable(sessionStorageVariableName))
    if(gridDataList){
        for(let i = 0; i < gridDataList.length; i++){
            //for extra precaution surround in try catch when accessing sessionStorage variables and current iterations
            try{
                let currentObject = gridDataList[i]
                if(currentObject[idKey] === idValue){
                    currentObject[variableToChangeKey] = variableToChangeValue
                    break
                }
            } catch (e) {}
        }
        encryptAndStoreSessionVariable(sessionStorageVariableName, JSON.stringify(gridDataList))
    }
}

export function deleteObjectInSessionStorage(sessionStorageVariableName, idKey, idValue){
    let gridDataList = JSON.parse(decryptAndGetSessionVariable(sessionStorageVariableName))
    if(gridDataList){
        try{
            let filteredGridDataList = gridDataList.filter(object => object[idKey] !== idValue);
            encryptAndStoreSessionVariable(sessionStorageVariableName, JSON.stringify(filteredGridDataList))
        } catch (e) {}

    }
}

export function checkPermissionAndDeleteRowNodeFromGrid(gridApi, rowIdToDelete, rowIdKeyName, zenGroupId, permissionManagerName, permissionActionName){
    if(gridApi && !gridApi.destroyCalled){
        if(checkPermission(zenGroupId, permissionManagerName, permissionActionName)){
            //you only need to provide the id in the rowNode object when performing a remove transaction according to ag grid docs
            let objectToDelete = {}
            objectToDelete[rowIdKeyName] = rowIdToDelete
            gridApi.applyTransactionAsync({
                remove: [objectToDelete]
            })
        }
    }
}

export function getAndStoreDistinctAgentVersionsInSession(){
    distinctAgentVersionsReactive().then(data => {
        //store list in descending order by version number
        data?.sort()?.reverse()
        data.unshift("Do Not Update")
        encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
    }).catch(error => {})
}







