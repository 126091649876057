import React from "react";

function Footer() {
    return (
        <footer className="text-gray-600 bg-dark-grey body-font">
            <div className="bg-white">
                <div className="mx-auto pb-4 flex flex-wrap flex-col sm:flex-row ">
                    <p className="text-gray-500 text-sm sm:text-left text-left">© 2024 Cybercrucible, Inc. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )

}

export function NonGridPageFooter() {
    return (
        <footer className="text-gray-600 bg-dark-grey body-font">
            <div className="bg-white">
                <div className="mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row ">
                    <p className="text-gray-500 text-sm text-center sm:text-left text-left">© 2024 Cybercrucible, Inc. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )

}

export default Footer
