import axios from "../../utils/axiosInstance";

export const getLicensesReactive = async () => {
    const response = await axios.post("/licensesListReactive", {
    });
    return response.data;
};

export const changeLicenseGroupReactive = async (zenGroup, license) => {
    return await axios.post("/changeLicenseGroupReactive", {
        newZenGroupId: zenGroup,
        licenseId: license//only one licenseId for now
    });
};

export const changeLicenseNameReactive = async (newName, groupId, licenseId) => {
    const response = await axios.post("/changeLicenseNameReactive", {
        "newName": newName,
        "zenGroupId": groupId,
        "id": licenseId
    });
    return response;
};
export const bulkChangeLicensesGroupReactive = async (licenseCount, groupToMoveFrom, groupToMoveTo, licenseType) => {
    return await axios.post("/bulkChangeLicensesGroupReactive", {
        numberOfLicenses: licenseCount,
        groupToMoveFrom: groupToMoveFrom,
        groupToMoveTo: groupToMoveTo,
        licenseType: licenseType
    });
};

export const singleReleaseLicensesReactive = async (licenseId) => {
    return await axios.post("/singleReleaseLicenseWebReactive", {
        licenseId: licenseId
    });
};
export const getAgentFriendlyNamesReactive = async (agentIds) => {
    const response = await axios.post("/getAgentFriendlyNamesReactive",{
        agentIds
    })
    return response.data
}
export const getLicenseNameWithAgentIdReactive = async (agentId) => {
    const response = await axios.post("/getLicenseNameWithAgentIdReactive",{
        agentId: agentId
    })
    return response.data;
}
export const findByLicenseIdListReactive = async (licenseIds) => {
    const response = await axios.post("/findByLicenseIdListReactive",{
        licenseIds
    })
    return response.data
}
export const licenseUsageReactive = async () => {
    const response = await axios.post("/licenseUsageReactive",{})
    return response.data
}
export const nonExpiredLicenseUsageReactive = async () => {
    const response = await axios.post("/nonExpiredLicenseUsageReactive",{})
    return response.data
}

export const getLicensesGridFilterStateReactive = async () => {
    const response =  await axios.post("/getLicensesGridFilterStateReactive", {});
    return response.data;
};

export const updateLicensesGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateLicensesGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateLicensesGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateLicensesGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getLicensesGridColumnStateReactive = async () => {
    const response =  await axios.post("/getLicensesGridColumnStateReactive", {});
    return response.data;
};
export const updateLicensesGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateLicensesGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateLicensesGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateLicensesGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const licensesNamesListWithAgentIdReactive = async () => {
    const response = await axios.post("/licensesNamesListWithAgentIdReactive", {
    });
    return response.data;
};
export const updateLicensesGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateLicensesGridColumnModeReactive", {
        columnMode: columnMode
    })
}
export const updateAgentLicenseChartVisibilityReactive = async (visible) => {
    return await axios.post("/updateAgentLicenseChartVisibilityReactive", {
        visible: visible
    });
};
export const updateFilterExpiredLicensesByDefaultReactive = async (newFilterExpiredLicensesByDefault) => {
    return await axios.post("/updateFilterExpiredLicensesByDefaultReactive", {
        newFilterExpiredLicensesByDefault: newFilterExpiredLicensesByDefault
    });
};