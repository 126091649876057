import React, {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, CardActionArea} from "@mui/material";
export default function PartnerDemoVideos() {
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const handleContextMenu = (e) => {
            // prevent the right-click menu from appearing for videos
            if (e.target.nodeName === "VIDEO") {
                e.preventDefault();
            }
        }
        document.addEventListener("contextmenu", handleContextMenu)

        // clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener("contextmenu", handleContextMenu)
        }
    }, [])

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Demo Videos</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-5 ml-5 mr-10 mt-8">
                    {privatePageHeaderHelper("Demo Videos")}
                    <hr className="bg-black h-0.5" />
                    <div className={"flex flex-row flex-wrap grow-0 gap-y-5 gap-x-10"}>
                        <div className={"flex flex-col grow-0 gap-y-5"}>
                            <div className={"flex flex-row flex-wrap gap-y-10 gap-x-10"}>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Bitdefender With Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Bitdefender <b>WITH</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Bitdefender Without Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Bitdefender <b>WITHOUT</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/MS Endpoint With Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Microsoft Defender ATP/Endpoint <b>WITH</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/MS Endpoint Without Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Microsoft Defender ATP/Endpoint <b>WITHOUT</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/SentinelOne With Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    SentinelOne <b>WITH</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/SentinelOne Without Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    SentinelOne <b>WITHOUT</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Sophos With Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Sophos <b>WITH</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Sophos Without Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Sophos <b>WITHOUT</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Kaspersky With Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Kaspersky <b>WITH</b> Cyber Crucible Installed with Audio
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Kaspersky Without Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Kaspersky <b>WITHOUT</b> Cyber Crucible Installed with Audio
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                                <Box boxShadow={5} borderRadius={"8px"}>
                                    <Card sx={{ maxWidth: 600 }}>
                                        <CardActionArea>
                                            <CardMedia
                                                component="video"
                                                type="video/mp4"
                                                src={"/videos/Webroot Without Cyber Crucible.mp4"}
                                                alt=""
                                                controls
                                                controlsList="nodownload"
                                            />
                                            <CardContent>
                                                <Typography gutterBottom variant="h5" component="div">
                                                    Webroot <b>WITHOUT</b> Cyber Crucible Installed
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <hr className="mb-4 bg-black h-0.5" />
                    <Footer/>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
}

