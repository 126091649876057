import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {MuiAutocompleteForZenGroupsWithoutCreateGroupOption, MuiCloseIconButton} from "./muiComponents";
import {getZenGroupDropDownContents} from "../utils/zenGroupSessionStorageManager";
import {
    getBrowserUtilityProcessTuningSettingsReactive,
    submitBrowserUtilityProcessTuningReactive
} from "../pages/api/silentResponsesApi";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {Button, Switch, ThemeProvider} from "@mui/material";
import {buttonTheme, switchTheme} from "../utils/muiStyling";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {RichSelectModule} from "@ag-grid-enterprise/rich-select";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
export const chromeUtilityProcessTuningOption = "Any Chromium Utility Process"
export const chromeSandboxTuningOption = "Only When Sandbox is Disabled"
export const initialTuningGridRowData = [
    {
        "tuningOption": chromeUtilityProcessTuningOption, "whitelistChecked": false, "whitelistDisabled": false, "silentChecked": false, "silentDisabled": false
    },
    {
        "tuningOption": chromeSandboxTuningOption, "whitelistChecked": false, "whitelistDisabled": true, "silentChecked": false, "silentDisabled": true
    }
]
export const BrowserUtilityProcessTuningModal = ({tuningModalIsOpen, setTuningModalIsOpen, setIsLoading, initialZenGroup = null}) => {
    const [zenGroup, setZenGroup] = useState(null);
    const [disableTuningModalSubmitButton, setDisableTuningModalSubmitButton] = useState(true);
    const [tuningModalGridApi, setTuningModalGridApi] = useState(null);
    const [tuningGridRowData, setTuningGridRowData] = useState(initialTuningGridRowData)

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(tuningModalIsOpen && initialZenGroup !== null && initialZenGroup !== undefined && tuningModalGridApi !== null && tuningModalGridApi !== undefined){
                handleGroupOnChange(initialZenGroup)
            }
        })()
        return () => controller?.abort();
    }, [tuningModalIsOpen, initialZenGroup, tuningModalGridApi]);

    function resetTuningModal(){
        setTuningModalIsOpen && setTuningModalIsOpen(false)
        setTuningModalGridApi(null)
        setZenGroup(null)
        setDisableTuningModalSubmitButton(true)
        setTuningGridRowData(initialTuningGridRowData)
    }

    function handleGroupOnChange(newGroupId){
        tuningModalGridApi && tuningModalGridApi.showLoadingOverlay()
        setZenGroup(newGroupId)
        setDisableTuningModalSubmitButton(true) //disable, when query finished below it will be enabled again
        if(newGroupId === null || newGroupId === undefined){
            setTuningGridRowData(initialTuningGridRowData)
            tuningModalGridApi && tuningModalGridApi.hideOverlay()
            return
        }
        //else good to query for settings
        getBrowserUtilityProcessTuningSettingsReactive(newGroupId).then(response => {
            setDisableTuningModalSubmitButton(false)
            //populate grid data now
            let chromeUtilityWhitelistsExistWithoutSandboxArgs = response.chromeUtilityWhitelistsExistWithoutSandboxArgs
            let chromeUtilityWhitelistsExistWithSandboxArgs = response.chromeUtilityWhitelistsExistWithSandboxArgs
            let chromeUtilitySilentResponsesExistWithoutSandboxArgs = response.chromeUtilitySilentResponsesExistWithoutSandboxArgs
            let chromeUtilitySilentResponsesExistWithSandboxArgs = response.chromeUtilitySilentResponsesExistWithSandboxArgs

            let chromeWhitelistWithoutArgsChecked = chromeUtilityWhitelistsExistWithoutSandboxArgs || chromeUtilityWhitelistsExistWithSandboxArgs
            let chromeSilentResponseExists = chromeUtilitySilentResponsesExistWithoutSandboxArgs || chromeUtilitySilentResponsesExistWithSandboxArgs
            let chromeSilentResponseWithoutArgsChecked = chromeWhitelistWithoutArgsChecked ? false : chromeSilentResponseExists
            //1st row, initial row for any utility process
            let chromeWhitelistWithoutArgsRow = {
                "tuningOption": chromeUtilityProcessTuningOption,
                "whitelistChecked": chromeWhitelistWithoutArgsChecked,
                "whitelistDisabled": false, //whitelist never disabled for this row
                "silentChecked": chromeSilentResponseWithoutArgsChecked,
                "silentDisabled": chromeWhitelistWithoutArgsChecked
            }
            //2nd row, sandbox args row
            let chromeWhitelistWithArgsRow = {
                "tuningOption": chromeSandboxTuningOption,
                "whitelistChecked": chromeWhitelistWithoutArgsChecked ? chromeUtilityWhitelistsExistWithSandboxArgs : false,
                "whitelistDisabled": !chromeWhitelistWithoutArgsChecked,
                "silentChecked": chromeSilentResponseWithoutArgsChecked ? chromeUtilitySilentResponsesExistWithSandboxArgs : false,
                "silentDisabled": !chromeSilentResponseWithoutArgsChecked
            }
            setTuningGridRowData([chromeWhitelistWithoutArgsRow, chromeWhitelistWithArgsRow])
            tuningModalGridApi && tuningModalGridApi.hideOverlay()
        }).catch(function(error){
            setDisableTuningModalSubmitButton(false)
            setTuningGridRowData([
                {
                    "tuningOption": chromeUtilityProcessTuningOption, "whitelistChecked": false, "whitelistDisabled": false, "silentChecked": false, "silentDisabled": false
                },
                {
                    "tuningOption": chromeSandboxTuningOption, "whitelistChecked": false, "whitelistDisabled": true, "silentChecked": false, "silentDisabled": true
                }
            ])
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error, please try again.");
            }
            tuningModalGridApi && tuningModalGridApi.hideOverlay()
        })
    }

    return (
        <Modal contentLabel="Browser Utility Process Tuning"
               isOpen={tuningModalIsOpen}
               onRequestClose={() => {
                   resetTuningModal()
               }}
               shouldCloseOnOverlayClick={true}
               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-4xl max-w-4xl inset-y-10 mx-auto rounded-2xl`}
               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        >
            <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-3">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-between">
                    <h1 className="font-bold text-3xl">Browser Utility Process Tuning</h1>
                    <MuiCloseIconButton
                        onClick={() => {
                            resetTuningModal()
                        }}
                    />
                </div>
                <hr className="h-0.5" />
                {/*Form content*/}
                <div className={"ml-1"}>
                    <div className={"flex flex-col gap-y-3"}>
                        <div>Attacks against Google Chrome can leverage Chrome’s Mojom library to execute data theft, credential theft, and ransomware
                            attacks.  These attacks rely on the ability to make Chrome create a utility process that loads the mojom library.  The attackers also routinely remove
                            security sandbox features built into Chrome.</div>
                        <div>Google may also task Chrome to conduct file scanning activities, which is often undesired or at least unexpected
                            by customers.</div>
                        <div>
                            Adjust the response settings as desired for these browser utility processes.
                        </div>
                    </div>
                </div>
                <div className="ml-1">
                    <label>Select Group</label>
                    <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                        zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                        value={zenGroup}
                        onChange={( event, value ) => {
                            handleGroupOnChange(value?.value)
                        }}
                    />
                </div>
                <div id="browserUtilityTuningGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <div className="ag-theme-alpine" style={{height: 160}}>
                        <AgGridReact
                            defaultColDef={{
                                cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                            }}
                            columnDefs={[
                                {
                                    field:"tuningOption", headerName: "Option", resizable:true, initialWidth: 250,
                                    cellRenderer: function (params){
                                        let divContent = params.node.data.tuningOption
                                        if(params.node.data.tuningOption === chromeSandboxTuningOption){
                                            divContent = <div className={"indent-5"}>-{params.node.data.tuningOption}</div>
                                        }
                                        return (
                                            <div className={"flex flex-row flex-nowrap"}>
                                                {divContent}
                                            </div>
                                        )
                                    }
                                },
                                {
                                    field:"whitelistChecked", headerName: "Exclude Response", resizable:true, initialWidth: 295,
                                    cellRenderer: function (params) {
                                        return (
                                            <div className={`flex flex-row items-center`}>
                                                <ThemeProvider theme={switchTheme}>
                                                    <Switch
                                                        disabled={(zenGroup === null || zenGroup === undefined) ? true : params.node.data.whitelistDisabled}
                                                        checked={(zenGroup === null || zenGroup === undefined) ? false : params.node.data.whitelistChecked}
                                                        className={"cursor-pointer"}
                                                        onChange={(event) => {
                                                            let checked = event.target.checked

                                                            //Different settings for silentDisabled for chromeSandboxTuningOption
                                                            if(params.node.data.tuningOption === chromeUtilityProcessTuningOption){
                                                                params.api && params.api.applyTransaction({
                                                                    update: [{"tuningOption": params.node.data.tuningOption, "whitelistChecked": checked, "silentDisabled": checked, "silentChecked": false}]
                                                                })
                                                            }
                                                            else if(params.node.data.tuningOption === chromeSandboxTuningOption){
                                                                let silentDisabled = !params.node.data.silentChecked
                                                                params.api && params.api.applyTransaction({
                                                                    update: [{"tuningOption": params.node.data.tuningOption, "whitelistChecked": checked, "silentDisabled": silentDisabled, "silentChecked": false}]
                                                                })
                                                            }

                                                            //update if chromeSandboxTuningOption whitelistChecked is disabled or not
                                                            if(params.node.data.tuningOption === chromeUtilityProcessTuningOption){
                                                                let update = null
                                                                if(checked){
                                                                    update = {
                                                                        "tuningOption": chromeSandboxTuningOption, "whitelistChecked": false, "whitelistDisabled": false, "silentChecked": false, "silentDisabled": true
                                                                    }
                                                                }
                                                                else {
                                                                    update = {
                                                                        "tuningOption": chromeSandboxTuningOption, "whitelistChecked": false, "whitelistDisabled": true, "silentChecked": false, "silentDisabled": true
                                                                    }
                                                                }
                                                                params.api && params.api.applyTransaction({
                                                                    update: [update]
                                                                })
                                                            }
                                                            params.api.refreshCells({suppressFlash: true, force: true})

                                                        }}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        )
                                    },
                                },
                                {
                                    field:"silentChecked", headerName: "Respond Silently", resizable:true, initialWidth: 285,
                                    cellRenderer: function (params) {
                                        return (
                                            <div className={`flex flex-row items-center`}>
                                                <ThemeProvider theme={switchTheme}>
                                                    <Switch
                                                        disabled={(zenGroup === null || zenGroup === undefined) ? true : params.node.data.silentDisabled}
                                                        checked={(zenGroup === null || zenGroup === undefined) ? false : params.node.data.silentChecked}
                                                        className={"cursor-pointer"}
                                                        onChange={(event) => {
                                                            let checked = event.target.checked
                                                            params.node.setDataValue("silentChecked", checked);

                                                            //update if chromeSandboxTuningOption silentChecked is disabled or not
                                                            if(params.node.data.tuningOption === chromeUtilityProcessTuningOption){
                                                                let update = null
                                                                if(checked){
                                                                    update = {
                                                                        "tuningOption": chromeSandboxTuningOption, "silentChecked": false, "silentDisabled": false, "whitelistChecked": false, "whitelistDisabled": true,
                                                                    }
                                                                }
                                                                else {
                                                                    update = {
                                                                        "tuningOption": chromeSandboxTuningOption, "silentChecked": false, "silentDisabled": true, "whitelistChecked": false, "whitelistDisabled": true,
                                                                    }
                                                                }
                                                                params.api && params.api.applyTransaction({
                                                                    update: [update]
                                                                })
                                                            }
                                                            params.api.refreshCells({suppressFlash: true, force: true})

                                                        }}
                                                    />
                                                </ThemeProvider>
                                            </div>
                                        )
                                    },
                                }
                            ]}
                            rowData={tuningGridRowData}
                            getRowId={(params) => {
                                return params.data.tuningOption
                            }}
                            onGridReady={(params) => {
                                setTuningModalGridApi(params?.api)
                            }}
                            onFirstDataRendered={(params) => {
                                params.api.autoSizeAllColumns()
                            }}
                            suppressContextMenu={true}
                            suppressExcelExport={true}
                            maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                            suppressCsvExport={true}
                            modules={[ClientSideRowModelModule,RichSelectModule,SetFilterModule,MenuModule,ColumnsToolPanelModule]}
                            rowSelection={'single'}
                            suppressMultiSort={true}
                            enableCellTextSelection={true}
                            ensureDomOrder={true}
                            suppressNoRowsOverlay={true}
                        />
                    </div>
                </div>
                <div className="flex flex-1 flex-col">
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"} color={"primary"} type={"submit"} disabled={disableTuningModalSubmitButton}
                                onClick={(event) => {
                                    if(zenGroup === null || zenGroup === undefined){
                                        NotificationManager.info("Select a group before submitting")
                                        return
                                    }
                                    if(tuningModalGridApi === null || tuningModalGridApi === undefined){
                                        NotificationManager.error("Unexpected error, please try again.");
                                        return
                                    }
                                    let whitelistChromeUtilityProcess = false
                                    let whitelistChromeOnlyWhenSandboxDisabled = false

                                    let silentChromeUtilityProcess = false
                                    let silenceChromeOnlyWhenSandboxDisabled = false
                                    //get the settings from the grid to send to endpoint
                                    tuningModalGridApi.forEachNode(rowNode => {
                                        if(rowNode.data.tuningOption === chromeUtilityProcessTuningOption){
                                            whitelistChromeUtilityProcess = rowNode.data.whitelistChecked
                                            silentChromeUtilityProcess = rowNode.data.silentChecked
                                        }
                                        else if(rowNode.data.tuningOption === chromeSandboxTuningOption){
                                            whitelistChromeOnlyWhenSandboxDisabled = rowNode.data.whitelistChecked
                                            silenceChromeOnlyWhenSandboxDisabled = rowNode.data.silentChecked
                                        }
                                    })

                                    let action = "None" //default to None
                                    let sandboxArgsIncluded = false

                                    //check if whitelist is toggled, takes precendence over silence
                                    if(whitelistChromeUtilityProcess){
                                        action = "Whitelist"
                                        sandboxArgsIncluded = whitelistChromeOnlyWhenSandboxDisabled
                                    }
                                    else if(silentChromeUtilityProcess){
                                        action = "Silence"
                                        sandboxArgsIncluded = silenceChromeOnlyWhenSandboxDisabled
                                    }
                                    //else nothing toggled, action default is already "None"

                                    setIsLoading(true)
                                    submitBrowserUtilityProcessTuningReactive(zenGroup, action, sandboxArgsIncluded).then(response => {
                                        setIsLoading(false)
                                        NotificationManager.success("Successfully updated the response settings for this group");
                                        resetTuningModal()
                                    }).catch(function(error){
                                        setIsLoading(false);
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error, please try again.");
                                        }
                                    })

                                }}
                        >
                            Submit
                        </Button>
                    </ThemeProvider>
                </div>
            </div>
        </Modal>
    );
}