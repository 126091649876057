import React, {useState} from "react";
import AddIcon from "@mui/icons-material/Add";
import {MuiStandardButtonWithEndIconAndTooltip} from "./muiComponents";
import {Grow, Tooltip, ToggleButton} from "@mui/material";
import RemoveIcon from '@mui/icons-material/Remove';
import {roundButtonTheme} from "../utils/muiStyling";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    updateColumnModeInSessionHelper
} from "../utils/gridFilterStateAndColumnStateHelper";
import {decryptAndGetSessionVariable} from "../utils/storageHelper";
import {PersonSharp} from "@mui/icons-material";
import PropTypes from 'prop-types';

export const ClickToShowButtonsExpandingRight = ({buttonsDiv, buttonsText, tooltipTitle}) => {
    const [showButtons, setShowButtons] = useState(false);

    return (
        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-center">
            <MuiStandardButtonWithEndIconAndTooltip
                onClick={() => {
                    setShowButtons(!showButtons)
                }}
                endIcon={showButtons? <RemoveIcon className={"cursor-pointer"}/> : <AddIcon className={"cursor-pointer"}/>}
                sx={{
                    "&:hover": {
                        backgroundColor: "#f78f5e",
                        cursor: "pointer"
                    },
                    color: "white",
                    background: "#e76a24",
                    p: 1.3, pl: 1.8, pr: 1.8
                }}
                tooltipTitle={tooltipTitle}
                tooltipPlacement={"top"}
                buttonText={buttonsText}
                theme={roundButtonTheme}
            />
                <Grow children={buttonsDiv} in={showButtons} timeout={1000} unmountOnExit/>
        </div>
    );
};

export const minColumnModeText = "MIN"
export const mediumColumnModeText = "MED"
export const maxColumnModeText = "MAX"
export const customColumnModeText = "CUSTOM"

export const ClickToShowColumnOptionsWithToggleButtonGroup = ({
    columnMode=mediumColumnModeText, setColumnMode, buttonsText="Columns", tooltipTitle="Grid Column State Options", gridApi,
    minColumnIds=[], medColumnIds=[], gridColumnStateSessionVariableName, updateGridColumnModeFunction, disableMinOption=false, disableMediumOption=false
}) => {
    return (
        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-center justify-end">
            <ToggleButtonGroup
                value={columnMode}
                exclusive
                size={"small"}
            >
                <ToggleButton value={minColumnModeText} disabled={disableMinOption}
                              onClick={(event) => {
                                  standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridApi, setColumnMode, minColumnModeText, minColumnIds, updateGridColumnModeFunction)
                              }}
                >
                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                             title={<div className={"text-sm"}>Show Minimum Amount of Columns</div>} placement={"top"}>
                        <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-min"/>
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={mediumColumnModeText} disabled={disableMediumOption}
                              onClick={(event) => {
                                  standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridApi, setColumnMode, mediumColumnModeText, medColumnIds, updateGridColumnModeFunction)
                              }}
                >
                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                             title={<div className={"text-sm"}>Show Medium Amount of Columns</div>} placement={"top"}>
                        <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-med"/>
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={maxColumnModeText}
                              onClick={(event) => {
                                  standardApplyMaximumColumnMode(gridColumnStateSessionVariableName, gridApi, setColumnMode, maxColumnModeText, updateGridColumnModeFunction)
                              }}
                >
                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                             title={<div className={"text-sm"}>Show Maximum Amount of Columns</div>} placement={"top"}>
                        <FontAwesomeIcon size={"2xl"} className="object-contain" icon="fa-duotone fa-dial-max"/>
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={customColumnModeText}
                              onClick={(event) => {
                                  standardApplyCustomColumnMode(gridApi, setColumnMode, gridColumnStateSessionVariableName, updateGridColumnModeFunction)
                              }}
                >
                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                             title={<div className={"text-sm"}>Show Custom Column State</div>} placement={"top"}>
                        <PersonSharp />
                    </Tooltip>
                </ToggleButton>

            </ToggleButtonGroup>
        </div>
    );
};

ClickToShowColumnOptionsWithToggleButtonGroup.propTypes = {
    columnMode: PropTypes.string,
    setColumnMode: PropTypes.func,
    gridApi: PropTypes.any,
    buttonsText: PropTypes.string,
    tooltipTitle: PropTypes.string,
    minColumnIds: PropTypes.array,
    medColumnIds: PropTypes.array,
    gridColumnStateSessionVariableName: PropTypes.string,
    updateGridColumnModeFunction: PropTypes.func,
    disableMinOption: PropTypes.bool,
    disableMediumOption: PropTypes.bool
}

export function standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridApi, setColumnModeFunction, newColumnMode, colIds, updateGridColumnModeFunction, applyOrder=false){
    if(gridApi){
        setColumnModeFunction(newColumnMode)
        let currentColumnState = gridApi.getColumnState()
        let columnStateUpdated = []
        currentColumnState.forEach(function (column) {
            column.hide = !colIds.includes(column.colId);
            columnStateUpdated.push(column)
        });
        gridApi.applyColumnState({applyOrder: applyOrder, state: columnStateUpdated})
    }
    updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateGridColumnModeFunction, newColumnMode)
}

export function standardApplyMaximumColumnMode(gridColumnStateSessionVariableName, gridApi, setColumnModeFunction, newColumnMode, updateGridColumnModeFunction){
    if(gridApi){
        setColumnModeFunction(newColumnMode)
        let colIds = []
        gridApi.getColumns().forEach(function (column) {
            let colDef = column.getColDef()
            if(colDef.suppressColumnsToolPanel !== true){
                colIds.push(column.colId)
            }
        });
        gridApi.setColumnsVisible(colIds, true)
    }
    updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateGridColumnModeFunction, newColumnMode)
}

export function standardApplyCustomColumnMode(gridApi, setColumnModeFunction, gridColumnStateSessionVariableName, updateGridColumnModeFunction, applyOrder=true){
    if(gridApi){
        setColumnModeFunction(customColumnModeText)
        let currentColumnState = decryptAndGetSessionVariable(gridColumnStateSessionVariableName);
        if (currentColumnState){
            try{
                let parsedColumnState = JSON.parse(currentColumnState);
                gridApi.applyColumnState({applyOrder: applyOrder, state: parsedColumnState.columnStatesList})
            } catch (error){
                console.log(error);
            }
        }
    }
    updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateGridColumnModeFunction, customColumnModeText)
}
