import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandlePopulateGrid
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {
    bulkEditGroupsIdentityVisibilityCCOnly,
    ccGetUsersInGroupReactive,
    editGroupIdentityBlocksVisibleCCOnly,
    editGroupIdentityHidesVisibleCCOnly
} from "../api/groupsApi";
import {MasterDetailModule} from "@ag-grid-enterprise/master-detail";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import NotificationManager from "react-notifications/lib/NotificationManager";
import AddIcon from "@mui/icons-material/Add";
import {addSelfToGroupsCCOnly, listAllGroupsCCOnly} from "../api/ccOnlyAddToGroupApi";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiCloseIconButton,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import {Auth} from "aws-amplify";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {getDemoModeSetting, getTrainingModeSetting} from "../../utils/trainingAndDemoModeHelper";

export default function CCOnlyGroupIdentityAccessVisibility() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [enableButtons, setEnableButtons] = useState(false);
    const [groupsSelected, setGroupsSelected] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [allGroupsList, setAllGroupsList] = useState([]);
    const [modalIdentityHidesVisibleChecked, setModalIdentityHidesVisibleChecked] = useState(false);
    const [modalIdentityBlocksVisibleChecked, setModalIdentityBlocksVisibleChecked] = useState(false);
    const [columnDefs, setColumnDefs] = useState([
        { field: "zenGroupDisplayName", headerName: "Group", initialWidth: 400,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: true,
            },
            sortable: true,
            editable: false,
            cellRenderer: 'agGroupCellRenderer',
            comparator: function (valueA, valueB) {
                if((valueA === null || valueA === undefined) && (valueB === null || valueB === undefined)){ //if both null
                    return 0
                }
                else if(valueA === null || valueA === undefined){ //both are not null, but valueA is null, return 1 to sort valueA after valueB
                    return 1
                }
                else if(valueB === null || valueB === undefined){ //both are not null, but valueB is null, return -1 to sort valueA before valueB
                    return -1
                }
                else{
                    //else compare both lowercase values
                    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
                }
            }
        },
        { field: "organizationName", headerName: "Organization Name", sortable: true,
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            editable: false
        },
        { field: "identityHidesVisible", headerName: "Identity Access Hides Are Visible", initialWidth: 350,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values:['Hidden','Visible'],
                suppressSorting: false,
                //now in ag grid (since v29) when the col def has a keyCreator and filter is set filter, you have to provide a valueFormatter to filterParams or set convertValuesToStrings to true
                convertValuesToStrings: true
            },
            sortable: true,
            keyCreator: (params) => {
                if(params && params.node && params.node.data){ //since v29, the keyCreator params.node is null at first it seems, so this null check is needed to avoid npe
                    if(params.node.data.identityHidesVisible){
                        return "Visible";
                    }else{
                        return "Hidden";
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.identityHidesVisible){
                    return "Visible";
                }else{
                    return "Hidden";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.identityHidesVisible}
                                    name={`cellToggleIdentityHidesVisible${params.node.data.agentId}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.id){
                                            editGroupIdentityHidesVisibleCCOnly(params.node.data.id, !params.node.data.identityHidesVisible).then(response => {
                                                NotificationManager.success(`Successfully updated this setting`);
                                                params.node.setDataValue("identityHidesVisible", !params.node.data.identityHidesVisible);
                                            }).catch(function(error){
                                                if(error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error(`Unexpected error making this request`);
                                                }
                                            })
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        },
        { field: "identityBlocksVisible", headerName: "Identity Access Blocks Are Visible", initialWidth: 350,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values:['Hidden','Visible'],
                suppressSorting: false,
                //now in ag grid (since v29) when the col def has a keyCreator and filter is set filter, you have to provide a valueFormatter to filterParams or set convertValuesToStrings to true
                convertValuesToStrings: true
            },
            sortable: true,
            keyCreator: (params) => {
                if(params && params.node && params.node.data){ //since v29, the keyCreator params.node is null at first it seems, so this null check is needed to avoid npe
                    if(params.node.data.identityBlocksVisible){
                        return "Visible";
                    }else{
                        return "Hidden";
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.identityBlocksVisible){
                    return "Visible";
                }else{
                    return "Hidden";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={params.node.data.identityBlocksVisible}
                                    name={`cellToggleIdentityBlocksVisible${params.node.data.agentId}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.id){
                                            editGroupIdentityBlocksVisibleCCOnly(params.node.data.id, !params.node.data.identityBlocksVisible).then(response => {
                                                NotificationManager.success(`Successfully updated this setting`);
                                                params.node.setDataValue("identityBlocksVisible", !params.node.data.identityBlocksVisible);
                                            }).catch(function(error){
                                                if(error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error(`Unexpected error making this request`);
                                                }
                                            })
                                        }
                                    }}
                                />
                            </ThemeProvider>
                        </div>
                    )
                }
        }
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);

    function resetModal(){
        setModalIsOpen(false)
        setGroupsSelected([])
        setModalIdentityHidesVisibleChecked(false)
        setModalIdentityBlocksVisibleChecked(false)
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>CC Only Group Identity Access Visibility</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Edit Group Identity Access Page Visibility" isOpen={modalIsOpen}
                   onRequestClose={() => {
                       resetModal()
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Edit Group Identity Access Page Visibility</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetModal()
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5"/>
                    <div>
                        <label>Select the Group(s) to Edit</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={allGroupsList}
                            value={groupsSelected} multiple={true}
                            onChange={(event, valueList) => {
                                setGroupsSelected(valueList)
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={modalIdentityHidesVisibleChecked}
                                    name="modalIdentityHidesVisibleToggle"
                                    onChange={e => setModalIdentityHidesVisibleChecked(e.target.checked)}
                                />
                            } label={modalIdentityHidesVisibleChecked ? "Identity Access Hides Page Visible" : "Identity Access Hides Page Hidden"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={modalIdentityBlocksVisibleChecked}
                                    name="modalIdentityBlocksVisibleToggle"
                                    onChange={e => setModalIdentityBlocksVisibleChecked(e.target.checked)}
                                />
                            } label={modalIdentityBlocksVisibleChecked ? "Identity Access Blocks Page Visible" : "Identity Access Blocks Page Hidden"}/>
                        </ThemeProvider>
                    </div>
                    <div className="flex flex-col">
                        <ThemeProvider theme={buttonTheme}>
                            <Button variant={"contained"} type={"submit"} color={"primary"}
                                    onClick={(e) => {
                                        if (groupsSelected !== null && groupsSelected !== undefined && groupsSelected.length > 0) {
                                            setIsLoading(true)
                                            let groupIdsToSend = []
                                            groupsSelected.forEach(groupOption => {
                                                groupIdsToSend.push(groupOption.value)
                                            })
                                            bulkEditGroupsIdentityVisibilityCCOnly(groupIdsToSend, modalIdentityHidesVisibleChecked, modalIdentityBlocksVisibleChecked).then(result => {
                                                setIsLoading(false)
                                                NotificationManager.success("Successfully updated groups. Refresh the page to see the changes");
                                                resetModal()
                                            }).catch(function (error) {
                                                setIsLoading(false)
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Unexpected error making this request");
                                                }
                                            })
                                        }
                                    }}
                            >
                                Submit
                            </Button>
                        </ThemeProvider>
                    </div>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("CC Only Group Identity Access Visibility")}
                    <hr className="bg-black h-0.5"/>
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            <MuiIconButtonWithTooltipAndBox
                                icon={<FontAwesomeIcon className="object-contain" icon="fa-duotone fa-pen-to-square" size="sm"/>}
                                tooltipTitle={"The groups shown on grid have hide or block page visibility turned on. Click to edit these settings for any group (or multiple)"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    resetModal()
                                    setModalIsOpen(true)
                                    let abortController = new AbortController()
                                    //if we have not populated allGroupsList yet then do so now
                                    if(allGroupsList.length === 0) {
                                        Auth.currentSession().then(response => {
                                            let accessToken = response.getAccessToken().getJwtToken()
                                            let groupsFormattedForOptions = []
                                            fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/listAllGroupsCCOnly", {
                                                method: "POST",
                                                headers: {
                                                    "Accept": "text/event-stream",
                                                    "Content-Type": "application/json",
                                                    "Authorization": "Bearer " + accessToken
                                                },
                                                signal: abortController.signal,
                                                //Get trainingMode and demoMode settings dynamically from session
                                                body: JSON.stringify({
                                                    trainingMode: getTrainingModeSetting(),
                                                    demoMode: getDemoModeSetting()
                                                }),
                                                openWhenHidden: true, //initial sse data pull should be open when hidden so if they hide then view the same tab the initial sse data pull does not start again. This causes
                                                // problems with the populateGridFunction since it may try and add the same object/row with the same rowNodeId to the grid, which causes problems for ag grid.
                                                onmessage(event) {
                                                    let rowData = JSON.parse(event.data)
                                                    let objToPush = {}
                                                    objToPush.value = rowData.id
                                                    objToPush.label = rowData.userSetFriendlyName || rowData.friendlyName || rowData.id
                                                    groupsFormattedForOptions.push(objToPush)
                                                },
                                                onclose() {
                                                    groupsFormattedForOptions.sort(function (a, b) {
                                                        return a.label.toLowerCase().localeCompare(b.label.toLowerCase());
                                                    });
                                                    setAllGroupsList(groupsFormattedForOptions)
                                                },
                                                onerror(err) {
                                                    console.error("There was an error from server", err);
                                                    throw err //stops operations
                                                },
                                            }).then()
                                        })
                                    }
                                }}/>
                        </div>
                        <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} />
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setEnableButtons={setEnableButtons}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                        />
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };

    detailCellRendererParams = (detailParams) => {
        let groupId = detailParams.node.data.id
        let detailGridColumnDefs = [
            {
                field: "username", headerName: `Username`, sortable: true, width: 600,
                filter: 'agSetColumnFilter',
                filterParams: {
                    suppressSorting: false,
                    buttons: ["reset", "apply", "cancel"],
                }
            }
        ]
        return {
            detailGridOptions: {
                getRowId: (params) => {
                    return params.data.username;
                },
                columnDefs: detailGridColumnDefs,
                defaultColDef: {
                    resizable: true,
                    floatingFilter: true,
                    cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                },
                suppressExcelExport: true,
                suppressCsvExport: true,
                enableCellTextSelection : true,
                getContextMenuItems: (params => {return ["resetColumns", "autoSizeAll"]})
            },
            getDetailRowData: async (detailRowDataParams) => {
                let detailRowData = []
                try{
                    detailRowData = await ccGetUsersInGroupReactive(groupId)
                } catch (e) {}
                detailRowDataParams.successCallback(detailRowData)
            },
        }

    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Default sort to sort on username asc.
        if(gridReadyParams && gridReadyParams.api){
            gridReadyParams.api.applyColumnState({
                state: [{colId: "friendlyName", sort: "asc"}]
            })
        }

        await loadDataWithSSEAndStartChangeStreamListener("/listGroupsWithIdentityBlocksOrHidesVisibility", null,
            this.populateGrid, null, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, MasterDetailModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        maintainColumnOrder={true}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        detailRowHeight={400}
                        keepDetailRows={true} //keeps detail rows
                        masterDetail={true}
                        detailCellRendererParams={this.detailCellRendererParams}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
