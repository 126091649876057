import Modal from "react-modal";
import React, {useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import * as EmailValidator from "email-validator";
import {createSalesEmailNotificationObjectReactive} from "../pages/api/salesNotificationsApi";
import {createEmailNotificationObjectReactive} from "../pages/api/notificationsApi";
import {buttonTheme, switchTheme} from "../utils/muiStyling";
import {Button, FormControlLabel, Switch, ThemeProvider} from "@mui/material";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton
} from "./muiComponents";

const SALES_NOTIFICATION_TYPES = [
    //values line up with SalesEmailNotification model. Will need to update if model is changed
    {
        value: "dealExpired",
        label: "Deal Expired",
    },
    {
        value: "dealCreated",
        label: "New Partner Deal Registration (one time alert)",
    },
    {
        value: "dealDisabled",
        label: "Deal Disabled (one time alert)",
    },
    {
        value: "upcomingDealExpiration",
        label: "Upcoming Deal Expiration (one time alert)",
    },
    {
        value: "dealPOCActivity",
        label: "Deal POC Activity for Agent Installs/Uninstalls and POC Finished",
    },
    {
        value: "invoiceCreated",
        label: "Invoice Sent (one time alert)",
    }
]
const SECURITY_NOTIFICATION_TYPES = [
    //values line up with EmailNotification model. Will need to update if model is changed
    {
        value: "ransomwareActivity",
        label: "Ransomware Activity",
    },
    {
        value: "offlineAgent",
        label: "Fully Offline Agent",
    },
    {
        value: "partiallyOfflineAgent",
        label: "Partially Offline Agent",
    },
    {
        value: "non_RansomwareIntrusionActivity",
        label: "Abnormal Identity Access",
    },
    {
        value: "autoGeneratedAVWhitelist",
        label: "Behavioral Model Tuned",
    },
    {
        value: "newAgentVersion",
        label: "New Agent Version (one time alert)",
    }
]
//only displayed in new sales notification form, users can't select or deselect any of these options though
const UPCOMING_DEAL_EXPIRATION_OPTIONS = [
    {
        label: "14 Days",
    },
    {
        label: "7 Days",
    },
    {
        label: "3 Days",
    },
    {
        label: "1 Day",
    },
    {
        label: "Final Notice",
    }
]
const INTERVAL_OPTIONS = [
    //{
    //  value: "300",
    //  label: "5 Minutes",
    //},
    {
        value: "900",
        label: "15 Minutes",
    },
    {
        value: "1800",
        label: "30 Minutes",
    },
    {
        value: "3600",
        label: "1 Hour",
    },
    {
        value: "43200",
        label: "12 Hours",
    },
    {
        value: "86400",
        label: "24 Hours",
    },
    {
        value: "604800",
        label: "1 Week",
    },
    {
        value: "2419200",
        label: "1 Month",
    },
];

export const PartnerOnBoardingHomeModal = ({ onClose, opened, salesNotificationButtonDisabled, securityNotificationButtonDisabled, setSalesNotificationSetupModalOpened,
                                               setSecurityNotificationSetupModalOpened, salesNotificationGroups, securityNotificationGroups}) => {
    return (
        <Modal
            onRequestClose={() => {
                onClose()
            }}
            isOpen={opened}
            contentLabel={"Channel Partner On-Boarding"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-xl lg:w-2xl md:w-2xl sm:w-2xl xs:w-11/12 max-w-2xl inset-y-10 mx-auto rounded-2xl overflow-auto`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
            shouldCloseOnEsc={false}
        >
            <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                {/*Title with exit button*/}
                <div className="flex flex-row justify-center">
                    <h1 className="font-bold text-3xl">Complete Channel Partner <br /> On-Boarding</h1>
                </div>
                <hr className="h-0.5" />
                {/*Form content*/}
                <div className="ml-1 w-full">
                    <label>We have some additional items that you need to setup in order to complete your channel partner on-boarding process.</label>
                </div>
                {!salesNotificationButtonDisabled && salesNotificationGroups && salesNotificationGroups.length > 0 &&
                    <div className={"flex flex-col gap-y-1"}>
                        <label>Partner Groups that Need to Setup Sales Notification:</label>
                            {salesNotificationGroups.map(({id, friendlyName}) => {
                                return (
                                    <div className={"ml-7"} key={id}>
                                        <li>{friendlyName}</li>
                                    </div>
                                );
                            })}
                    </div>
                }
                {!securityNotificationButtonDisabled && securityNotificationGroups && securityNotificationGroups.length > 0 &&
                    <div className={"flex flex-col gap-y-1"}>
                        <label>Partner Groups that Need to Setup Security Notification:</label>
                        {securityNotificationGroups.map(({id, friendlyName}) => {
                            return (
                                <div className={"ml-7"} key={id}>
                                    <li>{friendlyName}</li>
                                </div>
                            );
                        })}
                    </div>
                }
                <div className="flex flex-row flex-wrap justify-between gap-y-3 gap-x-2">
                    <ThemeProvider theme={buttonTheme}>
                        <Button
                            variant={"contained"} className={"flex-1"}
                            color={"primary"}
                            style={{minWidth:"150px"}}
                            disabled={salesNotificationButtonDisabled}
                            onClick={() => {
                                setSalesNotificationSetupModalOpened && setSalesNotificationSetupModalOpened(true)
                            }}>
                            Setup Sales Notification
                        </Button>
                        <Button
                            variant={"contained"} className={"flex-1"}
                            color={"primary"}
                            style={{minWidth:"150px"}}
                            disabled={securityNotificationButtonDisabled}
                            onClick={() => {
                                setSecurityNotificationSetupModalOpened && setSecurityNotificationSetupModalOpened(true)
                            }} >
                            Setup Security Notification
                        </Button>
                    </ThemeProvider>
                </div>

            </div>

        </Modal>
    );
};

export const PartnerOnBoardingSetupSalesNotificationModal = ({ onSuccess, opened, setOpened, zenGroups, setIsLoading}) => {
    const { register, handleSubmit, reset} = useForm();
    const [newNotificationObj, setNewNotificationObj] = useState({});
    const [newNotificationObjTypesChecked, setNewNotificationObjTypesChecked] = useState([]);
    const [notifyAllGroupMembersToggled, setNotifyAllGroupMembersToggled] = useState(true);

    const createNewNotificationSubmit = async (data) => {
        if(data && newNotificationObj){
            if(newNotificationObj.group && newNotificationObj.timeInterval){
                let emailAddressValueToSend = null
                if(!notifyAllGroupMembersToggled){
                    //validate email if notifyAllGroupMembers is not toggled
                    if(!data.emailAddress || data.emailAddress.trim().length < 1){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    data.emailAddress = data.emailAddress.trim();
                    if(!EmailValidator.validate(data.emailAddress)){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    //valid email if we get to here
                    emailAddressValueToSend = data.emailAddress;
                }
                if(newNotificationObjTypesChecked && newNotificationObjTypesChecked.length > 0){
                    let dealCreated = newNotificationObjTypesChecked.includes("dealCreated")
                    let dealDisabled = newNotificationObjTypesChecked.includes("dealDisabled")
                    let dealExpired = newNotificationObjTypesChecked.includes("dealExpired")
                    let upcomingDealExpiration = newNotificationObjTypesChecked.includes("upcomingDealExpiration")
                    let invoiceCreated = newNotificationObjTypesChecked.includes("invoiceCreated")
                    let dealPOCActivity = newNotificationObjTypesChecked.includes("dealPOCActivity")

                    let nameToSend = null
                    if(data.name && data.name.trim().length > 0){
                        nameToSend = data.name.trim()
                    }
                    setIsLoading && setIsLoading(true)
                    try{
                        await createSalesEmailNotificationObjectReactive(newNotificationObj.group, notifyAllGroupMembersToggled, emailAddressValueToSend, nameToSend, newNotificationObj.timeInterval,
                            dealCreated, dealDisabled, dealExpired, upcomingDealExpiration, invoiceCreated, dealPOCActivity)
                        NotificationManager.success("Successfully created email notification")
                        setNewNotificationObj({})
                        setNewNotificationObjTypesChecked([])
                        setOpened(false)
                        setNotifyAllGroupMembersToggled(true)
                        reset({})
                        onSuccess && onSuccess(newNotificationObj.group)
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating this email notification");
                        }
                    }
                }
                else{
                    NotificationManager.error("Please initially select at least one notification type, you will be able to edit this in the grid below later")
                }
            }
            else{
                NotificationManager.error("Please fill out all of the fields")
            }
        }
        setIsLoading && setIsLoading(false)
    };


    return (
        <Modal contentLabel="Setup Sales Notification" isOpen={opened}
               onRequestClose={() => {
                   setOpened(false)
                   reset({})
                   setNewNotificationObj({})
                   setNewNotificationObjTypesChecked([])
                   setNotifyAllGroupMembersToggled(true)
               }} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false}
               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"

        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createNewNotificationSubmit)}>
                <div className="flex flex-1 flex-col gap-y-4">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Setup Sales Notification</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                reset({})
                                setOpened(false)
                            }}
                        />
                    </div>
                    <hr className="h-0.5" />
                    <div className="ml-1">
                        <label>Channel Partner Group</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={zenGroups.map(({ id, organizationName, friendlyName }) => ({
                                value: id,
                                label: organizationName || friendlyName || id,
                            }))}
                            value={newNotificationObj.group}
                            onChange={( event, value ) => {
                                setNewNotificationObj({ ...newNotificationObj, group: value?.value })
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center ml-1`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={notifyAllGroupMembersToggled}
                                    name="toggle3"
                                    onChange={e => setNotifyAllGroupMembersToggled(e.target.checked)}
                                />
                            } label={notifyAllGroupMembersToggled ? "Notify All Group Members" : "Notify Single Email Address"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${notifyAllGroupMembersToggled ? "hidden" : "block"} ml-1`}>
                        <label>Email Address to Notify</label>
                        <input
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            type="text"
                            required={!notifyAllGroupMembersToggled}
                            name="emailAddress"
                            {...register("emailAddress")}
                            placeholder="Required"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="ml-1">
                        <label>Name for Notification</label>
                        <input
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            type="text"
                            name="name"
                            {...register("name")}
                            placeholder="Optional"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>

                    <div className="ml-1">
                        <label>Notification type:</label>
                        {SALES_NOTIFICATION_TYPES.map(({value, label}) => {
                            return (
                                <div key={value}>
                                    {value === "upcomingDealExpiration" ? (
                                        <div className="flex flex-col">
                                            <div className="ml-1 mt-5 flex flex-row">
                                                <input
                                                    className="mr-2"
                                                    type="checkbox"
                                                    defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                    onChange={(val) => {
                                                        let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                        if(selectedTypesArray.includes(value)){
                                                            let index = selectedTypesArray.indexOf(value);
                                                            selectedTypesArray.splice(index, 1);
                                                            setNewNotificationObjTypesChecked(selectedTypesArray);
                                                        }
                                                        else{
                                                            selectedTypesArray.push(value);
                                                            setNewNotificationObjTypesChecked(selectedTypesArray);
                                                        }
                                                    }}

                                                />
                                                <label>{label}</label>
                                            </div>
                                            {UPCOMING_DEAL_EXPIRATION_OPTIONS.map(({label}) => {
                                                return (
                                                    <div key={label} className="ml-5 mt-1 flex flex-row">
                                                        <input
                                                            disabled={true}
                                                            className="mr-2"
                                                            type="checkbox"
                                                            checked={newNotificationObjTypesChecked.includes(value)}
                                                            defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                        />
                                                        <label>{label}</label>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    ) : (
                                        <div className="ml-1 mt-5 flex flex-row">
                                            <input
                                                className="mr-2"
                                                type="checkbox"
                                                defaultValue={newNotificationObjTypesChecked.includes(value)}
                                                onChange={(val) => {
                                                    let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                                    if(selectedTypesArray.includes(value)){
                                                        let index = selectedTypesArray.indexOf(value);
                                                        selectedTypesArray.splice(index, 1);
                                                        setNewNotificationObjTypesChecked(selectedTypesArray);
                                                    }
                                                    else{
                                                        selectedTypesArray.push(value);
                                                        setNewNotificationObjTypesChecked(selectedTypesArray);
                                                    }
                                                }}

                                            />
                                            <label>{label}</label>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>

                    <div className="ml-1">
                        <label>Time Interval</label>
                        <MuiAutocompleteNonGroupOptions
                            options={INTERVAL_OPTIONS}
                            value={newNotificationObj.timeInterval}
                            onChange={(event, value) => {
                                setNewNotificationObj({ ...newNotificationObj, timeInterval: value?.value })
                            }}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                type="submit">
                                Create
                        </Button>
                    </ThemeProvider>
                </div>
            </form>
        </Modal>
    );
};

export const PartnerOnBoardingSetupSecurityNotificationModal = ({ onSuccess, opened, setOpened, zenGroups, setIsLoading}) => {
    const { register, handleSubmit, reset} = useForm();
    const [newNotificationObj, setNewNotificationObj] = useState({});
    const [newNotificationObjTypesChecked, setNewNotificationObjTypesChecked] = useState([]);
    const [notifyAllGroupMembersToggled, setNotifyAllGroupMembersToggled] = useState(true);
    const [sendOfflineAgentAlertsToHiddenAgentsToggled, setSendOfflineAgentAlertsToHiddenAgentsToggled] = useState(true);
    const [sendRansomwareActivityAlertsForSilentIncidentsToggled, setSendRansomwareActivityAlertsForSilentIncidentsToggled] = useState(false);

    const createNewNotificationSubmit = async (data) => {
        if(data && newNotificationObj){
            if(newNotificationObj.group && newNotificationObj.timeInterval){
                let emailAddressValueToSend = null
                if(!notifyAllGroupMembersToggled){
                    //validate email if notifyAllGroupMembers is not toggled
                    if(!data.emailAddress || data.emailAddress.trim().length < 1){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    data.emailAddress = data.emailAddress.trim();
                    if(!EmailValidator.validate(data.emailAddress)){
                        NotificationManager.error("Please enter a valid email address")
                        return;
                    }
                    //valid email if we get to here
                    emailAddressValueToSend = data.emailAddress;
                }
                if(newNotificationObjTypesChecked && newNotificationObjTypesChecked.length > 0){
                    let ransomwareActivity = newNotificationObjTypesChecked.includes("ransomwareActivity")
                    let non_RansomwareIntrusionActivity = newNotificationObjTypesChecked.includes("non_RansomwareIntrusionActivity")
                    let offlineAgent = newNotificationObjTypesChecked.includes("offlineAgent")
                    let partiallyOfflineAgent = newNotificationObjTypesChecked.includes("partiallyOfflineAgent")
                    let autoGeneratedAVWhitelist = newNotificationObjTypesChecked.includes("autoGeneratedAVWhitelist")
                    let newAgentVersion = newNotificationObjTypesChecked.includes("newAgentVersion")

                    let nameToSend = null
                    if(data.name && data.name.trim().length > 0){
                        nameToSend = data.name.trim()
                    }
                    setIsLoading(true)
                    try{
                        await createEmailNotificationObjectReactive(newNotificationObj.group, notifyAllGroupMembersToggled, emailAddressValueToSend, nameToSend, newNotificationObj.timeInterval,
                            ransomwareActivity, non_RansomwareIntrusionActivity, offlineAgent, autoGeneratedAVWhitelist, sendOfflineAgentAlertsToHiddenAgentsToggled, sendRansomwareActivityAlertsForSilentIncidentsToggled,
                            newAgentVersion, partiallyOfflineAgent)
                        NotificationManager.success("Successfully created email notification")
                        setNewNotificationObj({})
                        setNewNotificationObjTypesChecked([])
                        setOpened(false)
                        setNotifyAllGroupMembersToggled(true)
                        setSendOfflineAgentAlertsToHiddenAgentsToggled(true)
                        setSendRansomwareActivityAlertsForSilentIncidentsToggled(false)
                        reset({})
                        onSuccess && onSuccess(newNotificationObj.group)
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Error creating this email notification");
                        }
                    }
                }
                else{
                    NotificationManager.error("Please select at least one notification type, you will be able to edit this in the grid below later")
                }
            }
            else{
                NotificationManager.error("Please fill out all of the fields")
            }
        }
        setIsLoading(false)
    };

    return (
        <Modal contentLabel="Setup Security Notification" isOpen={opened}
               onRequestClose={() => {
                   setOpened(false)
                   reset({})
                   setNewNotificationObj({})
                   setNewNotificationObjTypesChecked([])
                   setNotifyAllGroupMembersToggled(true)
                   setSendOfflineAgentAlertsToHiddenAgentsToggled(true)
                   setSendRansomwareActivityAlertsForSilentIncidentsToggled(false)
               }} shouldCloseOnOverlayClick={false} shouldCloseOnEsc={false}
               className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
               overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createNewNotificationSubmit)}>
                <div className="flex flex-1 flex-col gap-y-4">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Setup Security Notification</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                reset({})
                                setOpened(false)
                            }}
                        />
                    </div>
                    <hr className="h-0.5" />
                    <div className="ml-1">
                        <label>Channel Partner Group</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={zenGroups.map(({ id, organizationName, friendlyName }) => ({
                                value: id,
                                label: organizationName || friendlyName || id,
                            }))}
                            value={newNotificationObj.group}
                            onChange={( event, value ) => {
                                setNewNotificationObj({ ...newNotificationObj, group: value?.value })
                            }}
                        />
                    </div>
                    <div className={`flex flex-row items-center ml-1`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={sendOfflineAgentAlertsToHiddenAgentsToggled}
                                    name="toggleSendOfflineAgentAlertsToHiddenAgentsToggled"
                                    onChange={e => setSendOfflineAgentAlertsToHiddenAgentsToggled(e.target.checked)}
                                />
                            } label={sendOfflineAgentAlertsToHiddenAgentsToggled ? "Send Offline Agent Alerts for Hidden Agents" : "Do Not Send Offline Agent Alerts for Hidden Agents"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center ml-1`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={sendRansomwareActivityAlertsForSilentIncidentsToggled}
                                    name="sendRansomwareActivityAlertsForSilentIncidentsToggle"
                                    onChange={e => setSendRansomwareActivityAlertsForSilentIncidentsToggled(e.target.checked)}
                                />
                            } label={sendRansomwareActivityAlertsForSilentIncidentsToggled ? "Send Ransomware Activity Alerts for Silent Responses" : "Do Not Send Ransomware Activity Alerts for Silent Responses"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`flex flex-row items-center ml-1`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={notifyAllGroupMembersToggled}
                                    name="toggle3"
                                    onChange={e => setNotifyAllGroupMembersToggled(e.target.checked)}
                                />
                            } label={notifyAllGroupMembersToggled ? "Notify All Group Members" : "Notify Single Email Address"}/>
                        </ThemeProvider>
                    </div>
                    <div className={`${notifyAllGroupMembersToggled ? "hidden" : "block"} ml-1`}>
                        <label>Email Address to Notify</label>
                        <input
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            type="text"
                            required={!notifyAllGroupMembersToggled}
                            name="emailAddress"
                            {...register("emailAddress")}
                            placeholder="Required"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className="ml-1">
                        <label>Name for Notification</label>
                        <input
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            type="text"
                            name="name"
                            {...register("name")}
                            placeholder="Optional"
                            className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>

                    <div className="ml-1">
                        <label>Notification type:</label>
                        {SECURITY_NOTIFICATION_TYPES.map(({value, label}) => {
                            return (
                                <div key={value} className="ml-1 mt-5 flex flex-row">
                                    <input
                                        className="mr-2"
                                        type="checkbox"
                                        defaultValue={newNotificationObjTypesChecked.includes(value)}
                                        onChange={(val) => {
                                            let selectedTypesArray = newNotificationObjTypesChecked.slice();
                                            if(selectedTypesArray.includes(value)){
                                                let index = selectedTypesArray.indexOf(value);
                                                selectedTypesArray.splice(index, 1);
                                                setNewNotificationObjTypesChecked(selectedTypesArray);
                                            }
                                            else{
                                                selectedTypesArray.push(value);
                                                setNewNotificationObjTypesChecked(selectedTypesArray);
                                            }
                                        }}

                                    />
                                    <label>{label}</label>

                                </div>

                            );
                        })}
                    </div>

                    <div className="ml-1">
                        <label>Time Interval</label>
                        <MuiAutocompleteNonGroupOptions
                            options={INTERVAL_OPTIONS}
                            value={newNotificationObj.timeInterval}
                            onChange={(event, value) => {
                                setNewNotificationObj({ ...newNotificationObj, timeInterval: value?.value })
                            }}
                        />
                    </div>

                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                type="submit">
                            Create
                        </Button>
                    </ThemeProvider>
                </div>
            </form>
        </Modal>
    );
};



















