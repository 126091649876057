import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./storageHelper";
import {getDemoModeSetting, getTrainingModeSetting} from "./trainingAndDemoModeHelper";
import axios from 'axios';

const tokenProvider = require('axios-token-interceptor');

//const oauth = require('axios-oauth-client');

//const axios = require('axios')

const appClientId = process.env.REACT_APP_APP_CLIENT_ID;
const redirectURI = process.env.REACT_APP_REDIRECT_URI;
const logoutRedirectURI = process.env.REACT_APP_LOGOUT_REDIRECT_URI;
const oauthTokenEndpoint = process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT;
const oauthLogoutURL = process.env.REACT_APP_OAUTH_LOGOUT_ENDPOINT;

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_BASE_URL
});
export const axiosNoRequestTokenInterceptorInstance = axios.create({
    baseURL: process.env.REACT_APP_AXIOS_BASE_URL
});

axiosInstance.defaults.headers.post['Content-Type'] = "application/json";
axiosInstance.defaults.headers.post["Accept"] = "application/json";
axiosNoRequestTokenInterceptorInstance.defaults.headers.post['Content-Type'] = "application/json";
axiosNoRequestTokenInterceptorInstance.defaults.headers.post["Accept"] = "application/json";

//intercept the response from api call, checks for errors returned response
axiosInstance.interceptors.response.use(function (response) {
    //returns with no error
    return response;
}, function (error) {
    //returns with an error
    if(error && error.response && error.response.data && error.response.data.exceptionReason){ //check for our custom exceptions thrown by checking 'exceptionReason' field in response
        throw new Error(error.response.data.exceptionReason)
        //just a string with the custom error message, adding a check for exceptionReason lets is know if it is a custom exception thrown by us, if not then just reject normally below
    }
    //else
    return Promise.reject(error);
});
axiosNoRequestTokenInterceptorInstance.interceptors.response.use(function (response) {
    //returns with no error
    return response;
}, function (error) {
    //returns with an error
    if(error && error.response && error.response.data && error.response.data.exceptionReason){ //check for our custom exceptions thrown by checking 'exceptionReason' field in response
        throw new Error(error.response.data.exceptionReason)
        //just a string with the custom error message, adding a check for exceptionReason lets is know if it is a custom exception thrown by us, if not then just reject normally below
    }
    //else
    return Promise.reject(error);
});
//This is used so we can send the trainingMode and demoMode settings in every request by default
axiosInstance.interceptors.request.use(
    function(req) {
        if(req.data){
            //Get session variables for trainingMode and demoMode dynamically from the session
            let trainingMode = getTrainingModeSetting()
            let demoMode = getDemoModeSetting()
            req.data["trainingMode"] = trainingMode
            req.data["demoMode"] = demoMode
        }
        return req
    }
);
axiosNoRequestTokenInterceptorInstance.interceptors.request.use(
    function(req) {
        if(req.data){
            //Get session variables for trainingMode and demoMode dynamically from the session
            let trainingMode = getTrainingModeSetting()
            let demoMode = getDemoModeSetting()
            req.data["trainingMode"] = trainingMode
            req.data["demoMode"] = demoMode
        }
        return req
    }
);

export function resetAxiosNonTokenRelatedInterceptorsAndApplyAgain(){
    try{
        //clear interceptors
        axiosInstance.interceptors.request.clear()
        axiosInstance.interceptors.response.clear()
        //apply again
        axiosInstance.interceptors.response.use(function (response) {
            //returns with no error
            return response;
        }, function (error) {
            //returns with an error
            if(error && error.response && error.response.data && error.response.data.exceptionReason){ //check for our custom exceptions thrown by checking 'exceptionReason' field in response
                throw new Error(error.response.data.exceptionReason)
                //just a string with the custom error message, adding a check for exceptionReason lets is know if it is a custom exception thrown by us, if not then just reject normally below
            }
            //else
            return Promise.reject(error);
        });

        axiosInstance.interceptors.request.use(
            function(req) {
                if(req.data){
                    //Get session variables for trainingMode and demoMode dynamically from the session
                    let trainingMode = getTrainingModeSetting()
                    let demoMode = getDemoModeSetting()
                    req.data["trainingMode"] = trainingMode
                    req.data["demoMode"] = demoMode
                }
                return req
            }
        );
    } catch (e) {}
}

//checks to see if there is a refresh token in session storage
export function checkRefreshToken() {
    let refreshToken = decryptAndGetSessionVariable("refresh_token")
    if (refreshToken !== null) {

        let refreshTokenFormData = {
            'grant_type': "refresh_token",
            "client_id": String(appClientId),
            "refresh_token": String(refreshToken)
        }
        let oauthAxiosRequestConfig = {
            url:oauthTokenEndpoint,
            data:refreshTokenFormData,
            method: "POST",
            headers:{'Content-Type': 'application/x-www-form-urlencoded'}
        }
        let oauthAxiosInstance = axios.create({});
        oauthAxiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
        oauthAxiosInstance(oauthAxiosRequestConfig).then(res => {
            return res;
        },(mal) => {
            return null;
        });
        axiosInstance.interceptors.request.use(
            tokenProvider({
                getToken: tokenProvider.tokenCache(
                    () => oauthAxiosInstance(oauthAxiosRequestConfig).then(res => {
                        return res.data;
                    },(mal) => {
                        return null;
                    }),
                    {getMaxAge: (body) => body.expires_in * 1000}
                ),
                headerFormatter: (body) => 'Bearer ' + body.access_token,
            })
        )

    }
}

export function getAuthorizationCode(code_argument){
  if(code_argument){
    let code_verifier = decryptAndGetSessionVariable('code_verifier');
    let oauthAxiosInstance = axios.create({});
    oauthAxiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
    oauthAxiosInstance.interceptors.response.use(
        response => {
            if(response.config.url === oauthTokenEndpoint){
                encryptAndStoreSessionVariable("refresh_token", response.data["refresh_token"])
            }
            return response;
        }
    )

      let authorizationCodeFormData = {
          'grant_type': "authorization_code",
          "client_id": String(appClientId),
          "redirect_uri": String(redirectURI),
          "state":"STATE",
          "code":code_argument,
          "code_verifier":code_verifier,

      }
      let authorizationCodeRequestConfig = {
          url:oauthTokenEndpoint,
          data:authorizationCodeFormData,
          method: "POST",
          headers:{'Content-Type': 'application/x-www-form-urlencoded'}
      }
      let authCodeRequestAxiosInstance = axios.create({});
      authCodeRequestAxiosInstance.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded';
      axiosInstance.interceptors.request.use(
          tokenProvider({
              getToken: tokenProvider.tokenCache(
                  () => authCodeRequestAxiosInstance(authorizationCodeRequestConfig).then(res => {
                      return res.data;
                  },(mal) => {
                      return null;
                  }),
                  {getMaxAge: (body) => body.expires_in * 1000}
              ),
              headerFormatter: (body) => 'Bearer ' + body.access_token,
          })
      )
  }else{
    console.log("No oauth code found");
  }
}

export function handleLogout(){
    //clear sessionStorage
    sessionStorage.clear();
    localStorage.clear()
    //sign out by calling to oauthLogoutURL
    window.location = oauthLogoutURL + "?client_id=" + appClientId +"&logout_uri=" + logoutRedirectURI;
}


export default axiosInstance
