import React, {Component, useEffect, useMemo, useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import {
    ccApproveDealReactive,
    ccCreateStripeSubscriptionForDealReactive,
    ccRejectDealReactive,
    ccRenewDealReactive,
    changePartnerDealNameReactive, createPartnerPOCReactive,
    disablePartnerDealReactive,
    enablePartnerDealReactive,
    partnerDealRequestNet30PaymentReactive,
    partnerDealResendInvoiceReactive,
    removePartnerDealNameReactive,
    sendPartnerDealPricingQuotePDFReactive,
    updateDealsGridColumnModeReactive,
    updateDealsGridColumnStateReactive,
    updateDealsGridFilterModelReactive,
    updateDealsGridUseColumnStateReactive,
    updateDealsGridUseFilterStateReactive,
    updateReferralPartnerDealCCDashboardAccountReactive
} from "../api/dealsApi";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import SidebarMenu from "../../components/sideBarComponent";
import 'react-phone-number-input/style.css'
import * as EmailValidator from 'email-validator';
import {ConfirmationModal} from "../../components/confirmationModal";
import {dateValueFormatter} from "../../utils/gridDateFormatter";

import {
    findZenGroupById,
    getGroupNameForDealsGridForChangeStream,
    getZenGroupOrOrganizationNames,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    partnerDealsPageVolumePricingTableVisibleSessionVariable,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {decryptAndGetSessionVariable, getItemFromStorageWithoutDecrypting} from "../../utils/storageHelper";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {autocompleteTheme, cellButtonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Autocomplete, Button, Switch, TextField, ThemeProvider, Tooltip} from "@mui/material";
import {
    MuiCloseIconButton,
    MuiDealStatusLogoIconWithMenu,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox, MuiIconWithTooltip,
    MuiPartnerDealPaymentIconWithMenu,
    MuiPartnerDealQuoteIconWithMenu
} from "../../components/muiComponents";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {PopupModal} from "react-calendly";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useLocation} from "react-router-dom";
import {InformationModal} from "../../components/informationOnlyModal";
import {base64ToArrayBuffer, getDateStringForAgGridFilter} from "./incidents";
import {CreateAndEditPartnerDealModal} from "../../components/createAndEditPartnerDealModal";
import {
    ccGetPartnerGroupDaysUntilInvoiceDueSettingsReactive,
    ccGetPartnerGroupsReactive,
    getPartnerGroupSettingsReactive
} from "../api/groupsApi";
import {maxDaysUntilInvoiceDue, minDaysUntilInvoiceDue} from "./ccOnlyPartnerGroupManagement";
import {ccRetrieveUsernamesReactive} from "../api/notificationsApi";
import {
    dealsGridPocGroupColumnValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    findNotificationEventForDealExpiredAutoFilter,
    findNotificationEventForSingleDealAutoFilter
} from "../api/notificationEventApi";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

function createVolumePricingTableRow(licenseCount, volumeDiscount, pricePerServerLicense, pricePerDesktopLicense) {
    return { licenseCount, volumeDiscount, pricePerServerLicense, pricePerDesktopLicense };
}
/*const volumePricingTableRows = [
    createVolumePricingTableRow('1-99', '0%', "$120.00", "$60.00"),
    createVolumePricingTableRow('100-499', '5%', "$114.00", "$57.00"),
    createVolumePricingTableRow('500-999', '8%', "$110.40", "$55.20"),
    createVolumePricingTableRow('1,000-4,999', '10%', "$108.00", "$54.00"),
    createVolumePricingTableRow('5,000-9,999', '15%', "$102.00", "$51.00"),
    createVolumePricingTableRow('10,000-49,999', '20%', "$96.00", "$48.00"),
    createVolumePricingTableRow('50,000-99,999', '25%', "$90.00", "$45.00"),
];*/

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});
export const threeYearBillingCycleText = "3 Years"
export const twoYearBillingCycleText = "2 Years"
export const annuallyBillingCycleText = "Annually"
export const monthlyBillingCycleText = "Monthly"
export const serverLicensePriceYearly = 140.00
export const serverLicensePrice2YearsUpfront = 252.00
export const serverLicensePrice2YearsYearlyBilling = 133.00
export const serverLicensePrice3YearsUpfront = 357.00
export const serverLicensePrice3YearsYearlyBilling = 126.00
export const serverLicensePriceMonthly = 13
export const desktopLicensePriceYearly = 70.00
export const desktopLicensePrice2YearsUpfront = 126.00
export const desktopLicensePrice2YearsYearlyBilling = 66.50
export const desktopLicensePrice3YearsUpfront = 178.50
export const desktopLicensePrice3YearsYearlyBilling = 63.00
export const desktopLicensePriceMonthly = 6.50
export const billingCycleOptions = [threeYearBillingCycleText, twoYearBillingCycleText, annuallyBillingCycleText, monthlyBillingCycleText]
let dealPendingText = "Pending"
let dealApprovedText = "Approved"
let dealRejectedText = "Rejected"
let dealRenewText = "Renew"
let referralBillPartnerDealTypeText = "Referral_Bill_Partner"
let referralBillCustomerDealTypeText = "Referral_Bill_Customer"
let channelPartnerDealTypeText = "Channel_Partner"
let distributorDealTypeText = "Distributor"
let tableVisibilitySessionVariableName = partnerDealsPageVolumePricingTableVisibleSessionVariable
let gridColumnStateSessionVariableName = "partnerDealsGridColumnState"
let minColumnIds = ["cyberCrucibleApproved", "prospectOrganization", "submitted", "expirationDate", "dealLength", "billingCycle", "approximateTotalCashValue", "prospectEmail", "createdBy"]
let medColumnIds = ["cyberCrucibleApproved", "prospectOrganization", "partnerDealDisplayName", "submitted", "expirationDate", "dealCompleted", "numberOfServerLicensesBought", "numberOfDesktopLicensesBought",
    "dealLength", "billingCycle", "approximateTotalCashValue", "dealType", "zenGroupDisplayName", "prospectEmail", "ccDashboardAccountUsername", "createdBy"]
export default function Deals() {
    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState();
    const [showNewDealModal, setShowNewDealModal] = useState(false);
    const [dealModalIsForNewDeal, setDealModalIsForNewDeal] = useState(true);
    const { register: registerSignedQuote, handleSubmit: handleSubmitSignedQuote, reset: resetSignedQuoteForm } = useForm();
    const { register: registerPOCSetup, handleSubmit: handlePOCSetupSubmit, reset: resetPOCSetup } = useForm();
    const [isCyberCrucibleUser, setIsCyberCrucibleUser] = useState(getItemFromStorageWithoutDecrypting("username")?.endsWith("@cybercrucible.com"));
    const [activeDeal, setActiveDeal] = useState();
    const [showDisableConfirmation, setShowDisableConfirmation] = useState(false);
    const [showEnableConfirmation, setShowEnableConfirmation] = useState(false);
    const [showConfirmCreateSubscriptionModal, setShowConfirmCreateSubscriptionModal] = useState(false);
    const [showNet30RequestedInformationModal, setShowNet30RequestedInformationModal] = useState(false);
    const [showNet30RequestedInformationModalForReferralCustomerBillingDeals, setShowNet30RequestedInformationModalForReferralCustomerBillingDeals] = useState(false);
    const [pocSetupModalOpen, setPocSetupModalOpen] = useState(false);
    const [pocSetupModalPartnerGroupCanCreatePOC, setPOCSetupModalPartnerGroupCanCreatePOC] = useState(false);
    const [pocSetupAdminUsersList, setPOCSetupAdminUsersList] = useState([]);
    const [pocSetupUsersOnlyList, setPocSetupUsersOnlyList] = useState([]);
    const [pocSetupModalMaxTrialLicenseDays, setPOCSetupModalMaxTrialLicenseDays] = useState(14); //default of 14 days
    const [showErrorCreatingUserForPOCModal, setShowErrorCreatingUserForPOCModal] = useState(false);
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [gridApi, setGridApi] = useState();
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("dealsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [calendlyModalOpen, setCalendlyModalOpen] = useState(false);
    const [userChannelPartnerGroupSettingsList, setUserChannelPartnerGroupSettingsList] = useState([]);
    const [ccUserAllPartnerGroupsList, setCCUserAllPartnerGroupsList] = useState([]);
    const [ccAllUsernamesList, setCCAllUsernamesList] = useState([]);
    const partnerDealLocation = useLocation();
    //const [tableToggled, setTableToggled] = useState(getChartVisibilitySettingInSession(tableVisibilitySessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        { field: "dealId", hide: true, suppressColumnsToolPanel: true, lockVisible: true,
            //This col is only added in so we can filter by dealId easily for auto filters for deal notifications, its not visible on grid
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },
        { field: "cyberCrucibleApproved", headerName: "Deal Flow", initialWidth: 250,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: [dealPendingText, dealApprovedText, dealRejectedText, dealRenewText],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    let dealExpired = isDealExpiredHelper(params.node.data.expirationDate)
                    let dealStatus = params.node.data.dealStatus
                    if(dealStatus === dealPendingText){
                        return dealPendingText
                    }
                    else if(dealStatus === dealRejectedText){
                        return dealRejectedText
                    }
                    else if(dealStatus === dealApprovedText){
                        //check if expired
                        if(dealExpired){
                            return dealRenewText
                        }
                        else{
                            return dealApprovedText
                        }
                    }
                    else{
                        //else dealStatus was null so default to Pending
                        return dealPendingText
                    }
                }
            },
            valueGetter: (params) => {
                let dealExpired = isDealExpiredHelper(params.node.data.expirationDate)
                let dealStatus = params.node.data.dealStatus
                if(dealStatus === dealPendingText){
                    return dealPendingText
                }
                else if(dealStatus === dealRejectedText){
                    return dealRejectedText
                }
                else if(dealStatus === dealApprovedText){
                    //check if expired
                    if(dealExpired){
                        return dealRenewText
                    }
                    else{
                        return dealApprovedText
                    }
                }
                else{
                    //else dealStatus was null so default to Pending
                    return dealPendingText
                }
            },
            cellRenderer: function(params) {
                let dealExpired = isDealExpiredHelper(params.node.data.expirationDate)
                let dealStatus = params.node.data.dealStatus
                let iconDiv = ""
                let editDealIconDiv =
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-pen-to-square"
                                size="sm"
                            />
                        }
                        onClick={() => {
                            setActiveDeal(params.node.data)
                            setDealModalIsForNewDeal(false)
                            setShowNewDealModal(true)
                        }}
                        tooltipTitle={"Click to edit this deal"}
                        tooltipPlacement={"bottom-start"}
                    />
                let disableEnableIconDiv = (params.node.data.disabledDate ?
                    (<MuiIconButtonWithTooltip disabled={isCyberCrucibleUser} icon={<DoneAllIcon fontSize={"small"} className={"cursor-pointer"}/>} tooltipTitle={"Enable Deal"} tooltipPlacement={"bottom-start"}
                        onClick={() => {
                            //Deal is currently disabled so show enabled confirmation
                            setActiveDeal(params.node.data)
                            setShowEnableConfirmation(true)
                        }}
                    />) :
                    (<MuiIconButtonWithTooltip
                        disabled={isCyberCrucibleUser}
                        icon={<RemoveCircleOutlineIcon fontSize={"small"} className={"cursor-pointer"}/>} tooltipTitle={"Disable Deal"} tooltipPlacement={"bottom-start"}
                        onClick={() => {
                            //Deal is currently enabled so show disabled confirmation
                            setActiveDeal(params.node.data)
                            setShowDisableConfirmation(true)
                        }}
                    />))

                if(dealStatus === dealRejectedText){
                    //Deal has been rejected, show a deal rejected icon with an icon for cc users to approve the rejected deal
                    iconDiv =
                        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                            {disableEnableIconDiv}
                            {editDealIconDiv}
                            <MuiDealStatusLogoIconWithMenu
                                partnerDealId={params.node.data.dealId} fontAwesomeIcon={"fa-solid fa-xmark"} isCyberCrucibleUser={isCyberCrucibleUser} iconColor={"red"}
                                tooltipTitle={`${isCyberCrucibleUser ? "This deal has been rejected. Click to change deal status. Note that changing the deal status will send an alert to users in this group" : "This deal has been rejected by Cyber Crucible"}`}
                                tooltipPlacement={"bottom-start"}
                                onApproveDealClick={() => {approveDealClickedHelper(params)}}
                                hideRenewOption={true} hideRejectOption={true} hideSubscriptionOption={true}
                            />
                        </div>
                }
                else if(dealStatus === dealApprovedText){
                    //Deal has been approved, check if it is expired and needs renewed
                    if(dealExpired){
                        //Deal is expired, needs renewal
                        iconDiv =
                            <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                                {disableEnableIconDiv}
                                {editDealIconDiv}
                                <MuiDealStatusLogoIconWithMenu
                                    partnerDealId={params.node.data.dealId} isCyberCrucibleUser={isCyberCrucibleUser} fontAwesomeIcon={"fa-duotone fa-clock-rotate-left"}
                                    tooltipTitle={`${isCyberCrucibleUser ? "This deal has been approved but needs renewed. Click to change deal status. Note that changing the deal status will send an alert to users in this group" : "This deal is currently expired and awaiting renewal from Cyber Crucible"}`}
                                    tooltipPlacement={"bottom-start"}
                                    onRenewDealClick={() => {renewDealClickedHelper(params)}}
                                    onRejectDealClick={() => {rejectDealClickedHelper(params)}}
                                    hideRenewOption={false} hideApproveOption={true} hideRejectOption={false} hideSubscriptionOption={true}
                                />
                            </div>
                    }
                    else{
                        //Deal is approved and not expired, so we can show the payment icons
                        let disableInternalPricing = false
                        if(params.node.data.dealType === "Referral_Bill_Customer"){
                            disableInternalPricing = true
                        }
                        iconDiv =
                            <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                                {disableEnableIconDiv}
                                <MuiPartnerDealQuoteIconWithMenu
                                    params={params} partnerDealId={params.node.data.dealId} tooltipPlacement={"bottom-start"} isCyberCrucibleUser={isCyberCrucibleUser} tooltipTitle={"Send Quote or Download"}
                                    disableInternalPricingOptions={disableInternalPricing}
                                    onQuoteOptionClickedFunction={(sendQuoteToAllGroupMembers, showInternalPricing) => {
                                        setIsLoading(true)
                                        sendPartnerDealPricingQuotePDFReactive(params.node.data.dealId, sendQuoteToAllGroupMembers, showInternalPricing).then(response => {
                                            NotificationManager.success("Successfully sent quote for this deal")
                                            setIsLoading(false)
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error completing this request");
                                            }
                                            setIsLoading(false)
                                        })
                                    }}
                                    onDownloadSignedQuoteClickedFunction={() => {
                                        if(params.node.data.signedQuotePDFBytes){
                                            try{
                                                let prospectOrganization = params.node.data.prospectOrganization
                                                let bytes = base64ToArrayBuffer(params.node.data.signedQuotePDFBytes); // pass your byte response to this constructor
                                                let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                let link=document.createElement('a');
                                                link.href=window.URL.createObjectURL(blob);
                                                link.download=`SignedPartnerDealQuote-${prospectOrganization ? prospectOrganization : params.node.data.partnerDealDisplayName}.pdf`;
                                                link.click();
                                            } catch(error){
                                                NotificationManager.error("Unexpected error downloading signed quote");
                                            }
                                        }
                                    }}
                                />
                                <MuiPartnerDealPaymentIconWithMenu
                                    params={params}
                                    partnerDealId={params.node.data.dealId} tooltipPlacement={"bottom-start"} isCyberCrucibleUser={isCyberCrucibleUser} tooltipTitle={"Pay for Deal"}
                                    onPaymentOptionClickedFunction={(net30Clicked) => {
                                        if(isCyberCrucibleUser){
                                            //User was a CC user and should not be clicking this button
                                            return
                                        }
                                        //Else user is not a CC user and can proceed
                                        //Anticipating two options for net30 and pay now, for now net30 payment is the only option
                                        if(net30Clicked){
                                            //user pressed Net 30 options
                                            setIsLoading(true)
                                            partnerDealRequestNet30PaymentReactive(params.node.data.dealId).then(response => {
                                                //NotificationManager.success("Successfully requested net 30 payment for this deal")
                                                setIsLoading(false)
                                                if(params.node.data.dealType === "Referral_Bill_Customer"){
                                                    setShowNet30RequestedInformationModalForReferralCustomerBillingDeals(true)
                                                }
                                                else{
                                                    setShowNet30RequestedInformationModal(true)
                                                }
                                            }).catch(error => {
                                                if(error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Unexpected error completing this request");
                                                }
                                                setIsLoading(false)
                                            })
                                        }
                                        /*else{
                                            //else they clicked pay now option
                                        }*/
                                    }}
                                    onResendInvoiceButtonClicked={() => {
                                        if(isCyberCrucibleUser){
                                            //User was a CC user and should not be clicking this button
                                            return
                                        }
                                        //else user is not a cc user and can continue
                                        setIsLoading(true)
                                        partnerDealResendInvoiceReactive(params.node.data.dealId).then(response => {
                                            setIsLoading(false)
                                            let invoiceSentThroughStripe = response.invoiceSentThroughStripe
                                            if(invoiceSentThroughStripe){
                                                NotificationManager.success("Successfully sent invoice")
                                            }
                                            else{
                                                NotificationManager.success("A Cyber Crucible salesperson will resend the invoice for this deal soon")
                                            }
                                        }).catch(error => {
                                            if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Unexpected error completing this request");
                                            }
                                            setIsLoading(false)
                                        })

                                    }}
                                />
                                {editDealIconDiv}
                                <MuiDealStatusLogoIconWithMenu
                                    partnerDealId={params.node.data.dealId} fontAwesomeIcon={"fa-solid fa-check"} iconColor={"green"} isCyberCrucibleUser={isCyberCrucibleUser}
                                    tooltipTitle={`${isCyberCrucibleUser ? "This deal has been approved. Click to change deal status. Note that changing the deal status will send an alert to users in this group" : "This deal has been approved by Cyber Crucible"}`}
                                    tooltipPlacement={"bottom-start"}
                                    onRejectDealClick={() => {rejectDealClickedHelper(params)}}
                                    params={params}
                                    onCreateSubscription={() => {
                                        setActiveDeal(params.node.data)
                                        setShowConfirmCreateSubscriptionModal(true)
                                        //Get the group setting for days until invoice is due
                                        ccGetPartnerGroupDaysUntilInvoiceDueSettingsReactive(params.node.data.zenGroupId).then(response => {
                                            if(response && response.partnerInvoiceDaysUntilDue){
                                                resetSignedQuoteForm({daysUntilPartnerDealInvoiceDue: response.partnerInvoiceDaysUntilDue})
                                            }
                                            else{
                                                //default to 30
                                                resetSignedQuoteForm({daysUntilPartnerDealInvoiceDue: "30"})
                                            }
                                        }).catch(error => {
                                            //default to 30
                                            resetSignedQuoteForm({daysUntilPartnerDealInvoiceDue: "30"})
                                        })
                                    }}
                                    hideRenewOption={true} hideApproveOption={true} hideRejectOption={false} hideSubscriptionOption={false}
                                />
                            </div>
                    }
                }
                else{
                    //Deal is not rejected or approved, so value is Pending or null. Show pending icon
                    iconDiv =
                        <div className={"flex flex-row flex-nowrap gap-x-2 items-center"}>
                            {disableEnableIconDiv}
                            {editDealIconDiv}
                            <MuiDealStatusLogoIconWithMenu
                                partnerDealId={params.node.data.dealId} fontAwesomeIcon={"fa-duotone fa-calendar-clock"} isCyberCrucibleUser={isCyberCrucibleUser}
                                tooltipTitle={`${isCyberCrucibleUser ? "This deal is awaiting approval. Click to change deal status. Note that changing the deal status will send an alert to users in this group" : "This deal is awaiting review from Cyber Crucible"}`}
                                tooltipPlacement={"bottom-start"}
                                onApproveDealClick={() => {approveDealClickedHelper(params)}}
                                onRejectDealClick={() => {rejectDealClickedHelper(params)}}
                                hideRenewOption={true} hideSubscriptionOption={true}
                            />
                        </div>
                }

                return (
                    <div className={"flex flex-nowrap justify-start gap-x-2 items-center"}>
                        {iconDiv}
                    </div>
                )
            },
            pinned: "left",
            lockPinned: true,
            lockVisible: true,
            sortable: true
        },
        { field: "prospectOrganization", headerName: "Prospect Org", initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "partnerDealDisplayName", headerName: "Deal Name", initialWidth: 430,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Deal's Name", "partnerDealDisplayName")
            },
            onCellValueChanged: async (event) => {
                let newName = event.newValue;
                if(newName && newName.trim().length > 0){
                    if(!event.data.dealId){
                        NotificationManager.error("Error updating deal name")
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                        resetGrid()
                        return;
                    }
                    try{
                        setIsLoading(true)
                        await changePartnerDealNameReactive(newName.trim(), event.data.dealId)
                        NotificationManager.success("Successfully changed this deal's name")
                        /*updateObjectInSessionStorage("partnerDealsGridList", "dealId",
                            event.data.dealId, "partnerDealDisplayName", newName.trim())*/
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message)
                        }
                        else{
                            NotificationManager.error("Error updating deal name")
                        }
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                    }
                    resetGrid()
                    setIsLoading(false)
                }
                else{
                    /*
                        Else make call to remove the deal's userSetFriendlyName, then in the future the random name
                        will be shown on the grid for the specific deal
                     */
                    if(!event.data.dealId){
                        NotificationManager.error("Error updating deal name")
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                        resetGrid()
                        return;
                    }
                    try{
                        setIsLoading(true)
                        await removePartnerDealNameReactive(event.data.dealId)
                        NotificationManager.success("Removed the user set name for this deal," +
                            " the random name will now be displayed.")
                    }
                    catch(error){
                        if(error.message){
                            NotificationManager.error(error.message)
                        }
                        else{
                            NotificationManager.error("Error updating deal name")
                        }
                        //need to convert back or else user will see the new value they just entered even though there was an error and name was not updated
                        event.node.data.partnerDealDisplayName = event.oldValue
                        event.api.refreshCells({columns: ["partnerDealDisplayName"], rowNodes: [event.node], suppressFlash: true})
                    }
                    resetGrid()
                    setIsLoading(false)
                }
            },
        },
        { field: "pocGroupDisplayName", headerName: "Proof of Concept Group", initialWidth: 430,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                //Letting ag grid handle getting the set filter values
            },
            sortable: true,
            valueGetter: dealsGridPocGroupColumnValueGetter,
            cellRenderer: function (params) {
                if(params.node.data.pocZenGroupId){
                    //poc group exists, return the poc group name only
                    return params.value
                }
                else{
                    //poc group does not exist. Check if deal is approved and not completed/requested to sign to show create button
                    let dealStatus = params.node.data.dealStatus
                    let dealExpired = isDealExpiredHelper(params.node.data.expirationDate)
                    if(dealStatus === dealApprovedText && !dealExpired && !params.node.data.dealCompleted){
                        if(params.node.data.billingCycle === monthlyBillingCycleText){
                            if(params.node.data.net30MonthlyPaymentRequestedDate !== null && params.node.data.net30MonthlyPaymentRequestedDate !== undefined){
                                //cannot create poc group if deal has been requested to be signed
                                return ""
                            }
                        }
                        else{
                            //Else if billing cycle is Annually, TwoYears, or ThreeYears we use the net30YearlyPaymentRequestedDate field to check if requested to sign
                            if(params.node.data.net30YearlyPaymentRequestedDate !== null && params.node.data.net30YearlyPaymentRequestedDate !== undefined){
                                //cannot create poc group if deal has been requested to be signed
                                return ""
                            }
                        }

                        //We can show create POC Group Button
                        return (
                            <ThemeProvider theme = {cellButtonTheme}>
                                <Button
                                    variant={"contained"} color={"primary"}
                                    onClick={() => {
                                        resetPOCSetupModal()
                                        //Note the useEffect with the activeDeal dependency handles finding the max days the trial licenses can be set to expire
                                        setActiveDeal(params.node.data)
                                        setPocSetupModalOpen(true)
                                    }}
                                >
                                    Create POC
                                </Button>
                            </ThemeProvider>
                        )
                    }

                    //cannot create poc group if deal is not approved, if deal is expired, or deal is completed
                    return ""
                }
            },
        },
        { field: "submitted", headerName: "Submitted", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "expirationDate", headerName: "Expiration Date", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "dealCompleted", headerName: "Completed Date", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "numberOfServerLicensesBought", headerName: "Expected Server Licenses",
            initialWidth: 325,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "numberOfDesktopLicensesBought", headerName: "Expected Desktop Licenses",
            initialWidth: 325,
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "dealLength", headerName: "Deal Length",
            initialWidth: 200,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["1 Year", "2 Years", "3 Years"],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.dealLength === null || params.node.data.dealLength === undefined){
                        return "1 Year" //default 1 year
                    }
                    else if(params.node.data.dealLength === "OneYear"){
                        return "1 Year"
                    }
                    else if(params.node.data.dealLength === "TwoYears"){
                        return "2 Years"
                    }
                    else if(params.node.data.dealLength === "ThreeYears"){
                        return "3 Years"
                    }
                    else {
                        return "1 Year" //default 1 year
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.dealLength === null || params.node.data.dealLength === undefined){
                    return "1 Year" //default 1 year
                }
                else if(params.node.data.dealLength === "OneYear"){
                    return "1 Year"
                }
                else if(params.node.data.dealLength === "TwoYears"){
                    return "2 Years"
                }
                else if(params.node.data.dealLength === "ThreeYears"){
                    return "3 Years"
                }
                else {
                    return "1 Year" //default 1 year
                }
            },
            sortable: true
        },
        { field: "billingCycle", headerName: "Billing Cycle",
            initialWidth: 200,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: billingCycleOptions,
                suppressSorting: true,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.billingCycle === null || params.node.data.billingCycle === undefined){
                        return annuallyBillingCycleText //default to annually
                    }
                    else if(params.node.data.billingCycle === "TwoYears"){
                        return twoYearBillingCycleText
                    }
                    else if(params.node.data.billingCycle === "ThreeYears"){
                        return threeYearBillingCycleText
                    }
                    else {
                        return params.node.data.billingCycle
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.billingCycle === null || params.node.data.billingCycle === undefined){
                    return annuallyBillingCycleText //default to annually
                }
                else if(params.node.data.billingCycle === "TwoYears"){
                    return twoYearBillingCycleText
                }
                else if(params.node.data.billingCycle === "ThreeYears"){
                    return threeYearBillingCycleText
                }
                else {
                    return params.node.data.billingCycle
                }
            },
            sortable: true
        },
        { field: "approximateTotalCashValue", headerName: "Gross Deal Value per Billing Cycle",
            initialWidth: 335,
            valueFormatter: function(params) {
                if(params.node.data && params.node.data.approximateTotalCashValue){
                    let billingCycleToDisplay = "Year" //default
                    if(params.node.data.billingCycle === "Monthly"){
                        billingCycleToDisplay = "Month"
                    }
                    else if(params.node.data.billingCycle === "TwoYears"){
                        billingCycleToDisplay = "2 Years"
                    }
                    else if(params.node.data.billingCycle === "ThreeYears"){
                        billingCycleToDisplay = "3 Years"
                    }
                    return formatter.format(params.node.data.approximateTotalCashValue) + ` / ${billingCycleToDisplay}`
                }
                else{
                    return ""
                }
            },
            filter: 'agNumberColumnFilter',
            filterParams: {
                suppressSorting: true,
                buttons: ["reset", "apply"],

                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 1,
            },
            sortable: true
        },
        { field: "dealType", headerName: "Prospect Deal Type",
            initialWidth: 340,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["Prospect is a Referral, Partner Will Bill", "Prospect is a Referral, Cyber Crucible Will Bill", "Prospect is an End User", "Prospect is a Reseller"],
                suppressSorting: false,
                convertValuesToStrings: true
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.dealType === "Channel_Partner"){
                        return "Prospect is an End User"
                    }
                    else if(params.node.data.dealType === "Distributor"){
                        return "Prospect is a Reseller"
                    }
                    else if(params.node.data.dealType === "Referral_Bill_Partner"){
                        return "Prospect is a Referral, Partner Will Bill"
                    }
                    else if(params.node.data.dealType === "Referral_Bill_Customer"){
                        return "Prospect is a Referral, Cyber Crucible Will Bill"
                    }
                    else{
                        return params.node.data.dealType
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.dealType === "Channel_Partner"){
                    return "Prospect is an End User"
                }
                else if(params.node.data.dealType === "Distributor"){
                    return "Prospect is a Reseller"
                }
                else if(params.node.data.dealType === "Referral_Bill_Partner"){
                    return "Prospect is a Referral, Partner Will Bill"
                }
                else if(params.node.data.dealType === "Referral_Bill_Customer"){
                    return "Prospect is a Referral, Cyber Crucible Will Bill"
                }
                else{
                    return params.node.data.dealType
                }
            },
            sortable: true
        },
        { field: "disabledDate", headerName: "Disabled Date", initialWidth: 325,
            filter: 'agDateColumnFilter',
            sortable: true,
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "zenGroupDisplayName", headerName: "Group",
            initialWidth: 400,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: function(params) {
                    //the endpoint that is used to supply the ccUsersDealsZenGroupSessionStorage variable handles confirming if user is a cc user
                    let CCSessionStorageValue = decryptAndGetSessionVariable("ccUsersDealsZenGroupSessionStorage");
                    if (CCSessionStorageValue) {
                        //combine the two lists, cc groups list and regular groups list into set for values for this col
                        try{
                            let CCNames = JSON.parse(CCSessionStorageValue);
                            let groupFriendlyNames = new Set();
                            CCNames.forEach(CCNames => {
                                // for groupListForCCUsersOnDealsPage() org name is sent as friendlyName (if it is present) in the query already
                                groupFriendlyNames.add(CCNames.friendlyName);
                            })
                            let zenGroups = getZenGroupOrOrganizationNames()
                            zenGroups.forEach(zenGroups => {
                                groupFriendlyNames.add(zenGroups);
                            })
                            params.success(Array.from(groupFriendlyNames));
                        } catch (error){
                            params.success(getZenGroupOrOrganizationNames());
                        }
                    }
                    else{
                        params.success(getZenGroupOrOrganizationNames());
                    }
                },
                refreshValuesOnOpen: true,
                showTooltips: true
            },
            sortable: true,
        },
        { field: "prospectName", headerName: "Prospect Point of Contact",
            initialWidth: 315,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectEmail", headerName: "Prospect Email",
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "ccDashboardAccountUsername", headerName: "Referral Deal License Recipient",
            initialWidth: 415,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            headerTooltip: "Referral agents do not manage Cyber Crucible software, this is the customer that will have access to the Cyber Crucible dashboard",
            keyCreator: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.dealType === "Referral_Bill_Partner" || params.node.data.dealType === "Referral_Bill_Customer"){
                        if(params.node.data.ccDashboardAccountUsername !== null && params.node.data.ccDashboardAccountUsername !== undefined){
                            return params.node.data.ccDashboardAccountUsername
                        }
                        else{
                            return params.node.data.prospectEmail
                        }
                    }
                    else{
                        return null
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.dealType === "Referral_Bill_Partner" || params.node.data.dealType === "Referral_Bill_Customer"){
                    if(params.node.data.ccDashboardAccountUsername !== null && params.node.data.ccDashboardAccountUsername !== undefined){
                        return params.node.data.ccDashboardAccountUsername
                    }
                    else{
                        return params.node.data.prospectEmail
                    }
                }
                else{
                    return null
                }
            },
            editable: (params) => {
                //Only let referral deals edit this cell
                return params.node.data.dealType === "Referral_Bill_Partner" || params.node.data.dealType === "Referral_Bill_Customer";
            },
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                if(params.node.data.dealType === "Referral_Bill_Partner" || params.node.data.dealType === "Referral_Bill_Customer"){
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Tooltip title={
                                            <div className={"text-sm"}>
                                                {(params.value === null || params.value === params.node.data.prospectEmail) ? "The end user and license recipient are the same, click to change the license recipient" :
                                                    "The end user and license recipient are not the same, click to set the end user as the license recipient"}
                                            </div>}
                                         placement={"bottom-start"} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                         followCursor={false}
                                         enterDelay={750} arrow
                                >
                                    <Switch
                                        checked={params.value === null || params.value === params.node.data.prospectEmail}
                                        color={"primary"}
                                        name={`cellToggleCCDashboardAccountUsername${params.node.data.dealId}`}
                                        onChange={(changeEvent) => {
                                            let newCheckedValue = changeEvent.target.checked
                                            if(newCheckedValue === true){
                                                //set prospect email value as ccDashboardAccountUsername
                                                params.node.setDataValue("ccDashboardAccountUsername", null) //this triggers change detection in the onCellValueChanged
                                            }
                                            else{
                                                //else start editing value
                                                params.api.startEditingCell({
                                                    rowIndex: params.node.rowIndex,
                                                    colKey: "ccDashboardAccountUsername"
                                                })
                                            }
                                        }}
                                    />
                                </Tooltip>
                            </ThemeProvider>
                            {params.value}
                        </div>
                    )
                }
                return null
            },
            onCellValueChanged: (event) => {
                let newValue = event.newValue
                let oldValue = event.oldValue
                if(newValue && newValue.trim().length > 0){
                    if(newValue.trim().toLowerCase() === oldValue){
                        event.node.data.ccDashboardAccountUsername = oldValue
                        event.api.refreshCells({columns: ["ccDashboardAccountUsername"], rowNodes: [event.node], suppressFlash: true})
                        return
                    }
                    if(!EmailValidator.validate(newValue.trim().toLowerCase())){
                        NotificationManager.error("Please enter a valid email address")
                        event.node.data.ccDashboardAccountUsername = oldValue
                        event.api.refreshCells({columns: ["ccDashboardAccountUsername"], rowNodes: [event.node], suppressFlash: true})
                        return;
                    }
                    updateReferralPartnerDealCCDashboardAccountReactive(event.data.dealId, newValue.trim().toLowerCase()).then(response => {
                        //no further action needed
                    }).catch(error => {
                        if(error.message){
                            NotificationManager.error(error.message);
                        }
                        else{
                            NotificationManager.error("Unexpected error completing this request");
                        }
                        event.node.data.ccDashboardAccountUsername = oldValue
                        event.api.refreshCells({columns: ["ccDashboardAccountUsername"], rowNodes: [event.node], suppressFlash: true})
                    })
                }
                else{
                    //else reset
                    event.node.data.ccDashboardAccountUsername = oldValue
                    event.api.refreshCells({columns: ["ccDashboardAccountUsername"], rowNodes: [event.node], suppressFlash: true})
                }
            },
            sortable: true
        },
        { field: "prospectPhone", headerName: "Prospect Phone",
            initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectZipCode", headerName: "Prospect Zipcode",
            initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectCity", headerName: "Prospect City",
            initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "prospectAddress", headerName: "Prospect Address",
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "createdBy", headerName: "Deal Owner",
            initialWidth: 325,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        }

    ]);
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(325)
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            let username = getItemFromStorageWithoutDecrypting("username")
            setUser(username)
            if(username && username.trim().toLowerCase().endsWith("@cybercrucible.com")){
                ccGetPartnerGroupsReactive().then(response => {
                    setCCUserAllPartnerGroupsList(response)
                }).catch(error => {
                    setCCUserAllPartnerGroupsList([])
                })
                ccRetrieveUsernamesReactive().then(response => {
                    setCCAllUsernamesList(response)
                }).catch(function (error) {
                    setCCAllUsernamesList([])
                })

            }
        })()
        return () => controller?.abort();
    }, [zenGroupSessionStorage]);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            getPartnerGroupSettingsReactive().then(response => {
                if(response === null || response === undefined){
                    setUserChannelPartnerGroupSettingsList([])
                }
                else{
                    setUserChannelPartnerGroupSettingsList(response)
                }

            }).catch(error => {
                setUserChannelPartnerGroupSettingsList([])
            })
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        if(partnerDealLocation && partnerDealLocation.state && partnerDealLocation.state.openNewDealForm === true){
            setDealModalIsForNewDeal(true)
            setShowNewDealModal(true)
            //so on refresh or clicking back then forward tabs this modal does not keep popping up
            window.history.replaceState(partnerDealLocation.state, '')
        }
    }, [partnerDealLocation]);

    /*
        Since in the columnDefinition for the Proof of Concept Group Column in ag grid will not see react hook updates for when userChannelPartnerGroupSettingsList or ccUserAllPartnerGroupsList
        gets populated (unless we manually refresh the column/cell renderer after these hooks are populated), we have to set this useEffect to trigger on the activeDeal hook change and
        check partner settings that way.
     */
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(activeDeal && activeDeal.zenGroupId){
                let username = getItemFromStorageWithoutDecrypting("username")
                let partnerGroupListToUse = userChannelPartnerGroupSettingsList
                if(username && username.toLowerCase().endsWith("@cybercrucible.com")){
                    //user is cc user, use ccUserAllPartnerGroupsList to get the max trial license days and if they can create POC
                    partnerGroupListToUse = ccUserAllPartnerGroupsList
                }
                if(partnerGroupListToUse){
                    let partnerGroupFound = partnerGroupListToUse.find((group) => group.id === activeDeal.zenGroupId)
                    if(partnerGroupFound === null || partnerGroupFound === undefined){
                        //did not find this deal's partner group
                        setPOCSetupModalPartnerGroupCanCreatePOC(false)
                        setPOCSetupModalMaxTrialLicenseDays(14)
                    }
                    else{
                        //else we found the group
                        if(partnerGroupFound.canCreatePOC){
                            //partner group cannot create POC
                            setPOCSetupModalPartnerGroupCanCreatePOC(true)
                        }
                        else{
                            setPOCSetupModalPartnerGroupCanCreatePOC(false)
                        }

                        if(partnerGroupFound.partnerMaxDaysForPOCTrialLicensesToExpire === null ||  partnerGroupFound.partnerMaxDaysForPOCTrialLicensesToExpire === undefined ||
                            partnerGroupFound.partnerMaxDaysForPOCTrialLicensesToExpire < 1){
                            //set to default if partnerMaxDaysForPOCTrialLicensesToExpire does not have a value or its less than 1
                            setPOCSetupModalMaxTrialLicenseDays(14)
                        }
                        else{
                            //else set to the partner groups partnerMaxDaysForPOCTrialLicensesToExpire setting
                            setPOCSetupModalMaxTrialLicenseDays(partnerGroupFound.partnerMaxDaysForPOCTrialLicensesToExpire)
                        }
                    }
                }
                else{
                    setPOCSetupModalPartnerGroupCanCreatePOC(false)
                    setPOCSetupModalMaxTrialLicenseDays(14)
                }
            }
        })()
        return () => controller?.abort();
    }, [activeDeal]);

    function approveDealClickedHelper(params){
        ccApproveDealReactive(params.node.data.dealId).then(response => {
            NotificationManager.success("Successfully approved this deal");
            params.node.data.dealStatus = dealApprovedText
            params.node.setDataValue("cyberCrucibleApproved", dealApprovedText)
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
        })
    }

    function rejectDealClickedHelper(params){
        ccRejectDealReactive(params.node.data.dealId).then(response => {
            NotificationManager.success("Successfully rejected this deal");
            params.node.data.dealStatus = dealRejectedText
            params.node.setDataValue("cyberCrucibleApproved", dealRejectedText)
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
        })
    }
    function renewDealClickedHelper(params){
        ccRenewDealReactive(params.node.data.dealId).then(response => {
            NotificationManager.success("Successfully renewed this deal");
            params.node.data.dealStatus = dealApprovedText
            params.node.setDataValue("cyberCrucibleApproved", dealApprovedText)
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
        })
    }
    function isDealExpiredHelper(expirationDate){
        //Helper function to check if a deal is expired, this function should pass in the expirationDate field from the grid
        if(expirationDate === null || expirationDate === undefined){
            return true
        }
        try{
            let dealExpirationDate = new Date(expirationDate).getTime()
            let currentDate = new Date().getTime()
            return currentDate >= dealExpirationDate;
        } catch(error){
            return true
        }
    }

    function resetPOCSetupModal(){
        setActiveDeal(null)
        setPocSetupModalOpen(false)
        setPOCSetupModalMaxTrialLicenseDays(14)
        setPOCSetupModalPartnerGroupCanCreatePOC(false)
        setPOCSetupAdminUsersList([])
        setPocSetupUsersOnlyList([])
        resetPOCSetup({daysUntilTrialLicensesExpire: ""})

    }

    const uploadSignedQuoteAndCreateSubscription = (data) => {
        if(activeDeal && activeDeal.dealId && data && data.signedQuotePdf && data.signedQuotePdf.length === 1 && data.daysUntilPartnerDealInvoiceDue){ //check deal and file
            if(activeDeal.createdBy && activeDeal.createdBy.toLowerCase().endsWith("@cybercrucible.com")){
                let confirmBoolean = window.confirm(`WARNING, this deal's owner is set to a Cyber Crucible user: ${activeDeal.createdBy}. The deal owner is who the Stripe customer will be for the subscription. Please confirm this is desired before making the subscription, or click Cancel.`)
                if(!confirmBoolean){ //if they don't confirm then do not continue to call to ccCreateStripeSubscriptionForDealReactive
                    return;
                }
                //else they clicked confirm so continue
            }
            //check file type now
            let signedQuotePdf = data.signedQuotePdf[0]
            if(!signedQuotePdf){
                NotificationManager.error("Unexpected error uploading this file and completing the request");
                return
            }
            if(signedQuotePdf.type !== "application/pdf"){
                NotificationManager.error("The signed quote must be a PDF");
                return
            }
            setIsLoading(true)
            ccCreateStripeSubscriptionForDealReactive(activeDeal.dealId, signedQuotePdf, data.daysUntilPartnerDealInvoiceDue).then(response => {
                setShowConfirmCreateSubscriptionModal(false)
                NotificationManager.success("Successfully uploaded the signed quote and created subscription with net 30 invoice");
                setIsLoading(false)
                resetSignedQuoteForm({signedQuotePdf: null, daysUntilPartnerDealInvoiceDue: ""})
            }).catch(error => {
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error completing this request");
                }
                setIsLoading(false)
            })
        }
    }

    const pocSetupSubmit = (data) => {
        if(data === null || data === undefined || activeDeal === null || activeDeal === undefined || !activeDeal.dealId){
            return;
        }
        if(!data.daysUntilTrialLicensesExpire){
            return;
        }
        //Max for admins and users list is 5 each, but they need to enter at least one user total in either
        if(!pocSetupAdminUsersList || !pocSetupUsersOnlyList){
            return;
        }
        if(pocSetupAdminUsersList.length === 0 && pocSetupUsersOnlyList.length === 0){
            NotificationManager.info("You must enter at least 1 user to be added to the POC Group");
            return
        }
        if(pocSetupAdminUsersList.length > 5){
            NotificationManager.info("You can enter up to 5 admin users");
            return
        }
        if(pocSetupUsersOnlyList.length > 5){
            NotificationManager.info("You can enter up to 5 users");
            return
        }
        let foundInvalidEmail = false
        pocSetupAdminUsersList.forEach(username => {
            if(!EmailValidator.validate(username.trim().toLowerCase())){
                foundInvalidEmail = true
            }
        })
        pocSetupUsersOnlyList.forEach(username => {
            if(!EmailValidator.validate(username.trim().toLowerCase())){
                foundInvalidEmail = true
            }
        })
        if(foundInvalidEmail){
            NotificationManager.info("You have entered an invalid user email address, please fix before continuing");
            return
        }
        setIsLoading(true)
        createPartnerPOCReactive(activeDeal.dealId, data.daysUntilTrialLicensesExpire, pocSetupAdminUsersList, pocSetupUsersOnlyList).then(response => {
            setIsLoading(false)
            resetPOCSetupModal()
            if(response.errorCreatingAUser){
                //There was an error creating a user in the endpoint, alert user
                setShowErrorCreatingUserForPOCModal(true)
            }
            else{
                NotificationManager.success("Successfully created the POC Group");
            }
        }).catch(error => {
            if(error.message){
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error("Unexpected error completing this request");
            }
            setIsLoading(false)
        })

    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Deal Registration</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Register New Deal Modal*/}
            <CreateAndEditPartnerDealModal
                isOpen={showNewDealModal} setIsOpen={setShowNewDealModal} user={user} activeDeal={activeDeal} creatingNewDeal={dealModalIsForNewDeal}
                setIsLoading={setIsLoading} resetGrid={resetGrid} userChannelPartnerGroupSettingsList={userChannelPartnerGroupSettingsList}
                ccUserAllPartnerGroupsList={ccUserAllPartnerGroupsList} ccAllUsernamesList={ccAllUsernamesList}
            />
            {/*Disable*/}
            <ConfirmationModal
                text={`WARNING: You are about to disable this deal, would you like to continue?`}
                onConfirm={async () => {
                    if(activeDeal && activeDeal.dealId){
                        if(activeDeal.disabledDate){
                            //deal is already disabled
                            NotificationManager.info("This partner deal is already disabled");
                            return;
                        }
                        setIsLoading(true)
                        try{
                            await disablePartnerDealReactive(activeDeal.dealId)
                            NotificationManager.success("Successfully disabled this deal");
                            resetGrid()
                        }
                        catch(error){
                            if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error("Error disabling this deal");
                            }
                        }
                        setIsLoading(false)
                    }
                }}
                onClose={() => {
                    setShowDisableConfirmation(false);
                }}
                opened={showDisableConfirmation}
            />
            {/*Enable*/}
            <ConfirmationModal
                text={`You are about to enable this deal, would you like to continue?`}
                onConfirm={async () => {
                    if(activeDeal && activeDeal.dealId){
                        if(!activeDeal.disabledDate){
                            //deal is already disabled
                            NotificationManager.info("This partner deal is already enabled");
                            return;
                        }
                        setIsLoading(true)
                        try{
                            await enablePartnerDealReactive(activeDeal.dealId)
                            NotificationManager.success("Successfully enabled this deal");
                            resetGrid()
                        }
                        catch(error){
                            if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error("Error enabling this deal");
                            }
                        }
                        setIsLoading(false)
                    }
                }}
                onClose={() => {
                    setShowEnableConfirmation(false);
                }}
                opened={showEnableConfirmation}
            />
            {/*CC complete deal and create subscription modal*/}
            <Modal contentLabel="Upload Signed Quote and Create Subscription with Net30"
                   isOpen={showConfirmCreateSubscriptionModal}
                   onRequestClose={() => {
                       setShowConfirmCreateSubscriptionModal(false)
                       resetSignedQuoteForm({signedQuotePdf: null, daysUntilPartnerDealInvoiceDue: ""})
                   }}
                   shouldCloseOnOverlayClick={false}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-xl max-w-xl min-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmitSignedQuote(uploadSignedQuoteAndCreateSubscription)}>
                    <div className="flex flex-1 flex-col gap-y-5 ml-1">
                        <div className="flex flex-row justify-between gap-x-2">
                            <h1 className="font-bold text-3xl">Create Subscription with Net 30 Invoice to Complete Deal</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    setShowConfirmCreateSubscriptionModal(false)
                                    resetSignedQuoteForm({signedQuotePdf: null, daysUntilPartnerDealInvoiceDue: ""})
                                }}
                            />
                        </div>
                        <hr className="h-0.5" />
                        <div className="">
                            <label>Partner Deal Name: </label>{activeDeal?.partnerDealDisplayName}
                        </div>
                        <div className="">
                            {`In order to create the subscription for this deal and send the net 30 invoice, you must upload the signed quote from this
                                partner. Note that creating the Stripe subscription will also populate the agent licenses for this deal.`}
                        </div>
                        <div className="flex flex-col gap-y-3">
                            <label>Upload Signed Quote PDF Document:</label>
                            <input className={'ml-2 w-min'} type="file" name="signedQuotePdf" multiple={false}
                                   {...registerSignedQuote("signedQuotePdf")}
                                   required={true}
                                   accept="application/pdf, .pdf"
                            />
                        </div>
                        <div className={``}>
                            <label className={"flex flex-row"}>How Many Days Until This Invoice is Due?<MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="ml-1 object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={<div>The group setting is used by default and can be changed on the Partner Group Management Page, or you can edit the days until invoice is due for this deal only</div>}
                                tooltipPlacement={"bottom-start"}
                            /></label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={minDaysUntilInvoiceDue} max={maxDaysUntilInvoiceDue} step={1}
                                type="number" name="daysUntilPartnerDealInvoiceDue"
                                {...registerSignedQuote("daysUntilPartnerDealInvoiceDue")}
                                required={true}
                                placeholder="The Group Setting is used by Default"
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                            <ThemeProvider theme = {roundButtonTheme}>
                                <Button variant={"contained"} className={"flex-1"}
                                        color={"primary"}
                                        type={"submit"}
                                        style={{minWidth:"200px"}}>
                                    Upload Signed Quote and Create Subscription
                                </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            {/*Information modal after user requests net 30 payment*/}
            <InformationModal
                opened={showNet30RequestedInformationModal}
                onClose={() => {
                    setShowNet30RequestedInformationModal(false)
                }}
                text="Successfully requested to sign and finalize the quote for this deal. You will be contacted soon by a Cyber Crucible salesperson with the next steps."
                contentLabel={"Finalize Quote Next Steps"}
            />
            <InformationModal
                opened={showNet30RequestedInformationModalForReferralCustomerBillingDeals}
                onClose={() => {
                    setShowNet30RequestedInformationModalForReferralCustomerBillingDeals(false)
                }}
                text="Successfully requested to sign and finalize the quote for this deal. The customer will be contacted soon by a Cyber Crucible salesperson with the next steps."
                contentLabel={"Finalize Quote Next Steps"}
            />
            <InformationModal
                opened={showErrorCreatingUserForPOCModal}
                onClose={() => {
                    setShowErrorCreatingUserForPOCModal(false)
                }}
                text="There was an unexpected error creating and adding a user to the POC group. You may manage this group on the Groups page."
                contentLabel={"Error Creating and Adding User to POC Group"}
            />
            <PopupModal
                url="https://calendly.com/cybercrucible-sales/product-demonstration-partner"
                onModalClose={() => setCalendlyModalOpen(false)}
                open={calendlyModalOpen}
                rootElement={document.getElementById("root")}
            />
            <Modal contentLabel="POC Setup"
                   isOpen={pocSetupModalOpen}
                   onRequestClose={() => {
                       resetPOCSetupModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl min-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handlePOCSetupSubmit(pocSetupSubmit)}>
                    <div className="flex flex-1 flex-col gap-y-5 ml-1">
                        <div className="flex flex-row justify-between gap-x-2">
                            <h1 className="font-bold text-3xl">POC Setup</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetPOCSetupModal()
                                }}
                            />
                        </div>
                        <hr className="h-0.5" />
                        <div className="">
                            The POC Group will be given five trial licenses after its creation. Note that the user accounts will be created automatically for users if they do not exist yet.
                        </div>
                        <hr className="h-0.5" />
                        <div className="">
                            <label>Partner Deal Name: </label>{activeDeal?.partnerDealDisplayName}
                        </div>
                        <div className="">
                            <label>Prospect Organization: </label>{activeDeal?.prospectOrganization}
                        </div>
                        <div className={""}>
                            <label>Days Until Trial Licenses Expire</label>
                            <input
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter' || e.key === "e" || e.key === "-" || e.key === "+" || e.key === "."){
                                        e.preventDefault();
                                    }}}
                                min={1} max={pocSetupModalMaxTrialLicenseDays} step={1} required
                                type="number" name="daysUntilTrialLicensesExpire"
                                {...registerPOCSetup("daysUntilTrialLicensesExpire")}
                                placeholder={`Max ${pocSetupModalMaxTrialLicenseDays ? pocSetupModalMaxTrialLicenseDays : 14} Days`}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={"flex flex-col gap-y-3"}>
                            <label>Enter User Email Addresses that should be Added to New POC Group</label>
                            <div className={"flex flex-col gap-y-3 mx-2"}>
                                <label>Admins</label>
                                <ThemeProvider theme={autocompleteTheme}>
                                    <Autocomplete
                                        className="" disablePortal={true} freeSolo openOnFocus={true} size={"small"}
                                        options={[]} //only allow for them to input the users since they will likely not be existing yet
                                        multiple //allow for multiple users to be entered
                                        renderInput={(params) => <TextField {...params} label={"You may enter multiple users"} />}
                                        onChange={(event, valueList) => {
                                            setPOCSetupAdminUsersList(valueList)
                                        }}
                                        onKeyDown={(event) => {
                                            if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                            <div className={"flex flex-col gap-y-3 mx-2"}>
                                <label>Users</label>
                                <ThemeProvider theme={autocompleteTheme}>
                                    <Autocomplete
                                        className="" disablePortal={true} freeSolo openOnFocus={true} size={"small"}
                                        options={[]} //only allow for them to input the users since they will likely not be existing yet
                                        multiple //allow for multiple users to be entered
                                        renderInput={(params) => <TextField {...params} label={"You may enter multiple users"} />}
                                        onChange={(event, valueList) => {
                                            setPocSetupUsersOnlyList(valueList)
                                        }}
                                        onKeyDown={(event) => {
                                            if(event.key === 'Enter'){ //Don't submit form if enter key pressed in input
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </ThemeProvider>
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap justify-between gap-y-3 gap-x-3"}>
                            {/*Only want to show a tooltip when the partner group cannot create a POC and disable the button in that case*/}
                            {pocSetupModalPartnerGroupCanCreatePOC ? (
                                <ThemeProvider theme = {roundButtonTheme}>
                                    <Button variant={"contained"} className={"flex-1"}
                                            color={"primary"}
                                            type={"submit"}
                                            style={{minWidth:"200px"}}>
                                        Create POC
                                    </Button>
                                </ThemeProvider>
                            ) : (
                                <ThemeProvider theme = {roundButtonTheme}>
                                    <Tooltip className={"w-full"} title={<div className={"text-sm"}>This partner group cannot setup a POC</div>} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                             placement={"bottom-end"} followCursor={false} enterDelay={750} arrow>
                                        <span>
                                            <Button disabled={true} variant={"contained"} className={"flex-1 w-full"}
                                                    color={"primary"}
                                                    type={"submit"}
                                                    style={{minWidth:"200px"}}>
                                        Create POC
                                    </Button>
                                        </span>
                                    </Tooltip>
                                </ThemeProvider>
                            )}
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col mr-10 ml-5 mt-8 flex-nowrap gap-y-3 h-full">
                    {privatePageHeaderHelper("Deal Registration")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<AddShoppingCartIcon className={"cursor-pointer"}/>} tooltipTitle={"Register a New Deal"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setDealModalIsForNewDeal(true)
                                        setShowNewDealModal(true)
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<CalendarMonthIcon className={"cursor-pointer"}/>} tooltipTitle={"Schedule a Demo"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setCalendlyModalOpen(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateDealsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={true} sseDataPullActive={sseDataPullActive}
                                          excelExportFunction = {excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
                partnerDealLocation={partnerDealLocation}
            />
        );
    }

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'dealsGridFilterState', updateDealsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateDealsGridUseColumnStateReactive);
    }

    function resetGrid(){
        setShowDisableConfirmation(false);
        setShowEnableConfirmation(false)
        setActiveDeal(null)
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "dealsGridExport")
    }
}

let saveFilterChanges = true //If user clicks link from a deal notification/alert email then we don't want to save filter changes because we will be auto filtering
class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateDealsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateDealsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateDealsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateDealsGridColumnModeReactive, customColumnModeText)
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        objectBody["dealId"] = objectBody["id"]
        objectBody["createdBy"] = objectBody["createdByUsername"]
        objectBody["partnerDealDisplayName"] = objectBody["userSetFriendlyName"] ? objectBody["userSetFriendlyName"] : objectBody["friendlyName"]
        //Need to populate the zenGroupDisplayName with the group's organization name if not null, else the group's zenGroupDisplayName since for initial sse data population we do a join with zenGroup collection
        objectBody["zenGroupDisplayName"] = getGroupNameForDealsGridForChangeStream(objectBody["zenGroupId"])

        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.dealId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;
        this.props.setGridApi(params.api);


        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let columnMode = this.props.columnMode

        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateDealsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateDealsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        let filterForNotification = false
        saveFilterChanges = true //reset to true
        if(this.props.partnerDealLocation && this.props.partnerDealLocation.state){
            //check for "filterSingleDealIdURLSearchParams" and "dealExpiredURLSearchParams" states
            let filterSingleDealIdURLSearchParams = this.props.partnerDealLocation.state.filterSingleDealIdURLSearchParams
            let dealExpiredURLSearchParams = this.props.partnerDealLocation.state.dealExpiredURLSearchParams
            if(filterSingleDealIdURLSearchParams !== null && filterSingleDealIdURLSearchParams !== undefined && filterSingleDealIdURLSearchParams.trim().length > 0){
                try{
                    let response = await findNotificationEventForSingleDealAutoFilter(filterSingleDealIdURLSearchParams)
                    let zenGroupId = response.zenGroupId
                    let partnerDealId = response.partnerDealId
                    if(zenGroupId && partnerDealId){
                        filterForNotification = true
                        saveFilterChanges = false //don't save filter changes for when we auto filter
                        //just filter by dealId
                        let locationFilterModel = {"dealId": {filterType: "text", type: "equals", filter: partnerDealId}}
                        params.api.setFilterModel(locationFilterModel)
                    }
                } catch (e) {}
            }
            else if(dealExpiredURLSearchParams !== null && dealExpiredURLSearchParams !== undefined && dealExpiredURLSearchParams.trim().length > 0){
                try{
                    let response = await findNotificationEventForDealExpiredAutoFilter(dealExpiredURLSearchParams)
                    let zenGroupId = response.zenGroupId
                    let queryDateTimeMillis = response.queryDateTimeMillis
                    let zenGroup = findZenGroupById(zenGroupId)
                    //deals page uses group organization name if available
                    if(zenGroupId && queryDateTimeMillis && zenGroup){
                        let groupNameForFilter = zenGroup.organizationName ? zenGroup.organizationName : zenGroup.friendlyName
                        let queryDateTimeFilterString = getDateStringForAgGridFilter(queryDateTimeMillis)
                        if(queryDateTimeFilterString && groupNameForFilter){
                            filterForNotification = true
                            saveFilterChanges = false //don't save filter changes for when we auto filter
                            let filter = {}
                            filter["zenGroupDisplayName"] = {
                                "values": [
                                    groupNameForFilter
                                ],
                                "filterType": "set"
                            }
                            //filter expirationDate
                            filter["expirationDate"] = {
                                "dateFrom": queryDateTimeFilterString,
                                "dateTo": null,
                                "filterType": "date",
                                "type": "lessThan"
                            }
                            params.api.setFilterModel(filter);
                        }
                    }
                } catch (e) {}
            }

        }

        if(!filterForNotification){
            onGridReadyHelper(params, "dealsGridFilterState");
        }

        await loadDataWithSSEAndStartChangeStreamListener("/sse/getPartnerDealsReactive", "/sse/listenToPartnerDealEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)

        //params.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="registeredDealGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        rowSelection={'single'}
                        tooltipShowDelay={300}
                        tooltipInteraction={true} //allows tooltips supplied by ag grid to remain open when hovered over by mouse
                        multiSortKey={"ctrl"}
                        rowMultiSelectWithClick={false}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'dealsGridFilterState', updateDealsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
