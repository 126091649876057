import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {ToggleButton} from "@mui/material";
import {decryptAndGetSessionVariable} from "../../utils/storageHelper";
import {MuiIconWithTooltip} from "../../components/muiComponents";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {foundation} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

export default function RestIntegration() {
    const [isLoading, setIsLoading] = useState(false);
    const [languageSelected, setLanguageSelected] = useState("bash");
    const appClientId = process.env.REACT_APP_APP_CLIENT_ID;
    const oauthTokenEndpoint = process.env.REACT_APP_OAUTH_TOKEN_ENDPOINT;
    const refreshToken = decryptAndGetSessionVariable("refresh_token")
    const bashCodeString = `refresh_token=${refreshToken}\n\ncurl -X POST https://ransomwarerewind.auth.us-west-2.amazoncognito.com/oauth2/token -H "Content-Type: application/x-www-form-urlencoded" --data "grant_type=refresh_token&client_id=73ul9p1lhi9is87n01pusfomhq&refresh_token=$refresh_token"`
    const powershellCodeString = `$refresh_token="${refreshToken}"\n\nInvoke-RestMethod -Uri "https://ransomwarerewind.auth.us-west-2.amazoncognito.com/oauth2/token" -Headers @{
    "Content-Type"="application/x-www-form-urlencoded"
} -Body @{
    "grant_type"="refresh_token"
    "client_id"="73ul9p1lhi9is87n01pusfomhq"
    "refresh_token"=$refresh_token
} -Method "POST" -UseBasicParsing`
    const cmdCodeString = `set refresh_token=${refreshToken}\n\ncurl -X POST https://ransomwarerewind.auth.us-west-2.amazoncognito.com/oauth2/token -H "Content-Type: application/x-www-form-urlencoded" --data "grant_type=refresh_token&client_id=73ul9p1lhi9is87n01pusfomhq&refresh_token=%refresh_token%" `
    const [codeString, setCodeString] = useState(bashCodeString);

    return (
        <div className="flex flex-col">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Rest Integration</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-2 ml-5 mr-10 mt-8">
                    {privatePageHeaderHelper("Rest Integration")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-col flex-wrap gap-y-5">
                        <div className={"flex flex-row items-end gap-x-2 gap-y-1 flex-wrap"}>
                            <label>Download our REST API Documentation for Integration:</label>
                            <a
                                href="../documents/restDocumentation.html"
                                download
                            >
                                <FontAwesomeIcon
                                    className={`object-contain`}
                                    icon={"fa-duotone fa-download"}
                                    size="2xl"
                                    color={"black"}
                                />
                            </a>
                        </div>
                        <div className={`flex flex-row items-center gap-x-1 gap-y-1 flex-wrap`}>
                            <label>Cyber Crucible REST API URL:</label>https://web.tasking.rpp.cybercrucible.com
                        </div>
                        <div><label>Client Id:</label> {appClientId}</div>
                        <div className={`flex flex-row items-center gap-x-1 gap-y-1 flex-wrap`}>
                            <label>AWS Token endpoint:</label> {oauthTokenEndpoint}
                            <MuiIconWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="mt-1 ml-1 object-contain"
                                        icon="fa-light fa-circle-info"
                                        size="lg"
                                    />
                                }
                                tooltipTitle={
                                    <p> <a className={"cursor-pointer hover:underline"} href ="https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html" rel="noreferrer" target="_blank">See more about the token endpoint here</a></p>
                                }
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                        <div><label>See the Examples Below on How to Retrieve an Access Token. This is Your Real Refresh Token, Do Not Share!</label></div>
                        <div className={"flex flex-col gap-y-3"}>
                            <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-center justify-start">
                                <ToggleButtonGroup
                                    value={languageSelected}
                                    exclusive
                                    size={"small"}
                                >
                                    <ToggleButton value={"bash"}
                                                  onClick={(event) => {
                                                      setLanguageSelected("bash")
                                                      setCodeString(bashCodeString)
                                                  }}
                                    >
                                        <div>bash</div>
                                    </ToggleButton>
                                    <ToggleButton value={"powershell"}
                                                  onClick={(event) => {
                                                      setLanguageSelected("powershell")
                                                      setCodeString(powershellCodeString)
                                                  }}
                                    >
                                        <div>powershell</div>
                                    </ToggleButton>
                                    <ToggleButton value={"plaintext"}
                                                  onClick={(event) => {
                                                      setLanguageSelected("plaintext")
                                                      setCodeString(cmdCodeString)
                                                  }}
                                    >
                                        <div>cmd</div>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </div>
                            <SyntaxHighlighter wrapLongLines className={"rounded-xl break-all"} language={languageSelected} style={foundation}>
                                {codeString}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                    <hr className="mb-4 bg-black h-0.5" />
                    <Footer/>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );
}

