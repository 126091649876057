import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";

function Terms() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Cyber Crucible EULA"/>
                <meta name="keywords" content="" />
                <meta name="author" content="" />
                <script src="https://js.stripe.com/v3/"/>
                <title>EULA</title>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>

            {/*Title Banner*/}
            <div className="banner-contact">
                <div className="py-20">
                    <div className="container text-center mx-auto px-6">
                        <h2 className="text-4xl font-bold mb-2 text-white">
                            End User License Agreement
                        </h2>
                    </div>
                </div>
            </div>

            <section className="text-gray-600 body-font">
                <div className="container px-5 mx-auto">
                    <div className="flex flex-wrap w-full mb-20 flex-col items-start">
                        <article className="lg:p-24 md:p-10 sm:p-10 xs:p-10">
                            <p className="lg:w-full w-full leading-relaxed text-dark-grey mb-5">
                                This copy of Cyber Crucible ("the Software Product") and accompanying documentation is licensed and not sold. This Software Product is protected by copyright laws and treaties, as well as laws and treaties related to other forms of intellectual property. Cyber Crucible, Inc. or its subsidiaries, affiliates, and suppliers (collectively "Cyber Crucible") own intellectual property rights in the Software Product. The Licensee's ("you" or "your") license to download, use, copy, or change the Software Product is subject to these rights and to all the terms and conditions of this End User License Agreement ("Agreement").
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mb-2 text-dark-grey">Acceptance</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                YOU ACCEPT AND AGREE TO BE BOUND BY THE TERMS OF THIS AGREEMENT BY SELECTING THE "ACCEPT" OPTION AND DOWNLOADING THE SOFTWARE PRODUCT OR BY INSTALLING, USING, OR COPYING THE SOFTWARE PRODUCT. YOU MUST AGREE TO ALL OF THE TERMS OF THIS AGREEMENT BEFORE YOU WILL BE ALLOWED TO DOWNLOAD THE SOFTWARE PRODUCT. IF YOU DO NOT AGREE TO ALL OF THE TERMS OF THIS AGREEMENT, YOU MUST SELECT "DECLINE" AND YOU MUST NOT INSTALL, USE, OR COPY THE SOFTWARE PRODUCT.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-2 mb-2 text-dark-grey">License Grant</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                This Agreement entitles you to install and use one copy of the Software Product. In addition, you may make one archival copy of the Software Product. The archival copy must be on a storage medium other than a hard drive, and may only be used for the reinstallation of the Software Product. This Agreement does not permit the installation or use of multiple copies of the Software Product, or the installation of the Software Product on more than one computer at any given time, on a system that allows shared used of applications, on a multi-user network, or on any configuration or system of computers that allows multiple users. Multiple copy use or installation is only allowed if you obtain an appropriate licensing agreement for each user and each copy of the Software Product. For further information regarding multiple copy licensing of the Software Product, please contact:
                            </p>
                            <h3 className="lg:text-2xl md:text-2xl sm:text-base xs:text-base mt-6 mb2 font-semibold text-dark-grey">
                                Representative:
                            </h3>
                            <ul className="mt-3 mb-5 text-dark-grey">
                                <li><span className="font-semibold">CEO Address</span>: 1378 Freeport Road, Suite 1B, Pittsburgh, PA 15238</li>
                                <li><span className="font-semibold">Phone Number</span>: 412.775.2158</li>
                                <li><span className="font-semibold">E-mail Address</span>: support@cybercrucible.com</li>
                            </ul>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-2 mb-2 text-dark-grey">Restrictions on Transfer</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                Without first obtaining the express written consent of Cyber Crucible, you may not assign your rights and obligations under this Agreement, or redistribute, encumber, sell, rent, lease, sublicense, or otherwise transfer your rights to the Software Product.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-2 mb-2 text-dark-grey">Restrictions on Use</h2>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                You may not use, copy, or install the Software Product on any system with more than one computer, or permit the use, copying, or installation of the Software Product by more than one user or on more than one computer. If you hold multiple, validly licensed copies, you may not use, copy, or install the Software Product on any system with more than the number of computers permitted by license, or permit the use, copying, or installation by more users, or on more computers than the number permitted by license.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                You may not decompile, "reverse-engineer", disassemble, or otherwise attempt to derive the source code for the Software Product.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                You may not use the database portion of the Software Product in connection with any software other than the Software Product.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-2 mb-2 text-dark-grey">Restrictions on Alteration</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                You may not modify the Software Product or create any derivative work of the Software Product or its accompanying documentation. Derivative works include but are not limited to translations. You may not alter any files or libraries in any portion of the Software Product. You may not reproduce the database portion or create any tables or reports relating to the database portion.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-2 mb-2 text-dark-grey">Restrictions on Copying</h2>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                You may not copy any part of the Software Product except to the extent that licensed use inherently demands the creation of a temporary copy stored in computer memory and not permanently affixed on storage medium. You may make one archival copy which must be stored on a medium other than a computer hard drive.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-4 mb-2 text-dark-grey">Disclaimer of Warranties and Limitation of Liability</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                UNLESS OTHERWISE EXPLICITLY AGREED TO IN WRITING BY CYBER CRUCIBLE, CYBER CRUCIBLE MAKES NO OTHER WARRANTIES, EXPRESS OR IMPLIED, IN FACT OR IN LAW, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OTHER THAN AS SET FORTH IN THIS AGREEMENT OR IN THE LIMITED WARRANTY DOCUMENTS PROVIDED WITH THE SOFTWARE PRODUCT.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                Cyber Crucible makes no warranty that the Software Product will meet your requirements or operate under your specific conditions of use. Cyber Crucible makes no warranty that operation of the Software Product will be secure, error free, or free from interruption. YOU MUST DETERMINE WHETHER THE SOFTWARE PRODUCT SUFFICIENTLY MEETS YOUR REQUIREMENTS FOR SECURITY AND UNINTERRUPTABILITY. YOU BEAR SOLE RESPONSIBILITY AND ALL LIABILITY FOR ANY LOSS INCURRED DUE TO FAILURE OF THE SOFTWARE PRODUCT TO MEET YOUR REQUIREMENTS. CYBER CRUCIBLE WILL NOT, UNDER ANY CIRCUMSTANCES, BE RESPONSIBLE OR LIABLE FOR THE LOSS OF DATA ON ANY COMPUTER OR INFORMATION STORAGE DEVICE.
                            </p>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                UNDER NO CIRCUMSTANCES SHALL CYBER CRUCIBLE, ITS DIRECTORS, OFFICERS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY OTHER PARTY FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES OF ANY KIND (INCLUDING LOST REVENUES OR PROFITS OR LOSS OF BUSINESS) RESULTING FROM THIS AGREEMENT, OR FROM THE FURNISHING, PERFORMANCE, INSTALLATION, OR USE OF THE SOFTWARE PRODUCT, WHETHER DUE TO A BREACH OF CONTRACT, BREACH OF WARRANTY, OR THE NEGLIGENCE OF CYBER CRUCIBLE OR ANY OTHER PARTY, EVEN IF CYBER CRUCIBLE IS ADVISED BEFOREHAND OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT THAT THE APPLICABLE JURISDICTION LIMITS CYBER CRUCIBLE'S ABILITY TO DISCLAIM ANY IMPLIED WARRANTIES, THIS DISCLAIMER SHALL BE EFFECTIVE TO THE MAXIMUM EXTENT PERMITTED.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-4 mb-2 text-dark-grey">Limitation of Remedies and Damages</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                Your remedy for a breach of this Agreement or of any warranty included in this Agreement is the correction or replacement of the Software Product. Selection of whether to correct or replace shall be solely at the discretion of Cyber Crucible. Cyber Crucible reserves the right to substitute a functionally equivalent copy of the Software Product as a replacement. If Cyber Crucible is unable to provide a replacement or substitute Software Product or corrections to the Software Product, your sole alternate remedy shall be a refund of the purchase price for the Software Product exclusive of any costs for shipping and handling.
                            </p>
                            <p className="lg:w-full mt-4 w-full leading-relaxed text-dark-grey break-all">
                                Any claim must be made within the applicable warranty period. All warranties cover only defects arising under normal use and do not include malfunctions or failure resulting from misuse, abuse, neglect, alteration, problems with electrical power, acts of nature, unusual temperatures or humidity, improper installation, or damage determined by Cyber Crucible to have been caused by you. All limited warranties on the Software Product are granted only to you and are non-transferable. You agree to indemnify and hold Cyber Crucible harmless from all claims, judgments, liabilities, expenses, or costs arising from your breach of this Agreement and/or acts or omissions.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-4 mb-2 text-dark-grey">Governing Law, Jurisdiction and Costs</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                This Agreement is governed by the laws of Delaware, without regard to Delaware's conflict or choice of law provisions.
                            </p>
                            <h2 className="lg:text-3xl md:text-3xl sm:text-2xl xs:text-2xl font-bold mt-4 mb-2 text-dark-grey">Severability</h2>
                            <p className="lg:w-full mt-2 w-full leading-relaxed text-dark-grey">
                                If any provision of this Agreement shall be held to be invalid or unenforceable, the remainder of this Agreement shall remain in full force and effect. To the extent any express or implied restrictions are not permitted by applicable laws, these express or implied restrictions shall remain in force and effect to the maximum extent permitted by such applicable laws.
                            </p>

                        </article>
                    </div>
                </div>
            </section>


            <NonGridPageFooter/>

        </div>
    )
}

export default Terms
