import React, {useEffect} from "react";
import {getAuthorizationCode} from "../../utils/axiosInstance";
import Footer, {NonGridPageFooter} from "../../components/footer";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import {Link} from "react-router-dom";

export default function Callback() {

    useEffect(() => {
        async function code(){
            try{
                let urlParams = new URLSearchParams(window.location.search);
                let code = urlParams.get('code');
                if(!code){
                    //TODO: maybe go back to home page when no code is found.
                    console.log("no code found")
                }
                else{
                    console.log("code found")
                    getAuthorizationCode(code);
                    document.getElementById("agentsLink").click();
                }
            }
            catch(error){
                console.log(error);
                //TODO: maybe go back to home page and alert user with notification
            }
        }
        code();
    }, [])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="keywords" content="" />
                <meta name="author" content="" />
                <title>Cyber Crucible</title>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>

            <Header/>

            <div className="hidden">
                {/*<Link id="agentsLink" to="/private/agents"/>*/}
                <Link id="agentsLink" to="/private/incidents"/>
            </div>

            <NonGridPageFooter/>

        </div>
    );
}
