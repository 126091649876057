import {useForm} from "react-hook-form";
import Modal from "react-modal";
import React, {useState} from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {getZenGroupDropDownContents} from "../utils/zenGroupSessionStorageManager";
import {
    createStripeTrainingLicensesCheckoutSessionReactive,
    sendInvoiceForTrainingLicensesReactive
} from "../pages/api/trainingLicensesApi";
import {Button, ThemeProvider} from "@mui/material";
import {MuiAutocompleteForZenGroupsWithoutCreateGroupOption, MuiCloseIconButton} from "./muiComponents";
import {buttonTheme} from "../utils/muiStyling";

export const StripeTrainingLicensesSetupModal = ({ onClose, opened, setIsLoading, setIsOpened }) => {
    const {handleSubmit, register, reset} = useForm();
    const [zenGroup, setZenGroup] = useState();
    const [submittedWithStripe, setSubmittedWithStripe] = useState(false);
    const [submittedWithInvoice, setSubmittedWithInvoice] = useState(false);

    const resetAll = () => {
        setIsLoading && setIsLoading(false)
        setZenGroup()
        onClose && onClose()
        reset({trainingLicensesCount: 1})
    }

    const onSubmit = (data) => {
        if(zenGroup && data && data.trainingLicensesCount){
            if(data.trainingLicensesCount < 1){
                NotificationManager.info("You must select at least one license");
                return
            }
            if(submittedWithStripe){
                //stripe checkout setup
                let pk = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
                setIsLoading && setIsLoading(true)
                createStripeTrainingLicensesCheckoutSessionReactive(zenGroup, data.trainingLicensesCount).then(session => {
                    try{
                        let stripe = window.Stripe(pk)
                        stripe.redirectToCheckout({sessionId: session.sessionId})
                        setIsLoading && setIsLoading(false)
                    }
                    catch(error){
                        NotificationManager.error("Error continuing to payment");
                        //console.log(error)
                    }
                    setIsLoading && setIsLoading(false)
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error continuing to payment");
                    }
                    setIsLoading && setIsLoading(false)
                })
                //setIsLoading && setIsLoading(false)
            }
            else if (submittedWithInvoice){
                //sending invoice for licenses
                setIsLoading && setIsLoading(true)
                sendInvoiceForTrainingLicensesReactive(zenGroup, data.trainingLicensesCount).then(data => {
                    NotificationManager.success("Successfully sent invoice");
                    setIsOpened && setIsOpened(false)
                    resetAll()
                    setIsLoading && setIsLoading(false)
                }).catch(error => {
                    if(error.message){
                        NotificationManager.error(error.message);
                    }
                    else{
                        NotificationManager.error("Error sending invoice");
                    }
                    setIsLoading && setIsLoading(false)
                })
            }
        }
        else {
            NotificationManager.error("Please fill out all fields");
        }
    }
    return (
        <Modal
            onRequestClose={() => {
                resetAll()
            }}
            isOpen={opened}
            contentLabel={"Form Data Before Continuing to Payment"}
            className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white xl:w-md lg:w-md md:w-md sm:w-md xs:w-11/12 max-w-md inset-y-10 mx-auto rounded-2xl`}
            overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            shouldCloseOnOverlayClick={false}
        >
            <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-1 flex-col w-full gap-y-3">
                    {/*Title with exit button*/}
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Purchase Training Licenses</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                resetAll()
                            }}
                        />
                    </div>
                    <hr className="h-0.5" />
                    {/*Form content*/}
                    <div className={"items-center gap-x-2 gap-y-2"}>
                        <div className="ml-1">
                            <small>Select which group to purchase training licenses for</small>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                value={zenGroup}
                                onChange={( event, value ) => {
                                    setZenGroup(value?.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={"ml-1 flex flex-col items-center"}>
                        <small>Training licenses</small>
                        <input
                            id={'trainingLicensesCount'}
                            name="trainingLicensesCount"
                            type="number"
                            defaultValue={1}
                            onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g,'');
                            }}
                            onKeyPress={(e) => {
                                if(e.key === 'Enter'){
                                    e.preventDefault();
                                }}}
                            {...register("trainingLicensesCount")}
                            className="text-center self-center w-32 focus:outline-none h-10 p-1 mt-2 rounded-lg border border-black border-opacity-25 border-solid"
                        />
                    </div>
                    <div className={"flex flex-row flex-wrap gap-y-3 gap-x-3 justify-between"}>
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    className={"flex-1"}
                                    color={"primary"}
                                    type={"submit"}
                                    onClick={() => {
                                        setSubmittedWithInvoice(false)
                                        setSubmittedWithStripe(true)
                                    }}>
                                Pay Now
                            </Button>
                        </ThemeProvider>
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    className={"flex-1"}
                                    color={"primary"}
                                    type={"submit"}
                                    onClick={() => {
                                        setSubmittedWithInvoice(true)
                                        setSubmittedWithStripe(false)
                                    }}>
                                Send Invoice
                            </Button>
                        </ThemeProvider>
                    </div>

                </div>

            </form>
        </Modal>
    );
};
