import React, {Component, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Modal from "react-modal";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {refreshGridZenGroupOnlyWithSetDataValue} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {handleGroupColumnChangeNameOnly, SilentIncidentCellEditingStopped} from "../../utils/gridCellEditing";
import {findZenGroupById, getZenGroupDropDownContents} from "../../utils/zenGroupSessionStorageManager";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {
    changeSilentIncidentNameReactive,
    deleteSilentIncidentReactive,
    findBySilentIncidentIdListReactive,
    updateSilentIncidentsGridColumnModeReactive,
    updateSilentIncidentsGridColumnStateReactive,
    updateSilentIncidentsGridFilterModelReactive,
    updateSilentIncidentsGridUseColumnStateReactive,
    updateSilentIncidentsGridUseFilterStateReactive,
    userCreateSilentIncidentReactive
} from "../api/silentResponsesApi";
import {useLocation} from "react-router-dom";
import CustomNameCellEditor, {editNameIconOnlyCellRenderer} from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, IconButton, Switch, ThemeProvider} from "@mui/material";
import {
    MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiCloseIconButton,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    mediumColumnModeText,
    minColumnModeText,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {BrowserUtilityProcessTuningModal} from "../../components/responseTuningComponents";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

let gridColumnStateSessionVariableName = "silentIncidentsGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "path", "programArguments"]
let medColumnIds = ["zenGroupDisplayName", "silentIncidentDisplayName", "path", "programArguments"]

export default function SilentResponses() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [zenGroup, setZenGroup] = useState(null);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [tuningModalIsOpen, setTuningModalIsOpen] = useState(false);
    const [programArgumentsToggled, setProgramArgumentsToggled] = useState(true);
    const [gridApi, setGridApi] = useState();
    const [enableButtons, setEnableButtons] = useState(false);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("silentIncidentsGridFilterState"));
    const [createSilentIncidentDefaultPathValue, setCreateSilentIncidentDefaultPathValue] = useState();
    const [createSilentIncidentDefaultArgsValue, setCreateSilentIncidentDefaultArgsValue] = useState();
    const silentIncidentLocation = useLocation();
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        { field: "silentIncidentDisplayName", headerName: "Name", initialWidth : 330,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
                return editNameIconOnlyCellRenderer(params, "Click to Edit this Silent Response's Name", "silentIncidentDisplayName")
            }
        },
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "path", headerName: "Path", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "programArguments", headerName: "Program Arguments", initialWidth: 700,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
        { field: "created", headerName: "Created", initialWidth: 300,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "userCreatedItUsername", headerName: "Created By", initialWidth: 600,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    useEffect(() => {
        if(silentIncidentLocation && silentIncidentLocation.state){
            window.scroll({behavior: "smooth", top: 0, left: 0})
            setModalIsOpen(true)
            if(silentIncidentLocation.state.zenGroupId){
                setZenGroup(findZenGroupById(silentIncidentLocation.state.zenGroupId).id)
            }
            if(silentIncidentLocation.state.path){
                setCreateSilentIncidentDefaultPathValue(silentIncidentLocation.state.path)
            }
            if(silentIncidentLocation.state.programArguments){
                setProgramArgumentsToggled(true)
                setCreateSilentIncidentDefaultArgsValue(silentIncidentLocation.state.programArguments)
            }
            else{
                setProgramArgumentsToggled(false)
            }
            //so on refresh or clicking back then forward tabs this modal does not keep popping up
            window.history.replaceState(silentIncidentLocation.state, '')
        }
    }, [silentIncidentLocation]);
    const createSilentIncident = (data) => {
        if (data.path && data.path.trim().length > 0 && zenGroup) {
            setIsLoading(true);
            let programArguments = null;
            if(programArgumentsToggled){
                if(!data.programArguments){
                    NotificationManager.error("Please make sure all fields are filled out and try again.");
                    setIsLoading(false)
                    return;
                }
                else{
                    if(data.programArguments.trim().length < 1){
                        NotificationManager.error("Please make sure all fields are filled out and try again.");
                        setIsLoading(false)
                        return;
                    }
                    else{
                        programArguments = data.programArguments.trim()
                    }
                }
            }
            let friendlyName = null
            if(data.friendlyName && data.friendlyName.trim().length > 0){
                friendlyName = data.friendlyName.trim()
            }
            userCreateSilentIncidentReactive(data.path.trim(), zenGroup, programArguments, friendlyName).then(response => {
                setIsLoading(false);
                if(response.silentIncidentExistedAlready === true && response.silentIncidentEnabled === false){
                    NotificationManager.info("A Silent Response existed already for this path and program argument combination within the same group");
                }
                else if(response.silentIncidentExistedAlready === true && response.silentIncidentEnabled === true){
                    NotificationManager.success("Successfully enabled the silent response that already existed for this path and program argument combination within the same group!");
                    resetCreateResponseModal()
                }
                else{
                    NotificationManager.success("Silent Response created successfully!");
                    resetCreateResponseModal()
                }
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error, please try again.");
                }
                setIsLoading(false);
            })
        }
        else{
            NotificationManager.error("Please make sure all fields are filled out and try again.");
        }
    };

    const removeSilentIncident = () => {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            //loop through all the nodes
            setIsLoading(true)
            for (let i in gridApi.getSelectedNodes()){
                let silentIncidentToDelete = gridApi.getSelectedNodes()[i] //only allowing single selection on this page, so grab first element
                if(silentIncidentToDelete && silentIncidentToDelete.data && silentIncidentToDelete.data.id){
                    deleteSilentIncidentReactive(silentIncidentToDelete.data.id).then(response => {
                        gridApi.applyTransactionAsync({
                            remove: [silentIncidentToDelete.data]
                        })
                    }).catch(function(error){
                       // if(error.message){
                       //     NotificationManager.error(error.message);
                       // }
                       // else{
                       //     NotificationManager.error("Unexpected error, please try again.");
                       // }
                       // setIsLoading(false)
                    })
                }
            }

            // successfully removed all
            setIsLoading(false)
            NotificationManager.success("Silent Response(s) successfully removed for groups you have permission in");
        }
    };

    function resetCreateResponseModal(){
        reset({
            path: "",
            friendlyName: "",
            programArguments: ""
        })
        setZenGroup(null)
        setModalIsOpen(false)
        setProgramArgumentsToggled(true)
        setCreateSilentIncidentDefaultPathValue()
        setCreateSilentIncidentDefaultArgsValue()
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Silent Response Rules</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Create Silent Response Modal*/}
            <Modal contentLabel="Create Silent Response"
                   isOpen={modalIsOpen}
                   onRequestClose={() => {
                       resetCreateResponseModal()
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(createSilentIncident)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Create Silent Response</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    resetCreateResponseModal()
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className="ml-1 mt-5">
                            <label>Select which group to add to</label>
                            <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                                zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                                value={zenGroup}
                                onChange={( event, value ) => {
                                    setZenGroup(value?.value)
                                }}
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Only use a single \ for your paths, and you can use '.*' as a wildcard. e.g. C:\Program Files\.*\word.exe</label>
                            <br />
                            <input
                                name="path"
                                type="text"
                                defaultValue={createSilentIncidentDefaultPathValue}
                                required
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("path")}
                                placeholder={"Path"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className={`flex flex-row items-center ml-1 mt-5`}>
                            <ThemeProvider theme = {switchTheme}>
                                <FormControlLabel control={
                                    <Switch
                                        checked={programArgumentsToggled}
                                        name="toggleSilentIncidentProgramArguments"
                                        onChange={e => setProgramArgumentsToggled(e.target.checked)}
                                    />
                                } label={programArgumentsToggled ? "Limit to Program + Arguments" : "Do Not Limit to Program + Arguments"}/>
                            </ThemeProvider>
                        </div>
                        <div className={`${programArgumentsToggled ? "block": "hidden"} ml-1 mt-5`}>
                            <label>Program Arguments. You may use '.*' as a wildcard.</label>
                            <br />
                            <input
                                name="programArguments"
                                type="text"
                                defaultValue={createSilentIncidentDefaultArgsValue}
                                required={programArgumentsToggled}
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("programArguments")}
                                placeholder={"Program Arguments"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Name for the silent response</label>
                            <br />
                            <input
                                name="friendlyName"
                                type="text"
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("friendlyName")}
                                placeholder={"Optional"}
                                className="focus:outline-none h-10 p-1 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-1 flex-col mt-3">
                        <ThemeProvider theme = {buttonTheme}>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    type={"submit"}
                            >
                            Create
                            </Button>
                        </ThemeProvider>
                        </div>
                    </div>

                </form>
            </Modal>
            <BrowserUtilityProcessTuningModal tuningModalIsOpen={tuningModalIsOpen} setTuningModalIsOpen={setTuningModalIsOpen} setIsLoading={setIsLoading} />
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col mr-10 ml-5 mt-8 flex-nowrap gap-y-2 h-full">
                    {privatePageHeaderHelper("Silent Response Rules")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<PersonAddAlt1Icon className={"cursor-pointer"}/>} tooltipTitle={"Create a Silent Response"}
                                    tooltipPlacement={"top"}
                                    onClick={() => {
                                        setModalIsOpen(!modalIsOpen)
                                    }}/>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>} tooltipTitle={"Delete Silent Responses"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        removeSilentIncident()
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={
                                        <IconButton
                                            sx={{width: 25, height: 25}} className={`self-center object-contain`} disableRipple={true}
                                        >
                                            <FontAwesomeIcon className={`object-contain`} icon={"fa-duotone fa-music-note"} size="sm" color={`black`}/>
                                        </IconButton>
                                    }
                                    tooltipTitle={"Tuning Available"} tooltipPlacement={"top"}
                                    onClick={() => {
                                        setTuningModalIsOpen(true)
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} updateGridColumnModeFunction={updateSilentIncidentsGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} refreshGridFunction = {refreshGrid} sseDataPullActive={sseDataPullActive}
                                          showExcelExportIcon={true} excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'silentIncidentsGridFilterState', updateSilentIncidentsGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateSilentIncidentsGridUseColumnStateReactive);
    }


    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                setEnableButtons={setEnableButtons}
                setGridApi={setGridApi}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }
    async function refreshGrid(){
        await refreshGridZenGroupOnlyWithSetDataValue(gridApi, "id", "id", findBySilentIncidentIdListReactive, ["silentIncidentDisplayName"])
    }

    function excelExport(){
        standardExcelExportHelper(gridApi, sseDataPullActive, "silentResponsesGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, setEnableButtons, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        //params.api.getFilterInstance("zenGroupDisplayName");
        params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"], suppressFlash: true})
        });
    };
    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSilentIncidentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateSilentIncidentsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateSilentIncidentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateSilentIncidentsGridColumnModeReactive, customColumnModeText)
        }
    }

    cellEditingStoppedFunctionCaller = (event) => {
        if(event.column.colId === "zenGroupDisplayName"){
            handleGroupColumnChangeNameOnly(event)
        }
        else{
            SilentIncidentCellEditingStopped(event, changeSilentIncidentNameReactive,"silentIncidentDisplayName")
        }
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateSilentIncidentsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateSilentIncidentsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        onGridReadyHelper(gridReadyParams, "silentIncidentsGridFilterState");
        await loadDataWithSSEAndStartChangeStreamListener("/sse/getSilentIncidentsReactive", "/sse/listenToSilentIncidentEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, this.props.setAsyncTransactionWaitMillis, this.updateTransactionsToApply,
            this.abortController)
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={this.cellEditingStoppedFunctionCaller}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'silentIncidentsGridFilterState', updateSilentIncidentsGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
