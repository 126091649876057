import {decryptAndGetSessionVariable} from "./storageHelper";

export function dateValueFormatter(params){
    return convertDateToDateWithSessionTimeZone(params.value)
}

export function convertDateToDateWithSessionTimeZone(dateValue){
    if(dateValue && dateValue.trim().length > 0){
        if(decryptAndGetSessionVariable("timezone")){
            try{
                return new Date(dateValue).toLocaleString('en-US', {
                    timeZone: decryptAndGetSessionVariable("timezone"),
                    dateStyle: "long",
                    timeStyle: "long"
                })
            }
            catch(error){
                //default to UTC String
                return new Date(dateValue).toUTCString()
            }
        }
        else{
            //default to UTC String
            return new Date(dateValue).toUTCString()
        }
    }else{
        return ""
    }
}

export function dateValueGetter(params, objectKey){
    try{
        return convertDateToDateWithSessionTimeZone(params.node.data[objectKey])
    } catch (e) {
        return ""
    }
}