import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import React, {useState} from "react";
import {NavLink} from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {registerUserReactive} from "../api/registerApi";
import {useForm} from "react-hook-form";
import {AcceptEulaModal} from "../../components/LoginModals";
import {InformationModal} from "../../components/informationOnlyModal";
import * as EmailValidator from "email-validator";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {Button, IconButton, ThemeProvider} from "@mui/material";
import {roundButtonTheme} from "../../utils/muiStyling";
import CCLogo from "../../components/CCLogo";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
//const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function Register(){
    const [isLoading, setIsLoading] = useState(false);
    const [showEula, setShowEula] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    //const [eulaAccepted, setEulaAccepted] = useState(false);
    const { register, handleSubmit} = useForm();

    const onSubmit = async ({ firstName, lastName, email, company, pass, passConfirm, eulaAccepted }) => {
        if(firstName.trim().length > 0 && lastName.trim().length > 0 && email.trim().length > 0 && pass.trim().length > 0 && passConfirm.trim().length > 0 && eulaAccepted === true ){
            email = email.trim();
            if(!EmailValidator.validate(email)){
                NotificationManager.error("Please enter a valid email address")
                return;
            }
            if(pass === passConfirm){
                try {
                    setIsLoading(true);
                    email = email.toLowerCase();
                    await registerUserReactive(firstName.trim(), lastName.trim(), email, company, pass);
                    setIsLoading(false);
                    setShowSuccessModal(true);
                } catch (error) {
                    if(error.message){
                        NotificationManager.error(error.message)
                    }
                    else{
                        NotificationManager.error("Error creating your account.")
                    }
                    setIsLoading(false);
                }
            }
            else{
                NotificationManager.error("Passwords must match");
                setIsLoading(false);
            }
        }
        else{
            NotificationManager.error("Make sure all fields are filled out correctly");
            setIsLoading(false);
        }
    };

    return(
        <div className="flex flex-col justify-center align-center">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Cyber Crucible User Registration</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <AcceptEulaModal
                opened={showEula}
                onClose={() => {
                    setShowEula(false)
                }}
                infoOnly={true}
            />
            <InformationModal
                opened={showSuccessModal}
                onClose={() => {
                    //go to login page
                    document.getElementById("loginNavLink").click();
                }}
                text="You have successfully created a new account, please verify your account by visiting the link sent to your email address. You will not be able to login without verifying your account."
                contentLabel={"Successfully Registered New Account"}
            />
            <form className="flex justify-center" onSubmit={handleSubmit(onSubmit)}>
                <div className="flex mx-6 flex-col justify-center align-center gap-y-5 content-center sm:max-w-[75%] md:max-w-[50%]">
                    {/*Image div*/}
                    <CCLogo className={""} />
                    {/*Register Form Div*/}
                    <div className="flex flex-1 flex-col text-left items-start content-center gap-y-5">
                        <label className="text-3xl">Create Account</label>
                        <span className="inline-block h-1 w-32 rounded bg-theme"></span>
                        <div className="w-full flex flex-col gap-y-2 flex-nowrap">
                            <small className="">First Name</small>
                            <input
                                autoComplete="off"
                                autoFocus={true}
                                type="text"
                                required
                                name="firstName"
                                {...register("firstName")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full flex flex-col gap-y-2 flex-nowrap">
                            <small className="">Last Name</small>
                            <input
                                autoComplete="off"
                                type="text"
                                required
                                name="lastName"
                                {...register("lastName")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full flex flex-col gap-y-2 flex-nowrap">
                            <small className="">Email</small>
                            <input
                                autoComplete="off"
                                type="text"
                                required
                                name="email"
                                {...register("email")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full flex flex-col gap-y-2 flex-nowrap">
                            <small className="">Company Name (Optional)</small>
                            <input
                                autoComplete="off"
                                type="text"
                                name="company"
                                {...register("company")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full">
                            <small className="">Password</small>
                            <input
                                type={showPassword ? 'text' : 'password'}
                                autoComplete="off"
                                required
                                name="pass"
                                {...register("pass")}
                                className="focus:outline-none h-10 p-2 w-full mt-2 rounded-lg border border-black border-opacity-25 border-solid pr-10"
                            />
                            <span className="passwordIcon">
                                            {showPassword
                                                ?
                                                <IconButton sx={{width: 25, height: 25}}
                                                            className={`self-center object-contain`}>
                                                    <Visibility className={"cursor-pointer"} onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}/>
                                                </IconButton>
                                                :
                                                <IconButton sx={{width: 25, height: 25}}
                                                            className={`self-center object-contain`}>
                                                    <VisibilityOff className={"cursor-pointer"} onClick={() => {
                                                        setShowPassword(!showPassword)
                                                    }}/>
                                                </IconButton>
                                            }
                                        </span>
                        </div>
                        <div className="w-full flex flex-col gap-y-2 flex-nowrap">
                            <small className="">Confirm Password</small>
                            <input
                                autoComplete="off"
                                type={showPassword ? 'text' : 'password'}
                                required
                                name="passConfirm"
                                {...register("passConfirm")}
                                className="focus:outline-none h-10 p-2 w-full mt-1 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="w-full flex flex-row gap-x-3">
                            <input
                                className="checkbox checked:bg-orange-100 checked:border-orange-100"
                                type="checkbox"
                                required
                                name="eulaAccepted"
                                {...register("eulaAccepted")}
                            />
                            <small className="flex flex-row flex-wrap justify-between">
                                I agree to comply with the End User License Agreement found
                                <div
                                    className="cursor-pointer text-theme-teal hover:text-theme"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowEula(true)
                                    }}
                                >&nbsp;here</div>.</small>
                        </div>

                    </div>
                    <div className="w-full flex flex-row justify-center">
                        <ThemeProvider theme={roundButtonTheme}>
                            <Button variant={"contained"} color={"primary"}
                                    type={"submit"}
                                    className={"flex-1"}
                                    style={{fontSize: 20}}
                            >
                                Create Account
                            </Button>
                        </ThemeProvider>
                    </div>
                    <div className="mt-10 text-center">
                        <p>Have an account with us already?
                            <NavLink id="loginNavLink" className="ml-3 text-theme-teal hover:text-theme" to="/login">
                                Sign In
                            </NavLink></p>
                    </div>

                </div>
            </form>
            <div className={"self-center"}>
                <NonGridPageFooter/>
            </div>
            <NotificationContainer/>
        </div>
    )
}
