import {createTheme} from '@mui/material/styles';
import shadows from "@mui/material/styles/shadows";

export const buttonTheme = createTheme({
    palette: {
        primary: {
            main: '#e76a24',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fff',
            contrastText: '#000000',
        },
        action: {
            disabledBackground: '#FFC0A4',
            disabled: '#fff'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 16,
        px:2,
        transform: "none",
        button: {
            textTransform: 'none',
            fontSize: 16,
            fontWeight: 500,
        },
    },
});

export const blueButtonTheme = createTheme({
    palette: {
        primary: {
            main: '#4194a5',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#FFC0A4',
            disabled: '#fff'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: { minWidth: 0 }
            }
        }
    },
    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 16,
        px:2,
        transform: "none",
        button: {
            textTransform: 'none',
            fontSize: 16,
            fontWeight: 500,
        },
    },
});

export const roundButtonTheme = createTheme({
    palette: {
        primary: {
            main: '#e76a24',
            contrastText: '#fff',
        },
        secondary: {
            main: '#F6F6F6',
            contrastText: '#000000',
        },
        action: {
            disabledBackground: '#FFC0A4',
            disabled: '#fff'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 28,
                },
            },
        },
    },

    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 15,
        px:2,
        transform: "none",
        button: {
            textTransform: 'none',
            fontSize: 15,
            fontWeight: 500,
        },
    },
});
export const iconButtonTheme = createTheme({
    shadows: shadows.map(() => "none"),
    palette: {
        primary: {
            main: '#fff',
            contrastText: '#000000',
        },
        action: {
            disabledBackground: '#FFC0A4',
            disabled: '#fff'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    minWidth: 40,
                    maxWidth: 40,
                    minHeight: 40,
                    maxHeight: 40,
                },
                startIcon: {
                    marginRight: "5px"
                },
            },
        },
    },

    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 16,
        px:2,
        transform: "none",
        button: {
            textTransform: 'none',
            fontSize: 16,
            fontWeight: 500,
        },
    },
});
export const switchTheme = createTheme({
    palette: {
        primary: {
            main: '#e76a24',
            contrastText: '#fff',

        },
        action: {
            disabledBackground: '#FFC0A4',
            disabled: '#fff'
        },
        common: {
            white: '#d9d9d9',
        },
    },

    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 15,
        px:2,
        transform: "none",
        button: {
            textTransform: 'none',
            fontSize: 16,
            fontWeight: 500,
        },
    },
});

export const cellButtonTheme = createTheme({
    palette: {
        primary: {
            main: '#e76a24',
            contrastText: '#fff',
        },
        action: {
            disabledBackground: '#FFC0A4',
            disabled: '#fff'
        },
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },

    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 14,
        px:2,
        transform: "none",
        button: {
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 500,
        },
    },
});

export const autocompleteTheme = createTheme({
    typography: {
        fontFamily: ['Open Sans', 'sans-serif'].join(','),
        textTransform: "none",
        fontSize: 12,
        px:2,
        transform: "none",
    },
});