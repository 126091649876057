import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {LoadingButton} from "@mui/lab";
import {MuiIconButtonWithTooltip} from "./muiComponents";
import {Box, Tooltip} from "@mui/material";


function clearFilters(gridApi){
    if (gridApi !== null && JSON.stringify(gridApi.getFilterModel()) !== '{}'){
        gridApi.setFilterModel({});
    }
}

function clearSort(gridApi){
    if(gridApi !== null){
        gridApi.applyColumnState({
            defaultState: { sort: null },
        });
    }
}
/*
function filteredColumnKeys(gridApi) {
    let columnIdsToIncludeInExport = [];

    gridApi && gridApi.getColumnDefs().forEach(function (column) {
        if(!column.suppressColumnsToolPanel && (column.colId !== "assignedDistributionGroupId")){
            columnIdsToIncludeInExport.push(column.colId);
        }
    });

    return columnIdsToIncludeInExport;
};
*/
export const ClearRefresh = ({gridApi, refreshGridFunction, showRefreshIcon=true,
                              showExcelExportIcon = false, sseDataPullActive = false,
                              fileName = '', excelExportFunction}) => {
    return (
        <div className='flex flex-row justify-end flex-wrap gap-x-5 ml-1 items-center'>
            <div className='flex flex-row justify-end flex-wrap gap-x-5 ml-1'>
            <MuiIconButtonWithTooltip
                icon={
                    <FontAwesomeIcon
                        className="object-contain"
                        icon="fa-duotone fa-filter-slash"
                        size="lg"
                    />
                }
                onClick={() => clearFilters(gridApi)}
                tooltipTitle={"Clear all current grid filters"}
                tooltipPlacement={"top"}
            />
            <MuiIconButtonWithTooltip
                icon={
                    <FontAwesomeIcon
                        className="object-contain"
                        icon="fa-duotone fa-table-columns"
                        swapOpacity
                        size="lg"
                    />
                }
                onClick={() => clearSort(gridApi)}
                tooltipTitle={"Clear current grid sort"}
                tooltipPlacement={"top"}
            />
            {showRefreshIcon && (
                <MuiIconButtonWithTooltip
                    icon={
                        <FontAwesomeIcon
                            className="object-contain"
                            icon="fa-duotone fa-rotate"
                            size="lg"
                        />
                    }
                    onClick={() => refreshGridFunction && refreshGridFunction()}
                    tooltipTitle={"Refresh present grid data"}
                    tooltipPlacement={"top"}
                />
            )}
            </div>
            {showExcelExportIcon && (
                <Tooltip title={<div className={"text-sm"}>{"This button will export the grid data to Excel and will only contain what is shown on the grid. Clear all filters and column states to export all data."}</div>}
                         placement={"top"} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                         followCursor={false}
                         enterDelay={750} arrow>
                    <Box>
                        <LoadingButton
                            loading={sseDataPullActive}
                            disabled={sseDataPullActive}
                            onClick ={() => {
                                if (excelExportFunction) {
                                    excelExportFunction()
                                }
                            }}
                            loadingPosition={"center"}
                            classes={{loadingIndicatorCenter: "pr-2", root: "customMuiLoadingButtonNoPaddingAndMinWidth"}} //aligns loading circle in center of icon
                            sx={{color: "transparent", fontFamily: "Open Sans", textTransform: "none", transform: "none",
                                ':hover': {
                                    bgcolor: "transparent",
                                }
                            }}
                            className={"self-center object-contain"}
                            startIcon={
                                <img className="" src="/images/excel.png" width={"25"} alt="Excel Spreadsheet Icon"/>
                            }
                        />
                    </Box>
                </Tooltip>
            )}
        </div>
    );
};
