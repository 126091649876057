import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {
    findZenGroupById,
    findZenGroupByIdAsync,
    getDistributionGroups,
    getZenGroupFriendlyNames
} from "./zenGroupSessionStorageManager";
import {changeZenGroupDistributionGroupFieldReactive, getZenGroupFriendlyNamesReactive} from "../pages/api/groupsApi";
import React from "react";
import {getEditIconComponent} from "./customCellEditor";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {MuiIconButtonWithTooltip, MuiIconWithTooltip} from "../components/muiComponents";

export const defaultZenGroupColumnInitNoEditableOptions = {
    field: "zenGroupDisplayName",
    headerName: "Group",
    width: 450,
    filter: 'agSetColumnFilter',
    filterParams: {
        buttons: ["reset", "apply", "cancel"],
        valueFormatter: zenGroupFilterParamsCellRenderer,
        values: zenGroupFilterParamsValues,
        refreshValuesOnOpen: true,
        suppressSorting: true,
        showTooltips: true
    },
    sortable: true,
    valueFormatter: zenGroupValueFormatter,
    cellRenderer: zenGroupCellRenderFramework ,
    // cellEditor: "agSelectCellEditor",
    // editable: true,
    // editableOptions: zenGroupEditableOptions,
}

export const defaultZenGroupColumnInitWithOptions = (sortable, editable, onlyCanEditGroupName) => {
    //Ag grid specifies you cannot give both cellEditor and cellEditorSelector in a colDef, so just use onlyCanEditGroupName to determine which one to use
    if(onlyCanEditGroupName){
        return {
            field: "zenGroupDisplayName",
            headerName: "Group",
            initialWidth: 450,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: zenGroupFilterParamsCellRenderer,
                values: zenGroupFilterParamsValues,
                refreshValuesOnOpen: true,
                suppressSorting: true,
                showTooltips: true
            },
            sortable: sortable,
            valueFormatter: zenGroupValueFormatter,
            cellRenderer: zenGroupCellRenderFramework,
            editable: editable,
            cellEditor: "customNameCellEditor"
        }
    }
    else{
        return {
            field: "zenGroupDisplayName",
            headerName: "Group",
            width: 450,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: zenGroupFilterParamsCellRenderer,
                values: zenGroupFilterParamsValues,
                refreshValuesOnOpen: true,
                suppressSorting: true,
                showTooltips: true
            },
            sortable: sortable,
            valueFormatter: zenGroupValueFormatter,
            cellRenderer: zenGroupCellRenderFramework,
            editable: editable,
            cellEditorSelector:function (params) {
                //The eventKey is only set to a value (editNameIconClicked) if the edit icon is pressed, which we would return the customNameCellEditor. If the user double clicks the cell,
                // this eventKey will be null and we would return the agSelectCellEditor.
                //The caller's onCellEditingStopped function needs to handle if it should change the group name or change the object's group, to help with that we are adding a
                // custom attribute to the row data called useChangeGroupNameEditor where if true we should change group name, if false we should change the object's group
                if(params.eventKey === "editNameIconClicked"){
                    params.data.useChangeGroupNameEditor = true
                    return { component: "customNameCellEditor" };
                }
                else{
                    params.data.useChangeGroupNameEditor = false
                    return {
                        component: "customMuiAutocompleteGroupCellEditor",
                    };
                    /*return {
                        component: "agSelectCellEditor",
                        params: {values: getZenGroupFriendlyNames()}
                    };*/
                }
            },
        }
    }
}

export const defaultZenGroupColumnInitWithOptionsWithValueGetter = (sortable, editable, onlyCanEditGroupName, cellEditor="customNameCellEditor",cellEditorPopup=false) => {
    //Ag grid specifies you cannot give both cellEditor and cellEditorSelector in a colDef, so just use onlyCanEditGroupName to determine which one to use
    if(onlyCanEditGroupName){
        return {
            field: "zenGroupDisplayName",
            headerName: "Group",
            initialWidth: 450,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: zenGroupFilterParamsCellRenderer,
                values: zenGroupFilterParamsValues,
                refreshValuesOnOpen: true,
                suppressSorting: true,
                showTooltips: true
            },
            sortable: sortable,
            valueGetter: zenGroupValueGetter,
            cellRenderer: zenGroupCellRenderFrameworkForValueGetter,
            editable: editable,
            cellEditor: "customNameCellEditor"
        }
    }
    else{
        return {
            field: "zenGroupDisplayName",
            headerName: "Group",
            initialWidth: 450,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: zenGroupFilterParamsCellRenderer,
                values: zenGroupFilterParamsValues,
                refreshValuesOnOpen: true,
                suppressSorting: true,
                showTooltips: true
            },
            sortable: sortable,
            valueGetter: zenGroupValueGetter,
            cellRenderer: zenGroupCellRenderFrameworkForValueGetter,
            editable: editable,
            cellEditorSelector:function (params) {
                //The eventKey is only set to a value (editNameIconClicked) if the edit icon is pressed, which we would return the customNameCellEditor. If the user double clicks the cell,
                // this eventKey will be null and we would return the agSelectCellEditor.
                //The caller's onCellEditingStopped function needs to handle if it should change the group name or change the object's group, to help with that we are adding a
                // custom attribute to the row data called useChangeGroupNameEditor where if true we should change group name, if false we should change the object's group
                if(params.eventKey === "editNameIconClicked"){
                    params.data.useChangeGroupNameEditor = true
                    return { component: "customNameCellEditor" };
                }
                else{
                    params.data.useChangeGroupNameEditor = false
                    return {
                        component: "customMuiAutocompleteGroupCellEditor",
                    };
                    /*return {
                        component: "agSelectCellEditor",
                        params: {values: getZenGroupFriendlyNames()}
                    };*/
                }
            },
        }
    }
}

export function zenGroupFilterParamsCellRenderer(params){
    if(params.value!=="(Select All)"){
        //console.debug("zenGroupFilterParamsCellRenderer not select all")
        //console.debug(params)
        //console.debug(params.value.friendlyName)
        //console.debug(params.value["friendlyName"])
        //return params.value.friendlyName
    }else{
        //console.debug("zenGroupFilterParamsCellRenderer select all")
        //console.debug(params)
    }
        //return JSON.parse(params.value).friendlyName;
      //  console.log("Inside select all"+params)
    //}
    //return JSON.parse(params.value).friendlyName;
    return params.value
}

export function zenGroupFilterParamsValues(params){
    params.success(getZenGroupFriendlyNames())
}

/*
    This function assumes the ZenGroup id is 'zenGroupId', and the ZenGroup name column is 'zenGroupDisplayName'.
    I do not think we can add additional parameters to the function since it is directly tied to the ag-grid valueFormatter()
    function (https://www.ag-grid.com/react-data-grid/value-formatters/), so we have to work by assuming the info above.
 */
export function zenGroupValueFormatter(params){
    if(params.node.data.zenGroupDisplayName){
        return params.node.data.zenGroupDisplayName
    }
    if(params && params.node && params.node.data && params.node.data.zenGroupId){
        let matchingZenGroup = findZenGroupById(params.node.data.zenGroupId)
        //let index = params.api.getFilterInstance("zenGroupDisplayName").valueModel.allValues.findIndex(element => element.includes(params.node.data.zenGroupId))
        if(matchingZenGroup && matchingZenGroup.friendlyName){
            if(params.node.data.zenGroupDisplayName===matchingZenGroup.friendlyName){
                return params.node.data.zenGroupDisplayName
            }else{
                params.node.setDataValue("zenGroupDisplayName", matchingZenGroup.friendlyName)
                return matchingZenGroup.friendlyName
            }
        }
        return params.node.data.zenGroupId
    }else{
        //console.log("got to a bad place");
    }
}

export function zenGroupValueGetter(params){
    if(params.node.data.zenGroupDisplayName){
        return params.node.data.zenGroupDisplayName
    }
    if(params && params.node && params.node.data && params.node.data.zenGroupId){
        let matchingZenGroup = findZenGroupById(params.node.data.zenGroupId)
        //let index = params.api.getFilterInstance("zenGroupDisplayName").valueModel.allValues.findIndex(element => element.includes(params.node.data.zenGroupId))
        if(matchingZenGroup && matchingZenGroup.friendlyName){
            if(params.node.data.zenGroupDisplayName===matchingZenGroup.friendlyName){
                return params.node.data.zenGroupDisplayName
            }else{
                params.node.data.zenGroupDisplayName = matchingZenGroup.friendlyName
                return matchingZenGroup.friendlyName
            }
        }
        return params.node.data.zenGroupId
    }else{
        //console.log("got to a bad place");
    }
}
export function parentDistributorGroupNameValueGetter(params){
    if(params.node.data.parentGroupDisplayName){
        return params.node.data.parentGroupDisplayName
    }
    if(params && params.node && params.node.data && params.node.data.parentPartnerGroupId){
        let matchingZenGroup = findZenGroupById(params.node.data.parentPartnerGroupId)
        //let index = params.api.getFilterInstance("parentGroupDisplayName").valueModel.allValues.findIndex(element => element.includes(params.node.data.parentPartnerGroupId))
        if(matchingZenGroup && matchingZenGroup.friendlyName){
            if(params.node.data.parentGroupDisplayName===matchingZenGroup.friendlyName){
                return params.node.data.parentGroupDisplayName
            }else{
                params.node.data.parentGroupDisplayName = matchingZenGroup.friendlyName
                return matchingZenGroup.friendlyName
            }
        }
        return params.node.data.parentPartnerGroupId
    }else{
        //console.log("got to a bad place");
    }
}

export function dealsGridPocGroupColumnValueGetter(params){
    if(params && params.node && params.node.data && params.node.data.pocZenGroupId){
        if(params.node.data.pocGroupDisplayName){
            return params.node.data.pocGroupDisplayName
        }
        let matchingZenGroup = findZenGroupById(params.node.data.pocZenGroupId)
        //let index = params.api.getFilterInstance("zenGroupDisplayName").valueModel.allValues.findIndex(element => element.includes(params.node.data.pocZenGroupId))
        if(matchingZenGroup && matchingZenGroup.friendlyName){
            if(params.node.data.pocGroupDisplayName===matchingZenGroup.friendlyName){
                return params.node.data.pocGroupDisplayName
            }else{
                params.node.data.pocGroupDisplayName = matchingZenGroup.friendlyName
                return matchingZenGroup.friendlyName
            }
        }
        //We did not find group in session, default to displaying the default group name for POCs
        if(params.node.data.prospectOrganization && params.node.data.prospectOrganization.trim().length > 0){
            return `POC - ${params.node.data.prospectOrganization.trim()}`
        }
        else if (params.node.data.partnerDealDisplayName && params.node.data.partnerDealDisplayName.trim().length > 0){
            return `POC - ${params.node.data.partnerDealDisplayName.trim()}`
        }

        return "POC - Group Not Found"
    }else{
        //There is no pocZenGroupId
        return null
    }
}

export function zenGroupValueFormatterAsync(params){
    if(params && params.node && params.node.data && params.node.data.zenGroupId){
        findZenGroupByIdAsync(params.node.data.zenGroupId).then(function(matchingZenGroup){
            if(matchingZenGroup && matchingZenGroup.friendlyName) {
                if(params.node.data.zenGroupDisplayName===matchingZenGroup.friendlyName) {
                    //do nothing
                }else {
                    params.node.setDataValue("zenGroupDisplayName", matchingZenGroup.friendlyName)
                }
            }else{
                params.node.setDataValue("zenGroupDisplayName"," ERROR 1")
            }
        }).catch(function(error){
            console.error(error)
            //in case of error, stop showing spinning div since we have returned from api call
            params.node.setDataValue("zenGroupDisplayName", "ERROR 2")
        })
        if(params.node.data.zenGroupDisplayName){
            return params.node.data.zenGroupDisplayName
        }
        return params.node.data.zenGroupId
    }else{
        console.error("Grid zenGroupId missing.");
    }
}
/*
    This function assumes the ZenGroup id is 'zenGroupId', and the ZenGroup name column is 'zenGroupDisplayName'.
 */
export function zenGroupEditableOptions(params){
    if (params && params.node && params.node.data && params.node.data.zenGroupId) {
        return {values: getZenGroupFriendlyNames()}
    } else {
        //console.log("no param, data, or zenGroupId.");
    }
    return {values: []}
}


// use this cellRender for all other pages except agents page
export async function distributionGroupEnableToggled(params,oldValue, newValue, setZenGroupIdToUpdateDistributionGroup,setDistributionGroupsForReassignModal,
                                                     setGroupsToReassign, setShowReassignGroupsModal) {
    //await new Promise(r => setTimeout(r, 4000));
    //console.log("old value: "+oldValue+"   and new value: "+newValue);
    if (params.node.data && params.node.data.zenGroupId) {
        //console.log("about to send up changeDistributionGroupField call "+params.node.data.zenGroupId+"  "+newValue)
        try {
            let response = await changeZenGroupDistributionGroupFieldReactive(params.node.data.zenGroupId, newValue);
            if (!response.needToReassignGroups) {
                NotificationManager.success("Successfully updated this group");
            } else {
                //user needs to reassign existing groups to have a different assignedDistributionGroupId
                if (response.zenGroupsThatNeedReassigned) {
                    let distributionGroups = getDistributionGroups(true)
                    if (distributionGroups) {
                        setZenGroupIdToUpdateDistributionGroup(params.node.data.zenGroupId)
                        //good to go, we need to remove the value.data.zenGroupId group from the distributionGroups list
                        let distributionGroupsList = []
                        distributionGroups.forEach(e => {
                            if(e.id && e.id !== params.node.data.zenGroupId){
                                distributionGroupsList.push(e)
                            }
                        })
                        let noGroupOption = {"id":"none", "friendlyName":"Do Not Reassign a Value"}
                        distributionGroupsList.push(noGroupOption)
                        setDistributionGroupsForReassignModal(distributionGroupsList)
                        setGroupsToReassign(response.zenGroupsThatNeedReassigned)
                        setShowReassignGroupsModal(true)
                    } else {
                        NotificationManager.error("Unexpected error updating group");
                        params.node.data.distributionGroup = oldValue
                        params.api.refreshCells({
                            columns: ["distributionGroup"],
                            suppressFlash: true,
                            rowNodes: [params.node]
                        })
                    }
                } else {
                    NotificationManager.error("Unexpected error updating group");
                    params.node.data.distributionGroup = oldValue
                    params.api.refreshCells({columns: ["distributionGroup"], suppressFlash: true, rowNodes: [params.node]})
                }
            }
        } catch(error) {
            // here you will have access to error.response
            if(error.message){
                //console.log(error);
                NotificationManager.error(error.message);
            }
            else{
                NotificationManager.error(
                    `Unexpected error updating group`
                );
            }
            params.node.data.distributionGroup = oldValue
            params.api.refreshCells({columns: ["distributionGroup"], suppressFlash: true, rowNodes: [params.node]})
        }
    }
}

export function isZenGroupDistributionGroup(params) {
    let distributionGroupFound = false
    if(params && params.node && params.node.data && params.node.data.zenGroupId) {
        let distributionGroupList = getDistributionGroups()
        if (distributionGroupList.length > 0) {
            let index = distributionGroupList.findIndex(element => element.id === params.node.data.zenGroupId)
            //let index = params.api.getFilterInstance("zenGroupDisplayName").valueModel.allValues.findIndex(element => element.includes(params.node.data.zenGroupId))
            if (index !== -1) {
                return distributionGroupList[index].distributionGroup;
            }
        }
    }
    return distributionGroupFound
}

export function zenGroupCellRenderFramework(params) {
    let spinnerDiv = ""
    let editNameIconDiv = ""
    if(params.colDef.editable === true){
        editNameIconDiv = getEditIconComponent(params, "Click to Edit this Group's Name", "zenGroupDisplayName")
    }
    if(params.data.zenGroupId === params.valueFormatted){
        spinnerDiv = <FontAwesomeIcon
            className="contain fa-pulse"
            icon="fa-light fa-spinner"
            size="lg"
            name="zenGroupDisplayNameLoading"
        />
    }else{
        spinnerDiv = ""
    }

    let distributionDiv = ""

    if (isZenGroupDistributionGroup(params)){
        distributionDiv =
            <div className={"mb-3"}>
                <MuiIconWithTooltip
                    icon={
                        <FontAwesomeIcon
                            className="object-contain"
                            icon="fa-duotone fa-warehouse"
                            size="lg"
                        />
                    }
                    tooltipTitle={"This group is a distribution group. It can automatically supply licenses to linked child groups as necessary."}
                    tooltipPlacement={"bottom-start"}
                />
            </div>
    }


    return(
        <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
            {spinnerDiv}
            <Link to={{pathname:"/private/groups"}} state={{zenGroupClickId: params.node.data.zenGroupId}} className="" >
                <div className={"mb-1"}>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-user-gear"
                                size="xs"
                            />
                        }
                        tooltipTitle={"Click to manage this group"}
                        tooltipPlacement={"bottom-start"}
                    />
                </div>
            </Link>
            {editNameIconDiv}
            {distributionDiv}
            {params.node.data.zenGroupDisplayName}
        </div>
    )
}

export function zenGroupCellRenderFrameworkForValueGetter(params) {
    let spinnerDiv = ""
    let editNameIconDiv = ""
    if(params.colDef.editable === true){
        editNameIconDiv = getEditIconComponent(params, "Click to Edit this Group's Name", "zenGroupDisplayName")
    }
    if(params.data.zenGroupId && !params.data.zenGroupDisplayName){
        spinnerDiv = <FontAwesomeIcon
            className="contain fa-pulse"
            icon="fa-light fa-spinner"
            size="lg"
            name="zenGroupDisplayNameLoading"
        />
    }else{
        spinnerDiv = ""
    }

    let distributionDiv = ""

    if (isZenGroupDistributionGroup(params)){
        distributionDiv = <div className={"mb-1"}>
            <MuiIconButtonWithTooltip
                icon={
                    <FontAwesomeIcon
                        className="object-contain"
                        icon="fa-duotone fa-warehouse"
                        size="xs"
                    />
                }
                tooltipTitle={"This group is a distribution group. It can automatically supply licenses to linked child groups as necessary."}
                tooltipPlacement={"bottom-start"}
            />
        </div>
    }


    return(
        <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
            {spinnerDiv}
            <Link to={{pathname:"/private/groups"}} state={{zenGroupClickId: params.node.data.zenGroupId}} className="" >
                <div className={"mb-1"}>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-user-gear"
                                size="xs"
                            />
                        }
                        tooltipTitle={"Click to manage this group"}
                        tooltipPlacement={"bottom-start"}
                    />
                </div>
            </Link>
            {editNameIconDiv}
            {distributionDiv}
            {params.node.data.zenGroupDisplayName}
        </div>
    )
}

export function assignedDistributionGroupValueFormatterForGroupsPage(params){
    //There are 5 columns that have editable: true on groups grid so not using the .setDataValue() here so it does not break the groups grid with triggering the cell value
    // changing, just refreshing the cells after updating row node to avoid messiness with having to convert all onUpdate functions in col defs to onCellEditingStoped.
    if(params.node.data.assignedDistributionGroupName){
        return params.node.data.assignedDistributionGroupName
    }
    if(params.api && params.node && params.node.data && params.node.data.assignedDistributionGroupId){
        let idList = []
        idList.push(params.node.data.assignedDistributionGroupId);
        getZenGroupFriendlyNamesReactive(idList).then(function(nameObjects){
            if(nameObjects && nameObjects.length>0) {
                if (nameObjects[0].name) {
                    if(nameObjects[0].name === params.node.data.assignedDistributionGroupName){
                        //console.debug(nameObjects[0].name+ " equals assignedDistributionGroupName")
                    }else{
                        params.node.setDataValue("assignedDistributionGroupName", nameObjects[0].name)

                        //Check if sort is present on the assignedDistributionGroupName column before calling params.api.onSortChanged() since that would put the column mode to custom
                        if(params.api){
                            let column = params.api.getColumn("assignedDistributionGroupName")
                            if(column !== null && column !== undefined && (column.sort !== undefined && column.sort !== null)){
                                params.api && params.api.onSortChanged()
                            }
                        }
                        params.api && params.api.onFilterChanged()
                    }
                }else{
                    params.node.setDataValue("assignedDistributionGroupName", " ")
                }
            }
            else{
                params.node.setDataValue("assignedDistributionGroupName", " ")
            }
        }).catch(function(error){
            params.node.setDataValue("assignedDistributionGroupName", " ")
        })
        params.node.data.assignedDistributionGroupName = params.node.data.assignedDistributionGroupId
        return params.node.data.assignedDistributionGroupId
    }
    else{
        if(params.api && params.node && params.node.data){
            params.node.data.assignedDistributionGroupName = "(Double Click to Assign)"
            //params.api.refreshCells({force: true, columns: ["assignedDistributionGroupName"], rowNodes: [params.node]})
            return "(Double Click to Assign)"
        }

    }
}
export function assignedDistributionGroupCellRendererForGroupsPage (params) {
    let spinnerDiv = ""
    //if(params.data.assignedDistributionGroupId === params.valueFormatted){
      if(params.data.assignedDistributionGroupId && ((params.data.assignedDistributionGroupId && !params.data.assignedDistributionGroupName) || (params.data.assignedDistributionGroupId === params.data.assignedDistributionGroupName))){
        spinnerDiv = <FontAwesomeIcon
            className="contain fa-pulse"
            icon="fa-light fa-spinner"
            size="lg"
            name="assignedDistributionGroupNameLoading"
        />
    }else{
        spinnerDiv = ""
    }
    return(
        <div className={"flex flex-nowrap items-center justify-start gap-x-2"}>
            {spinnerDiv}
            {params.data.assignedDistributionGroupName}
        </div>
    )
}
