import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandleInsertEvent,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import NotificationManager from "react-notifications/lib/NotificationManager";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import {sendAgentReleaseEmailToAllUsersReactive} from "../api/agentReleaseEmailSubmissionApi";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {decryptAndGetSessionVariable} from "../../utils/storageHelper";
import {distinctAgentVersionsReactive} from "../api/agentsApi";
import {buttonTheme} from "../../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";
import {
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton,
    MuiIconButtonWithTooltipAndBox
} from "../../components/muiComponents";
import SendIcon from '@mui/icons-material/Send';
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";

let doNotUpdateKeywordForApprovedVersion = "Do Not Update"

export default function AgentReleaseEmailSubmissions() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState([]);
    const [agentVersion, setAgentVersion] = useState();
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "userCreatedItUsername", initialWidth: 330, headerName: "Submitted By",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        { field: "dateOfSubmission", headerName: "Submitted",  initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "agentVersion", headerName: "Agent Version", initialWidth: 200,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        { field: "agentReleaseNotesUrl", initialWidth: 700, headerName: "Agent Release Notes Url",
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            sortable: true
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(275)
    }, []);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            try{
                //We don't want to show "Do Not Update" since that doesn't apply to this page, so if sessionAgentVersionsList is null then just query for the versions, else remove "Do Not Update" from list
                let sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                if(sessionAgentVersionsList === null){
                    distinctAgentVersionsReactive().then(data => {
                        //store list in descending order by version number
                        data?.sort()?.reverse()
                        setDistinctAgentVersionsList(data)
                    }).catch(error => {})
                }
                else{
                    sessionAgentVersionsList = sessionAgentVersionsList.filter(version => version !== doNotUpdateKeywordForApprovedVersion)
                    setDistinctAgentVersionsList(sessionAgentVersionsList)
                }
            } catch (e) {}
        })()
        return () => controller?.abort();
    }, [])

    const sendUsersEmails = (data) => {
        if (agentVersion && agentVersion.trim().length > 0 && data.agentReleaseNotesUrl && data.agentReleaseNotesUrl.trim().length > 0) {
            setIsLoading(true);
            sendAgentReleaseEmailToAllUsersReactive(data.agentReleaseNotesUrl.trim(), agentVersion.trim()).then(response => {
                setIsLoading(false);
                NotificationManager.success("Successfully triggered emails to send");
                setModalIsOpen(false);
                reset({
                    agentReleaseNotesUrl: ""
                })
                setAgentVersion()
            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message);
                }
                else{
                    NotificationManager.error("Unexpected error, please try again.");
                }
                setIsLoading(false);
            })
        }
        else{
            NotificationManager.error("Please make sure all fields are filled out and try again.");
        }
    };

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agent Release Emails</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <Modal contentLabel="Send New Agent Release Email To Users"
                   isOpen={modalIsOpen}
                   onRequestClose={() => {
                       reset({
                           agentReleaseNotesUrl: ""
                       })
                       setAgentVersion()
                       setModalIsOpen(false)
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white w-2xl max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <form className="flex flex-1 flex-col p-8 w-full ml-4 mr-4" onSubmit={handleSubmit(sendUsersEmails)}>
                    <div className="flex flex-1 flex-col">
                        {/*Title with exit button*/}
                        <div className="flex flex-row justify-between">
                            <h1 className="font-bold text-3xl">Send New Agent Release Email To All Users</h1>
                            <MuiCloseIconButton
                                onClick={() => {
                                    reset({
                                        agentReleaseNotesUrl: ""
                                    })
                                    setAgentVersion()
                                    setModalIsOpen(false)
                                }}
                            />
                        </div>
                        <hr className="mt-3 h-0.5" />
                        {/*Form content*/}
                        <div className={`ml-1 mt-5`}>
                            <label>Agent Version</label>
                            <MuiAutocompleteNonGroupOptions
                                options={distinctAgentVersionsList}
                                value={agentVersion}
                                onChange={(event, value) => {
                                    setAgentVersion(value)
                                }}
                            />
                        </div>
                        <div className="ml-1 mt-5">
                            <label>Agent Release Notes URL to Include in Email</label>
                            <br />
                            <input
                                name="agentReleaseNotesUrl"
                                type="text"
                                onKeyPress={(e) => {
                                    if(e.key === 'Enter'){
                                        e.preventDefault();
                                    }}}
                                {...register("agentReleaseNotesUrl")}
                                required={true}
                                className="focus:outline-none h-10 p-2 w-full mt-3 rounded-lg border border-black border-opacity-25 border-solid"
                            />
                        </div>
                        <div className="flex flex-col mt-5">
                            <ThemeProvider theme = {buttonTheme}>
                            <Button type={"submit"} color={"primary"} variant={"contained"}>
                                Send Email To All Users
                            </Button>
                            </ThemeProvider>
                        </div>
                    </div>
                </form>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("Agent Release Emails")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                            <MuiIconButtonWithTooltipAndBox
                                icon={<SendIcon className={"cursor-pointer"}/>}
                                tooltipTitle={"Send Email to all Users for New Release"}
                                tooltipPlacement={"top"}
                                onClick={() => {
                                    setModalIsOpen(true)
                                }}/>
                        </div>
                        <ClearRefresh gridApi = {gridApi} showRefreshIcon={false} />
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            sideBar={sideBar}
                            defaultColDef={defaultColDef}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                        />
                        <Footer />
                    </div>
                </div>
            </div>

            <NotificationContainer />
        </div>
    );
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let objectBody = changeStreamData.body
        if(operationType === "UPDATE" || operationType === "REPLACE"){
            standardHandleUpdateAndReplaceEvent(objectBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
        }
        else if (operationType === "INSERT"){
            standardHandleInsertEvent(objectBody, this.gridApi, this.props.sseDataPullActive)
        }
    }

    getRowId = (params) => {
        return params.data.id
    }

    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        await loadDataWithSSEAndStartChangeStreamListener("/sse/agentReleaseEmailSubmissionListReactive", "/sse/listenToAgentReleaseEmailSubmissionEvent",
            this.populateGrid, this.updateGridForChangeStream, gridReadyParams, this.props.setSSEDataPullActive, null, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div  className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        onCellEditingStopped={this.onCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
