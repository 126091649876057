import React, {useEffect, useState} from 'react'
import {useIdleTimer} from 'react-idle-timer'
import {getShortestSessionExpirationReactive} from "../pages/api/sessionExpirationApi";
import {Auth} from "aws-amplify";
import {handleLogout} from "../utils/axiosInstance";
import {Link} from "react-router-dom";
import {checkIsUsingRememberMe} from "../utils/storageHelper";

let hourMillis = 3600000 //one hour in milliseconds
let maxMillisValue = 1728000000 //20 days
//let hourMillis = 5000 //testing time

export default function SessionTimer({loggedUser}) {
    const [timeOut, setTimeout] = useState();

    useEffect(() => {
        if(loggedUser){
            //if user is logged in, make api call to get the session duration
            getShortestSessionExpirationReactive().then(response => {
                if(response && response.duration !== null){
                    let duration = response.duration //in seconds
                    //convert seconds to milliseconds
                    let durationMillis = duration * 1000
                    //to fix issue with error message from react-idle-timer that popped up "The value for the timeout property must fit in a 32 bit signed integer, 2147483647.". So maxMillisValue is max we are allowing
                    if(durationMillis > maxMillisValue){
                        setTimeout(maxMillisValue)
                    }
                    else{
                        setTimeout(durationMillis)
                    }
                }
                else{
                    //else use the default time of one hour for idle timeout to log users out
                    setTimeout(hourMillis)
                }
            }).catch(function (error) {
                //On error, use the default time of one hour for idle timeout to log users out
                setTimeout(hourMillis)
            })
        }
    }, [loggedUser]) //the code runs when loggedUser is changed in header.js

    useEffect(() => {
        //when the timeOut is set after the api call above (on error or success), reset the idle timer
        handleOnActive()
        if(timeOut !== null && timeOut !== undefined){
            start()
        }
    }, [timeOut])

    const handleOnIdle = event => {
        //console.log('user is idle', event)
        //console.log('last active', new Date(getLastActiveTime()))
        if(loggedUser){
            if(checkIsUsingRememberMe()){
                //If using remember me, then we are just redirecting them to the login page (with the rememberMeEnabled state) but keeping them logged in without clearing
                // storage, where they can just refresh the login page and they will be redirected to the agents page since they are logged in
                document.getElementById("loginLinkWithState").click();
            }
            else{
                //log them out
                Auth.signOut().then(response => {
                    handleLogout()
                }).catch(function (error) {
                    handleLogout()
                })
            }
        }
        else{
            //console.log("user is idle but not logged in")
        }
    }

    const handleOnActive = event => {
        /*console.log('user is active', event)
        console.log('time remaining', getRemainingTime())
        console.log('user is active')*/
    }

    const handleOnAction = event => {
        //console.log('user did something')
    }

    const { start } = useIdleTimer({
        timeout: timeOut,
        startManually: true,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 250
    })

    return (
        <div>
            <div className="hidden">
                <Link id="loginLinkWithState" to="/login" state={{rememberMeEnabled: true}} />
            </div>
        </div>
    )
}