import axios from "../../utils/axiosInstance";

export const changeBrowserDataIncidentNameReactive = async (newName, groupId, incidentId, endpointPathToUse="/changeBrowserDataIncidentNameReactive") => {
    const response = await axios.post(endpointPathToUse, {
        "newName": newName,
        "zenGroupId": groupId,
        "id": incidentId
    });
    return response;
};

export const getBrowserDataIncidentsLazy = async (count, pageCount, sortModel, filters, multiConditionFilters, showAllTelemetry) => {
    const response = await axios.post("/browserDataIncidentList", {
        page: pageCount,
        count: count,
        filters: filters,
        multiConditionFilters: multiConditionFilters,
        sortModel: sortModel,
        showAllTelemetry: showAllTelemetry
    });
    return response.data;
};
export const browserDataIncidentBlocksList = async (count, pageCount, sortModel, filters, multiConditionFilters, showAllTelemetry) => {
    const response = await axios.post("/browserDataIncidentBlocksList", {
        page: pageCount,
        count: count,
        filters: filters,
        multiConditionFilters: multiConditionFilters,
        sortModel: sortModel,
        showAllTelemetry: showAllTelemetry
    });
    return response.data;
};
export const browserDataIncidentHidesList = async (count, pageCount, sortModel, filters, multiConditionFilters, showAllTelemetry) => {
    const response = await axios.post("/browserDataIncidentHidesList", {
        page: pageCount,
        count: count,
        filters: filters,
        multiConditionFilters: multiConditionFilters,
        sortModel: sortModel,
        showAllTelemetry: showAllTelemetry
    });
    return response.data;
};

export const findByBrowserDataIncidentIdListReactive = async (ids) => {
    const response = await axios.post("/findByBrowserDataIncidentIdListReactive",{
        ids
    })
    return response.data
}
export const findByBrowserDataIncidentBlocksIdListReactive = async (ids) => {
    const response = await axios.post("/findByBrowserDataIncidentBlocksIdListReactive",{
        ids
    })
    return response.data
}
export const findByBrowserDataIncidentHidesIdListReactive = async (ids) => {
    const response = await axios.post("/findByBrowserDataIncidentHidesIdListReactive",{
        ids
    })
    return response.data
}

export const browserDataIncidentsPerGroupCountReactive = async (includeWhitelistedIncidents) => {
    const response = await axios.post("/browserDataIncidentsPerGroupCountReactive",{
        includeWhitelistedIncidents: includeWhitelistedIncidents
    })
    return response.data
}

export const browserDataIncidentHidesPerGroupCountReactive = async (includeWhitelistedIncidents) => {
    const response = await axios.post("/browserDataIncidentHidesPerGroupCountReactive",{
        includeWhitelistedIncidents: includeWhitelistedIncidents
    })
    return response.data
}
export const browserDataIncidentBlocksPerGroupCountReactive = async (includeWhitelistedIncidents) => {
    const response = await axios.post("/browserDataIncidentBlocksPerGroupCountReactive",{
        includeWhitelistedIncidents: includeWhitelistedIncidents
    })
    return response.data
}

export const getBrowserDataIncidentsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getBrowserDataIncidentsGridFilterStateReactive", {});
    return response.data;
};

export const updateBrowserDataIncidentsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateBrowserDataIncidentsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateBrowserDataIncidentsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateBrowserDataIncidentsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};
export const findSilentIncidentNamesForSingleBrowserDataIncidentId = async (incidentId) => {
    const response = await axios.post("/findSilentIncidentNamesForSingleBrowserDataIncidentIdReactive", {
        incidentId: incidentId
    });
    return response.data;
};

export const getBrowserDataIncidentsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getBrowserDataIncidentsGridColumnStateReactive", {});
    return response.data;
};
export const updateBrowserDataIncidentsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateBrowserDataIncidentsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateBrowserDataIncidentsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateBrowserDataIncidentsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const updateBrowserDataIncidentsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateBrowserDataIncidentsGridColumnModeReactive", {
        columnMode: columnMode
    })
}
export const updateIdentityIncidentChartVisibilityReactive = async (visible) => {
    return await axios.post("/updateIdentityIncidentChartVisibilityReactive", {
        visible: visible
    });
};
export const updateShowAllIdentityIncidentsTelemetryReactive = async (visible) => {
    return await axios.post("/updateShowAllIdentityIncidentsTelemetryReactive", {
        visible: visible
    });
};
export const checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive = async (incidentId, zenGroupId, uniqueProcessIdentifier, childPath, endpointToUse="/checkBrowserIncidentHasRelatedProcessInjectionsAndCreationsReactive") => {
    const response =  await axios.post(endpointToUse, {
        incidentId: incidentId,
        zenGroupId: zenGroupId,
        uniqueProcessIdentifier: uniqueProcessIdentifier,
        childPath: childPath
    });
    return response.data;
};
export const getBrowserIncidentCCDiffFileReactive = async (incidentId, endpointToUse="/getBrowserIncidentCCDiffFileReactive") => {
    return await axios.post(endpointToUse, {
        incidentId: incidentId
    });
};
export const submitBrowserIncidentForReviewReactive = async (incidentId, endpointToUse="/submitBrowserIncidentForReviewReactive") => {
    return await axios.post(endpointToUse, {
        incidentId
    });
};