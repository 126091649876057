export const rememberMeSignInTimeKey = "rememberMeLoginTime" //if local storage with this key exists then remember me was enabled

export function encryptAndStoreSessionVariable(sessionVariableName, variableStored){
    let variableToBeStored = encryptOrDecrypt(variableStored);
    if(variableToBeStored){
        if(checkIsUsingRememberMe()){
            localStorage.setItem(sessionVariableName, variableToBeStored);
        }
        else{
            sessionStorage.setItem(sessionVariableName, variableToBeStored);
        }
    }
}

export function decryptAndGetSessionVariable(sessionVariableName){
    let encryptedStoredValue = null
    if(checkIsUsingRememberMe()){
        encryptedStoredValue = localStorage.getItem(sessionVariableName);
    }
    else{
        encryptedStoredValue = sessionStorage.getItem(sessionVariableName);
    }
    if (encryptedStoredValue) {
        return encryptOrDecrypt(encryptedStoredValue);
    }
    else{
        return null;
    }
}

function encryptOrDecrypt(input) {
    let key = getItemFromStorageWithoutDecrypting("username")
    if (key === null){
        return null;
    }

    let result = '';
    for (let i = 0; i < input.length; i++) {
        result += String.fromCharCode(input.charCodeAt(i) ^ key.charCodeAt(i % key.length));
    }
    return result;
}

export function removeItemFromStorage(key){
    //removes key from both storage types
    sessionStorage.removeItem(key)
    localStorage.removeItem(key)
}

export function checkIsUsingRememberMe(){
    let localStorageSignIn = localStorage.getItem(rememberMeSignInTimeKey)
    if(localStorageSignIn === null || localStorageSignIn === undefined){
        return false
    }
    else{
        return true
    }
}

export function storeWithoutEncrypting(key, value){
    if(checkIsUsingRememberMe()){
        localStorage.setItem(key, value)
    }
    else{
        sessionStorage.setItem(key, value)
    }
}
export function getItemFromStorageWithoutDecrypting(key){
    if(checkIsUsingRememberMe()){
        return localStorage.getItem(key)
    }
    else{
        return sessionStorage.getItem(key)
    }
}