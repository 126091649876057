import React, {Component, useEffect, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker from "../../utils/DTPicker";
import {
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandlePopulateGrid
} from "../../utils/sseAndChangeStreamHelper";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import {getDefaultAgGridSidebarProps} from "../../utils/gridFilterStateAndColumnStateHelper";
import {useForm} from "react-hook-form";
import {ThemeProvider} from "@mui/material";
import {buttonTheme} from "../../utils/muiStyling";
import LoadingButton from "@mui/lab/LoadingButton";
import {Auth} from "aws-amplify";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {getDemoModeSetting, getTrainingModeSetting} from "../../utils/trainingAndDemoModeHelper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MuiIconWithTooltip} from "../../components/muiComponents";

export default function CcOnlyLicenseTrueUps() {
    const { register, handleSubmit, reset } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(200); //200 to start for the initial sse data pull, will change when sse data pull is done for change streams
    const [columnDefs, setColumnDefs] = useState([
        {
            field: "zenGroupName", initialWidth: 300, headerName: "Group",
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: true,
                suppressSorting: false
            },
            sortable: true
        },
        { field: "totalUncoveredAgents", headerName: "Total Agents with Expired or No License", initialWidth: 390,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "totalUncoveredDesktopAgents", headerName: "Total Desktops with Expired or No License", initialWidth: 400,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "totalUncoveredServerAgents", headerName: "Total Servers with Expired or No License", initialWidth: 400,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "desktopAgentsWithoutLicense", headerName: "Desktops with No License", initialWidth: 275,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "desktopAgentsWithExpiredLicense", headerName: "Desktops with Expired License", initialWidth: 300,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "serverAgentsWithoutLicense", headerName: "Servers with No License", initialWidth: 275,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
        { field: "serverAgentsWithExpiredLicense", headerName: "Servers with Expired License", initialWidth: 300,
            filter: 'agNumberColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                filterOptions: ['equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual'],
                maxNumConditions: 2,
            },
            sortable: true
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(450)
    }, []);

    const [ abortController, setAbortController ] = useState(new AbortController());

    useEffect(() => {
        //This code is here to stop the sse query that retrieves counts if leaving the page so that it does not keep consuming resources
        if(sseDataPullActive){
            return () => {
                stopTrueUpQuery()
            }
        }
    }, [sseDataPullActive]);

    function stopTrueUpQuery() {
        abortController.abort();
        setAbortController(new AbortController());
    }
    const onMonthsSubmit = async (data) => {
        if(gridApi && data && data.dateTimeMinusMonths){
            //Reset grid and set sseDataPullActive to true again so submit button and excel export will be disabled until its done
            setSSEDataPullActive(true)
            gridApi.setGridOption("rowData", []);
            Auth.currentSession().then(response => {
                let accessToken = response.getAccessToken().getJwtToken()
                fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/licenseTrueUpsCCOnly", {
                    method: "POST",
                    headers: {
                        "Accept": "text/event-stream",
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + accessToken
                    },
                    signal: abortController.signal,
                    //Get trainingMode and demoMode settings dynamically from session
                    body: JSON.stringify({
                        trainingMode: getTrainingModeSetting(),
                        demoMode: getDemoModeSetting(),
                        dateTimeMinusMonths: data.dateTimeMinusMonths,
                    }),
                    openWhenHidden: true, //initial sse data pull should be open when hidden so if they hide then view the same tab the initial sse data pull does not start again. This causes
                    // problems with the populateGridFunction since it may try and add the same object/row with the same rowNodeId to the grid, which causes problems for ag grid.
                    onmessage(event) {
                        let rowData = JSON.parse(event.data)
                        if (gridApi) {
                            standardHandlePopulateGrid(rowData, gridApi)
                        }
                    },
                    onclose() {
                        setSSEDataPullActive(false)
                    },
                    onerror(err) {
                        console.error("There was an error from server", err);
                        setSSEDataPullActive(false)
                        throw err //stops operations
                    },
                }).then()
            })
        }
    };

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>CC Only License True Ups</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    {privatePageHeaderHelper("CC Only License True Ups")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between flex-wrap gap-x-0 gap-y-3">
                        <form className="flex flex-row justify-start gap-x-3 flex-wrap gap-y-2 items-center" onSubmit={handleSubmit(onMonthsSubmit)}>
                            <div className="flex flex-row items-center gap-x-3 gap-y-2 flex-wrap">
                                <div className={"flex flex-row gap-x-1 mt-1 flex-nowrap items-center"}>
                                    <MuiIconWithTooltip
                                        icon={<FontAwesomeIcon className="object-contain mt-1 cursor-pointer" icon="fa-light fa-circle-info" size="lg"/>}
                                        tooltipTitle={"This grid shows the number of agents " +
                                            "in each group that are not assigned a license or assigned an " +
                                            "expired license. The criteria for agents in the group is that they are not uninstalled and have called into " +
                                            "validate since the months specified in the input (default 2 months)"} tooltipPlacement={"bottom-start"}
                                    />
                                    <label>Months for Validate Criteria in Query:</label>
                                </div>
                                <input
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter' || e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
                                            e.preventDefault();
                                        }
                                    }}
                                    min={1} max={12} type="number" autoComplete="off" placeholder={"2"} required
                                    name="dateTimeMinusMonths"{...register("dateTimeMinusMonths")}
                                    className="text-center w-16 focus:outline-none h-10 p-1 rounded-lg border border-black border-opacity-25 border-solid"
                                />
                            </div>
                            <ThemeProvider theme={buttonTheme}>
                                <LoadingButton variant={"contained"} color={"primary"} type={"submit"} loading={sseDataPullActive}>
                                    Submit
                                </LoadingButton>
                            </ThemeProvider>

                        </form>
                        <ClearRefresh gridApi={gridApi} showRefreshIcon={false} showExcelExportIcon={true}
                                      sseDataPullActive={sseDataPullActive} excelExportFunction={excelExport}/>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        <Grid
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            sideBar={sideBar}
                            setGridApi={setGridApi}
                            sseDataPullActive={sseDataPullActive}
                            setSSEDataPullActive={setSSEDataPullActive}
                            asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                            setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                            excelExport={excelExport}
                            setIsLoading={setIsLoading}
                        />
                        <Footer/>
                    </div>
                </div>
            </div>

            <NotificationContainer/>
        </div>
    );

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "licenseTrueUpsGridExport")
    }
}

class Grid extends Component {
    rowData = []
    updateTransactionsToApply = []
    abortController = new AbortController()

    constructor(props, onClickRow, filterVals) {
        super(props);
    }

    componentWillUnmount(){
        this.abortController.abort()
    }

    populateGrid = async (rowData) => {
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    getRowId = (params) => {
        return params.data.zenGroupId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onCellEditingStopped = (event) => {
        let gridApi = event.api
        //Nothing to edit on this grid
    }

    onGridReady = async (gridReadyParams) => {
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        //Default sort to sort on totalUncoveredAgents desc.
        if(gridReadyParams && gridReadyParams.api){
            gridReadyParams.api.applyColumnState({
                state: [{colId: "totalUncoveredAgents", sort: "desc"}]
            })
        }

        await loadDataWithSSEAndStartChangeStreamListener("/licenseTrueUpsCCOnly", null,
            this.populateGrid, null, gridReadyParams, this.props.setSSEDataPullActive, null, this.updateTransactionsToApply,
            this.abortController)
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        multiSortKey={"ctrl"}
                        rowData={this.rowData}
                        onGridReady={this.onGridReady}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        suppressModelUpdateAfterUpdateTransaction={true}
                        getRowId={this.getRowId}
                        rowSelection={'single'}
                        onSelectionChanged={() => {}}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        getContextMenuItems={this.getContextMenuItems}
                        onCellEditingStopped={this.onCellEditingStopped}
                        suppressClickEdit={true}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
