import React, {Component, useEffect, useMemo, useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import "../../css/private-web-pages.css";
import {AgGridReact} from "@ag-grid-community/react";
import {ExcelExportModule} from "@ag-grid-enterprise/excel-export";
import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {
    assignAgentLicenseReactive, bulkAssignAgentLicensesReactive,
    cancelUninstallAgentReactive, changeAgentAutoAssignLicenseInValidateReactive,
    distinctAgentVersionsReactive,
    editAgentManagedUpdateAutoUpdateSettingReactive,
    findByAgentIdListReactive,
    getAgentVersionsCountPerGroupReactive,
    getLatestAgentVersionReactive,
    generateInstallerReactive,
    getZenGroupCodesForSiteReactive,
    overrideGroupUpdatePolicyForAgentReactive, releaseAgentLicenseReactive,
    removeAgentManagedUpdateSettingsReactive,
    singleChangeAgentGroupReactive,
    singleSetAgentVisibilityReactive,
    uninstallAgentReactive,
    updateAgentChartVisibilityReactive,
    updateAgentsGridColumnModeReactive,
    updateAgentsGridColumnStateReactive,
    updateAgentsGridFilterModelReactive,
    updateAgentsGridUseColumnStateReactive,
    updateAgentsGridUseFilterStateReactive
} from "../api/agentsApi";
import {NotificationContainer} from 'react-notifications';
import {getLicenseNameWithAgentIdReactive} from "../api/licensesApi";
import {downloadShutdownScriptFile, shutdownServiceScriptGenerate} from "../../utils/generator";
import Modal from "react-modal";
import {
    ConfirmationModal,
    ConfirmationModalWithPermissionsShown,
    PermissionsGrid,
    processGroupPermissionData
} from "../../components/confirmationModal";
import NotificationManager from "react-notifications/lib/NotificationManager";
import 'react-notifications/lib/notifications.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SidebarMenu from "../../components/sideBarComponent";
import {
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    defaultLicenseColumnWithAssignLicenseToAgentOptionCellRendererAndValueGetterForClientSide,
    defaultLicenseDateAssignedColumnWithClientSide,
    defaultLicenseExpirationDateColumnWithClientSide,
    licensesListWithAgentIdSessionStorageChangeStreamListener
} from "../../utils/licenseNameGridHelper";
import {agentPageCellEditingStopped} from "../../utils/gridCellEditing";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {
    findZenGroupById,
    getZenGroupDropDownContents, getZenGroupSessionStorageOrDefault,
    useZenGroupSessionStorage
} from "../../utils/zenGroupSessionStorageManager";
import {
    agentChartLastActiveDaysSessionVariable,
    agentChartVisibleSessionVariable, getAgentChartLastActiveDaysValueInSession,
    getChartVisibilitySettingInSession,
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState,
    updateChartVisibilitySettingInSession,
    updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import {ClearRefresh} from "../../components/clearRefreshButtons";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "../../utils/storageHelper";
import {useLocation} from "react-router-dom";
import CustomNameCellEditor, {
    CustomMuiAutocompleteGroupCellEditor,
    editNameIconOnlyCellRenderer
} from "../../utils/customCellEditor";
import {checkPermission} from "../../utils/permissionCheckHelper";
import DTPicker, {dateFilterParametersInHeaderClientSideGrid} from "../../utils/DTPicker";

import {AgChartsReact} from "ag-charts-react";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import {
    helperCallToBulkLicenseNamesSSEAfterInitialAgentsGridLoad,
    loadDataWithSSEAndStartChangeStreamListener,
    standardHandlePopulateGrid,
    standardHandleUpdateAndReplaceEvent
} from "../../utils/sseAndChangeStreamHelper";
import {RichSelectModule} from "@ag-grid-enterprise/rich-select";
import {BackDropChartLoadingOverlay, BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {standardExcelExportHelper, standardExcelExportObjectInContextMenu} from "../../utils/excelExportHelper";
import {buttonTheme, cellButtonTheme, roundButtonTheme, switchTheme} from "../../utils/muiStyling";
import {Button, FormControlLabel, IconButton, Switch, ThemeProvider, ToggleButton, Tooltip} from "@mui/material";
import {
    MuiAutocompleteForZenGroupsWithCreateGroupOption, MuiAutocompleteForZenGroupsWithoutCreateGroupOption,
    MuiAutocompleteNonGroupOptions,
    MuiCloseIconButton,
    MuiIconButtonWithTooltip,
    MuiIconButtonWithTooltipAndBox, MuiIconWithTooltip
} from "../../components/muiComponents";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText, maxColumnModeText,
    mediumColumnModeText,
    minColumnModeText, standardApplyCustomColumnMode, standardApplyMaximumColumnMode,
    standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';
import GroupIcon from '@mui/icons-material/Group';
import EjectRoundedIcon from "@mui/icons-material/EjectRounded";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import {PersonSharp} from "@mui/icons-material";
import {updateAgentChartLastActiveDaysReactive} from "../api/loginApi";
import {downloadAgentGroupOptionsListReactive} from "../api/groupsApi";
import {inactiveAgentDaysOptions} from "./groups";
import {
    findNotificationEventForFullyOfflineAgentAutoFilter,
    findNotificationEventForOfflineAgentAutoFilter, findNotificationEventForPartiallyOfflineAgentAutoFilter
} from "../api/notificationEventApi";
import {getDateStringForAgGridFilter} from "./incidents";
import {defaultClientSideTextFilterParams} from "../../utils/filterHelper";

Modal.setAppElement('#root')
let chartSessionVariableName = agentChartVisibleSessionVariable
let gridColumnStateSessionVariableName = "agentsGridColumnState"
let followingGroupPolicyText = "Following Group Policy"
let overridingGroupPolicyText = "Overriding Group Policy"
let doNotUpdateKeywordForApprovedVersion = "Do Not Update"
let minColumnIds = ["zenGroupDisplayName", "machineName", "version", "specialStatusMessage", "lastRebootDateTime"]
let medColumnIds = ["zenGroupDisplayName", "agentDisplayName", "machineName", "version", "specialStatusMessage", "licenseDisplayName", "lastTaskingDateTime",
    "lastRebootDateTime", "lastAboutDateTime", "lastWhitelistDateTime", "hiddenFromUI"]
let specialStatusFilterValues = ['decrypted', 'decrypting', 'inactive', 'noEncryptionFound', 'none', 'scanForEncryptedFiles',
    'scanInProgress', 'uninstallInProgress','uninstalled', 'unknownEncryptionFound',
    'updateStaged']
let standardInstallerTypeOptionsList = ["Script (PS1)", "Script (BAT)", "GUI"]
let installerTypeOptionsWithDMZList = ["Script (PS1)", "Script (BAT)", "GUI", "DMZ"]
export const agentChart30DayValue = 30
export const agentChart60DayValue = 60
export const agentChart90DayValue = 90
export const agentChartAllAgentsValue = 0
export default function Agents() {
    const [isLoading, setIsLoading] = useState(false);
    //const [zenGroups, setZenGroups] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [zenGroup, setZenGroup] = useState();
    const [updateAgentZG, setUpdateAgentZG] = useState();
    const [zenGroupNamesWithPermission, setZenGroupNamesWithPermission] = useState(new Set());
    const [zenGroupNamesWithoutPermission, setZenGroupNamesWithoutPermission] = useState(new Set());
    const [zenGroupIdsWithoutPermission, setZenGroupIdsWithoutPermission] = useState(new Set());
    const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
    const [showSuspendServiceModal,setShowSuspendServiceModal] = useState(false);
    const [showUninstallConfirmation, setShowUninstallConfirmation] = useState(false);
    const [showInstallScriptModal, setShowInstallScriptModal] = useState(false);
    const [installScriptGroup, setInstallScriptGroup] = useState();
    const [installerType, setInstallerType] = useState("Script (PS1)");
    const [installerTypeOptionsList, setInstallerTypeOptionsList] = useState(standardInstallerTypeOptionsList);
    const [showCommandLineInstallerTypeOnly, setShowCommandLineInstallerTypeOnly] = useState(false);
    const [downloadAgentGroupOptions,setDownloadAgentGroupOptions] = useState([])
    const [downloadAgentModalProxyOptionsListForGroup,setDownloadAgentModalProxyOptionsListForGroup] = useState([])
    const [downloadAgentModalProxyConfigSelectedValue,setDownloadAgentModalProxyConfigSelectedValue] = useState(null)
    const [showChangeGroupsModal, setShowChangeGroupsModal] = useState(false);
    const [showBulkChangeAutoAllocateLicenseModal, setShowBulkChangeAutoAllocateLicenseModal] = useState(false);
    const [bulkAutoAllocateLicensesToggled, setBulkAutoAllocateLicensesToggled] = useState(false);
    const [newAgentZenGroup, setNewAgentZenGroup] = useState();
    const [showBulkVisibilityModal, setShowBulkVisibilityModal] = useState(false);
    const [bulkShowAgentsToggled, setBulkShowAgentsToggled] = useState(true);
    const [showAutoUpgradeModal, setShowAutoUpgradeModal] = useState(false);
    const [bulkAutoUpgradeToggled, setBulkAutoUpgradeToggled] = useState(true);
    // eslint-disable-next-line no-unused-vars
    const [chartDataSeries, setChartDataSeries] = useState([]);
    const [latestAgentVersion, setlatestAgentVersion] = useState("");
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("agentsGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [suspendServiceGroup,setSuspendServiceGroup] = useState();
    const [gridApi, setGridApi] = useState(null);
    const [gridFilters, setGridFilters] = useState(null);
    const [gridCount, setGridCount] = useState(null);
    const [gridPage, setGridPage] = useState(null);
    const [gridSortModel,setGridSortModel] = useState(null);
    const [enableButtons, setEnableButtons] = useState(false);
    const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
    const [showAssignLicenseConfirmModal, setShowAssignLicenseConfirmModal] = useState(false);
    const [assignLicenseAgentData, setAssignLicenseAgentData] = useState({});
    const agentLocation = useLocation();
    const [showPermissionGrid, setShowPermissionGrid] = useState(false);
    const [chartData, setChartData] = useState([]);
    const [chartIsLoading, setChartIsLoading] = useState(false);
    const [chartOptions, setChartOptions] = useState({});
    const [chartToggled, setChartToggled] = useState(getChartVisibilitySettingInSession(chartSessionVariableName));
    const [sseDataPullActive, setSSEDataPullActive] = useState(true);
    const [asyncTransactionWaitMillis, setAsyncTransactionWaitMillis] = useState(1000);
    const [distinctAgentVersionsList, setDistinctAgentVersionsList] = useState([]);
    const [agentChartLastActiveDaysValue, setAgentChartLastActiveDaysValue] = useState(getAgentChartLastActiveDaysValueInSession());
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    const [showReleaseLicensesConfirmation, setShowReleaseLicensesConfirmation] = useState(false);
    const [showBulkAssignLicensesConfirmationModal, setShowBulkAssignLicensesConfirmationModal] = useState(false);

    /*
        1000ms to start for the initial sse data pull, larger grids benefit from the second delay to give ag grid time to perform saved sorts to apply by default. If we have small number of
        ms then ag grid performance for certain column sorts is slower since it has to re-sort and re-render much more frequently.
     */
    const triggerAssignAgentLicense = (assignLicenseAgentData) => {
        if(assignLicenseAgentData && assignLicenseAgentData.agentId){
            if(assignLicenseAgentData.specialStatusMessage === "uninstalled"){
                NotificationManager.error("You cannot assign a license to an uninstalled agent.")
                return
            }
            assignAgentLicenseReactive(assignLicenseAgentData.agentId).then(response => {
                NotificationManager.success("Successfully assigned a license to this agent.")
                setAssignLicenseAgentData({})
                refreshGrid().then(r => {})

            }).catch(function(error){
                if(error.message){
                    NotificationManager.error(error.message)
                }
                else{
                    NotificationManager.error("Unexpected error assigning a license to this agent.")
                }
            })
        }
        else{
            NotificationManager.error("Unexpected error making this request");
        }

    }
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        { field: "agentId", hide: true, suppressColumnsToolPanel: true, lockVisible: true,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                convertValuesToStrings: true,
                values: []
            },
        },
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "agentWasInsertedInValidateWithoutLicense", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "licenseId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        { field: "grantedTemporaryValidation", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true,true, false),
        { field: "agentDisplayName", headerName: "Agent Name", sortable: true, initialWidth: 330,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            editable: true,
            cellEditor: "customNameCellEditor",
            cellRenderer: function (params) {
            let uninstallDiv = ""
                if(params.node.data.specialStatusMessage !== "uninstalled" && params.node.data.specialStatusMessage !== "uninstallInProgress"){
                    uninstallDiv =
                        <MuiIconButtonWithTooltip
                            icon={
                                <FontAwesomeIcon
                                    icon="fa-duotone fa-trash-can"
                                    size="xs"
                                    className="object-contain mr-0"
                                />
                            }
                            onClick={() => {
                                if(params.node.data.version){
                                    try{
                                        let versionStr = params.node.data.version.replaceAll(".", "")
                                        const regex = /\D/g; //all non-digits
                                        versionStr = versionStr.replaceAll(regex, "") //remove all non-digits from the string
                                        let versionInt = parseInt(versionStr, 10)
                                        if (versionInt <= 332 || isNaN(versionInt) || versionInt === null) {
                                            console.log(`Agent ${params.node.data.agentDisplayName} is not compatible with the remote uninstall feature.`)
                                        }
                                        else{
                                            if(params.node.data.specialStatusMessage === "uninstalled"){
                                                console.log(`Agent ${params.node.data.agentDisplayName} is uninstalled already`)
                                                NotificationManager.error(`This agent is already uninstalled`);
                                            }
                                            else{
                                                uninstallAgentReactive(params.node.data.agentId)
                                                    .then(() => {
                                                        NotificationManager.success(`Uninstall task successfully queued`)
                                                        params.node.setDataValue("specialStatusMessage","uninstallInProgress")
                                                        //need to refresh agent name col to make uninstall icon disappear, limiting to only this row's agentDisplayName cell
                                                        params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [params.node], suppressFlash: true, force: true})
                                                    }).catch((error) => {
                                                    if(error.message){
                                                        NotificationManager.error(error.message)
                                                    }
                                                    else{
                                                        NotificationManager.error("Unexpected error requesting uninstall")
                                                    }
                                                })
                                            }
                                        }
                                    }
                                    catch(error){
                                        NotificationManager.error(`Error requesting remote uninstall, agent is not compatible with the remote uninstall feature`);
                                    }
                                }
                            }}
                            tooltipTitle={"Click to uninstall this agent"}
                            tooltipPlacement={"bottom-start"}
                        />
                }
                return (
                    <div className={"flex flex-nowrap justify-start gap-x-1 items-center"}>
                        {uninstallDiv}
                        {editNameIconOnlyCellRenderer(params, "Click to Edit this Agent's Name", "agentDisplayName")}
                    </div>
                )
            },
        },
        { field: "version", headerName: "Version", sortable: true, initialWidth: 175,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },
        { field: "machineName", headerName: "Machine Name", sortable: true, initialWidth: 225,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },
        { field: "specialStatusMessage", headerName: "Attention", initialWidth: 200,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                valueFormatter: function (params) {
                    if(params.value!=="(Select All)"){
                        switch(params.value) {
                            case "none":
                                return "None"
                            case "uninstallInProgress":
                                return "Uninstall In Progress"
                            case "uninstalled":
                                return "Uninstalled"
                            case "scanForEncryptedFiles":
                                return "Scan For Encrypted Files"
                            case "scanInProgress":
                                return "Scan In Progress"
                            case "noEncryptionFound":
                                return "No Encryption Found"
                            case "unknownEncryptionFound":
                                return "Unknown Encryption Found"
                            case "decrypting":
                                return "Decrypting"
                            case "decrypted":
                                return "Decrypted"
                            case "updateStaged":
                                return "Update Staged"
                            case "inactive":
                                return "Inactive"
                            default:
                                return "";
                        }
                    }
                    else{
                        return params.value;
                    }
                },
                values: specialStatusFilterValues,
                suppressSorting: false,
            },
            sortable:true,
            cellRenderer:
                function (params) {
                    if(params.node.data.specialStatusMessage === "scanInProgress"){
                        return "Scanning"
                    }else if(params.node.data.specialStatusMessage === "decrypting"){
                        return "Decrypting"
                    }else if(params.node.data.specialStatusMessage === "decrypted"){
                        return "Decrypted"
                    }else if(params.node.data.specialStatusMessage === "uninstallInProgress"){
                        return (
                            <ThemeProvider theme = {cellButtonTheme}>
                                <Button variant={"contained"}
                                    color={"primary"}
                                    onClick={() => cancelUninstall(params.node, params)}
                                >Cancel Uninstall
                                </Button>
                            </ThemeProvider>
                        )
                    }else if(params.node.data.specialStatusMessage === "uninstalled"){
                        return "Uninstalled"
                    }
                    else if(params.node.data.specialStatusMessage === "noEncryptionFound"){
                        return "No Encryption Found"
                    }
                    else if(params.node.data.specialStatusMessage === "inactive"){
                        return "Inactive"
                    }
                    else if(params.node.data.specialStatusMessage === "unknownEncryptionFound"){
                        return "Unknown Encryption Found"
                    } else if(params.node.data.specialStatusMessage === "updateStagingStarted" || params.node.data.specialStatusMessage === "updateStaged" || params.node.data.specialStatusMessage === "updateStagedSuccessful"){
                        //format here since the params.node.data.updateStagedText was added in the populateGrid function below if agent is in one of the staged update status above
                        if(params.node.data.updateStagedText === undefined){
                            return null
                        }
                        return params.node.data.updateStagedText
                    }
                    else if(params.node.data.specialStatusMessage === "none"){
                        return null
                    }
                    else{
                        if(params.node.data.specialStatusMessage && params.node.data.specialStatusMessage.length>0 && params.node.data.specialStatusMessage!== "none") {
                            //console.log("params.node.data.specialStatusMessage: " + params.node.data.specialStatusMessage);
                        }
                        return null
                    }
                }
        },
        { field: "lastTaskingDateTime", headerName: "Latest Tasks Download", sortable: true, initialWidth: 285,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        defaultLicenseColumnWithAssignLicenseToAgentOptionCellRendererAndValueGetterForClientSide(triggerAssignAgentLicense, setAssignLicenseAgentData),
        defaultLicenseExpirationDateColumnWithClientSide(),
        defaultLicenseDateAssignedColumnWithClientSide(),
        { field: "installDate", headerName: "Installed", sortable: true, initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "lastRebootDateTime", headerName: "Latest Reboot", sortable: true, initialWidth: 280,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "lastAboutDateTime", headerName: "Latest Machine Data Update", sortable: true, initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "lastSchedulesDateTime", headerName: "Latest Schedules Download", sortable: true, initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "lastWhitelistDateTime", headerName: "Latest Tailored Behaviors Download", sortable: true, initialWidth: 390,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "lastShouldAgentUpdateDateTime", headerName: "Latest Update Check", sortable: true, initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "lastValidateDateTime", headerName: "Latest Validation Check", sortable: true, initialWidth: 330,
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParametersInHeaderClientSideGrid,
            valueFormatter: dateValueFormatter
        },
        { field: "osName", headerName: "OS Name", sortable: true, initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },
        { field: "updatePolicy", headerName: "Override Group Update Policy", initialWidth: 330,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: [overridingGroupPolicyText, followingGroupPolicyText],
                suppressSorting: false,
            },
            sortable: true,
            tooltipValueGetter: (params) => {
                return "This setting shows whether the agent is following its group policy for updating or overriding the group update policy and following its own update settings"
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                checked={params.node.data.updatePolicy !== followingGroupPolicyText}
                                color={"primary"}
                                name={`cellToggleUpdatePolicy${params.node.data.agentId}`}
                                onChange={(changeEvent) => {
                                    let oldValue = params.node.data.updatePolicy
                                    if(params.node.data.agentId){
                                        if(oldValue === followingGroupPolicyText){
                                            //Call to /overrideGroupUpdatePolicyForAgentReactive to override group policy and populate the agent's managedUpdateSettings
                                            overrideGroupUpdatePolicyForAgentReactive(params.node.data.agentId).then(result => {
                                                NotificationManager.success(`Successfully changed this agent's update policy.`);
                                                //let change stream handle changing these fields
                                            }).catch(function (error) {
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Error updating this agent's update policy");
                                                }
                                            })
                                        }
                                        else if(oldValue === overridingGroupPolicyText){
                                            //call to /removeAgentManagedUpdateSettingsReactive endpoint in order to follow group policy again
                                            removeAgentManagedUpdateSettingsReactive(params.node.data.agentId).then(result => {
                                                NotificationManager.success(`Successfully changed this agent's update policy.`);
                                                //let change stream handle changing these fields
                                            }).catch(function (error) {
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Error updating this agent's update policy");
                                                }
                                            })
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error making this request");
                                        }
                                    }
                                    else{
                                        NotificationManager.error("Unexpected error making this request");
                                    }
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
            }
        },
        /*
            The managedUpdateSettings column is really for autoUpdate setting, but there was issue with toggle not refreshing sometimes to disable/enable toggle when the field for the column was autoUpdate. By making it
            managedUpdateSettings instead we can define the equals function that handles if the cell renderer should be refreshed or not so we can always disable/enable correctly.
         */
        { field: "managedUpdateSettings", headerName: "Always Update to Latest Version", initialWidth: 350,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: ["On", "Off"],
                suppressSorting: true,
                //now in ag grid (since v29) when the col def has a keyCreator and filter is set filter, you have to provide a valueFormatter to filterParams or set convertValuesToStrings to true
                convertValuesToStrings: true
            },
            sortable: true,
            tooltipValueGetter: (params) => {
                return "You can only edit this setting when agents override their group update policy. You can edit the group update policy on the groups page."
            },
            equals: (managedUpdateSettings1, managedUpdateSettings2) => {
                if((managedUpdateSettings1 === null && managedUpdateSettings2 !== null) || (managedUpdateSettings2 === null && managedUpdateSettings1 !== null)){
                    return false
                }
                else return managedUpdateSettings1?.autoUpdate === managedUpdateSettings2?.autoUpdate;
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){ //since v29, the keyCreator params.node is null at first it seems, so this null check is needed to avoid npe
                    if(params.node.data.autoUpdate){
                        return "On"
                    }
                    else{
                        return "Off"
                    }
                }
            },
            comparator: function(valueA, valueB, nodeA, nodeB, isDescending){
                return (nodeA.data.autoUpdate === nodeB.data.autoUpdate) ? 0 : (nodeA.data.autoUpdate > nodeB.data.autoUpdate) ? 1 : -1;
            },
            cellRenderer: function (params) {
                return (
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme = {switchTheme}>
                            <Switch
                                disabled={params.node.data.updatePolicy === followingGroupPolicyText}
                                checked={params.node.data.autoUpdate}
                                name={`cellToggleAutoUpdate${params.node.data.agentId}`}
                                onChange={(changeEvent) => {
                                    if(params.node.data.updatePolicy === followingGroupPolicyText){
                                        //should not have gotten through to be able to toggle in this instance since we disable in this case, but double check
                                        return
                                    }
                                    let oldValue = params.node.data.autoUpdate
                                    if(params.node.data.agentId){
                                        editAgentManagedUpdateAutoUpdateSettingReactive(params.node.data.agentId, !oldValue).then(result => {
                                            NotificationManager.success(`Successfully changed this agent's auto update setting.`);
                                            //let change stream handle changing these fields
                                        }).catch(function (error) {
                                            if (error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error("Error updating this agent's auto update settings");
                                            }
                                        })
                                    }
                                }}
                            />
                        </ThemeProvider>
                    </div>
                )
                }
        },
        { field: "latestAgentVersionApproved", headerName: "Latest Agent Version Approved for Updates", initialWidth: 425,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                refreshValuesOnOpen: false,
                suppressSorting: true, //we want values to be in descending order which we do before storing in session, so suppress ag grid sorting this list in asc order to show most recent versions first
                values: function(params){
                    try{
                        //should not use the distinctAgentVersionsList hook here to provide filter values because after testing the filter does not get the updated list when using the
                        // setDistinctAgentVersionsList in the useEffect above, so just do some processing here to provide the filter values
                        let agentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                        if(agentVersionsList === null){
                            distinctAgentVersionsReactive().then(data => {
                                //store list in descending order by version number
                                data?.sort()?.reverse()
                                data.unshift(doNotUpdateKeywordForApprovedVersion) //put at beginning
                                params.success(data)
                                encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                            }).catch(error => {})
                        }
                        params.success(agentVersionsList)
                    }catch (e) {
                        params.success([])
                    }
                },
            },
            sortable: true,
            tooltipValueGetter: (params) => {
                return "This setting controls what version agents are allowed to update to. You can only edit this setting when " +
                    "agents override their group update policy. This setting will not downgrade agents."
            },
            editable: (params) => {
                return params.node.data.updatePolicy !== followingGroupPolicyText && params.node.data.autoUpdate === false;
            },
            cellEditor: "agSelectCellEditor",
            cellEditorParams: { values: distinctAgentVersionsList },
        },
        { field: "hiddenFromUI", headerName: "Dashboard Visibility", initialWidth: 250,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values:['Hidden','Visible'],
                suppressSorting: false,
                //now in ag grid (since v29) when the col def has a keyCreator and filter is set filter, you have to provide a valueFormatter to filterParams or set convertValuesToStrings to true
                convertValuesToStrings: true
            },
            sortable: true,
            keyCreator: (params) => {
                if(params && params.node && params.node.data){ //since v29, the keyCreator params.node is null at first it seems, so this null check is needed to avoid npe
                    if(params.node.data.hiddenFromUI){
                        return "Hidden";
                    }else{
                        return "Visible";
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.hiddenFromUI){
                    return "Hidden";
                }else{
                    return "Visible";
                }
            },
            cellRenderer:
                function (params) {
                    return (
                        <div className={`flex flex-row items-center`}>
                            <ThemeProvider theme = {switchTheme}>
                                <Switch
                                    checked={!params.node.data.hiddenFromUI}
                                    name={`cellToggleHiddenFromUI${params.node.data.agentId}`}
                                    onChange={(changeEvent) => {
                                        if(params.node.data.agentId){
                                            singleSetAgentVisibilityReactive(params.node.data.agentId, !params.node.data.hiddenFromUI).then(result => {
                                                NotificationManager.success(`Successfully changed this agent's visibility setting.`);
                                                params.node.setDataValue("hiddenFromUI",!params.node.data.hiddenFromUI);
                                            }).catch(function (error) {
                                                if (error.message){
                                                    NotificationManager.error(error.message);
                                                }
                                                else{
                                                    NotificationManager.error("Error updating this agent's visibility setting");
                                                }
                                            })
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error updating this agent's visibility setting");
                                        }
                                    }}
                                />
                            </ThemeProvider>
                            {/*{params.node.data.hiddenFromUI === true ? "Hidden" : "Visible"}*/}
                        </div>
                    )
                }
        },
        { field: "ipList", headerName: "IP Addresses List", sortable: true, initialWidth: 300,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
            valueGetter: function (params) {
                if(params.data.ipList){
                    let filteredIpList = params.data.ipList.filter(function(ip) {
                        return ip !== "0.0.0.0";
                    });
                    return filteredIpList.toString()
                }
            }
        },
        { field: "fqdn", headerName: "FQDN", sortable: true, initialWidth: 250,
            filter: 'agTextColumnFilter',
            filterParams: defaultClientSideTextFilterParams,
        },
        { field: "canConnectWithoutProxy", headerName: "Agent is Able to Bypass Proxy", initialWidth: 310,
            initialHide: true, suppressColumnsToolPanel: true, lockVisible: true, //default to hiding this column, useEffect below checks and updates if we should let this column be visible
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values:['Can Bypass Proxy','Cannot Bypass Proxy'],
                suppressSorting: false,
                //now in ag grid (since v29) when the col def has a keyCreator and filter is set filter, you have to provide a valueFormatter to filterParams or set convertValuesToStrings to true
                convertValuesToStrings: true
            },
            sortable: true,
            tooltipValueGetter: (params) => {
                if(params && params.node && params.node.data){
                    if(params.node.data.groupProxyEnabled){
                        //group's proxy is enabled, check the canConnectWithoutProxy value for this agent
                        if(params.node.data.canConnectWithoutProxy){
                            //agent can connect without proxy
                            return "This agent's group is configured to use a proxy but the agent is able to bypass the proxy and connect to our servers"
                        }
                        else{
                            //agent cannot connect without proxy
                            return "This agent's group is configured to use a proxy and the agent is not able to bypass the proxy to connect to our servers"
                        }
                    }
                    else{
                        //else proxy not enabled for this agent's group
                        return "This agent's group is not configured to use a proxy and will connect to our servers normally"
                    }
                }
            },
            keyCreator: (params) => {
                if(params && params.node && params.node.data){ //since v29, the keyCreator params.node is null at first it seems, so this null check is needed to avoid npe
                    if(params.node.data.canConnectWithoutProxy){
                        return "Can Bypass Proxy"
                    }else{
                        return "Cannot Bypass Proxy";
                    }
                }
            },
            valueGetter: (params) => {
                if(params.node.data.canConnectWithoutProxy){
                    return "Can Bypass Proxy"
                }else{
                    return "Cannot Bypass Proxy";
                }
            },
            cellRenderer:
                function (params) {
                    if(params.node.data.canConnectWithoutProxy){
                        return (
                            <div className={`flex flex-row items-center`}>
                                <IconButton
                                    sx={{color: "black", width: 25, height: 25}}
                                    className={"self-center object-contain"}
                                    onClick={() => {}}
                                >
                                    <FontAwesomeIcon className="object-contain" icon="fa-solid fa-check" size="xs" color="green" />
                                </IconButton>
                            </div>
                        )
                    }
                    else{
                        return (
                            <div className={`flex flex-row items-center`}>
                                <div className={`flex flex-row items-center`}>
                                    <IconButton
                                        sx={{color: "black", width: 25, height: 25}}
                                        className={"self-center object-contain"}
                                        onClick={() => {}}
                                    >
                                        <FontAwesomeIcon className="object-contain" icon="fa-solid fa-xmark" size="xs" color="red"/>
                                    </IconButton>
                                </div>
                            </div>
                        )
                    }
                }
        },
    ]);
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            tooltipValueGetter: (params) => {
                //we always want this on every column for the agent grid, if agentWasInsertedInValidateWithoutLicense is true then show the tooltip so users know what is happening with their agent
                if(params && params.node && params.node.data){
                    //If grantedTemporaryValidation is true, then show the temp license/activation tooltip
                    if(params.node.data.grantedTemporaryValidation === true){
                        return "Validation was granted to this agent temporarily on its latest validation check as no valid licenses were available, meaning the agent is still activated but was issued a true-up license"
                    }
                    else if(params.node.data.grantedTemporaryValidation === false && params.node.data.agentWasInsertedInValidateWithoutLicense === true){
                        //If grantedTemporaryValidation is false and agentWasInsertedInValidateWithoutLicense is true, that means no temp license was given and agent is not active
                        return "This agent is not activated because no valid licenses are available for this agent"
                    }
                }
                //else don't show a tooltip
                return null
            },
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps(350)
    }, []);
    var _ = require('lodash');

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            try {
                let sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList"))
                if (sessionAgentVersionsList === null) {
                    distinctAgentVersionsReactive().then(data => {
                        //store list in descending order by version number
                        data?.sort()?.reverse()
                        data.unshift(doNotUpdateKeywordForApprovedVersion)
                        setDistinctAgentVersionsList(data)
                        columnDefs?.forEach((col) => {
                            if(col && col.field === "latestAgentVersionApproved"){
                                col.cellEditorParams = {values: data}
                            }
                        })
                        encryptAndStoreSessionVariable("distinctAgentVersionsList", JSON.stringify(data))
                    }).catch(error => {})
                }
                else{
                    setDistinctAgentVersionsList(sessionAgentVersionsList)
                    columnDefs?.forEach((col) => {
                        if(col && col.field === "latestAgentVersionApproved"){
                            col.cellEditorParams = {values: sessionAgentVersionsList}
                        }
                    })
                }
            } catch (e) {}

            //see if we can show canConnectWithoutProxy column or not based on if a group they are a member of has proxy enabled
            //hide: true, suppressColumnsToolPanel: true, lockVisible: true
            if(zenGroupSessionStorage && zenGroupSessionStorage.length > 0){
                try{
                    let showProxyColumn = false
                    zenGroupSessionStorage.forEach(group => {
                        if(group.proxyEnabled === true){
                            showProxyColumn = true
                        }
                    })
                    if(showProxyColumn){
                        columnDefs?.forEach((col) => {
                            if(col && col.field === "canConnectWithoutProxy"){
                                col.suppressColumnsToolPanel = false //reset to be visible in panel
                                col.lockVisible = false //reset lockVisible to false
                                col.initialHide = false //reset initialHide to false
                                //don't touch the hide field in case user is in custom col mode and had it hidden before
                            }
                        })
                    }
                    else{
                        //else always hide
                        columnDefs?.forEach((col) => {
                            if(col && col.field === "canConnectWithoutProxy"){
                                col.hide = true //reset to be visible in panel
                            }
                        })
                    }
                } catch (e) {
                    //always hide if in catch
                    columnDefs?.forEach((col) => {
                        if(col && col.field === "canConnectWithoutProxy"){
                            col.hide = true //reset to be visible in panel
                        }
                    })
                }
            }
            else{
                //else always hide
                columnDefs?.forEach((col) => {
                    if(col && col.field === "canConnectWithoutProxy"){
                        col.hide = true //reset to be visible in panel
                    }
                })
            }

        }) ()
        return () => controller?.abort();
    }, [])

    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            //async call for latest agent version
            getLatestAgentVersionReactive().then(response => {
                setlatestAgentVersion(response)
            }).catch(function (error) {
                console.error(error)
            })
            //async call for chart data
            queryForAgentChartData(agentChartLastActiveDaysValue)
        })()
        return () => controller?.abort();
    }, []);

    useEffect(() => {
        let controller = new AbortController();
        //moving chart options to useEffect with dependency on chartData due to unexpected behaviour with the chart rendering
        //You can't notice any visual change but I saw a warning error pop up that dealt specifically with the ag chart when other set state functions were called, this seems to have fixed it
        (async () => {
            setChartOptions({
                autoSize: true,
                data: chartData,
                title: {
                    text: 'Number of Installed Agents Per Version',
                    fontSize: 18,
                },
                subtitle:{
                    text: 'Latest Version: ' + latestAgentVersion,
                    fontSize: 18,
                    spacing: 15
                },
                series: chartDataSeries,
                axes: [
                    {
                        type: 'category',
                        position: 'bottom',
                        keys: ["zenGroupId"],
                        label: {
                            rotation: 50,
                            formatter: function(params){
                                //The params include the zenGroupId (params.value) and the index of the label in the chart data array, so we can access the object and format return value
                                let index = params.index //index of the chart data array
                                if(chartData && index !== undefined && index !== null){
                                    try{
                                        let object = chartData[index]
                                        if(object){
                                            let name = params.value //initialize to zenGroupId (params.value) in case no zenGroupName found
                                            if(object.zenGroupName){
                                                name = object.zenGroupName
                                            }
                                            return name
                                        }
                                    } catch (e) {}
                                }
                                return params.value //if we get to here then something went wrong, return zenGroupId (params.value)

                            },
                            fontSize: 11
                        },
                    },
                    {
                        type: 'number',
                        position: 'left',
                    },
                ],
                legend: {
                    spacing: 40,
                    position: 'top',
                    enabled: true
                },
            })
        }) ()
        return () => controller?.abort();
    }, [chartData])

    //Hook for updating download agent modal proxy options based on installScriptGroup changes in value
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            setDownloadAgentModalProxyConfigSelectedValue(null) //always reset downloadAgentModalProxyConfigSelectedValue if installScriptGroup changes
            if(installScriptGroup === null || installScriptGroup === undefined){
                //Set to [] to let modal know not to show select proxy options
                setDownloadAgentModalProxyOptionsListForGroup([])
                setInstallerTypeOptionsList(standardInstallerTypeOptionsList) //reset to standard installer type options, check if installerType was set to DMZ, if so then reset to default
                if(installerType === "DMZ"){
                    setInstallerType("Script (PS1)")
                }
            }
            else{
                //else find installScriptGroup inside downloadAgentGroupOptions and check if proxy is enabled for selected group
                let installerGroupFound = downloadAgentGroupOptions.find((group) => group.id === installScriptGroup);
                if(installerGroupFound === null || installerGroupFound === undefined){
                    setDownloadAgentModalProxyOptionsListForGroup([])
                    setInstallerTypeOptionsList(standardInstallerTypeOptionsList) //reset to standard installer type options, check if installerType was set to DMZ, if so then reset to default
                    if(installerType === "DMZ"){
                        setInstallerType("Script (PS1)")
                    }
                }
                else{
                    //else installerGroupFound is not null or undefined
                    //need to handle updating the installer type options as well
                    handleGroupChangeForInstallerTypeOptions(installerGroupFound)
                    if (installerGroupFound.savedProxyServerConfigs === null || installerGroupFound.savedProxyServerConfigs === undefined || installerGroupFound.savedProxyServerConfigs.length === 0){
                        //if group does not have savedProxyServerConfigs then set to [] to let modal know not to show select proxy options
                        setDownloadAgentModalProxyOptionsListForGroup([])
                    }
                    else{ //else this group has savedProxyServerConfigs length > 0
                        //need to format options with value/label so we can add the current group setting in parentheses in options
                        let currentGroupProxyConfig = installerGroupFound.proxyServerConfig //add saved option to beginning and default to selected
                        let defaultSelectedOption = null
                        let formatedValues = []
                        let valuesAlreadyAdded = new Set()
                        installerGroupFound.savedProxyServerConfigs.forEach(config => {
                            let value = JSON.stringify(config) //since its an object, we have to stringify the value as well or MUI will display as [object Object] for the value
                            let label = JSON.stringify(config)
                            if(!valuesAlreadyAdded.has(value)){ //check so we don't add a duplicate option if there is the same url and username combo for this group's saved configs, or else mui error message pops up in console
                                valuesAlreadyAdded.add(value)
                                if(currentGroupProxyConfig !== null && currentGroupProxyConfig !== undefined){
                                    if(currentGroupProxyConfig.url === config.url && currentGroupProxyConfig.username === config.username){
                                        label = `${JSON.stringify(config)} (Current Group Setting)`
                                        let valToPush = {value: value, label: label}
                                        formatedValues.unshift(valToPush) //currently on the group's current set config, add to beginning
                                        defaultSelectedOption = valToPush
                                    }
                                    else{
                                        formatedValues.push({value: value, label: label})
                                    }
                                }
                                else{
                                    formatedValues.push({value: value, label: label})
                                }
                            }
                        })
                        //need to add a do not use proxy option as well
                        if(currentGroupProxyConfig === null || currentGroupProxyConfig === undefined){
                            //current group setting is to not use a proxy
                            let valToPush = {value: "Do Not Use Proxy", label: "Do Not Use Proxy (Current Group Setting)"}
                            formatedValues.unshift(valToPush)
                            defaultSelectedOption = valToPush
                        }
                        else{
                            formatedValues.push({value: "Do Not Use Proxy", label: "Do Not Use Proxy"})
                        }
                        setDownloadAgentModalProxyOptionsListForGroup(formatedValues)
                        //setDownloadAgentModalProxyConfigSelectedValue needs to be after setDownloadAgentModalProxyOptionsListForGroup so the options can populate first
                        setDownloadAgentModalProxyConfigSelectedValue(defaultSelectedOption)
                    }
                }
            }

        })()
        return () => controller?.abort();
    }, [installScriptGroup]);

    //Hook for if user selects use proxy in installer modal to only show the new installer option, else show normal 3 options
    useEffect(() => {
        let controller = new AbortController();
        (async () => {
            if(downloadAgentModalProxyConfigSelectedValue === null || downloadAgentModalProxyConfigSelectedValue === undefined || downloadAgentModalProxyConfigSelectedValue.value === "Do Not Use Proxy"){
                //if proxy config is null or Do Not Use Proxy selected, do not show the command line installer type only
                setShowCommandLineInstallerTypeOnly(false)
            }
            else{
                //else proxy config not null and value is not "Do Not Use Proxy"
                setShowCommandLineInstallerTypeOnly(true)
            }

        })()
        return () => controller?.abort();
    }, [downloadAgentModalProxyConfigSelectedValue]);

    const downloadShutdownScript = async () => {
        try {
            if(zenGroupSessionStorage && zenGroupSessionStorage.length>0){
                if(!suspendServiceGroup){
                    setSuspendServiceGroup(zenGroupSessionStorage[0].id)
                }
                const codes = await getZenGroupCodesForSiteReactive(suspendServiceGroup);
                const file = shutdownServiceScriptGenerate(codes[0], codes[1], codes[2]);
                downloadShutdownScriptFile(file, "RR-shutdown-service-script");
            }
        } catch (error) {
            NotificationManager.error(
                "Error generating Shutdown Service Script. Please try again."
            );
        }
    };

    const onZenGroupUpdate = async () => {
        if (updateAgentZG && zenGroupSessionStorage && zenGroupSessionStorage.length>0) {
            const { agentId, zenGroupId, data } = updateAgentZG;
            const updatedZenGroup = zenGroupSessionStorage.find(
                (zg) => zg.friendlyName === zenGroupId || zg.id === zenGroupId
            );
            if(!updatedZenGroup){
                return;
            }
            singleChangeAgentGroupReactive(updatedZenGroup.id,agentId).then(function(response){
                data.zenGroupId = updatedZenGroup.id
                data.zenGroupDisplayName = updatedZenGroup.friendlyName

                NotificationManager.success(
                    'Group successfully updated for this agent'
                );
            }).catch(function(error){
                if(error.message) {
                    NotificationManager.error("Group change failed due to "+error.message);
                }else{
                    NotificationManager.error("Group change failed for agent");
                }
            })
        }
        setUpdateAgentZG(null);
        setShowUpdateConfirmation(false);
    };

    function handleGroupChangeForInstallerTypeOptions(installerGroupFound){
        //function assumes caller has already handled finding the group selected in the downloadAgentGroupOptions and passes it here
        //Check if group has dmzMode enabled or not
        if(installerGroupFound.dmzMode){
            //update options to have dmz options
            setInstallerTypeOptionsList(installerTypeOptionsWithDMZList)
        }
        else{
            //standard installer type options, check if dmz type is selected, if so then reset it
            if(installerType === "DMZ"){
                setInstallerType("Script (PS1)")
            }
            setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
        }
    }

    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agents</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            {/*Update zenGroup confirm modal*/}
            <ConfirmationModal
                text="You are about to change the group of this agent, would you like to continue?"
                onConfirm={() => onZenGroupUpdate()}
                onClose={() => {
                    setShowUpdateConfirmation(false)
                }}
                opened={showUpdateConfirmation}
            />
            {/*Assign License Confirm Modal*/}
            <ConfirmationModal
                text={`The first available license in ${(assignLicenseAgentData && assignLicenseAgentData.agentDisplayName) ?
                    `agent: ${assignLicenseAgentData.agentDisplayName}'s` : "this agent's"} group (or the group's assigned distribution group) will be assigned to this agent, would you like to continue?`}
                onConfirm={() => {
                    if(assignLicenseAgentData && assignLicenseAgentData.agentId){
                        if(assignLicenseAgentData.specialStatusMessage === "uninstalled"){
                            NotificationManager.error("You cannot assign a license to an uninstalled agent.")
                            return
                        }
                        assignAgentLicenseReactive(assignLicenseAgentData.agentId).then(response => {
                            NotificationManager.success("Successfully assigned a license to this agent.")
                            setShowAssignLicenseConfirmModal(false)
                            setAssignLicenseAgentData({})
                            refreshGrid().then(r => {})
                        }).catch(function(error){
                            if(error.message){
                                NotificationManager.error(error.message)
                            }
                            else{
                                NotificationManager.error("Unexpected error assigning a license to this agent.")
                            }
                        })
                    }
                    else{
                        NotificationManager.error("Unexpected error making this request");
                    }
                }}
                onClose={() => {
                    setShowAssignLicenseConfirmModal(false)
                    setAssignLicenseAgentData({})
                }}
                opened={showAssignLicenseConfirmModal}
            />
            {/*Uninstall agent confirm modal*/}
            <ConfirmationModalWithPermissionsShown
                text="You are about to uninstall the selected agent(s). The grid below shows your permission level to do this action for each of the selected agents' group:"
                onConfirm={() => requestUninstall()}
                onClose={() => {
                    setShowUninstallConfirmation(false)
                    setZenGroupNamesWithPermission(new Set())
                    setZenGroupNamesWithoutPermission(new Set())
                    setZenGroupIdsWithoutPermission(new Set())
                }}
                opened={showUninstallConfirmation}
                groupNamesWithPermission={zenGroupNamesWithPermission}
                groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
            />
            <ConfirmationModalWithPermissionsShown
                text="You are about to release the licenses from the selected agents. The grid below shows your permission level to do this action for each of the selected agents' group:"
                onConfirm={() => releaseAgentLicenses()}
                onClose={() => {
                    setShowReleaseLicensesConfirmation(false)
                    setZenGroupNamesWithPermission(new Set())
                    setZenGroupNamesWithoutPermission(new Set())
                    setZenGroupIdsWithoutPermission(new Set())
                }}
                opened={showReleaseLicensesConfirmation}
                groupNamesWithPermission={zenGroupNamesWithPermission}
                groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
            />
            <ConfirmationModalWithPermissionsShown
                text="You are about to assign licenses to the selected agents. The grid below shows your permission level to do this action for each of the selected agents' group:"
                onConfirm={() => bulkAssignAgentsLicenses()}
                onClose={() => {
                    setShowBulkAssignLicensesConfirmationModal(false)
                    setZenGroupNamesWithPermission(new Set())
                    setZenGroupNamesWithoutPermission(new Set())
                    setZenGroupIdsWithoutPermission(new Set())
                }}
                opened={showBulkAssignLicensesConfirmationModal}
                groupNamesWithPermission={zenGroupNamesWithPermission}
                groupNamesWithoutPermission={zenGroupNamesWithoutPermission}
            />

            {/*New Agent Download Modal for picking group to install agent for*/}
            <Modal contentLabel="Download Agent Modal" isOpen={showInstallScriptModal}
                   onRequestClose={() => {
                       setShowInstallScriptModal(false)
                       setInstallScriptGroup()
                       setInstallerType("Script (PS1)")
                       setShowCommandLineInstallerTypeOnly(false)
                       setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-2xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Download Agent</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowInstallScriptModal(false)
                                setInstallScriptGroup()
                                setInstallerType("Script (PS1)")
                                setShowCommandLineInstallerTypeOnly(false)
                                setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5"/>
                    <div className="ml-1">
                        <label>Select a Group for the Agent</label>
                        <MuiAutocompleteForZenGroupsWithCreateGroupOption
                            setGroupListUnformattedHook={setDownloadAgentGroupOptions}
                            zenGroupDropdownOptionsList={downloadAgentGroupOptions.map(({id, friendlyName}) => ({
                                value: id,
                                label: friendlyName || id,
                            }))}
                            value={installScriptGroup}
                            setSelectedGroup={setInstallScriptGroup}
                            setIsLoading={setIsLoading}
                        />
                    </div>
                    <div
                        className={`ml-1 ${(downloadAgentModalProxyOptionsListForGroup && downloadAgentModalProxyOptionsListForGroup.length > 0) ? "block" : "hidden"}`}>
                        <label>Select Proxy Configuration for Installer</label>
                        <MuiAutocompleteNonGroupOptions
                            label={"Optional"}
                            options={downloadAgentModalProxyOptionsListForGroup}
                            value={downloadAgentModalProxyConfigSelectedValue}
                            onChange={(event, value) => {
                                setDownloadAgentModalProxyConfigSelectedValue(value)
                            }}
                        />
                    </div>
                    <div className={`ml-1 mt-1 ${showCommandLineInstallerTypeOnly ? "hidden" : "block"}`}>
                        <label>Select an Installer Type</label>
                        <MuiAutocompleteNonGroupOptions
                            options={installerTypeOptionsList}
                            value={installerType}
                            onChange={(event, value) => {
                                setInstallerType(value)
                            }}
                        />
                    </div>
                    <div className={`ml-1 ${showCommandLineInstallerTypeOnly ? "block" : "hidden"}`}>
                        <div className={`flex flex-row items-center`}>
                            <label>Select an Installer Type</label>
                            <div className="ml-1 mt-1">
                                <MuiIconWithTooltip
                                    icon={
                                        <FontAwesomeIcon
                                            className="ml-1 object-contain cursor-pointer"
                                            icon="fa-light fa-circle-info"
                                            size="lg"
                                        />
                                    }
                                    tooltipTitle={`When selecting to use a proxy, you must use the Command Line Installer type`}
                                    tooltipPlacement={"bottom-start"}
                                />
                            </div>
                        </div>
                        <MuiAutocompleteNonGroupOptions
                            disabled={true}
                            options={["Command Line Installer"]}
                            value={"Command Line Installer"}
                            onChange={(event, value) => {

                            }}
                        />
                    </div>
                    <ThemeProvider theme={buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                disabled={!installScriptGroup}
                                onClick={() => {
                                    if (installScriptGroup) {
                                        let proxyUrl = null
                                        let proxyUsername = null
                                        if (downloadAgentModalProxyConfigSelectedValue && downloadAgentModalProxyConfigSelectedValue.value && downloadAgentModalProxyConfigSelectedValue.value !== "Do Not Use Proxy") {
                                            //Parse selected proxy config value
                                            try {
                                                let parsedConfigValue = JSON.parse(downloadAgentModalProxyConfigSelectedValue.value)
                                                if (parsedConfigValue) {
                                                    proxyUrl = parsedConfigValue.url
                                                    proxyUsername = parsedConfigValue.username
                                                }
                                            } catch (e) {
                                            }
                                        }
                                        //first check if user selected proxy config, if so we need to send the new cli type to endpoint
                                        if(showCommandLineInstallerTypeOnly) {
                                            setIsLoading(true)
                                            generateInstallerReactive(installScriptGroup, "CLI_EXE", proxyUrl, proxyUsername).then(response => {
                                                try {
                                                    let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                    let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                    //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                    let today = new Date();
                                                    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                                                    let time = today.getHours() + "-" + today.getMinutes();
                                                    let dateTime = date + 'T' + time;

                                                    let link = document.createElement('a');
                                                    link.href = window.URL.createObjectURL(blob);
                                                    link.download = `CC-Command-Line-Installer-${dateTime}.exe`;
                                                    link.click();
                                                    setShowInstallScriptModal(false)
                                                    setInstallScriptGroup()
                                                    setInstallerType("Script (PS1)")
                                                    setShowCommandLineInstallerTypeOnly(false)
                                                    setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                                                } catch (error) {
                                                    console.error("Error creating command line installer")
                                                }
                                                setIsLoading(false)
                                            }).catch(function (error) {
                                                //console.error(error);
                                                if (error.message) {
                                                    NotificationManager.error(error.message)
                                                } else {
                                                    NotificationManager.error(`Unexpected error creating command line installer`);
                                                }
                                                setIsLoading(false)
                                            })
                                        }
                                        else if (installerType === "Script (BAT)") {
                                            setIsLoading(true)
                                            generateInstallerReactive(installScriptGroup, "SCRIPT_BAT").then(response => {
                                                try {
                                                    let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                    let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                    //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                    let today = new Date();
                                                    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                                                    let time = today.getHours() + "-" + today.getMinutes();
                                                    let dateTime = date + 'T' + time;
                                                    let link = document.createElement('a');
                                                    link.href = window.URL.createObjectURL(blob);
                                                    link.download = `CC-Install-Script-${dateTime}.bat`;
                                                    link.click();
                                                    setShowInstallScriptModal(false)
                                                    setInstallScriptGroup()
                                                    setInstallerType("Script (PS1)")
                                                    setShowCommandLineInstallerTypeOnly(false)
                                                    setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                                                } catch (error) {
                                                    console.error("Error creating generating installer script")
                                                }
                                                setIsLoading(false)
                                            }).catch(function (error) {
                                                //console.error(error);
                                                if (error.message) {
                                                    NotificationManager.error(error.message)
                                                } else {
                                                    NotificationManager.error(`Unexpected error generating the installer script`);
                                                }
                                                setIsLoading(false)
                                            })
                                        } else if (installerType === "Script (PS1)") {
                                            setIsLoading(true)
                                            generateInstallerReactive(installScriptGroup, "SCRIPT_PS1").then(response => {
                                                try {
                                                    let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                    let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                    //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                    let today = new Date();
                                                    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                                                    let time = today.getHours() + "-" + today.getMinutes();
                                                    let dateTime = date + 'T' + time;
                                                    let link = document.createElement('a');
                                                    link.href = window.URL.createObjectURL(blob);
                                                    link.download = `CC-Install-Script-${dateTime}.ps1`;
                                                    link.click();
                                                    setShowInstallScriptModal(false)
                                                    setInstallScriptGroup()
                                                    setInstallerType("Script (PS1)")
                                                    setShowCommandLineInstallerTypeOnly(false)
                                                    setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                                                } catch (error) {
                                                    console.error("Error creating generating installer script")
                                                }
                                                setIsLoading(false)
                                            }).catch(function (error) {
                                                //console.error(error);
                                                if (error.message) {
                                                    NotificationManager.error(error.message)
                                                } else {
                                                    NotificationManager.error(`Unexpected error generating the installer script`);
                                                }
                                                setIsLoading(false)
                                            })
                                        } else if (installerType === "GUI") {
                                            setIsLoading(true)
                                            generateInstallerReactive(installScriptGroup, "GUI").then(response => {
                                                try {
                                                    let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                    let blob = new Blob([bytes], {type: "application/octet-stream"});// change resultByte to bytes
                                                    //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                    let today = new Date();
                                                    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                                                    let time = today.getHours() + "-" + today.getMinutes();
                                                    let dateTime = date + 'T' + time;

                                                    let link = document.createElement('a');
                                                    link.href = window.URL.createObjectURL(blob);
                                                    link.download = `CC-Installer-${dateTime}.exe`;
                                                    link.click();
                                                    setShowInstallScriptModal(false)
                                                    setInstallScriptGroup()
                                                    setInstallerType("Script (PS1)")
                                                    setShowCommandLineInstallerTypeOnly(false)
                                                    setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                                                } catch (error) {
                                                    console.error("Error creating gui installer")
                                                }
                                                setIsLoading(false)
                                            }).catch(function (error) {
                                                //console.error(error);
                                                if (error.message) {
                                                    NotificationManager.error(error.message)
                                                } else {
                                                    NotificationManager.error(`Unexpected error creating gui installer`);
                                                }
                                                setIsLoading(false)
                                            })
                                        } else if (installerType === "DMZ") {
                                            setIsLoading(true)
                                            generateInstallerReactive(installScriptGroup, "DMZ_ZIP").then(response => {
                                                try {
                                                    let bytes = base64ToArrayBuffer(response.data); // pass your byte response to this constructor
                                                    let blob = new Blob([bytes], {type: "application/zip"});// change resultByte to bytes
                                                    //Add in local datetime string to filename down to the minutes, and this is in 24-hour time format
                                                    let today = new Date();
                                                    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
                                                    let time = today.getHours() + "-" + today.getMinutes();
                                                    let dateTime = date + 'T' + time;

                                                    let link = document.createElement('a');
                                                    link.href = window.URL.createObjectURL(blob);
                                                    link.download = `CC-Installer-${dateTime}.zip`;
                                                    link.click();
                                                    setShowInstallScriptModal(false)
                                                    setInstallScriptGroup()
                                                    setInstallerType("Script (PS1)")
                                                    setShowCommandLineInstallerTypeOnly(false)
                                                    setInstallerTypeOptionsList(standardInstallerTypeOptionsList)
                                                } catch (error) {
                                                    console.error("Error creating dmz installer")
                                                }
                                                setIsLoading(false)
                                            }).catch(function (error) {
                                                //console.error(error);
                                                if (error.message) {
                                                    NotificationManager.error(error.message)
                                                } else {
                                                    NotificationManager.error(`Unexpected error creating dmz installer`);
                                                }
                                                setIsLoading(false)
                                            })
                                        }
                                    }
                                }}>
                            Download Agent Installer
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>

            {/*Emergency Shutdown Service Modal*/}
            <Modal contentLabel="Emergency Shutdown Service" isOpen={showSuspendServiceModal}
                   onRequestClose={() => {
                       setShowSuspendServiceModal(false)
                       setSuspendServiceGroup(zenGroup)
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-xl inset-y-10 mx-auto rounded-2xl`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">Emergency Shutdown Service</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowSuspendServiceModal(false)
                                setSuspendServiceGroup(zenGroup)
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>Select a Group</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                            value={suspendServiceGroup}
                            onChange={( event, value ) => {
                                setSuspendServiceGroup(value?.value)
                            }}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button  variant={"contained"}
                                 color={"primary"}
                                 onClick={downloadShutdownScript}>
                            Generate Emergency Shutdown Service Script
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            {/*Change Agent's Group Modal*/}
            <Modal contentLabel="Change Agent Group" isOpen={showChangeGroupsModal}
                   onRequestClose={() => {
                       setShowChangeGroupsModal(false)
                       setNewAgentZenGroup(null)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }}
                   shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Agent's Group" : "Change Agents' Group"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowChangeGroupsModal(false)
                                setNewAgentZenGroup(null)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <div className="ml-1">
                        <label>New Group for Selected Agent(s)</label>
                        <MuiAutocompleteForZenGroupsWithoutCreateGroupOption
                            zenGroupDropdownOptionsList={getZenGroupDropDownContents()}
                            value={newAgentZenGroup}
                            onChange={( event, value ) => {
                                setNewAgentZenGroup(value?.value)
                            }}
                        />
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change an agent's group for each of the selected agents' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", headerName: "Groups with permission", width: 300},
                                { field: "zenGroupWithoutPermission", headerName: "Groups without permission", width: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button
                            disabled={zenGroupNamesWithPermission?.size < 1}
                            variant={"contained"}
                            color={"primary"}
                            onClick={() => {
                                changeAgentsGroup()
                            }}>
                            Update
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            {/*Bulk set agent default visibility modal*/}
            <Modal contentLabel="Set Default Agent Visibility" isOpen={showBulkVisibilityModal}
                   onRequestClose={() => {
                       setShowBulkVisibilityModal(false)
                       setBulkShowAgentsToggled(true)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Agent's Default Visibility Setting" : "Change Agents' Default Visibility Setting"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowBulkVisibilityModal(false)
                                setBulkShowAgentsToggled(true)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <label className="ml-1 mt-2">
                        By default, only agents that do not have their visibility setting as hidden will be shown in the grid. You may see hidden agents by changing the filter in the
                        'Default Grid Visibility Setting' column header.
                    </label>
                    <div className={`flex flex-row items-center ml-1 mt-2`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={bulkShowAgentsToggled}
                                    name="toggleBulkShowAgentOnGrid"
                                    onChange={e => setBulkShowAgentsToggled(e.target.checked)}
                                />
                            } label={bulkShowAgentsToggled ? "Visible" : "Hidden"}/>
                        </ThemeProvider>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change an agent's default visibility setting for each of the selected agents' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", headerName: "Groups with permission", width: 300},
                                { field: "zenGroupWithoutPermission", headerName: "Groups without permission", width: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                disabled={zenGroupNamesWithPermission?.size < 1}
                                onClick={() => {
                                    bulkSetAgentDefaultVisibilityRequest()
                                }}>
                                Update Selected Agent(s)
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            {/*Bulk change agent auto allocate*/}
            <Modal contentLabel="Set Agent Auto-Allocate License Setting" isOpen={showBulkChangeAutoAllocateLicenseModal}
                   onRequestClose={() => {
                       setShowBulkChangeAutoAllocateLicenseModal(false)
                       setBulkAutoAllocateLicensesToggled(false)
                       setZenGroupNamesWithPermission(new Set())
                       setZenGroupNamesWithoutPermission(new Set())
                       setZenGroupIdsWithoutPermission(new Set())
                   }} shouldCloseOnOverlayClick={true}
                   className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white inset-y-10 mx-auto rounded-2xl ${showPermissionGrid ? "max-w-3xl" : "max-w-xl"}`}
                   overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
            >
                <div className="flex flex-1 flex-col p-8 w-full ml-4 mr-4 gap-y-5">
                    <div className="flex flex-row justify-between">
                        <h1 className="font-bold text-3xl">{(!gridApi || gridApi.getSelectedNodes().length < 2) ? "Change Agent's Auto-Allocate License Setting" : "Change Agents' Auto-Allocate License Setting"}</h1>
                        <MuiCloseIconButton
                            onClick={() => {
                                setShowBulkChangeAutoAllocateLicenseModal(false)
                                setBulkAutoAllocateLicensesToggled(false)
                                setZenGroupNamesWithPermission(new Set())
                                setZenGroupNamesWithoutPermission(new Set())
                                setZenGroupIdsWithoutPermission(new Set())
                            }}
                        />
                    </div>
                    <hr className="mt-3 h-0.5" />
                    <label className="ml-1 mt-2">
                        By default, when a user releases a license from an agent, the agent will not be assigned a new license automatically the next time it calls in. You may change this behaviour for when the selected
                        agents call in next here or in the "License Name" column for agents that do not have a license assigned to them. Uninstalled agents and agents already assigned a license will not be updated.
                    </label>
                    <div className={`flex flex-row items-center ml-1 mt-2`}>
                        <ThemeProvider theme = {switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={bulkAutoAllocateLicensesToggled}
                                    name="toggleBulkAutoAllocateLicenses"
                                    onChange={e => setBulkAutoAllocateLicensesToggled(e.target.checked)}
                                />
                            } label={bulkAutoAllocateLicensesToggled ? "Auto-Allocate License" : "Do Not Auto-Allocate License"}/>
                        </ThemeProvider>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <div>The grid below shows your permission level to change this setting for each of the selected agents' group:</div>
                    </div>
                    <div className="ml-1" style={{display: showPermissionGrid ? "block" : "none"}}>
                        <PermissionsGrid
                            headers={[
                                { field: "zenGroupWithPermission", headerName: "Groups with permission", width: 300},
                                { field: "zenGroupWithoutPermission", headerName: "Groups without permission", width: 350}
                            ]}
                            data={processGroupPermissionData(zenGroupNamesWithPermission, zenGroupNamesWithoutPermission)}
                        />
                    </div>
                    <ThemeProvider theme = {buttonTheme}>
                        <Button variant={"contained"}
                                color={"primary"}
                                disabled={zenGroupNamesWithPermission?.size < 1}
                                onClick={() => {
                                    bulkChangeAutoAllocateLicenses()
                                }}>
                            Update Selected Agent(s)
                        </Button>
                    </ThemeProvider>
                </div>
            </Modal>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col flex-nowrap gap-y-3 mt-8 ml-5 mr-10 h-full">
                    <div className="flex flex-row justify-between flex-wrap gap-x-5 gap-y-3 items-center">
                        {/*Top with manage agents title and download agents button, flex it with button at end*/}
                        <div className="flex flex-row justify-start flex-nowrap gap-x-2 gap-y-3">
                            <img className="logo" src="/images/logo/CyberCrucibleLogo-web.png" alt=""/>
                            <div className="text-4xl self-center shrink-0">Agents</div>
                        </div>
                        <div className="flex flex-row flex-wrap gap-y-3">
                            <div className="">
                                <ThemeProvider theme={roundButtonTheme}>
                                    <Button variant={"contained"}
                                            color={"primary"}
                                            className={`focus:outline-none buttons p-4 rounded-3xl text-white`}
                                            onClick={() => {
                                                setShowInstallScriptModal(true)
                                                //check if we have populated options yet
                                                if (downloadAgentGroupOptions.length === 0) {
                                                    downloadAgentGroupOptionsListReactive().then(response => {
                                                        //format groups for mui autocomplete
                                                        if (response && response.length > 0) {
                                                            //need to sort first
                                                            response.sort((object1, object2) => (object1.friendlyName?.toLowerCase() > object2.friendlyName?.toLowerCase()) ? 1 : -1)
                                                            setDownloadAgentGroupOptions(response)
                                                        } else {
                                                            setDownloadAgentGroupOptions([])
                                                        }
                                                    }).catch(function (error) {
                                                        setDownloadAgentGroupOptions([])
                                                    })
                                                }
                                            }}
                                            sx={{
                                                "&:hover": {
                                                    backgroundColor: "#f78f5e",
                                                    cursor: "pointer"
                                                },
                                                color: "white",
                                                background: "#e76a24",
                                                p: 1.3, pl: 1.8, pr: 1.8
                                            }}
                                    >
                                        Download Agent
                                    </Button>
                                </ThemeProvider>
                            </div>
                        </div>
                    </div>
                    <hr className="bg-black h-0.5"/>
                    <div className={`flex flex-row items-center`}>
                        <ThemeProvider theme={switchTheme}>
                            <FormControlLabel control={
                                <Switch
                                    checked={chartToggled}
                                    name="licenseUsageToggle"
                                    onChange={e => {
                                        setChartToggled(e.target.checked)
                                        updateChartVisibilitySettingInSession(chartSessionVariableName, e.target.checked, updateAgentChartVisibilityReactive)
                                    }}
                                />
                            } label={chartToggled ? "Showing Agent Version Chart" : "Hiding Agent Version Chart"}/>
                        </ThemeProvider>
                    </div>
                    {/*Agent Count Chart*/}
                    <div className={`chartContainer ${chartToggled ? `block mb-10` : `hidden`} relative`}>
                        <div className="flex flex-row flex-wrap gap-x-4 gap-y-2 items-center justify-start">
                            <ToggleButtonGroup
                                value={agentChartLastActiveDaysValue}
                                exclusive
                                size={"small"}
                            >
                                <ToggleButton value={agentChart30DayValue}
                                              onClick={(event) => {
                                                  handleChangeAgentChartLastActiveDays(agentChart30DayValue)
                                              }}
                                >
                                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                             title={<div className={"text-sm"}>Only include counts on the chart for
                                                 agents that have called in over the past 30 days</div>}
                                             placement={"top"}>
                                        <div>30</div>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={agentChart60DayValue}
                                              onClick={(event) => {
                                                  handleChangeAgentChartLastActiveDays(agentChart60DayValue)
                                              }}
                                >
                                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                             title={<div className={"text-sm"}>Only include counts on the chart for
                                                 agents that have called in over the past 60 days</div>}
                                             placement={"top"}>
                                        <div>60</div>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={agentChart90DayValue}
                                              onClick={(event) => {
                                                  handleChangeAgentChartLastActiveDays(agentChart90DayValue)
                                              }}
                                >
                                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                             title={<div className={"text-sm"}>Only include counts on the chart for
                                                 agents that have called in over the past 90 days</div>}
                                             placement={"top"}>
                                        <div>90</div>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton value={agentChartAllAgentsValue}
                                              onClick={(event) => {
                                                  handleChangeAgentChartLastActiveDays(agentChartAllAgentsValue)
                                              }}
                                >
                                    <Tooltip arrow enterDelay={750} slotProps={{tooltip: {sx: {maxWidth: 700}}}}
                                             title={<div className={"text-sm"}>Do not limit counts on the chart</div>}
                                             placement={"top"}>
                                        <div className={"normal-case"}>All</div>
                                    </Tooltip>
                                </ToggleButton>

                            </ToggleButtonGroup>
                        </div>
                        <AgChartsReact options={chartOptions}/>
                        <BackDropChartLoadingOverlay opened={chartIsLoading}/>
                    </div>
                    <hr className="bg-black h-0.5"/>
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        {/*Top with manage agents title and download agents button, flex it with button at end*/}
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled={useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled={setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState={toggleUpdateUseFilterState}
                                useColumnStateSettingToggled={useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled={setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState={toggleUpdateUseColumnState}/>
                            <div className="flex flex-row justify-start gap-x-6 flex-wrap gap-y-2 items-center">
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<DeleteIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Uninstall All Selected Agents"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={async () => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        if (gridApi.getSelectedNodes().length > 0) {
                                            gridApi.getSelectedNodes().forEach(rowNode => {
                                                if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)) {
                                                    zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                    if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "uninstallAgent")) {
                                                        zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                        zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                        setZenGroupNamesWithoutPermission(new Set(zenGroupNamesWithoutPermission))
                                                        setZenGroupIdsWithoutPermission(new Set(zenGroupIdsWithoutPermission))
                                                        permissionCheckResult = false;
                                                    } else {
                                                        zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithPermission(new Set(zenGroupNamesWithPermission))
                                                    }
                                                }
                                            })
                                        }
                                        if (!permissionCheckResult) {
                                            setShowUninstallConfirmation(true)
                                        } else {
                                            await requestUninstall()
                                            setZenGroupNamesWithPermission(new Set())
                                            setZenGroupNamesWithoutPermission(new Set())
                                            setZenGroupIdsWithoutPermission(new Set())
                                        }
                                    }}>
                                </MuiIconButtonWithTooltipAndBox>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<GroupIcon className={"cursor-pointer"}/>} tooltipTitle={"Bulk Change Group"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={async () => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        if (gridApi.getSelectedNodes().length > 0) {
                                            gridApi.getSelectedNodes().forEach(rowNode => {
                                                if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)) {
                                                    zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                    if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "changeAgentGroup")) {
                                                        permissionCheckResult = false;
                                                        zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                        zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                        setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                    } else {
                                                        zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                        setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                    }
                                                }
                                            })
                                        }
                                        setShowPermissionGrid(!permissionCheckResult)
                                        setShowChangeGroupsModal(true)
                                    }}>
                                </MuiIconButtonWithTooltipAndBox>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<VisibilityIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Bulk Set Agent Default Visibility"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={async () => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true;
                                        gridApi.getSelectedNodes().forEach(rowNode => {
                                            if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)) {
                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "changeAgentVisibility")) {
                                                    permissionCheckResult = false
                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                } else {
                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                }
                                            }
                                        })
                                        setShowPermissionGrid(!permissionCheckResult)
                                        setShowBulkVisibilityModal(true)
                                    }}>
                                </MuiIconButtonWithTooltipAndBox>
                                <MuiIconButtonWithTooltipAndBox
                                    icon={<EjectRoundedIcon className={"cursor-pointer"}/>}
                                    tooltipTitle={"Bulk Release Licenses from Selected Agents"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        for (const rowNode of gridApi.getSelectedNodes()) {
                                            if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)) {
                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "releaseAgentLicense")) {
                                                    permissionCheckResult = false
                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                } else {
                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                }
                                            }
                                        }

                                        if (!permissionCheckResult) {   //  permission check failed, show modal
                                            setShowReleaseLicensesConfirmation(true)
                                        } else {    //  permission check pass, do not show modal and directly call api
                                            releaseAgentLicenses()
                                        }
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={
                                        <IconButton
                                            disabled={!enableButtons} sx={{width: 25, height: 25}}
                                            className={`self-center object-contain`} disableRipple={true}
                                        >
                                            <FontAwesomeIcon className={`object-contain`}
                                                             icon={"fa-duotone fa-id-badge"} size="sm"
                                                             color={`${!enableButtons ? "#C1c1c1" : "black"}`}/>
                                        </IconButton>
                                    } tooltipTitle={"Bulk Update Agent Auto-Allocate License Settings"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        for (const rowNode of gridApi.getSelectedNodes()) {
                                            if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)) {
                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "releaseAgentLicense")) {
                                                    permissionCheckResult = false
                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                } else {
                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                }
                                            }
                                        }
                                        setShowPermissionGrid(!permissionCheckResult)
                                        setShowBulkChangeAutoAllocateLicenseModal(true)
                                    }}
                                />
                                <MuiIconButtonWithTooltipAndBox
                                    icon={
                                        <IconButton
                                            disabled={!enableButtons} sx={{width: 25, height: 25}}
                                            className={`self-center object-contain`} disableRipple={true}
                                        >
                                            <FontAwesomeIcon className={`object-contain`}
                                                             icon={"fa-duotone fa-file-certificate"} size="sm"
                                                             color={`${!enableButtons ? "#C1c1c1" : "black"}`}/>
                                        </IconButton>
                                    } tooltipTitle={"Bulk Assign Licenses to Selected Agents"}
                                    tooltipPlacement={"top"} disabled={!enableButtons}
                                    onClick={() => {
                                        let zenGroupIdSet = new Set();
                                        let permissionCheckResult = true
                                        for (const rowNode of gridApi.getSelectedNodes()) {
                                            if (rowNode.data.zenGroupId && !zenGroupIdSet.has(rowNode.data.zenGroupId)) {
                                                zenGroupIdSet.add(rowNode.data.zenGroupId)
                                                if (!checkPermission(rowNode.data.zenGroupId, "agentManager", "releaseAgentLicense")) {
                                                    permissionCheckResult = false
                                                    zenGroupIdsWithoutPermission.add(rowNode.data.zenGroupId)
                                                    zenGroupNamesWithoutPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithoutPermission(zenGroupNamesWithoutPermission)
                                                    setZenGroupIdsWithoutPermission(zenGroupIdsWithoutPermission)
                                                } else {
                                                    zenGroupNamesWithPermission.add(rowNode.data.zenGroupDisplayName)
                                                    setZenGroupNamesWithPermission(zenGroupNamesWithPermission)
                                                }
                                            }
                                        }

                                        if (!permissionCheckResult) {   //  permission check failed, show modal
                                            setShowBulkAssignLicensesConfirmationModal(true)
                                        } else {    //  permission check pass, do not show modal and directly call api
                                            bulkAssignAgentsLicenses()
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode}
                                gridColumnStateSessionVariableName={gridColumnStateSessionVariableName}
                                gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds}
                                updateGridColumnModeFunction={updateAgentsGridColumnModeReactive}/>
                            <ClearRefresh gridApi={gridApi} showRefreshIcon={false}
                                          refreshGridFunction={refreshGrid} showExcelExportIcon={true}
                                          sseDataPullActive={sseDataPullActive}
                                          excelExportFunction={excelExport}/>
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer/>
                    </div>
                </div>
            </div>
            <NotificationContainer/>
        </div>
    );

    function handleChangeAgentChartLastActiveDays(newValue) {
        let validatedNewValue = 0
        if (newValue !== agentChart30DayValue && newValue !== agentChart60DayValue && newValue !== agentChart90DayValue) {
            //if value is not 30/60/90 then show all agents in chart
            validatedNewValue = agentChartAllAgentsValue
        } else { //else value was valid
            validatedNewValue = newValue
        }

        if (validatedNewValue !== agentChartLastActiveDaysValue) { //Only need to update if new option is selected
            //Update hook, session variable, and chart data
            setAgentChartLastActiveDaysValue(validatedNewValue)
            encryptAndStoreSessionVariable(agentChartLastActiveDaysSessionVariable, JSON.stringify(validatedNewValue));
            queryForAgentChartData(validatedNewValue)
            //update in user model
            updateAgentChartLastActiveDaysReactive(validatedNewValue).then(response => {
            }).catch(function (error) {
            })
        }
    }

    function queryForAgentChartData(lastActiveDays) {
        setChartIsLoading(true)
        getAgentVersionsCountPerGroupReactive(lastActiveDays).then(response => {
            let agentList = response
            if (agentList) {
                let versions = []
                let finalData = []
                //In this loop we get our y-value of versions for chart and format the data so each object has one zenGroup with each version/count key pair
                for (let i = 0; i < agentList.length; i++) {
                    let isInObject = false
                    if (agentList[i].version === null || agentList[i].zenGroupId === null) {
                        continue
                    }
                    //handle adding to versions list for unique agent versions from the data
                    if (agentList[i].version !== null && !versions.includes(agentList[i].version)) {
                        versions.push(agentList[i].version)
                    }
                    //handle formatting chart data
                    //In ag charts 8.0.0 you can no longer have "." in the yKey, so we can use a hyphen instead and just populate the yName with normal version. We need to update the chart data array version key to also not have any '.'s
                    finalData.forEach(object => {
                        if (object.zenGroupId === agentList[i].zenGroupId) {
                            //we already have an entry for this group in finalData, so we can add the version to this row/object in finalData
                            isInObject = true
                            object[agentList[i].version.replaceAll(".", "-")] = agentList[i].count //adding this agent version as a key to object for current group and the count as the value
                        }
                    })
                    //check if we found the groupId in finalData or not. If not then we need to initially populate finalData with this group and agent version
                    if (!isInObject) {
                        //also populate zenGroupName
                        let group = findZenGroupById(agentList[i].zenGroupId)
                        if (group && group.friendlyName) {
                            //found group in session
                            finalData.push({
                                "zenGroupId": agentList[i].zenGroupId,
                                [agentList[i].version.replaceAll(".", "-")]: agentList[i].count,
                                "zenGroupName": group.friendlyName
                            })
                        } else {
                            //else did not find group in session
                            finalData.push({
                                "zenGroupId": agentList[i].zenGroupId,
                                [agentList[i].version.replaceAll(".", "-")]: agentList[i].count
                            })
                        }
                    }
                }
                //sort finalData by zenGroupName, need to convert zenGroupName to lower case for comparison
                finalData.sort((object1, object2) => (object1.zenGroupName?.toLowerCase() > object2.zenGroupName?.toLowerCase()) ? 1 : -1)
                /*
                    The ag chart series list needs a separate series object for each yKey now, you can't use the yKeys array with all the yKeys (in our case agent versions) anymore.
                    So we will just format each series object and set the chartDataSeries list afterwords with each agent version as its own series object
                 */
                let localChartDataSeriesList = []
                //sort versions array before traversing
                versions.sort()
                versions.forEach(version => {
                    localChartDataSeriesList.push(
                        {
                            type: 'bar',
                            stacked: true,
                            xKey: 'zenGroupId',
                            yKey: version.replaceAll(".", "-"),
                            yName: version,
                            stroke: '#c2c2c2',
                            strokeWidth: 1,
                            highlightStyle: {
                                item: {
                                    fill: "#E8E8E8",
                                    stroke: "#181818",
                                    strokeWidth: 1
                                },
                            },
                            tooltip: { //for hovering over a column
                                renderer: function (params) {
                                    let content = "Count: " + params.datum[params.yKey]
                                    return {
                                        title: "Version: " + params.yName,
                                        content: content
                                    };
                                },
                            },
                        },
                    )
                })

                //populate hooks used in chart
                setChartDataSeries(localChartDataSeriesList)
                setChartData(finalData)

            } else {
                setChartData([])
                setChartDataSeries([])
            }
            setChartIsLoading(false)
        }).catch(function (error) {
            setChartIsLoading(false)
        })
    }

    function base64ToArrayBuffer(base64) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    function toggleUpdateUseFilterState(toggleSetting) {
        updateUseFilterStateHelper(toggleSetting, 'agentsGridFilterState', updateAgentsGridUseFilterStateReactive);
    }

    function toggleUpdateUseColumnState(toggleSetting) {
        updateUseColumnStateHelper(toggleSetting, 'agentsGridColumnState', updateAgentsGridUseColumnStateReactive);
    }


    function getGrid() {
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                gridApi={gridApi}
                setGridApi={setGridApi}
                //activeAgentsList={activeAgentsList}
                //setActiveAgentsList={setActiveAgentsList}
                setEnableButtons={setEnableButtons}
                gridCount={gridCount}
                setGridCount={setGridCount}
                gridPage={gridPage}
                setGridPage={setGridPage}
                gridFilters={gridFilters}
                setGridFilters={setGridFilters}
                gridSortModel={gridSortModel}
                setGridSortModel={setGridSortModel}
                setZenGroupSessionStorage={setZenGroupSessionStorage}
                zenGroupSessionStorage={zenGroupSessionStorage}
                agentLocation={agentLocation}
                sseDataPullActive={sseDataPullActive}
                setSSEDataPullActive={setSSEDataPullActive}
                asyncTransactionWaitMillis={asyncTransactionWaitMillis}
                setAsyncTransactionWaitMillis={setAsyncTransactionWaitMillis}
                excelExport={excelExport}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    async function refreshGrid() {
        //We are going to have change streams in the future, and with high quantities of data now in the grid, this code slows down the grid and becomes laggy, so disabling this for now by
        // just having a return line to stop the code from running
        return
    }

    async function cancelUninstall(rowNode, params) {
        if (rowNode && rowNode.data && rowNode.data.agentId) {
            cancelUninstallAgentReactive(rowNode.data.agentId).then(function (response) {
                NotificationManager.success("Successfully canceled uninstall");
                rowNode.setDataValue("specialStatusMessage", "none");
                //need to refresh agent name col to make uninstall icon appear, limiting to only this row's agentDisplayName cell
                params.api.refreshCells({
                    columns: ["agentDisplayName"],
                    rowNodes: [rowNode],
                    suppressFlash: true,
                    force: true
                })
                //refreshGrid()
            }).catch(function (error) {
                if (error.message) {
                    NotificationManager.error(error.message)
                } else {
                    NotificationManager.error(`Unexpected error cancelling the uninstall`);
                }
            })
            //await refreshGrid()
        } else {
            Notification.info("Please select an agent to cancel uninstall");
        }
    }

    async function requestUninstall() {
        if (gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0) {
            let agentIdsList = [];
            gridApi.getSelectedNodes().forEach(selectedNode => {
                if (selectedNode.data.version) {
                    try {
                        let versionStr = selectedNode.data.version.replaceAll(".", "")
                        const regex = /\D/g; //all non-digits
                        versionStr = versionStr.replaceAll(regex, "") //remove all non-digits from the string
                        let versionInt = parseInt(versionStr, 10)
                        if (versionInt <= 332 || isNaN(versionInt) || versionInt === null) {
                            console.log(`Agent ${selectedNode.data.agentDisplayName} is not compatible with the remote uninstall feature.`)
                        }
                        else{
                            if(selectedNode.data.specialStatusMessage === "uninstalled"){
                                console.log(`Agent ${selectedNode.data.agentDisplayName} is uninstalled already`)
                            }
                            else{
                                if(zenGroupIdsWithoutPermission.has(selectedNode.data.zenGroupId)){
                                    console.log(`User does not have permission inside Agent ${selectedNode.data.agentDisplayName}'s group to uninstall`)
                                }
                                else{
                                    agentIdsList.push(selectedNode.data.agentId)
                                    uninstallAgentReactive(selectedNode.data.agentId)
                                        .then(() => {
                                            /*
                                            *************************************
                                            * THIS IS UNTESTED
                                            * ***********************************
                                             */
                                            selectedNode.setDataValue("specialStatusMessage","uninstallInProgress")
                                            // gridApi.refreshCells({columns: ["agentDisplayName", "machineName"], suppressFlash: true, force: true, rowNodes: [currentLicense]})
                                        })
                                        .catch((error) => {
                                            console.log("Error requesting remote uninstall")
                                            /*if(error.message){
                                                NotificationManager.error(error.message);
                                            }
                                            else{
                                                NotificationManager.error(`Error requesting remote uninstall`);
                                            }*/
                                        })
                                }
                            }
                        }
                    }
                    catch(error){}
                }
            })
            //  Mason: still keeping this, so we don't lost the error message
            if(agentIdsList.length < 1){
                NotificationManager.error(`Error requesting remote uninstall, no selected agents are currently compatible with the remote uninstall feature`);
            }
            else{
                NotificationManager.success(`Uninstall task(s) queued for compatible agents in groups you have permission to do so in.`)
                setShowUninstallConfirmation(false)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        } else {
            console.log("requestUninstall called with no selected row");
            NotificationManager.error("Please select an agent");
        }
    }

    function releaseAgentLicenses(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let validAgentIdList = []
            gridApi.getSelectedNodes().forEach(currentAgent => {
                if(currentAgent.data.agentId && !zenGroupIdsWithoutPermission.has(currentAgent.data.zenGroupId)) {
                    validAgentIdList.push(currentAgent.data.agentId)
                    releaseAgentLicenseReactive(currentAgent.data.agentId)
                        .then(() => {
                            currentAgent.data.licenseManuallyRemovedByUser = true
                            currentAgent.setDataValue("licenseId", null)
                            currentAgent.setDataValue("licenseDisplayName", " ")
                            currentAgent.setDataValue("expiration", " ")
                            currentAgent.setDataValue("assigned", " ")
                        })
                        .catch(function(error){})
                }
            })

            if(validAgentIdList.length < 1){
                NotificationManager.info(`None of the selected agent licenses in groups you have permission in were eligible to be released`);
            }
            else{
                NotificationManager.success("Queued the release of eligible agent licenses for groups you have permission to do so in");
                // refreshGrid()
                setShowReleaseLicensesConfirmation(false)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        }
    }

    function bulkAssignAgentsLicenses(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let validAgentIdList = []
            gridApi.getSelectedNodes().forEach(currentAgent => {
                if(currentAgent.data.agentId && currentAgent.data.specialStatusMessage !== "uninstalled" && !zenGroupIdsWithoutPermission.has(currentAgent.data.zenGroupId)
                && (currentAgent.data.licenseId === null || currentAgent.data.licenseId === undefined)) {
                    validAgentIdList.push(currentAgent.data.agentId)
                }
            })

            if(validAgentIdList.length < 1){
                NotificationManager.info(`None of the selected agents in groups you have permission in were eligible to be assigned a license`);
            }
            else{
                bulkAssignAgentLicensesReactive(validAgentIdList).then(response => {
                    //no further action needed, change stream will handle updates to grid
                }).catch(function(error){})
                NotificationManager.success("Queued the eligible agents to be assigned licenses for groups you have permission to do so in");
                // refreshGrid()
                setShowBulkAssignLicensesConfirmationModal(false)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        }
    }

    function bulkChangeAutoAllocateLicenses(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){
            let validAgentIdList = []
            let doNotLookForLicense = !bulkAutoAllocateLicensesToggled
            gridApi.getSelectedNodes().forEach(currentAgent => {
                if(currentAgent.data.agentId && currentAgent.data.specialStatusMessage !== "uninstalled" &&
                    (currentAgent.data.licenseId === null || currentAgent.data.licenseId === undefined) && !zenGroupIdsWithoutPermission.has(currentAgent.data.zenGroupId)) {
                    validAgentIdList.push(currentAgent.data.agentId)
                    changeAgentAutoAssignLicenseInValidateReactive(currentAgent.data.agentId, doNotLookForLicense).then(response => {
                        currentAgent.data.licenseManuallyRemovedByUser = doNotLookForLicense
                        gridApi && gridApi.refreshCells({columns: ["licenseDisplayName"], rowNodes: [currentAgent], suppressFlash: true, force: true})
                    }).catch(function(error){})
                }
            })

            if(validAgentIdList.length < 1){
                NotificationManager.info(`None of the selected agents in groups you have permission in were eligible to be updated`);
            }
            else{
                NotificationManager.success("Queued the update of eligible agents for groups you have permission to do so in");
                setShowBulkChangeAutoAllocateLicenseModal(false)
                setBulkAutoAllocateLicensesToggled(false)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        }
    }

    async function changeAgentsGroup() {
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0 && newAgentZenGroup) {
            let agentIdsList = []; //store agent ids of agents
            let newZenGroup = findZenGroupById(newAgentZenGroup)
            gridApi.getSelectedNodes().forEach(selectedNode => {
                if (selectedNode.data.agentId) {
                    if(!zenGroupIdsWithoutPermission.has(selectedNode.data.zenGroupId)){
                        agentIdsList.push(selectedNode.data.agentId);
                        singleChangeAgentGroupReactive(newAgentZenGroup, selectedNode.data.agentId)
                            .then(() => {
                                selectedNode.setDataValue("zenGroupId", newAgentZenGroup)
                                if(newZenGroup && newZenGroup.friendlyName){
                                    selectedNode.setDataValue("zenGroupDisplayName", newZenGroup.friendlyName)
                                }
                            })
                            .catch((error) => {
                                console.log("Unexpected error making this request")
                                /*if (error.message) {
                                    NotificationManager.error(error.message);
                                } else {
                                    NotificationManager.error(`Unexpected error`);
                                }*/
                            })
                    }
                }
            })
            if(agentIdsList.length === 0){
                NotificationManager.error(`You don't have permission to change any selected agent's group`);
                return
            }

            NotificationManager.success(`Successfully updated the selected ${agentIdsList.length < 2 ? "agent's" : "agents'"} group that you have permission to do so in.`);
            setShowChangeGroupsModal(false)
            setNewAgentZenGroup(null)
            setZenGroupNamesWithPermission(new Set())
            setZenGroupNamesWithoutPermission(new Set())
            setZenGroupIdsWithoutPermission(new Set())
        } else {
            console.log("changeAgentsGroup called with no selected row(s)");
            NotificationManager.error("Please select an agent to change");
        }
    }

    function bulkSetAgentDefaultVisibilityRequest(){
        if(gridApi && gridApi.getSelectedNodes() && gridApi.getSelectedNodes().length > 0){

            let agentIdsList = []; //store agent ids of agents with compatible versions
            gridApi.getSelectedNodes().forEach(currentRowNode =>{
                if(zenGroupIdsWithoutPermission.has(currentRowNode.data.zenGroupId)){
                    console.log(`User does not have permission inside Agent ${currentRowNode.data.agentDisplayName}'s group to change it's visibility setting`)
                }
                else{
                    agentIdsList.push(currentRowNode.data.agentId)

                    singleSetAgentVisibilityReactive(currentRowNode.data.agentId, !bulkShowAgentsToggled)
                        .then(() => {
                            currentRowNode.setDataValue("hiddenFromUI", !bulkShowAgentsToggled)
                        })
                        .catch((error) => {
                            console.log("Error making this request")
                            /*if(error.message){
                                NotificationManager.error(error.message);
                            }
                            else{
                                NotificationManager.error(`Error making this request`);
                            }*/
                        })
                }
            })

            if(agentIdsList.length < 1){
                NotificationManager.info(`You do not have permission in any of the selected agent's groups to change their visibility setting.`);
            }
            else{
                NotificationManager.success("Successfully queued update for agent visibility setting (you may need to refresh the grid periodically to see changes for larger requests)");
                setShowBulkVisibilityModal(false)
                setBulkShowAgentsToggled(true)
                setZenGroupNamesWithPermission(new Set())
                setZenGroupNamesWithoutPermission(new Set())
                setZenGroupIdsWithoutPermission(new Set())
            }
        } else {
            NotificationManager.info("Please choose at least one agent.");
        }
    }

    function excelExport() {
        standardExcelExportHelper(gridApi, sseDataPullActive, "agentsGridExport")
    }
}

let saveFilterChanges = true //used for if user clicks link to come to agents page and we auto filter grid to show that specific agent, in that case we don't want to save
// the filters because that would mess with their previous filters they still may want.
class Grid extends Component {
    rowData = []
    licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
    sessionAgentVersionsList = JSON.parse(decryptAndGetSessionVariable("distinctAgentVersionsList")) //we have an await call on this data fetch after successful login so this should never be null
    updateTransactionsToApply = []
    agentIdsToVerifyLicenses = new Set()
    abortController = new AbortController()
    groupsFromSessionList = getZenGroupSessionStorageOrDefault()

    constructor(props, setEnableButtons, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        params.api.getFilterInstance("zenGroupDisplayName").refreshFilterValues()
        //console.log("onFirstDataRendered outside of filterInstance callback called in agents.js")
    };

    componentDidMount() {
        //console.log("componentDidMount in agents.js called")
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        //console.log("componentDidUpdate in agents.js called")
    }

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateAgentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateAgentsGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateAgentsGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateAgentsGridColumnModeReactive, customColumnModeText)
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
        //abort the change stream listener before leaving page
        this.abortController.abort()
    }

    handleAgentSpecialStatusMessageFormatting = (rowData) => {
        if(rowData.specialStatusMessage === "updateStagingStarted" || rowData.specialStatusMessage === "updateStaged" || rowData.specialStatusMessage === "updateStagedSuccessful"){
            let latestStagedUpdateVersion = rowData.latestStagedUpdateVersion
            if(latestStagedUpdateVersion){
                if(rowData.version !== latestStagedUpdateVersion){
                    rowData.updateStagedText = "Update Staged ("+latestStagedUpdateVersion+")"
                }
                else{
                    //agent version and staged update version are the same
                    rowData.updateStagedText = null
                    rowData.specialStatusMessage = "none" //none so the "None" filter will work properly. None or null values are treated as the same
                }
            }
            else{
                //the response was null or the stagedAgentVersion was null
                rowData.updateStagedText = null
                rowData.specialStatusMessage = "none" //none so the "None" filter will work properly. None or null values are treated as the same
            }
        }
        else if(rowData.specialStatusMessage === "updateStagedFailed" || !rowData.specialStatusMessage){
            //talked about and decided to show nothing in this case of updateStagedFailed
            rowData.specialStatusMessage = "none" //none so the "None" filter will work properly. None or null values are treated as the same
        }
    }

    populateGrid = async (rowData) => {
        if(!this.licensesWithAgentIdList){
            this.licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
        }
        await this.checkForLicenseNameInSessionStorageListForInitialPopulation(rowData, true) //pass true to keep track of the agents without a license found that we need query for
        //check agent's specialStatusMessage
        this.handleAgentSpecialStatusMessageFormatting(rowData)
        this.prepUpdatePolicyValues(rowData)
        this.prepAgentProxyConfig(rowData)
        standardHandlePopulateGrid(rowData, this.gridApi)
    }

    prepAgentProxyConfig(rowData){
        let groupFound = this.groupsFromSessionList?.find(zenGroupObject => zenGroupObject.id === rowData.zenGroupId)
        if(groupFound){
            if(groupFound.proxyEnabled === true){
                //if proxyEnabled for this agent's group, then check the 2 agent booleans for what value to show
                if(!rowData.lastAboutRequestUsedProxy && !rowData.lastTokenRequestUsedProxy){
                    //if both booleans are false then put canConnectWithoutProxy true
                    rowData.canConnectWithoutProxy = true
                }
                else{
                    //else one or both booleans were true so canConnectWithoutProxy is false
                    rowData.canConnectWithoutProxy = false
                }
                rowData.groupProxyEnabled = true //populate groupProxyEnabled so we can re-use it in the change stream code
            }
            else{
                //else proxy is not enabled for group, always show canConnectWithoutProxy true
                rowData.canConnectWithoutProxy = true
                rowData.groupProxyEnabled = false //populate groupProxyEnabled so we can re-use it in the change stream code
            }
        }
        else{
            //else group was not found, then always put can connect without proxy
            rowData.canConnectWithoutProxy = true
            rowData.groupProxyEnabled = false //populate groupProxyEnabled so we can re-use it in the change stream code
        }
    }

    handleBulkLicenseNamesApiCallForAgentIdsToVerifyLicenses = () => {
        if(this.agentIdsToVerifyLicenses && this.agentIdsToVerifyLicenses.size > 0){
            //this.props.setAsyncTransactionWaitMillis(2000) //apply transactions every 2 seconds until helperCallToBulkLicenseNamesSSEAfterInitialAgentsGridLoad is done

            /*
                Wait a second to call to bulkGetLicenseNameWithAgentIdReactive so the initial population can finish. Found that this is needed because our populate agents to grid code
                sometimes was not finished for a few agents, so just give it a second to finish
             */
            let msToSleep = 1000
            const timer = setTimeout(() => {
                helperCallToBulkLicenseNamesSSEAfterInitialAgentsGridLoad("/bulkGetLicenseNameWithAgentIdReactive", this.agentIdsToVerifyLicenses, this.gridApi, this.licensesWithAgentIdList, this.handleAgentIdsToVerifyLicenses, this.props.setAsyncTransactionWaitMillis).then(r => {})
            }, msToSleep)
            return () => clearTimeout(timer)
        }
        else{
            //else no need to call /bulkGetLicenseNameWithAgentIdReactive, set async transaction millis to 5000 for updates to apply to the grid
            this.props.setAsyncTransactionWaitMillis && this.props.setAsyncTransactionWaitMillis(5000)
        }
    }

    handleAgentIdsToVerifyLicenses = (agentId, licenseDisplayName, licenseId, expiration, assigned) => {
        //This is ran for each agent we receive from the bulkGetLicenseNameWithAgentIdReactive stream
        if(!this.gridApi.destroyCalled){
            //Get the row node for the agent and update the license name and id
            let agentRowNode = this.gridApi.getRowNode(agentId)
            if(agentRowNode && agentRowNode.data){
                let agentRowNodeData = agentRowNode.data
                agentRowNodeData.licenseDisplayName = licenseDisplayName
                agentRowNodeData.licenseId = licenseId
                agentRowNodeData.expiration = expiration
                agentRowNodeData.assigned = assigned
                //By this time, the sseDataPull for inital grid load is finished
                standardHandleUpdateAndReplaceEvent(agentRowNodeData, this.gridApi, false, null)
            }
        }
    }

    callToGetLicenseNameWithAgentIdReactiveHelper = async (rowData) => {
        try {
            let license = await getLicenseNameWithAgentIdReactive(rowData.agentId)
            if(license && license.licenseId) {
                if (license.name) {
                    if(license.name === rowData.licenseDisplayName){
                        //console.debug(license.name+ " equals licenseDisplayName")
                    }else{
                        rowData.licenseDisplayName = license.name
                        rowData.expiration = license.expiration
                        rowData.assigned = license.assigned
                        rowData.licenseId = license.licenseId
                    }
                }else{
                    rowData.licenseDisplayName = " "
                    rowData.expiration = " "
                    rowData.assigned = " "
                    rowData.licenseId = null
                }
            }
            else{
                rowData.licenseDisplayName = " "
                rowData.expiration = " "
                rowData.assigned = " "
                rowData.licenseId = null
            }
        } catch (e) {
            rowData.licenseDisplayName = " "
            rowData.expiration = " "
            rowData.assigned = " "
            rowData.licenseId = null
        }
    }

    checkForLicenseNameInSessionStorageListForInitialPopulation = async (rowData, addAgentIdToList=false) =>{
        if(this.licensesWithAgentIdList){
            let licenseFound = null
            for(let i = 0; i < this.licensesWithAgentIdList.length; i++){
                if(this.licensesWithAgentIdList[i].agentId === rowData.agentId){
                    licenseFound = this.licensesWithAgentIdList[i]
                }
            }
            if(licenseFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                if(licenseFound.name){
                    rowData.licenseDisplayName = licenseFound.name
                    rowData.expiration = licenseFound.expiration
                    rowData.assigned = licenseFound.assigned
                    rowData.licenseId = licenseFound.licenseId
                }
                else{
                    rowData.licenseDisplayName = " "
                    rowData.expiration = " "
                    rowData.assigned = " "
                    rowData.licenseId = null
                }
            }
            else{
                //we did not find license in session storage, add agentId to agentIdsToVerifyLicenses which is used in the bulk license name call
                //await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
                //Assign License button will show initially, then after handleBulkLicenseNamesApiCallForAgentIdsToVerifyLicenses runs if there is a license for this agent it will be updated on the grid
                rowData.licenseDisplayName = " "
                rowData.expiration = " "
                rowData.assigned = " "
                rowData.licenseId = null
                if(addAgentIdToList){
                    this.agentIdsToVerifyLicenses.add(rowData.agentId)
                }
            }
        }
        else{
            //list was null, check if addAgentIdToList is true (for initial grid population) for if we should use agentIdsToVerifyLicenses instead of calling to callToGetLicenseNameWithAgentIdReactiveHelper
            if(addAgentIdToList){
                rowData.licenseDisplayName = " "
                rowData.expiration = " "
                rowData.assigned = " "
                rowData.licenseId = null
                this.agentIdsToVerifyLicenses.add(rowData.agentId)
            }
            else{
                //this.licensesWithAgentIdList was null, backup to callToGetLicenseNameWithAgentIdReactiveHelper to get license name value for this agent
                await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
            }
        }
    }

    checkForLicenseNameInSessionStorageListForChangeStream = async (rowData) =>{
        //we have to get most updated licensesWithAgentIdList list since we added change streams to update this list for license agentId changes
        let licensesWithAgentIdList = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
        if(licensesWithAgentIdList){
            let licenseFound = null
            for(let i = 0; i < licensesWithAgentIdList.length; i++){
                if(licensesWithAgentIdList[i].agentId === rowData.agentId){
                    licenseFound = licensesWithAgentIdList[i]
                }
            }
            if(licenseFound){
                //need to implement some checks to make sure spinner stops loading in some rare cases
                if(licenseFound.name){
                    rowData.licenseDisplayName = licenseFound.name
                    rowData.expiration = licenseFound.expiration
                    rowData.assigned = licenseFound.assigned
                    rowData.licenseId = licenseFound.licenseId
                }
                else{
                    rowData.licenseDisplayName = " "
                    rowData.expiration = " "
                    rowData.assigned = " "
                    rowData.licenseId = null
                }
            }
            else{
                /*
                    We did not find license in session storage. We should not get this since handleBulkLicenseNamesApiCallForAgentIdsToVerifyLicenses will take care of any agents that
                    we did not find a license for initially and apply to the grid, then before this function is called it should reuse the agent row node's value for this.
                    The license CS listener will also apply updates to the agents grid if we don't find it here.
                 */
                //await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
                rowData.licenseDisplayName = " "
                rowData.expiration = " "
                rowData.assigned = " "
                rowData.licenseId = null
            }
        }
        else{
            //licensesWithAgentIdList was null, backup to callToGetLicenseNameWithAgentIdReactiveHelper to get license name value for this agent
            await this.callToGetLicenseNameWithAgentIdReactiveHelper(rowData)
        }
    }

    prepUpdatePolicyValues = (rowData) =>{
        if(rowData.managedUpdateSettings !== null){
            //Then agent is overriding the group policy
            rowData.updatePolicy = overridingGroupPolicyText
            rowData.autoUpdate = rowData.managedUpdateSettings.autoUpdate
            //If auto update is true then show the latest agent version for this value, make sure to check length before we access index at [1] since first element is "Do Not Update"
            if(rowData.autoUpdate === true && this.sessionAgentVersionsList?.length >= 2 ){
                try{
                    rowData.latestAgentVersionApproved = this.sessionAgentVersionsList[1]
                } catch (e) {
                    //default to value in row data if error accessing list
                    rowData.latestAgentVersionApproved = rowData.managedUpdateSettings.latestAgentVersionApproved
                }
            }
            else{
                rowData.latestAgentVersionApproved = rowData.managedUpdateSettings.latestAgentVersionApproved
            }
        }
        else{
            //then agent is following the group policy
            let groupFound = this.props.zenGroupSessionStorage?.find(zenGroupObject => zenGroupObject.id === rowData.zenGroupId)
            let autoUpdate = true //default to true
            let latestAgentVersionApproved = null
            if(groupFound && groupFound.managedUpdateSettings){
                autoUpdate = groupFound.managedUpdateSettings.autoUpdate
                latestAgentVersionApproved = groupFound.managedUpdateSettings.latestAgentVersionApproved
            }
            rowData.updatePolicy = followingGroupPolicyText
            rowData.autoUpdate = autoUpdate
            if(rowData.autoUpdate === true && this.sessionAgentVersionsList?.length >= 2 ){
                try{
                    rowData.latestAgentVersionApproved = this.sessionAgentVersionsList[1]
                } catch (e) {
                    //default to latestAgentVersionApproved if error accessing list
                    rowData.latestAgentVersionApproved = latestAgentVersionApproved
                }
            }
            else{
                rowData.latestAgentVersionApproved = latestAgentVersionApproved
            }
        }
    }

    updateGridForChangeStream = async (changeStreamData) => {
        let operationType = changeStreamData.operationType
        let agentBody = changeStreamData.body
        //need to make sure the fields line up with the change stream body and the keys we use for columns for the grid before we apply the transaction
        agentBody["agentId"] = agentBody["id"]
        agentBody["version"] = agentBody["agentVersion"]
        agentBody["autoUpgrade"] = agentBody["autoUpgrade"] ? agentBody["autoUpgrade"] : true
        agentBody["agentDisplayName"] = agentBody["userSetFriendlyName"] ? agentBody["userSetFriendlyName"] : agentBody["friendlyName"]

        if(operationType === "UPDATE" || operationType === "REPLACE"){
            /*
                The incoming agent won't have the licenseDisplayName field since we do client side processing to get the license name in the valueGetter, so we need to
                find the row node to get the current licenseDisplayName value to supply (if passes checks below). We also do some processing in the populateGrid function above
                for agent's specialStatusMessage field that should be reflected below in the agent coming in from the change stream
            */
            //gridApi.getRowNode throws an error if the grid was destroyed (user goes to another page), so add in the destroyCalled check
            if(!this.gridApi.destroyCalled){
                let agentRowNode = this.gridApi.getRowNode(agentBody["agentId"])
                if(agentRowNode && agentRowNode.data){
                    let agentRowNodeData = agentRowNode.data
                    if(agentRowNodeData.licenseManuallyRemovedByUser !== agentBody.licenseManuallyRemovedByUser){
                        //if license was removed from agent manually by user, or license was assigned to agent after being initially removed by user
                        await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                    }
                    else if((agentRowNodeData.specialStatusMessage === "uninstalled" && agentBody.specialStatusMessage !== "uninstalled") ||
                            (agentRowNodeData.specialStatusMessage !== "uninstalled" && agentBody.specialStatusMessage === "uninstalled")){
                        if(agentRowNodeData.specialStatusMessage !== "uninstalled" && agentBody.specialStatusMessage === "uninstalled"){
                            //agent was uninstalled
                            agentBody.licenseDisplayName = " "
                            agentBody.expiration = " "
                            agentBody.assigned = " "
                            agentBody.licenseId = null
                        }
                        else{
                            //previously uninstalled agent was installed again
                            await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                        }
                    }
                    else{
                        if(agentRowNodeData.licenseDisplayName === agentRowNodeData.agentId){
                            //console.log("in equals agentId for agent: " + agentRowNodeData.agentDisplayName)
                        }
                        else{
                            //else we can just re-use the existing row nodes licenseDisplayName value
                            agentBody.licenseDisplayName = agentRowNodeData.licenseDisplayName
                            agentBody.expiration = agentRowNodeData.expiration
                            agentBody.assigned = agentRowNodeData.assigned
                            agentBody.licenseId = agentRowNodeData.licenseId
                            if(agentBody.licenseDisplayName === null || agentBody.licenseDisplayName === undefined){
                                await this.checkForLicenseNameInSessionStorageListForChangeStream(agentBody)
                            }
                            else if(agentRowNodeData.specialStatusMessage !== agentBody.specialStatusMessage && agentBody.licenseDisplayName.startsWith('TEMP LICENSE FOR UNINSTALL')){
                                //need to call to callToGetLicenseNameWithAgentIdReactiveHelper in case of cancelling uninstall and temp license deleted
                                await this.callToGetLicenseNameWithAgentIdReactiveHelper(agentBody)
                                if(agentBody.licenseId === null){
                                    //Agent is not assigned a temp license anymore, we need to remove the TEMP license from session storage since we need to do additional setup for delete change streams in mongo
                                    let licensesWithAgentIdListInSession = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
                                    if(licensesWithAgentIdListInSession){
                                        licensesWithAgentIdListInSession = licensesWithAgentIdListInSession.filter(function (value, index, arr) {
                                            return value.licenseId !== agentRowNodeData.licenseId;
                                        })
                                        encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                    }
                                }
                            }
                        }

                    }

                    //See agent is following group policy, then we can re-use the values from existing row node to avoid looping through groups in session storage again
                    if(agentRowNodeData.managedUpdateSettings === null && agentBody.managedUpdateSettings === null && agentRowNodeData.updatePolicy !== null){
                        //Agent is following group update policy so just re-use values from agentRowNodeData, the agentRowNodeData.updatePolicy !== null check is
                        // just making sure agentRowNodeData was prepped with update policy values already at the time it was added to the grid, else we will just prep it in the else below
                        agentBody.updatePolicy = agentRowNodeData.updatePolicy
                        agentBody.autoUpdate = agentRowNodeData.autoUpdate
                        agentBody.latestAgentVersionApproved = agentRowNodeData.latestAgentVersionApproved
                    }
                    else{
                        //else call to prepUpdatePolicyValues
                        this.prepUpdatePolicyValues(agentBody)
                    }
                    this.handleAgentSpecialStatusMessageFormatting(agentBody)
                    //handle processing AgentProxyConfig for agent to see if value changed. Only need to check if agentRowNodeData groupProxyEnabled is true (populated before agent goes into grid)
                    if(agentRowNodeData.groupProxyEnabled){
                        //if proxyEnabled for this agent's group, then check the 2 agent booleans for what value to show
                        agentBody.canConnectWithoutProxy = !agentBody.lastAboutRequestUsedProxy && !agentBody.lastTokenRequestUsedProxy;
                    }
                    else{
                        agentBody.canConnectWithoutProxy = true //else proxy not enabled for group, always put true
                    }
                }
                else{
                    //else no row node was found which means this agent was not populated into grid yet, we need to prep the license name and special status (update staged) columns
                    await this.checkForLicenseNameInSessionStorageListForInitialPopulation(agentBody)
                    this.prepUpdatePolicyValues(agentBody)
                    this.prepAgentProxyConfig(agentBody)
                    this.handleAgentSpecialStatusMessageFormatting(agentBody)
                }
                standardHandleUpdateAndReplaceEvent(agentBody, this.gridApi, this.props.sseDataPullActive, this.updateTransactionsToApply)
            }
        }
        else if (operationType === "INSERT"){
            //if the initial supply of rowData to the grid is active, we don't want to apply this insert since the current sse data pull should include this agent
            if(!this.props.sseDataPullActive && !this.gridApi.destroyCalled){
                //Likely we will not have received the license update yet (if a license was found when agent first calls in when installing) that is assigned to this agent because agent insert happens
                // first. The license listener will apply the update when it is received
                await this.checkForLicenseNameInSessionStorageListForInitialPopulation(agentBody)
                this.prepUpdatePolicyValues(agentBody)
                this.prepAgentProxyConfig(agentBody)
                this.handleAgentSpecialStatusMessageFormatting(agentBody)

                //not using standardHandleInsertEvent since we are using applyTransaction (sync) for agent inserts so we can get the license listener updates
                this.gridApi.applyTransaction({
                    add: [agentBody]
                })
            }
        }
    }

    onGridReady = async (params) => {
        this.gridApi = params.api;
        licensesListWithAgentIdSessionStorageChangeStreamListener(params.api)
        this.props.setGridApi(params.api);
        let columnMode = this.props.columnMode

        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(params, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateAgentsGridColumnModeReactive)
        }
        else if(columnMode === mediumColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, params.api, this.props.setColumnMode, mediumColumnModeText, medColumnIds, updateAgentsGridColumnModeReactive)
        }
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });

        let applyNormalDefaultFilter = true
        if(this.props.agentLocation && this.props.agentLocation.state){
            //first check if we are coming from a different page where the user clicked a crosslink, this filter takes precedence over any other saved/default filter
            if(this.props.agentLocation.state.agentIdClicked){
                let instance = await params.api.getFilterInstance("agentId")
                if(instance != null){
                    applyNormalDefaultFilter = false
                    saveFilterChanges = false
                    let agentIds = [this.props.agentLocation.state.agentIdClicked]
                    let filter = {}
                    filter["agentId"] = {"values": agentIds,"filterType": "set"}
                    instance.setFilterValues(agentIds.concat(["blankFilterValue"]))
                    params.api.setFilterModel(filter)

                    window.scroll({behavior: "smooth", top: 0, left: 0}) //scroll to top of page or else it is very likely the user will be at the bottom of the grid and see no data (since they should only see one row) when being redirected
                }
            }
            //Check offline agent emails for auto filtering grid. First check original email links for filtering grid by dates, then check for fully/partially offline keys to filter grid by agentIds
            else if (this.props.agentLocation.state.loginURLSearchParams){
                //coming from security alert link for offline agent for filtering grid by dates
                let loginURLSearchParams = this.props.agentLocation.state.loginURLSearchParams
                if(loginURLSearchParams && loginURLSearchParams.trim().length > 0){
                    //call to findNotificationEventForOfflineAgentAutoFilter
                    try{
                        let response = await findNotificationEventForOfflineAgentAutoFilter(loginURLSearchParams)
                        let zenGroupId = response.zenGroupId
                        let queryDateTimeMillis = response.queryDateTimeMillis
                        let sendOfflineAlertsForHiddenAgents = response.sendOfflineAlertsForHiddenAgents
                        let zenGroup = findZenGroupById(zenGroupId)
                        if(zenGroupId && queryDateTimeMillis && zenGroup && zenGroup.friendlyName){
                            let queryDateTimeFilterString = getDateStringForAgGridFilter(queryDateTimeMillis)
                            if(queryDateTimeFilterString){
                                applyNormalDefaultFilter = false
                                saveFilterChanges = false
                                let filter = {}
                                filter["zenGroupDisplayName"] = {
                                    "values": [
                                        zenGroup.friendlyName
                                    ],
                                    "filterType": "set"
                                }
                                //filter dates
                                filter["lastTaskingDateTime"] = {
                                    "dateFrom": queryDateTimeFilterString,
                                    "dateTo": null,
                                    "filterType": "date",
                                    "type": "lessThan"
                                }
                                filter["lastSchedulesDateTime"] = {
                                    "dateFrom": queryDateTimeFilterString,
                                    "dateTo": null,
                                    "filterType": "date",
                                    "type": "lessThan"
                                }
                                filter["machineName"] = {
                                    "filterType": "text",
                                    "type": "notBlank"
                                }
                                if(!sendOfflineAlertsForHiddenAgents){
                                    //do not show hidden agents on grid if sendOfflineAlertsForHiddenAgents is false
                                    filter["hiddenFromUI"] = {
                                        type: 'set',
                                        values: ['Visible']
                                    }
                                }
                                //prep filter for specialStatusMessage to filter out uninstalled and uninstallInProgress values
                                let filterValuesList = []
                                specialStatusFilterValues.forEach(value => {
                                    if(value !== "uninstalled" && value !== "uninstallInProgress"){
                                        filterValuesList.push(value)
                                    }
                                })
                                filter["specialStatusMessage"] = {
                                    type: 'set',
                                    values: filterValuesList
                                }
                                //check to filter out hiddenFromUI or not
                                params.api.setFilterModel(filter);
                            }
                        }
                    } catch (e) {
                    }
                }
            }
            else if(this.props.agentLocation.state.fullyOfflineLoginURLSearchParams) {
                let loginURLSearchParams = this.props.agentLocation.state.fullyOfflineLoginURLSearchParams
                if(loginURLSearchParams && loginURLSearchParams.trim().length > 0){
                    try {
                        let response = await findNotificationEventForFullyOfflineAgentAutoFilter(loginURLSearchParams)
                        let agentIds = response.agentIdsCausingAlert
                        if(agentIds && agentIds.length > 0){
                            let instance = await params.api.getFilterInstance("agentId")
                            if(instance != null){
                                applyNormalDefaultFilter = false
                                saveFilterChanges = false
                                let filter = {}
                                filter["agentId"] = {"values": agentIds,"filterType": "set"}
                                let zenGroupId = response.zenGroupId
                                if(zenGroupId){ //adding group filter not essential since we have agentId filter, but adding so users can see a visual that a filter is in place
                                    let zenGroup = findZenGroupById(zenGroupId)
                                    if(zenGroup && zenGroup.friendlyName){
                                        filter["zenGroupDisplayName"] = {
                                            "values": [
                                                zenGroup.friendlyName
                                            ],
                                            "filterType": "set"
                                        }
                                    }

                                }
                                instance.setFilterValues(agentIds.concat(["blankFilterValue"]))
                                params.api.setFilterModel(filter)
                            }
                        }

                    } catch (e){

                    }
                }

            }
            else if(this.props.agentLocation.state.partiallyOfflineLoginURLSearchParams) {
                let loginURLSearchParams = this.props.agentLocation.state.partiallyOfflineLoginURLSearchParams
                if(loginURLSearchParams && loginURLSearchParams.trim().length > 0){
                    try {
                        let response = await findNotificationEventForPartiallyOfflineAgentAutoFilter(loginURLSearchParams)
                        let agentIds = response.agentIdsCausingAlert
                        if(agentIds && agentIds.length > 0){
                            let instance = await params.api.getFilterInstance("agentId")
                            if(instance != null){
                                applyNormalDefaultFilter = false
                                saveFilterChanges = false
                                let filter = {}
                                filter["agentId"] = {"values": agentIds,"filterType": "set"}
                                let zenGroupId = response.zenGroupId
                                if(zenGroupId){ //adding group filter not essential since we have agentId filter, but adding so users can see a visual that a filter is in place
                                    let zenGroup = findZenGroupById(zenGroupId)
                                    if(zenGroup && zenGroup.friendlyName){
                                        filter["zenGroupDisplayName"] = {
                                            "values": [
                                                zenGroup.friendlyName
                                            ],
                                            "filterType": "set"
                                        }
                                    }

                                }
                                instance.setFilterValues(agentIds.concat(["blankFilterValue"]))
                                params.api.setFilterModel(filter)
                            }
                        }

                    } catch (e){

                    }
                }
            }
        }

        if(applyNormalDefaultFilter){
            saveFilterChanges = true
            //by default when users go to agents page, we don't want to show hidden agents even if they have the apply saved filters toggled
            onGridReadyHelper(params, "agentsGridFilterState", {
                "hiddenFromUI": {
                    type: 'set',
                    values: ['Visible']
                }
            });
        }

        //check for licensesWithAgentIdList sessionStorage that will be used for the license name column values
        let licensesWithAgentIdListForInterval = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
        if(!licensesWithAgentIdListForInterval){
            //if this is null then setInterval to check for it in session
            const interval = setInterval(() => {
                licensesWithAgentIdListForInterval = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
                if(licensesWithAgentIdListForInterval){
                    params.api && params.api.onSortChanged()
                    params.api && params.api.onFilterChanged()
                    clearInterval(interval)
                }
            }, 2000);
            this.interval = interval
        }

        //await getAgentListReactiveSSE(this.populateGrid)
        //Don't send the setAsyncTransactionWaitMillis props function since handleBulkLicenseNamesApiCallForAgentIdsToVerifyLicenses will take care of it
        await loadDataWithSSEAndStartChangeStreamListener("/sse/agentListReactive", "/sse/listenToAgentEvent",
            this.populateGrid, this.updateGridForChangeStream, params, this.props.setSSEDataPullActive, null, this.updateTransactionsToApply,
            this.abortController, this.handleBulkLicenseNamesApiCallForAgentIdsToVerifyLicenses)

        //params.api.sizeColumnsToFit()
    };

    getRowId = (params) => {
        return params.data.agentId
    }

    getContextMenuItems = (params) => {
        let excelExport = this.props.excelExport //don't have access to this.props below in the action function so define it here
        return [
            standardExcelExportObjectInContextMenu(excelExport),
            "resetColumns",
            "autoSizeAll"
        ];
    };

    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        gridOptions={{
                            rowClassRules: {
                                'agentInsertedWithoutLicenseInValidateTrue': params => params?.data?.agentWasInsertedInValidateWithoutLicense === true || params?.data?.grantedTemporaryValidation === true
                            }}}
                        modules={[ClientSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule, ExcelExportModule, RichSelectModule]}
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor, customMuiAutocompleteGroupCellEditor: CustomMuiAutocompleteGroupCellEditor}}
                        rowData={this.rowData}
                        asyncTransactionWaitMillis={this.props.asyncTransactionWaitMillis}
                        suppressModelUpdateAfterUpdateTransaction={true}
                        maintainColumnOrder={true} //fixes issue where if you re-order/move column then click anywhere on the grid it reverts this change
                        getRowId={this.getRowId}
                        multiSortKey={"ctrl"}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentPageCellEditingStopped}
                        valueCache={true}
                        rowSelection={'multiple'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            if(selectedRows && selectedRows.length > 0){
                                //checks if the setEnableButtons method is null or not
                                this.props.setEnableButtons && this.props.setEnableButtons(true);
                            }
                            else{
                                this.props.setEnableButtons && this.props.setEnableButtons(false);
                            }
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        onFilterChanged={(params)=> {
                            //only update session and user saved filters if saveFilterChanges is true. We added this in to not save filter changes if user clicked a link to agents page because
                            // this filtering out for one agent would mess with their saved filters that they may still want.
                            if(saveFilterChanges){
                                onFilterChangedHelper(params, 'agentsGridFilterState', updateAgentsGridFilterModelReactive);
                            }
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        enableBrowserTooltips={false}
                        tooltipShowDelay={2000}
                        tooltipHideDelay={3000} //hides tooltip after 3 seconds
                        tooltipInteraction={true} //allows tooltips supplied by ag grid to remain open when hovered over by mouse
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}










