import {
    checkIsUsingRememberMe,
    decryptAndGetSessionVariable,
    encryptAndStoreSessionVariable,
    removeItemFromStorage
} from "./storageHelper";

let dataMemory = {};

export class AmplifyAuthStorage {
    static syncPromise = null;

    static setItem(key, value) {
        encryptAndStoreSessionVariable(key, value);
        dataMemory[key] = value;
        return dataMemory[key];
    }

    static getItem(key) {
        return Object.prototype.hasOwnProperty.call(dataMemory, key)
            ? dataMemory[key]
            : undefined;
    }

    static removeItem(key) {
        removeItemFromStorage(key)
        return delete dataMemory[key];
    }

    static clear() {
        dataMemory = {};
        return dataMemory;
    }

    static sync() {
        if (!AmplifyAuthStorage.syncPromise) {
            AmplifyAuthStorage.syncPromise = new Promise((res, rej) => {
                if(checkIsUsingRememberMe()){
                    for (let i = 0; i < localStorage.length; i++){
                        let key = localStorage.key(i)
                        dataMemory[key] = decryptAndGetSessionVariable(key);
                    }
                }
                else{
                    for (let i = 0; i < sessionStorage.length; i++){
                        let key = sessionStorage.key(i)
                        dataMemory[key] = decryptAndGetSessionVariable(key);
                    }
                }

                res();
            });
        }
        return AmplifyAuthStorage.syncPromise;
    }
}