import React, {Component, useMemo, useState} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ClearRefresh} from "../../components/clearRefreshButtons"
import {ColumnsToolPanelModule} from "@ag-grid-enterprise/column-tool-panel";
import {MenuModule} from "@ag-grid-enterprise/menu";
import {SetFilterModule} from "@ag-grid-enterprise/set-filter";
import {ServerSideRowModelModule} from "@ag-grid-enterprise/server-side-row-model";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer from "../../components/footer";
import {NotificationContainer} from "react-notifications";
import SidebarMenu from "../../components/sideBarComponent";
import {
    findByTaskIdListReactive,
    tasksListReactive, updateTasksGridColumnModeReactive,
    updateTasksGridColumnStateReactive,
    updateTasksGridFilterModelReactive,
    updateTasksGridUseColumnStateReactive,
    updateTasksGridUseFilterStateReactive
} from "../api/tasksApi";
import {refreshGridZenGroupAndAgentInformation} from "../../utils/refreshGridHelper";
import {
    defaultZenGroupColumnInitWithOptions,
    defaultZenGroupColumnInitWithOptionsWithValueGetter
} from "../../utils/zenGroupDisplayNameGridHelper";
import {
    defaultAgentNameColumnAndMachineNameInitWithFilters,
    defaultMachineNameColDefWithFilters
} from "../../utils/agentNameGridHelper";
import {agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped,} from "../../utils/gridCellEditing";
import {dateValueFormatter} from "../../utils/gridDateFormatter";
import {masterFilterHelper} from "../../utils/filterHelper";
import {
    getColumnModeInSession, getDefaultAgGridSidebarProps,
    getUseColumnStateInSession,
    getUseFilterStateInSession,
    onColumnStateChangedHelper,
    onFilterChangedHelper,
    onGridReadyHelper,
    onGridReadyHelperForColumnState, updateColumnModeInSessionHelper,
    updateUseColumnStateHelper,
    updateUseFilterStateHelper
} from "../../utils/gridFilterStateAndColumnStateHelper";
import CustomNameCellEditor from "../../utils/customCellEditor";
import DTPicker, {dateFilterParametersInHeaderSuppressSorting} from "../../utils/DTPicker";
import {GridColumnFilterStateSaving} from "../../components/columnfilterComponent";
import privatePageHeaderHelper from "../../utils/privatePageHeaderHelper";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";
import {
    ClickToShowColumnOptionsWithToggleButtonGroup,
    customColumnModeText,
    minColumnModeText, standardApplyMinimumOrMediumColumnMode
} from "../../components/clickToShowButtons";

let keysToRefresh = ["dateCompleted", "initialTaskType", "currentTaskProgress", "dateCreated"]

let initialTaskTypeValues = ["RECEIVE_CLIENT_ID","RECEIVE_OAUTH_TOKEN","UNINSTALL", "UNKNOWN", "RESET_ATOMIC_COUNTS"]
let currentTaskProgressValues = ["COMPLETE", "FAILED","QUEUED", "CANCELLED"]

let gridColumnStateSessionVariableName = "tasksGridColumnState"
let minColumnIds = ["zenGroupDisplayName", "agentDisplayName", "initialTaskType", "currentTaskProgress", "dateCreated"]
let medColumnIds = ["zenGroupDisplayName", "agentDisplayName", "initialTaskType", "currentTaskProgress", "dateCreated"] //The medium option is disabled for this grid so just re-using same columns to avoid a npe
export default function Tasks() {
    const [isLoading, setIsLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [useFilterStateSettingToggled, setUseFilterStateSettingToggled] = useState(getUseFilterStateInSession("tasksGridFilterState"));
    const [useColumnStateSettingToggled, setUseColumnStateSettingToggled] = useState(getUseColumnStateInSession(gridColumnStateSessionVariableName));
    const [columnMode, setColumnMode] = useState(getColumnModeInSession(gridColumnStateSessionVariableName));
    // eslint-disable-next-line no-unused-vars
    const [columnDefs, setColumnDefs] = useState([
        defaultZenGroupColumnInitWithOptionsWithValueGetter(true, true, true),
        { field: "zenGroupId", hide: true, suppressColumnsToolPanel: true, lockVisible: true},
        defaultAgentNameColumnAndMachineNameInitWithFilters,
        defaultMachineNameColDefWithFilters,
        { field: "initialTaskType", headerName: "Initial Task Type", width: 250,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: initialTaskTypeValues,
                valueFormatter: (params) => {
                    if(params.value!=="(Select All)"){
                        return getTaskTypeValueFormatted(params.value)
                    }
                    else{
                        return params.value;
                    }
                },
                suppressSorting: false,
                suppressSelectAll: false,
            },
            valueFormatter: (params) => {
                return getTaskTypeValueFormatted(params.value)
            },
            sortable: true
        },
        { field: "currentTaskProgress", headerName: "Current Task Status", width: 250,
            filter: 'agSetColumnFilter',
            filterParams: {
                buttons: ["reset", "apply", "cancel"],
                values: currentTaskProgressValues,
                valueFormatter: (params) => {
                    if(params.value!=="(Select All)"){
                        return getTaskTypeValueFormatted(params.value)
                    }
                    else{
                        return params.value;
                    }
                },
                suppressSorting: false,
                suppressSelectAll: false,
            },
            valueFormatter: (params) => {
                return getTaskTypeValueFormatted(params.value)
            },
            sortable: true
        },
        { field: "dateCreated", headerName: "Date Created", width: 300,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
        { field: "dateCompleted", headerName: "Date Completed", width: 300,
            filter: 'agDateColumnFilter',
            filterParams:dateFilterParametersInHeaderSuppressSorting,
            sortable: true,
            valueFormatter: dateValueFormatter
        },
    ])
    const [defaultColDef, setDefaultColDef] = useState(
        {
            resizable: true,
            filterParams: null,
            floatingFilter: true,
            headerClass: "border-0 border-b-0",
            cellClass: "outline:none",
            enableCellChangeFlash: true,
            autoHeight: false,
            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
        }
    )
    const sideBar = useMemo(() => {
        //Inside useMemo to help prevent the sidebar from re-rendering
        return getDefaultAgGridSidebarProps()
    }, []);
    return (
        <div className="flex flex-col h-full">
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <title>Agent Tasks</title>
                <script src="https://js.stripe.com/v3/"/>
                <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap" rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>
            <div className="flex flex-1 flex-row h-full overflow-y-auto">
                <SidebarMenu setIsLoading={setIsLoading}/>
                <div className="flex flex-1 flex-col mt-8 ml-5 mr-10 gap-y-3 h-full">
                    {privatePageHeaderHelper("Agent Tasks")}
                    <hr className="bg-black h-0.5" />
                    <div className="flex flex-row justify-between gap-x-1 gap-y-3">
                        <div className={"self-end flex flex-col gap-y-3"}>
                            <GridColumnFilterStateSaving
                                useFilterStateSettingToggled = {useFilterStateSettingToggled}
                                setUseFilterStateSettingToggled = {setUseFilterStateSettingToggled}
                                toggleUpdateUseFilterState = {toggleUpdateUseFilterState}
                                useColumnStateSettingToggled = {useColumnStateSettingToggled}
                                setUseColumnStateSettingToggled = {setUseColumnStateSettingToggled}
                                toggleUpdateUseColumnState = {toggleUpdateUseColumnState}/>
                        </div>
                        <div className={"flex flex-row flex-wrap gap-y-3 gap-x-8 self-end justify-end"}>
                            <ClickToShowColumnOptionsWithToggleButtonGroup
                                columnMode={columnMode} setColumnMode={setColumnMode} gridColumnStateSessionVariableName={gridColumnStateSessionVariableName} gridApi={gridApi}
                                minColumnIds={minColumnIds} medColumnIds={medColumnIds} disableMediumOption={true} updateGridColumnModeFunction={updateTasksGridColumnModeReactive} />
                            <ClearRefresh gridApi = {gridApi}
                                          refreshGridFunction = {resetGrid} showExcelExportIcon={false} />
                        </div>
                    </div>
                    <div className="h-full flex flex-col gap-y-5" id="gridRoot">
                        {getGrid()}
                        <Footer />
                    </div>
                </div>
            </div>
            <NotificationContainer />
        </div>
    );

    function toggleUpdateUseFilterState(toggleSetting){
        updateUseFilterStateHelper(toggleSetting, 'tasksGridFilterState', updateTasksGridUseFilterStateReactive);
    }
    function toggleUpdateUseColumnState(toggleSetting){
        updateUseColumnStateHelper(toggleSetting, gridColumnStateSessionVariableName, updateTasksGridUseColumnStateReactive);
    }

    function getGrid(){
        return (
            <Grid
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                sideBar={sideBar}
                setGridApi={setGridApi}
                columnMode={columnMode}
                setColumnMode={setColumnMode}
            />
        );
    }

    function getTaskTypeValueFormatted(value){
        switch(value) {
            case "RECEIVE_CLIENT_ID":
                return "Receive Client Id"
            case "RECEIVE_OAUTH_TOKEN":
                return "Receive OAuth Token"
            case "COMPLETE":
                return "Complete"
            case "FAILED":
                return "Failed"
            case "UNINSTALL":
                return "Uninstall"
            case "QUEUED":
                return "Queued"
            case "UNKNOWN":
                return "Unknown"
            case "CANCELLED":
                return "Cancelled"
            case "RESET_ATOMIC_COUNTS":
                return "Reset Atomic Counts"
            default:
                return value
        }
    }
    async function resetGrid(){
        await refreshGridZenGroupAndAgentInformation(gridApi, "taskId", "id", findByTaskIdListReactive, keysToRefresh, "agentId","machineName")
    }
}

class Grid extends Component {
    constructor(props, onClickRow, filterVals) {
        super(props);
    }
    onFirstDataRendered = (params) => {
        //params.api.sizeColumnsToFit();
        /*params.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            params.api.refreshCells({force: true, columns: ["zenGroupDisplayName"]})
        });*/
    };

    onColumnStateChanged = (params) => {
        //function to handle when column state changes: sort change, column visibility changes, or a column position on grid is moved
        if(params.source !== "api" && params.source !== "gridOptionsChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateTasksGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateTasksGridColumnModeReactive, customColumnModeText)
        }
        else if(params.source === "api" && params.type === "sortChanged"){
            this.props.setColumnMode && this.props.setColumnMode(customColumnModeText)
            onColumnStateChangedHelper(params, gridColumnStateSessionVariableName, updateTasksGridColumnStateReactive)
            updateColumnModeInSessionHelper(gridColumnStateSessionVariableName, updateTasksGridColumnModeReactive, customColumnModeText)
        }
    }

    getContextMenuItems = (params) => {
        return [
            "resetColumns",
            "autoSizeAll"
        ];
    };

    onGridReady = async (gridReadyParams) => {
/*        gridReadyParams.api.getFilterInstance("zenGroupDisplayName", filterInstance => {
            gridReadyParams.api.refreshCells({force: true, columns: ["zenGroupDisplayName"]})
        });*/
        this.gridApi = gridReadyParams.api;
        this.props.setGridApi(gridReadyParams.api);
        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
        onGridReadyHelper(gridReadyParams, "tasksGridFilterState");

        let columnMode = this.props.columnMode
        //check which initial column mode to apply
        if(columnMode === customColumnModeText){
            onGridReadyHelperForColumnState(gridReadyParams, gridColumnStateSessionVariableName)
        }
        else if(columnMode === minColumnModeText){
            standardApplyMinimumOrMediumColumnMode(gridColumnStateSessionVariableName, gridReadyParams.api, this.props.setColumnMode, minColumnModeText, minColumnIds, updateTasksGridColumnModeReactive)
        }
        //Medium not allowed for this grid, only min/max/custom
        //else if columnMode is max then the default column state already shows the max amount of columns no need to update

        var dataSource = {
            getRows: async function (params) {
                try{
                    let start = params.request.startRow;
                    let end = params.request.endRow;
                    let filters = masterFilterHelper(params.request.filterModel)
                    let sortModel = params.request.sortModel;
                    let sortModelForRequest = null;
                    if(sortModel && sortModel.length > 0){
                        try{ //extra precaution for array access on the sort model so it does not break
                            if(sortModel[0].colId && sortModel[0].sort){
                                sortModelForRequest = sortModel[0]
                            }
                        }
                        catch(error){
                            console.log("Error generating sort request")
                        }
                    }
                    let count = end - start;
                    let page = Math.floor(start / count)
                    let objects = await tasksListReactive(count, page, filters, sortModelForRequest);
                    let finalPage = true
                    //This logic mimics how we would check in the rest api for what to send for the finalPage in the response. Same logic is being used here so we can just return a flux from the
                    // endpoint instead of Mono with a Response object
                    if(objects && objects.length === count){
                        finalPage = false
                    }
                    let lastIdx = undefined
                    if (finalPage) {
                        lastIdx = (count * page) + objects.length
                    }

                    params.api.deselectAll();

                    const success = (objects.length !== 0);
                    if (success) {
                        params.api.hideOverlay()
                        params.success({
                            rowData: objects, rowCount: lastIdx
                        })
                    } else {
                        //params.api.showNoRowsOverlay();
                        params.success({
                            rowData: objects, rowCount: lastIdx
                        }) //to stop loading circle from spinning and make it disappear, nothing in objects.
                        params.fail();
                    }

                }
                catch(error){
                    //console.log(error);
                    params.api.showNoRowsOverlay();
                    params.fail();
                }
            }
        }
        gridReadyParams.api.setGridOption("serverSideDatasource", dataSource);
        //gridReadyParams.api.sizeColumnsToFit()
    };
    render() {
        return (
            <div className={"w-full h-full"} style={{minHeight: "400px"}}>
                <div id="myGrid" className="ag-theme-alpine rounded-md shadow h-full w-full">
                    <AgGridReact
                        modules={[ServerSideRowModelModule, MenuModule, ColumnsToolPanelModule, SetFilterModule]} //ExcelExportModule
                        defaultColDef={this.props.defaultColDef}
                        columnDefs={this.props.columnDefs}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        suppressMultiSort={true}
                        components={{agDateInput: DTPicker, customNameCellEditor: CustomNameCellEditor}}
                        rowModelType={'serverSide'}
                        onGridReady={this.onGridReady}
                        onCellEditingStopped={agentDisplayNameAndZenGroupDisplayNameOnlyCellEditingStopped}
                        rowSelection={'single'}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                        onFirstDataRendered={this.onFirstDataRendered.bind(this)}
                        tooltipMouseTrack={true }
                        onFilterChanged={(params)=> {
                            onFilterChangedHelper(params, 'tasksGridFilterState', updateTasksGridFilterModelReactive);
                        }}
                        //columnState listeners
                        onSortChanged={this.onColumnStateChanged}
                        onColumnMoved={this.onColumnStateChanged}
                        onColumnVisible={this.onColumnStateChanged}
                        getContextMenuItems={this.getContextMenuItems}
                        sideBar={this.props.sideBar}
                    />
                </div>
            </div>
        );
    }
}
