import React, {useState} from "react";
import {Helmet} from "react-helmet";
import Header from "../../components/header";
import Footer, {NonGridPageFooter} from "../../components/footer";
import "../../css/system-styles.css";
import "../../css/colors.css";
import "../../css/typography.css";
import "../../css/buttons.css";
import "../../css/web-pages.css";
import {BackDropPageLoadingOverlay} from "../../components/BackDropComponents";

function TermsOfService() {
    const [isLoading, setIsLoading] = useState(false);

    return (
        <div className="application">
            <Helmet>
                <meta charSet="utf-8"/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <meta name="description" content="Cyber Crucible Terms of Use"/>
                <meta name="keywords" content=""/>
                <meta name="author" content=""/>
                <title>Cyber Crucible Terms of Use</title>
                <script src="https://js.stripe.com/v3/"/>
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap"
                    rel="stylesheet"/>
            </Helmet>
            <BackDropPageLoadingOverlay opened={isLoading}/>
            <Header setIsLoading={setIsLoading}/>

            {/*Title Banner*/}
            <div className="banner-contact">
                <div className="py-20">
                    <div className="container text-center mx-auto px-6">
                        <h2 className="text-4xl font-bold mb-2 text-white">
                            Terms of Use
                        </h2>
                        <h3 className="text-2xl mb-8 text-gray-200">
                            Version: 01.2024
                        </h3>
                    </div>
                </div>
            </div>

            <section className="textContentSectionWrapper mx-auto">
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Introduction</h2>
                    <p className="textContentParagraph">These Terms of Use outline the rules and regulations for the use
                        of CyberCrucible, Inc.&#x27;s website, located at <a
                            href="https://www.cybercrucible.com" className="linkColor" target="_blank">https://www.cybercrucible.com</a>.<br/>‍<br/>By
                        accessing this website you agree to accept these terms. Do not continue to use
                        CyberCrucible&#x27;s website if you do not agree to all the terms stated on this page.<br/><br/>The
                        following terminology applies to these Terms of Use, Privacy Statement and Disclaimer Notices
                        and all Agreements: &quot;Client&quot;, &quot;You&quot; and &quot;Your&quot; refers to you, the
                        person logging onto or visiting this website and compliant to Company’s Terms of
                        Use. &quot;Company&quot;, &quot;Ourselves&quot;, &quot;We&quot;, &quot;Our&quot; and &quot;Us&quot;,
                        refers to CyberCrucible, Inc. &quot;Party&quot;, &quot;Parties&quot;, or &quot;Us&quot;, refers
                        to both Client and Company. All terms refer to the offer, acceptance, and consideration of
                        payment necessary to undertake the process of Company&#x27;s assistance to Client in the most
                        appropriate manner for the express purpose of meeting Client’s needs in respect of provision of
                        Company’s stated services, in accordance with and subject to, prevailing law of Netherlands. Any
                        use of the above terminology or other words in the singular, plural, capitalization, and/or
                        he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Cookies</h2>
                    <p className="textContentParagraph">CyberCrucible, Inc. employs the use of cookies. By accessing
                        Company&#x27;s website, you consent to the use of cookies in agreement with CyberCrucible,
                        Inc.&#x27;s <a href="https://dashboard.cybercrucible.com/privacy-policy" className="in-line-link linkColor">Privacy Policy</a>.<br/><br/>Most
                        interactive websites use cookies to let the owner retrieve the user’s details for each visit.
                        Cookies are used by Company&#x27;s website to enable the functionality of certain tools that
                        make it easier for people visiting the website to maintain a positive experience. Some of the
                        company&#x27;s affiliate/advertising partners may also use cookies.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">License</h2>
                    <p className="textContentParagraph">Unless otherwise stated, Company and/or its licensors own the
                        intellectual property rights for all material on Company&#x27;s website. All intellectual
                        property rights are reserved. You may access this from Company&#x27;s website for your own
                        personal use, subject to restrictions set in these terms.<br/>‍<br/>You must not:<br/></p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>Republish material from Company&#x27;s website</li>
                        <li>Sell, rent, or sub-license material from this website</li>
                        <li>Reproduce, duplicate, or copy material from CyberCrucible website<br/></li>
                        <li>Redistribute content from this website<br/></li>
                    </ul>
                    <p className="textContentParagraph">This agreement shall begin on the date hereof. These terms were
                        created with the help of the <a href="https://www.termsandconditionsgenerator.com/"
                                                        className="in-line-link linkColor">Free Terms and Conditions Generator</a>.<br/><br/>Parts
                        of this website offer an opportunity for users to post and exchange opinions and information.
                        Company does not filter, edit, publish, or review comments prior to their publication on this
                        website. Comments do not reflect the views and opinions of CyberCrucible, Inc.; its agents;
                        and/or its affiliates. Comments reflect the views and opinions of the person posting their views
                        and opinions. To the extent permitted by applicable laws, Company shall not be liable for the
                        comments or for any liability, damages, or expenses caused and/or suffered as a result of any
                        use of and/or posting of and/or appearance of these comments on this website.<br/><br/>Company
                        reserves the right to monitor all comments and to remove any comments which can be considered
                        inappropriate or offensive or which causes breach of these Terms of Use.<br/><br/>You warrant
                        and represent that:<br/></p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>You are entitled to post the comments on this website and have all necessary licenses and
                            consents to do so
                        </li>
                        <li>Said comments do not invade any intellectual property rights, including without limitation
                            copyright, patent, or trademark of any third party
                        </li>
                        <li>Comments do not contain any defamatory, libelous, offensive, indecent, or otherwise unlawful
                            material, which is an invasion of privacy<br/></li>
                        <li>Comments will not be used to solicit or promote business or custom or present commercial
                            activities or unlawful activity<br/></li>
                    </ul>
                    <p className="textContentParagraph">You hereby grant Company a non-exclusive license to use,
                        reproduce, edit, and authorize others to use, reproduce, and edit any of your comments in any
                        and all forms, formats, or media.<br/></p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Hyperlinking to website content</h2>
                    <p className="textContentParagraph">The following organizations may link to this website without
                        prior written approval:</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>Government agencies</li>
                        <li>Search engines</li>
                        <li>News organizations<br/></li>
                        <li>Online directory distributors may link to this website in the same manner as they hyperlink
                            to the websites of other listed businesses<br/></li>
                        <li>System-wide Accredited Businesses with the exception of soliciting non-profit organizations,
                            charity shopping malls, and charity fundraising groups, which may not hyperlink to this
                            website.<br/></li>
                    </ul>
                    <p className="textContentParagraph">These organizations may link to Company&#x27;s homepage,
                        publications, or other website information so long as the link: (a) is not in any way deceptive;
                        (b) does not falsely imply sponsorship, endorsement, or approval of the linking party, and its
                        products and/or services; and (c) fits within the context of the linking party’s site.<br/><br/>Company
                        may consider and approve other link requests from the following types of organizations:</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>commonly-known consumer and/or business information sources</li>
                        <li>dot.com community sites</li>
                        <li>associations or other groups representing charities<br/></li>
                        <li>online directory distributors<br/></li>
                        <li>internet portals<br/></li>
                        <li>accounting, law, and consulting firms<br/></li>
                        <li>educational institutions and trade associations<br/></li>
                    </ul>
                    <p className="textContentParagraph">Company will approve link requests from these organizations if
                        it is decided that: (a) the link would not make Company appear unfavorably to themselves or to
                        their accredited businesses; (b) the organization does not have any negative records with
                        Company; (c) the benefit to Company from the visibility of the hyperlink compensates the absence
                        of CyberCrucible, Inc.; and (d) the link is in the context of general resource information.<br/>‍<br/>These
                        organizations may link to Company homepage so long as the link: (a) is not in any way deceptive;
                        (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its
                        products or services; and (c) fits within the context of the linking party’s site.<br/><br/>If
                        you are one of the organizations listed in paragraph two above and are interested in linking to
                        this website, you must inform CyberCrucible, Inc. by sending an email to Company. Please include
                        your name, organization name, contact information, and the URL of your site. Please also include
                        a list of any URLs from which you intend to link to this website as well as a list of the URLs
                        on this site to which you would like to link. Please wait 2-3 weeks for a response.<br/><br/>Approved
                        organizations may hyperlink to this website as follows:</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>By use of CyberCrucible, Inc.&#x27;s corporate name</li>
                        <li>By use of the uniform resource locator being linked to</li>
                        <li>By use of any other description of Company website being linked to that makes sense within
                            the context and format of content on the linking party’s site<br/></li>
                    </ul>
                    <p className="textContentParagraph">No use of Cyber Crucible, Inc.&#x27;s logo or other artwork will
                        be permitted for linking absent a trademark license agreement.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">iFrames</h2>
                    <p className="textContentParagraph">Without prior approval and written permission, you may not
                        create frames around Company webpages that alter in any way the visual presentation or
                        appearance of this website.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Content liability</h2>
                    <p className="textContentParagraph">Company shall not be held responsible for any content that
                        appears on your website. You agree to protect and defend Company against all claims that arise
                        on your website. No link(s) should appear on any website that may be interpreted as libelous,
                        obscene, or criminal, or which infringes, otherwise violates, or advocates the infringement or
                        other violation of, any third party rights.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Your privacy</h2>
                    <p className="textContentParagraph">Please read Company&#x27;s <a
                        href="https://dashboard.cybercrucible.com/privacy-policy" className="in-line-link linkColor">Privacy
                        Policy</a>.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                <h2 className="textContentH2">Reservation of rights</h2>
                    <p className="textContentParagraph">Company reserves the right to request that you remove all links
                        or any particular link to this website. You agree to immediately remove specified links to this
                        website upon request. Company also reserves the right to amend these Terms of Use and this
                        linking policy at any time. By continuously linking to this website, you agree to be bound to
                        and follow these linking Terms of Use.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Removal of links from Company website</h2>
                    <p className="textContentParagraph">If you find any link on this website offensive for any reason,
                        you are free to contact and inform Company at any time. Company will consider requests to remove
                        links but is not obligated to or so nor obligated to respond to the request directly.<br/><br/>Company
                        does not ensure that the information on this website is correct, does not warrant its
                        completeness or accuracy, nor promises to ensure that the website remains available or that the
                        material on the website is kept up-to-date.</p>
                </div>
                <div className="textContentHeaderAndParagraphFlex">
                    <h2 className="textContentH2">Disclaimer</h2>
                    <p className="textContentParagraph">To the maximum extent permitted by applicable law, Company
                        excludes all representations, warranties, and conditions relating to this website and the use of
                        such. Nothing in this disclaimer will:</p>
                    <ul className={"list-disc list-inside pl-7"}>
                        <li>limit or exclude Company or your liability for death or personal injury</li>
                        <li>limit or exclude Company or your liability for fraud or fraudulent misrepresentation</li>
                        <li>limit any of Company&#x27;s or your liabilities in any way that is not permitted under
                            applicable law
                        </li>
                        <li>exclude any of Company&#x27;s or your liabilities that may not be excluded under applicable
                            law
                        </li>
                    </ul>
                    <p className="textContentParagraph">The limitations and prohibitions of liability set in this
                        section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b)
                        govern all liabilities arising under the disclaimer, including liabilities arising in contract,
                        in tort, and for breach of statutory duty.<br/><br/>As long as this website and the information
                        and services on the website are provided free of charge, Company will not be liable for any loss
                        or damage of any nature.</p>
                </div>
            </section>


            <NonGridPageFooter/>

        </div>
    )
}

export default TermsOfService
