import React from "react";

function Header({setIsLoading}) {
    return (<div></div>)
}

// function Header({setIsLoading}) {
//     const [loggedUser, setLoggedUser] = React.useState();
//     const [showLoggedInOptions, setShowLoggedInOptions] = useState(false);
//     const [isPartner, setIsPartner] = useState(false);
//     const [showAccountIcon, setShowAccountIcon] = useState(false);
//     const [distributionGroups, setDistributionGroups] = useState([]);
//     const [partnerPurchaseModalOpen, setPartnerPurchaseModalOpen] = useState(false);
//     const [showStripeLoggedInModal, setShowStripeLoggedInModal] = useState(false);
//     const [showPurchaseLicenses, setShowPurchaseLicenses] = useState(false);
//     const [zenGroupSessionStorage,setZenGroupSessionStorage] = useZenGroupSessionStorage()
//     const [showCollectipede, setShowCollectipede] = useState(false);
//
//     const trackingCode = process.env.REACT_APP_GA_ID
//
//     const logOut = async () => {
//         await Auth.signOut();
//         handleLogout()
//     };
//
//     //initialize google analytics and set pageview, and checkRefreshToken()
//     useEffect(() => {
//         //ReactGA initialized in App.js
//         try{
//             ReactGA.initialize(trackingCode);
//             ReactGA.set({ page: window.location.pathname});
//             ReactGA.pageview(window.location.pathname);
//         }
//         catch(error){
//             console.log(error)
//         }
//         checkRefreshToken();
//     }, [])
//     //user logged in check
//     useEffect(() => {
//         async function auth() {
//             let path = window.location.pathname
//             let privatePage = false;
//             if(/^\/private\//.test(path)){ //check if we are on a private page
//                 privatePage = true
//             }
//             setShowLoggedInOptions(!privatePage)
//             try {
//                 let refreshToken = decryptAndGetSessionVariable("refresh_token")
//                 if(refreshToken !== null){
//                     //user logged in
//                     loadJiraScript() //loads jira script if it's not loaded already on page
//                     //console.log("refresh token found, user logged in")
//                     if(!loggedUser)
//                         setLoggedUser(true)
//                     if(/^\/login/.test(path)){ //check if we are on the login page
//                         window.location.href='./private/agents'; //take user to agents page
//                     }
//                 }
//                 else{
//                     //user not logged in
//                     unloadJiraScript() //hide jira widget if it does exist when a user is not logged in
//                     //console.log("refresh token not found, user not logged in")
//                     setLoggedUser(false)
//                     //TODO: is there a better way to redirect users to another page?
//                     if(privatePage){
//                         window.location.href='.././'; //take user back to home page
//                     }
//
//                 }
//             } catch (error) {
//                 if(privatePage){
//                     window.location.href='.././'; //take user back to home page
//                 }
//             }
//         }
//         auth();
//         //!collapsed && window.addEventListener("scroll", handleScroll);
//     }, []);
//     //isPartner check
//     useEffect(() => {
//         if(loggedUser){
//             groupListForCCUsersOnDealsPage().then(result => {
//                 encryptAndStoreSessionVariable("ccUsersDealsZenGroupSessionStorage", JSON.stringify(result));
//             }).catch(error => {
//                 encryptAndStoreSessionVariable("ccUsersDealsZenGroupSessionStorage", JSON.stringify([]));
//             })
//             getAndStoreAllRoleNamesOfCurrentUserInSession()
//
//             getGroupsWithChannelPartnerInformationForUser().then(result => {
//                 setZenGroupSessionStorage(result.zenGroups)
//                 const zenGroups = result.zenGroups
//                 let isPartner = false;
//                 for (let idx in zenGroups) {
//                     let group = zenGroups[idx]
//                     if (group.channelPartner) {
//                         isPartner = true;
//                         break;
//                     }
//                 }
//
//                 if(result.username){
//                     //stored in session already from login
//                     //storeWithoutEncrypting("username",result.username)
//                     if(result.username.endsWith("@cybercrucible.com")){
//
//                         isPartner = true
//                     }
//                 }
//                 if(result.username === "mark.weideman@cybercrucible.com"){
//                     setShowCollectipede(true)
//                 }
//                 else{
//                     setShowCollectipede(false)
//
//                     let path = window.location.pathname
//                     //check if user is on this collectipede page and is not allowed to be
//                     if(/^\/private\/collectipede$/.test(path)){ //check if we are on collectipede page
//                         //take them to agents page
//                         window.location.href='./agents';
//                     }
//                 }
//                 encryptAndStoreSessionVariable("isPartner","true")
//                 setIsPartner(isPartner);
//                 let isMemberOfChannelPartnerCustomerGroup = result.isMemberOfChannelPartnerCustomerGroup
//                 let isMemberOfChannelPartnerGroup = result.isMemberOfChannelPartnerGroup
//                 if(isMemberOfChannelPartnerCustomerGroup){
//                     //we need to check if they are also a part of a channelPartner group
//                     if(isMemberOfChannelPartnerGroup){
//                         //good to show
//                         setShowPurchaseLicenses(true)
//                     }
//                     else{
//                         //don't allow tabs to show
//                         setShowPurchaseLicenses(false)
//                     }
//                 }
//                 else{
//                     //good to show
//                     setShowPurchaseLicenses(true)
//                 }
//                 if(!isPartner){
//                     let path = window.location.pathname
//                     let onDealsPage = false;
//                     if(/^\/private\/partnerRegisterDeal$/.test(path)){ //check if we are on partnerRegisterDeal page
//                         onDealsPage = true
//                     }
//                     if(onDealsPage){
//                         window.location.href='./agents'; //take user to agents page since they should not be on the deals page
//                     }
//                     if(decryptAndGetSessionVariable("isPartner") === "true"){
//                         encryptAndStoreSessionVariable("isPartner","false")
//                     }
//                 }
//             }).catch(function (error) {
//                 setIsPartner(false);
//                 setShowPurchaseLicenses(false)
//                 setZenGroupSessionStorage(null)
//                 encryptAndStoreSessionVariable("isPartner","false")
//             })
//         }
//         else{
//
//         }
//     }, [loggedUser]);
//
//     function loadJiraScript(){
//         //jira script for widget
//         try{
//             let jiraWidgetScript = document.createElement('script');
//             jiraWidgetScript.type = 'text/javascript';
//             jiraWidgetScript.id = "jiraHelpDeskWidget";
//             jiraWidgetScript.setAttribute('data-jsd-embedded', null);
//             jiraWidgetScript.setAttribute('data-key', '93b9f178-ca0f-47e9-bb55-4a681006da70');
//             jiraWidgetScript.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
//             jiraWidgetScript.src='https://jsd-widget.atlassian.com/assets/embed.js';
//             if(jiraWidgetScript.readyState) { // old IE support
//                 jiraWidgetScript.onreadystatechange = function() {
//                     if ( jiraWidgetScript.readyState === 'loaded' || jiraWidgetScript.readyState === 'complete' ) {
//                         jiraWidgetScript.onreadystatechange = null;
//                         jiraHelpdesk();
//                     }
//                 };
//             } else { //modern browsers
//                 jiraWidgetScript.onload = function() {
//                     jiraHelpdesk();
//                 };
//             }
//             if (!document.getElementById('jiraHelpDeskWidget')) {
//                 document.getElementsByTagName('head')[0].appendChild(jiraWidgetScript);
//                 window.document.dispatchEvent(new Event("DOMContentLoaded", {
//                     bubbles: true,
//                     cancelable: true
//                 }));
//             }
//
//             var jiraHelpdesk =(function() {
//                 window.document.dispatchEvent(new Event("DOMContentLoaded", {
//                     bubbles: true,
//                     cancelable: true
//                 }));
//             });
//         }
//         catch(error){
//             //console.log(error)
//         }
//     }
//
//     function unloadJiraScript(){
//         let iframe = document.querySelector("#jsd-widget");
//         //hide widget if it exists
//         if(iframe){
//             iframe.style.display = 'none';
//         }
//     }
//
//     return (
//         <div>
//             <Helmet>
//                 <script>{`var DID=260241;`}</script>
//                 <script type="text/javascript">{`
//                         linkedin_partner_id = "1395570";
//                         window.linkedin_data_partner_ids = window.linkedin_data_partner_ids || [];
//                         window.linkedin_data_partner_ids.push(linkedin_partner_id);
//                 `}</script>
//                 <noscript>{`<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1395570&fmt=gif" />`}
//                 </noscript>
//             </Helmet>
//             <SessionTimer loggedUser={loggedUser}/>
//             <PartnerStripeModal
//                 onClose={() => {
//                     setPartnerPurchaseModalOpen(false)
//                 }}
//                 setIsLoading={setIsLoading}
//                 opened={partnerPurchaseModalOpen}
//                 newDealData={null}
//                 zenGroups={distributionGroups}
//             />
//             <StripeLoggedInModalSetup
//                 onClose={() => {
//                     setShowStripeLoggedInModal(false)
//                 }}
//                 setIsLoading={setIsLoading}
//                 opened={showStripeLoggedInModal}
//                 setIsOpened={setShowStripeLoggedInModal}
//             />
//         <header className="bg-white text-gray-600 body-font xl:block lg:block md:hidden sm:hidden xs:hidden">
//             <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row items-center">
//                 <a className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
//                     <img className="logo" src="/images/logo/CyberCrucibleLogo-web.png" alt="Cyber Crucible Logo" />
//                 </a>
//                 {/*<nav id="main-navigation" className="md:mr-auto md:ml-4 md:py-1 md:pl-4 flex inline-flex items-center text-base justify-center">*/}
//                 {/*    <ul className="mt-4 ml-10">*/}
//                 {/*        <li><NavLink exact activeClassName="active" className="mr-10 tracking-wider" to="/">Home</NavLink></li>*/}
//                 {/*        <li><NavLink activeClassName="active" className="mr-10 tracking-wider" to="/about">About Us</NavLink></li>*/}
//                 {/*        <li>*/}
//                 {/*            <Link className="mr-10 tracking-wider" to="/ransomware-rewind">Products</Link>*/}
//                 {/*            <ul className="absolute top-9 mt-5 p-2 shadow-lg bg-white z-10">*/}
//                 {/*                <li className="p-2 whitespace-no-wrap text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100">*/}
//                 {/*                    <NavLink className="px-2 py-1" to="/ransomware-rewind">*/}
//                 {/*                        <span className="tracking-wider">Ransomware Prevention</span>*/}
//                 {/*                    </NavLink>*/}
//                 {/*                </li>*/}
//                 {/*                <li className="p-2 whitespace-no-wrap text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100">*/}
//                 {/*                    <NavLink className="px-2 py-1" to="/recovery-tech">*/}
//                 {/*                        <span className="tracking-wider">Ransomware Decryption</span>*/}
//                 {/*                    </NavLink>*/}
//                 {/*                </li>*/}
//                 {/*            </ul>*/}
//                 {/*        </li>*/}
//
//                 {/*        <li>*/}
//                 {/*            <Link className="mr-10 tracking-wider" to="/msp-mssp">Partner</Link>*/}
//                 {/*            <ul className="absolute top-9 mt-5 p-2 shadow-lg bg-white z-10">*/}
//                 {/*                <li className="p-2 whitespace-no-wrap text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100">*/}
//                 {/*                    <NavLink className="px-2 py-1" to="/msp-mssp">*/}
//                 {/*                        <span className="tracking-wider">For MSP/MSSP Partners</span>*/}
//                 {/*                    </NavLink>*/}
//                 {/*                </li>*/}
//                 {/*                <li className="p-2 whitespace-no-wrap text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100">*/}
//                 {/*                    <NavLink className="px-2 py-1" to="/tech-partners">*/}
//                 {/*                        <span className="tracking-wider">For Tech Partners</span>*/}
//                 {/*                    </NavLink>*/}
//                 {/*                </li>*/}
//                 {/*            </ul>*/}
//                 {/*        </li>*/}
//
//                 {/*        <li>*/}
//                 {/*            <Link className="mr-10 tracking-wider" to="/white-pages">Resources</Link>*/}
//                 {/*            <ul className="absolute top-9 mt-5 p-2 shadow-lg bg-white z-10">*/}
//                 {/*                <li className="p-2 whitespace-no-wrap text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100">*/}
//                 {/*                    <NavLink className="px-2 py-1" to="/white-pages">*/}
//                 {/*                        <span className="tracking-wider">White Pages</span>*/}
//                 {/*                    </NavLink>*/}
//                 {/*                </li>*/}
//                 {/*                <li className="p-2 whitespace-no-wrap text-sm md:text-base text-gray-600 hover:text-gray-800 hover:bg-gray-100">*/}
//                 {/*                    <NavLink className="px-2 py-1" to="/videos">*/}
//                 {/*                        <span className="tracking-wider">Videos</span>*/}
//                 {/*                    </NavLink>*/}
//                 {/*                </li>*/}
//                 {/*            </ul>*/}
//                 {/*        </li>*/}
//
//                 {/*        <li><NavLink activeClassName="active" className="mr-10 tracking-wider" to="/contact">Contact Us</NavLink></li>*/}
//                 {/*    </ul>*/}
//                 {/*</nav>*/}
//                 &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
//                 &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;&nbsp;
//                 <div className={`${loggedUser ? "block" : "hidden"}`}>
//                     <div className={`${showLoggedInOptions ? "block" : "hidden"}`}>
//                         <PostLoginTabsForHeader isPartner={isPartner} setDistributionGroups={setDistributionGroups} setPartnerPurchaseModalOpen={setPartnerPurchaseModalOpen}
//                                                 setShowStripeLoggedInModal={setShowStripeLoggedInModal} showPurchaseLicenses={showPurchaseLicenses} showCollectipede={showCollectipede}
//                         />
//                     </div>
//                     <div className={`${showLoggedInOptions ? "hidden" : "block"}`}>
//                         <button
//                             className={`mx-8 rounded-full mt-4 mr-36 fa-2x
//                         focus:outline-none focus:shadow-outline`}
//                             onClick={() => {setShowAccountIcon(!showAccountIcon)}}
//                         >
//                             <FontAwesomeIcon
//                                 className="mr-5"
//                                 icon="fa-duotone fa-circle-user"
//                             />
//                         </button>
//                         <div
//                             className={`${showAccountIcon ? "block" : "hidden"}
//                                 bg-white m-10 mt-0 mb-1 absolute`}>
//                             <NavLink activeClassName="active" className="mr-10 tracking-wider" to="/private/settings">
//                                 <button
//                                     className="focus:outline-none p-5 downloadAgentButton w-full text-left">
//                                     Account Settings
//                                 </button>
//                             </NavLink>
//
//                             <br />
//                             <button
//                                 onClick={logOut}
//                                 className="focus:outline-none p-5 downloadAgentButton w-full text-left">
//                                 Logout
//                             </button>
//
//                         </div>
//
//                     </div>
//
//                 </div>
//                 <div className={`${loggedUser ? "hidden" : "block"}`}>
//                     <NavLink activeClassName="active" className="mr-10 tracking-wider" to="/createAccount">
//                         <button id="signUp" className="mr-3 hover:underline border-theme text-theme font-bold tracking-wider rounded-full mt-4
//                         lg:mt-0 py-4 px-8 focus:outline-none focus:shadow-outline hover:no-underline transform transition hover:scale-105 duration-300 ease-in-out">
//                             Sign Up
//                         </button>
//                     </NavLink>
//                     <NavLink activeClassName="active" className="mr-10 tracking-wider" to="/login">
//                         <button id="signIn" className="mx-auto lg:mx-0 hover:underline bg-theme text-white font-bold tracking-wider rounded-full mt-4 lg:mt-0 py-4 px-8 focus:outline-none focus:shadow-outline hover:no-underline transform transition hover:scale-105 duration-300 ease-in-out">
//                             Sign In
//                         </button>
//                     </NavLink>
//                 </div>
//
//             </div>
//         </header>
//             <div className="bg-white xl:hidden lg:hidden md:block sm:block xs:block">
//                 <div className="container items-center">
//                     <a className="title-font font-medium items-center text-gray-900">
//                         <img className="logo mobile-logo" src="/images/logo/CyberCrucibleLogo-web.png" alt="Cyber Crucible Logo" />
//                     </a>
//                     <MobileMenu isPartner={isPartner} loggedUser={loggedUser} setDistributionGroups={setDistributionGroups} setPartnerPurchaseModalOpen={setPartnerPurchaseModalOpen}
//                                 setShowStripeLoggedInModal={setShowStripeLoggedInModal} showPurchaseLicenses={showPurchaseLicenses} showCollectipede={showCollectipede}
//                     />
//                 </div>
//             </div>
//             <NotificationContainer />
//         </div>
//     )
//
// }

export default Header
