import React from "react";


function privatePageHeaderHelper(data){
    return(
        <div className="flex flex-row justify-start flex-nowrap gap-x-5 gap-y-3">
            <img
                src="/images/logo/result.svg"
                className="xl:max-w-[10%] md:max-w-[15%] sm:max-w-[15%] xs:hidden"
                alt=""
            />
            <div className="text-4xl self-center shrink-0">{data}</div>
        </div>
    )
}
export default privatePageHeaderHelper;