import Modal from "react-modal";
import React, {Component} from "react";
import {AgGridReact} from "@ag-grid-community/react";
import {ClientSideRowModelModule} from "@ag-grid-community/client-side-row-model";
import {roundButtonTheme} from "../utils/muiStyling";
import {Button, ThemeProvider} from "@mui/material";
import {MenuModule} from "@ag-grid-enterprise/menu";

Modal.setAppElement('#root')

export const ConfirmationModal = ({ opened, onClose, onConfirm, text }) => (
    <Modal contentLabel="Confirm" isOpen={opened} onRequestClose={onClose} shouldCloseOnOverlayClick={true}
           className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-lg inset-y-10 mx-auto rounded-2xl`}
           overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
    >
        <div className="flex flex-col p-5 pl-8 pr-8">
            <div className="flex-1 mb-6 text-xl">
                <small>{text}</small>
            </div>
            <div className="flex flex-row flex-wrap ml-2 w2">
                <ThemeProvider theme={roundButtonTheme}>
                    <Button variant={"contained"} color={"primary"} onClick={onConfirm}>
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                        Cancel
                    </Button>
                </ThemeProvider>
            </div>
        </div>
    </Modal>
);
export const ConfirmationModalWithPermissionsShown = ({ opened, onClose, onConfirm, text, groupNamesWithPermission, groupNamesWithoutPermission }) => (
    <Modal contentLabel="Confirm" isOpen={opened} onRequestClose={onClose} shouldCloseOnOverlayClick={true}
           className={`focus:outline-none focus:shadow-sm border-2 flex relative z-50 bg-white max-w-3xl inset-y-10 mx-auto rounded-2xl`}
           overlayClassName="z-50 bg-black bg-opacity-5 fixed inset-0 overflow-scroll"
    >
        <div className="flex flex-col p-5 w-full">
            <div className="px-3 flex-1 text-xl">
                <small>{text}</small>
            </div>
            <div className="flex flex-1 flex-col px-6 p-5">
                <PermissionsGrid
                    headers={[
                        { field: "zenGroupWithPermission", headerName: "Groups with permission", width: 300},
                        { field: "zenGroupWithoutPermission", headerName: "Groups without permission", width: 350}
                    ]}
                    data={processGroupPermissionData(groupNamesWithPermission, groupNamesWithoutPermission)}
                />
            </div>
            <div className="flex flex-row flex-wrap ml-5">
                <ThemeProvider theme={roundButtonTheme}>
                    <Button variant={"contained"} color={"primary"} onClick={onConfirm}
                            disabled={groupNamesWithPermission?.size < 1}
                    >
                        Confirm
                    </Button>
                    &nbsp;&nbsp;&nbsp;
                    <Button variant={"contained"} color={"secondary"} onClick={onClose}>
                        Cancel
                    </Button>
                </ThemeProvider>
            </div>
        </div>
    </Modal>
);

export function processGroupPermissionData(groupNamesWithPermission, groupNamesWithoutPermission){
    let data = []
    if(groupNamesWithPermission){
        groupNamesWithPermission.forEach(group => {
            data.push({"zenGroupWithPermission": group})
        })
    }
    if(groupNamesWithoutPermission){
        groupNamesWithoutPermission.forEach(group => {
            data.push({"zenGroupWithoutPermission": group})
        })
    }
    return data
};
export class PermissionsGrid extends Component {
    constructor(props, onClickRow, filterVals, headers, data) {
        super(props);
    }
    onFirstDataRendered = (params) => {
    };

    onGridReady = async (params) => {
        this.gridApi = params.api;

        // Disable text selection on the page while holding shift or control (to allow grid selections to be done easily without selecting all text)
        ["keyup","keydown"].forEach((event) => {
            window.addEventListener(event, (e) => {
                document.onselectstart = function() {
                    return !(e.shiftKey || e.ctrlKey);
                }
            });
        });
    }


    render() {
        return (
            <div className="mt-2">
                <div
                    id="myGrid"

                    className="ag-theme-alpine rounded-md shadow h-full w-full"
                >
                    <AgGridReact
                        defaultColDef={{
                            resizable: true,
                            filterParams: null,
                            headerClass: "border-0 border-b-0",
                            cellClass: "outline:none",
                            autoHeight: false,
                            cellDataType: false //disable inferring cell data type automatically, can be overridden in individual colDef
                        }}
                        columnDefs={this.props.headers}
                        suppressContextMenu={true}
                        suppressExcelExport={true}
                        suppressCsvExport={true}
                        modules={[ClientSideRowModelModule, MenuModule]}
                        domLayout="autoHeight"
                        headerHeight={50}
                        suppressMultiSort={true}
                        rowData={this.props.data}
                        rowSelection={'single'}
                        onGridReady={this.onGridReady}
                        onFilterChanged={(params) => {
                            params.api.deselectAll();
                        }}
                        onSelectionChanged={() => {
                            const selectedRows = this.gridApi.getSelectedRows();
                            this.props.onClickRow && this.props.onClickRow(selectedRows);
                        }}
                        enableCellTextSelection={true}
                        ensureDomOrder={true}
                    />
                </div>
            </div>
        );
    }
}
