import axios from "../../utils/axiosInstance";

export const getPartnerDealsReactive = async () => {
    const response = await axios.post("/getPartnerDealsReactive", {
    });
    return response.data;
};

export const newPartnerDealReactive = async (req) => {
    return await axios.post("/newPartnerDealReactive", req)
};
export const editPartnerDealReactive = async (req) => {
    return await axios.post("/editPartnerDealReactive", req)
};
export const disablePartnerDealReactive = async (dealId) => {
    return await axios.post("/disablePartnerDealReactive", {
        partnerDealIdToDisable: dealId,
    })
};
export const enablePartnerDealReactive = async (dealId) => {
    return await axios.post("/enablePartnerDealReactive", {
        partnerDealIdToEnable: dealId,
    })
};
export const changePartnerDealNameReactive = async (newName, partnerDealId) => {
    return await axios.post("/changePartnerDealNameReactive", {
        newName: newName,
        partnerDealId: partnerDealId
    });
};
export const removePartnerDealNameReactive = async (partnerDealId) => {
    return await axios.post("/removePartnerDealNameReactive", {
        partnerDealId: partnerDealId
    });
};

export const getDealsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getDealsGridFilterStateReactive", {});
    return response.data;
};

export const updateDealsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateDealsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateDealsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateDealsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getDealsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getDealsGridColumnStateReactive", {});
    return response.data;
};
export const updateDealsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateDealsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateDealsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateDealsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const updateDealsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateDealsGridColumnModeReactive", {
        columnMode: columnMode
    });
};

export const updateVolumePricingTableVisibilityReactive = async (visible) => {
    return await axios.post("/updateVolumePricingTableVisibilityReactive", {
        visible: visible
    });
};
export const ccApproveDealReactive = async (partnerDealId) => {
    return await axios.post("/ccApproveDealReactive", {
        partnerDealId: partnerDealId
    });
};
export const ccRenewDealReactive = async (partnerDealId) => {
    return await axios.post("/ccRenewDealReactive", {
        partnerDealId: partnerDealId
    });
};
export const ccRejectDealReactive = async (partnerDealId) => {
    return await axios.post("/ccRejectDealReactive", {
        partnerDealId: partnerDealId
    });
};

export const sendPartnerDealPricingQuotePDFReactive = async (partnerDealId, sendInvoiceToAllGroupMembers, showInternalPricingInQuote) => {
    return await axios.post("/sendPartnerDealPricingQuotePDFReactive", {
        partnerDealId: partnerDealId,
        sendInvoiceToAllGroupMembers: sendInvoiceToAllGroupMembers,
        showInternalPricingInQuote: showInternalPricingInQuote

    });
};

export const partnerDealRequestNet30PaymentReactive = async (partnerDealId) => {
    return await axios.post("/partnerDealRequestNet30PaymentReactive", {
        partnerDealId: partnerDealId
    });
};
export const partnerDealResendInvoiceReactive = async (partnerDealId) => {
    const response = await axios.post("/partnerDealResendInvoiceReactive", {
        partnerDealId: partnerDealId
    });
    return response.data;
};
export const updateReferralPartnerDealCCDashboardAccountReactive = async (partnerDealId, newCCDashboardAccountUsername) => {
    return await axios.post("/updateReferralPartnerDealCCDashboardAccountReactive", {
        partnerDealId: partnerDealId,
        newCCDashboardAccountUsername: newCCDashboardAccountUsername
    });
};
export const ccCreateStripeSubscriptionForDealReactive = async (partnerDealId, signedQuotePdf, daysUntilInvoiceDue) => {
    //have to take some extra steps in this request since we are sending a file along with partnerDealId
    const formData = new FormData()
    formData.append('signedQuotePdf', signedQuotePdf)
    formData.append('partnerDealId', partnerDealId)
    formData.append('daysUntilInvoiceDue', daysUntilInvoiceDue)
    const config  = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    return await axios.post("/ccCreateStripeSubscriptionForDealReactive", formData, config);
};

export const createPartnerPOCReactive = async (partnerDealId, trialLicenseDays, adminUsernamesToAddToGroup, userOnlyUsernamesToAddToGroup) => {
    const response = await axios.post("/createPartnerPOCReactive", {
        partnerDealId: partnerDealId,
        trialLicenseDays: trialLicenseDays,
        adminUsernamesToAddToGroup: adminUsernamesToAddToGroup,
        userOnlyUsernamesToAddToGroup: userOnlyUsernamesToAddToGroup
    });
    return response.data;
};