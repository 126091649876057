import {changeAgentAutoAssignLicenseInValidateReactive} from "../pages/api/agentsApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Link} from "react-router-dom";
import {getEditIconComponent} from "./customCellEditor";
import {decryptAndGetSessionVariable, encryptAndStoreSessionVariable} from "./storageHelper";
import {licensesNamesListWithAgentIdReactive} from "../pages/api/licensesApi";
import {Auth} from "aws-amplify";
import {fetchEventSource} from "@microsoft/fetch-event-source";
import {getDemoModeSetting, getTrainingModeSetting} from "./trainingAndDemoModeHelper";
import {Button, ThemeProvider, Tooltip} from "@mui/material";
import {cellButtonTheme} from "./muiStyling";
import {MuiAgentLicenseAutoAssignLicenseIconWithMenu, MuiIconButtonWithTooltip} from "../components/muiComponents";
import NotificationManager from "react-notifications/lib/NotificationManager"
import {defaultClientSideTextFilterParams} from "./filterHelper";
import {dateFilterParametersInHeaderClientSideGrid} from "./DTPicker";
import {dateValueFormatter} from "./gridDateFormatter";

export const tempLicenseTextToShowInCell = "True-up License Issued"
export const defaultLicenseColumnWithAssignLicenseToAgentOptionCellRendererAndValueGetterForClientSide = (triggerAssignAgentLicense, setAgentToAssignLicense) => {
    return {
        field: "licenseDisplayName",
        headerName: "License Name",
        initialWidth: 380,
        filter: 'agTextColumnFilter',
        filterParams: defaultClientSideTextFilterParams,
        valueGetter: (params) => {
            /*
                If grantedTemporaryValidation and no licenseId found, show temp license text and payment is required.
                We need a valueGetter here so the temp license text is picked up in filter, excel export, and on change stream the cell content gets updated for this temp cell content
             */
            if(params.node.data.grantedTemporaryValidation === true && (params.node.data.licenseId === null || params.node.data.licenseId === undefined)){
                return tempLicenseTextToShowInCell;
            }else{
                return params.node.data.licenseDisplayName;
            }
        },
        cellRenderer: (params) => {
            let spinnerDiv = ""
            let editNameIconDiv = ""
            /*
                Check the grantedTemporaryValidation boolean if agent was granted validation without a valid license in /validateAgent.
                If this boolean is true but we still find the licenseId field is not null, show what we have for the license that is assigned
                to the agent instead of the temp granted validation cell content. Could just be the license storage didn't get the update
                or something
             */
            if(params.node.data.grantedTemporaryValidation === true && (params.node.data.licenseId === null || params.node.data.licenseId === undefined)){
                return(
                    <div id="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        <div>{tempLicenseTextToShowInCell}</div>
                        <Tooltip title={<div
                            className={"text-sm"}>{"Click to assign this agent a non-temporary license"}</div>}
                                 placement={"bottom-start"} enterDelay={750} arrow
                                 slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                            <FontAwesomeIcon
                                icon="fa-duotone fa-file-certificate"
                                className={"cursor-pointer"}
                                size="lg"
                                onClick={() => {
                                    setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                    triggerAssignAgentLicense && triggerAssignAgentLicense(params.data)
                                }}
                            />
                        </Tooltip>
                        <MuiAgentLicenseAutoAssignLicenseIconWithMenu
                            params={params} agentId={params.node.data.agentId}
                            onClickFunction={() => {
                                //If agent is not uninstalled they can change this setting
                                if(params.node.data.specialStatusMessage !== "uninstalled"){
                                    //Update this setting
                                    let doNotLookForLicense = true
                                    if(params.node.data.licenseManuallyRemovedByUser){
                                        doNotLookForLicense = false
                                    }
                                    changeAgentAutoAssignLicenseInValidateReactive(params.node.data.agentId, doNotLookForLicense).then(response => {
                                        NotificationManager.success("Successfully updated this setting")
                                        params.node.data.licenseManuallyRemovedByUser = doNotLookForLicense
                                        params.api.refreshCells({columns: ["licenseDisplayName"], rowNodes: [params.node], suppressFlash: true, force: true})
                                    }).catch(function (error) {
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error completing this request.");
                                        }
                                    })
                                }
                            }}
                        />
                    </div>
                )
            }
            if(params.data.licenseDisplayName === " "){
                return (
                    <div className={"flex flex-row flex-nowrap gap-x-2"}>
                        <MuiAgentLicenseAutoAssignLicenseIconWithMenu
                            params={params} agentId={params.node.data.agentId}
                            onClickFunction={() => {
                                //If agent is not uninstalled they can change this setting
                                if(params.node.data.specialStatusMessage !== "uninstalled"){
                                    //Update this setting
                                    let doNotLookForLicense = true
                                    if(params.node.data.licenseManuallyRemovedByUser){
                                        doNotLookForLicense = false
                                    }
                                    changeAgentAutoAssignLicenseInValidateReactive(params.node.data.agentId, doNotLookForLicense).then(response => {
                                        NotificationManager.success("Successfully updated this setting")
                                        params.node.data.licenseManuallyRemovedByUser = doNotLookForLicense
                                        params.api.refreshCells({columns: ["licenseDisplayName"], rowNodes: [params.node], suppressFlash: true, force: true})
                                    }).catch(function (error) {
                                        if(error.message){
                                            NotificationManager.error(error.message);
                                        }
                                        else{
                                            NotificationManager.error("Unexpected error completing this request.");
                                        }
                                    })
                                }
                            }}
                        />
                        <ThemeProvider theme = {cellButtonTheme}>
                            <Tooltip title={<div className={"text-sm"}>{`The first available license in this agent's group (or the group's assigned distribution group) will be assigned to this agent`}</div>}
                                     placement={"bottom-start"} enterDelay={750} arrow slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    onClick={() => {
                                        setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                        triggerAssignAgentLicense && triggerAssignAgentLicense(params.data)
                                    }}
                                >Assign License
                                </Button>
                            </Tooltip>
                        </ThemeProvider>
                    </div>
                )
            }
            let agentLinkDiv = ""
            if(params.node.data.licenseDisplayName !== " "){
                agentLinkDiv =
                    <Link to={{pathname:"/private/licenses"}} state={{licenseDisplayNameClicked: params.node.data.licenseDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        agentDisplayNameClicked: params.node.data.agentDisplayName}} className="">
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This License"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </Link>
                editNameIconDiv = getEditIconComponent(params, "Click to Edit this License's Name", "licenseDisplayName")
            }
            let tempLicenseReassignIcon = ""
            if(params.data.licenseDisplayName && params.data.licenseDisplayName.startsWith('TEMP LICENSE FOR UNINSTALL')){
                tempLicenseReassignIcon =
                    <Tooltip title={<div className={"text-sm"}>{"Click to assign this agent a non-temporary license"}</div>}
                             placement={"bottom-start"} enterDelay={750} arrow slotProps={{tooltip: {sx: {maxWidth: 700}}}}>
                        <FontAwesomeIcon
                            icon="fa-duotone fa-user-plus"
                            className={"cursor-pointer"}
                            size="lg"
                            onClick={() => {
                                setAgentToAssignLicense && setAgentToAssignLicense(params.data)
                                triggerAssignAgentLicense && triggerAssignAgentLicense(params.data)
                            }}
                        />
                    </Tooltip>
                editNameIconDiv = "" //reset editNameIconDiv since it's a temp license and they can't edit name anyway
            }
            return(
                <div id ="fortooltip" className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                    {spinnerDiv}
                    {agentLinkDiv}
                    {tempLicenseReassignIcon}
                    {editNameIconDiv}
                    {params.node.data.licenseDisplayName}
                </div>
            )
        },
        sortable: true,
        editable: (params) => {
            return params.node.data.licenseId !== null && params.node.data.licenseId !== undefined
        },
        cellEditor: "customNameCellEditor"
    }
}

export const defaultLicenseExpirationDateColumnWithClientSide = () => {
    return {
        field: "expiration", headerName: "License Expiration Date", initialWidth: 300,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParametersInHeaderClientSideGrid,
        valueFormatter: dateValueFormatter,
        cellRenderer: function(params){
            let licenseLinkDiv = ""
            const expirationInCell = params.valueFormatted
            if(params.node.data.licenseDisplayName && expirationInCell !== null && expirationInCell !== undefined && expirationInCell.trim().length > 0){
                licenseLinkDiv =
                    <Link to={{pathname:"/private/licenses"}} state={{licenseDisplayNameClicked: params.node.data.licenseDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        agentDisplayNameClicked: params.node.data.agentDisplayName}} className="">
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This License"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </Link>
                let showExpiredIcon = false
                let expirationDateValue = params.node.data.expiration

                if(expirationDateValue !== null && expirationDateValue !== undefined && expirationInCell.trim().length > 0) {
                    try{
                        let expirationDate = new Date(expirationDateValue).getTime()
                        let currentDate = new Date().getTime()
                        if(currentDate >= expirationDate){
                            //expired
                            showExpiredIcon = true
                        }
                    } catch(error){
                        //show expired icon
                        showExpiredIcon = true
                    }
                }
                return(
                    <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                        {showExpiredIcon && (
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon={"fa-duotone fa-clock-rotate-left"}
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"This license is expired, agents can no longer stop any extortion attack with an expired license!"}
                                tooltipPlacement={"bottom-start"}
                            />
                        )}
                        {licenseLinkDiv}
                        {expirationInCell}
                    </div>
                )
            }
            return (<div></div>)
        },
        sortable: true
    }
}

export const defaultLicenseDateAssignedColumnWithClientSide = () => {
    return {
        field: "assigned", headerName: "Date License Assigned", initialWidth: 300,
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParametersInHeaderClientSideGrid,
        valueGetter: (params) => {
            /*
                If grantedTemporaryValidation=true, no licenseId found, and firstGrantedTemporaryValidationDateTime is not null -> show firstGrantedTemporaryValidationDateTime
             */
            if(params.node.data.grantedTemporaryValidation === true && (params.node.data.licenseId === null || params.node.data.licenseId === undefined) && params.node.data.firstGrantedTemporaryValidationDateTime){
                return params.node.data.firstGrantedTemporaryValidationDateTime;
            }else{
                return params.node.data.assigned;
            }
        },
        valueFormatter: dateValueFormatter,
        cellRenderer: function(params){
            const assignedInCell = params.valueFormatted
            if(params.node.data.licenseDisplayName && assignedInCell !== null && assignedInCell !== undefined && assignedInCell.trim().length > 0){
                //If agent is granted temp validation, no license assigned, and firstGrantedTemporaryValidationDateTime has a value, then don't show Link
                if(params.node.data.grantedTemporaryValidation === true && (params.node.data.licenseId === null || params.node.data.licenseId === undefined) && params.node.data.firstGrantedTemporaryValidationDateTime){
                    return (<div>{assignedInCell}</div>)
                }
                return(
                    <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                        <Link to={{pathname:"/private/licenses"}} state={{licenseDisplayNameClicked: params.node.data.licenseDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                            agentDisplayNameClicked: params.node.data.agentDisplayName}} className="">
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This License. Note that the agent needs to call in for validation after the license was assigned in order for the agent to acknowledge it has a license"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </Link>
                        {assignedInCell}
                    </div>
                )
            }
            return (<div></div>)
        },
        sortable: true
    }
}


export function getAndStoreLicensesListWithAgentIdReactive(){
    licensesNamesListWithAgentIdReactive().then(licenses => {
        encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licenses))
    }).catch(error => {})
}

export function getAndStoreLicensesListWithAgentIdReactiveWithSSE(callToLicenseListener){
    Auth.currentSession().then(response => {
        let licensesList = []
        let accessToken = response.getAccessToken().getJwtToken()
        fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/sse/licensesNamesListWithAgentIdReactive", {
            method: "POST",
            headers: {
                "Accept": "text/event-stream",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + accessToken
            },
            //Get trainingMode and demoMode settings dynamically from session
            body: JSON.stringify({
                trainingMode: getTrainingModeSetting(),
                demoMode: getDemoModeSetting()
            }),
            openWhenHidden: true, //initial sse data pull should be open when hidden so if they hide then view the same tab the initial sse data pull does not start again. This causes
            // problems with the populateGridFunction since it may try and add the same object/row with the same rowNodeId to the grid, which causes problems for ag grid.
            onopen(res) {
                if (res.ok && res.status === 200) {
                    //console.log("Connection made ", res);

                } else if (
                    res.status >= 400 &&
                    res.status < 500 &&
                    res.status !== 429
                ) {
                    console.error("Client side error ", res);
                }
                //console.time(`sseDataPull${endpointUrl}`)
            },
            onmessage(event) {
                let rowData = JSON.parse(event.data)
                licensesList.push(rowData)
            },
            onclose() {
                encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesList))
                if(callToLicenseListener){
                    licensesListWithAgentIdSessionStorageChangeStreamListener()
                }
            },
            onerror(err) {
                console.error("There was an error from server", err);
            },
        })
    })
}

let changeStreamListenerActive = false
let gridApi = null
export function licensesListWithAgentIdSessionStorageChangeStreamListener(gridApiPassedIn=null){
    /*
        This will only run when changeStreamListenerActive is false. So the change stream listener will remain active and update the licensesWithAgentIdList session storage variable when navigating from
        page to page. The changeStreamListenerActive variable will reset to false when the page is refreshed and the CS listener is also killed when a page refresh happens, so when the sidebar component
        code calls to this function on a refresh the CS will start again. Otherwise, when sidebar component calls to this function on navigation to another tab, the current CS listener will keep running
        and we can avoid having more than one CS run at a time.
     */
    if(gridApiPassedIn != null){
        gridApi = gridApiPassedIn
    }
    if(!changeStreamListenerActive){
        changeStreamListenerActive = true
        Auth.currentSession().then(response => {
            let accessToken = response.getAccessToken().getJwtToken()
            //licensesWithAgentIdListInSession is the list we will modify when a license change stream comes in and update the licensesWithAgentIdList session storage variable with
            let licensesWithAgentIdListInSession = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
            fetchEventSource(process.env.REACT_APP_AXIOS_BASE_URL + "/sse/listenToLicenseEvent", {
                method: "POST",
                headers: {
                    "Accept": "text/event-stream",
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + accessToken
                },
                //Get trainingMode and demoMode settings dynamically from session
                body: JSON.stringify({
                    trainingMode: getTrainingModeSetting(),
                    demoMode: getDemoModeSetting()
                }),
                openWhenHidden: true, //we should leave this CS listener open when hidden so agents page license name column will always have the correct values
                onopen(res) {
                    if (res.ok && res.status === 200) {
                        //console.log("Connection made ", res);
                    } else if (
                        res.status >= 400 &&
                        res.status < 500 &&
                        res.status !== 429
                    ) {
                        console.error("Client side error ", res);
                    }
                },
                onmessage(event) {
                    let changeStreamEvent = JSON.parse(event.data)
                    let licenseEventData = changeStreamEvent.body
                    let operationType = changeStreamEvent.operationType
                    let licenseObjectPreppedForStorage = {}
                    //the only fields we really care about for session storage list are licenseId, agentId, and name
                    licenseObjectPreppedForStorage["licenseId"] = licenseEventData.id
                    licenseObjectPreppedForStorage["agentId"] = licenseEventData.agentId
                    licenseObjectPreppedForStorage["name"] = licenseEventData["userSetFriendlyName"] ? licenseEventData["userSetFriendlyName"] : licenseEventData["friendlyName"]
                    licenseObjectPreppedForStorage["expiration"] = licenseEventData.expirationDate
                    licenseObjectPreppedForStorage["assigned"] = licenseEventData.dateAssignedToAgent
                    if(!licensesWithAgentIdListInSession){
                        licensesWithAgentIdListInSession = JSON.parse(decryptAndGetSessionVariable("licensesWithAgentIdList"))
                    }
                    if(licensesWithAgentIdListInSession){
                        if(operationType === "UPDATE" || operationType === "REPLACE"){
                            let licenseFoundInSessionList = licensesWithAgentIdListInSession.find(licenseIterator => licenseIterator.licenseId === licenseEventData.id);
                            if(licenseFoundInSessionList){
                                //License was found in session storage. If licenseObjectPreppedForStorage.agentId is not null then we can check if the agentIds are different, else we can remove from session storage
                                let oldAgentId = licenseFoundInSessionList.agentId
                                if(licenseObjectPreppedForStorage.agentId){
                                    if(licenseObjectPreppedForStorage.agentId !== licenseFoundInSessionList.agentId){
                                        //assign agentId and update in session

                                        licenseFoundInSessionList.agentId = licenseObjectPreppedForStorage.agentId
                                        encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                        //this license was assigned to another agent, so need to update both agents
                                        applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, oldAgentId, " ", null, true, " ", " ")
                                        applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["name"], licenseObjectPreppedForStorage["licenseId"],
                                            false, licenseObjectPreppedForStorage["expiration"], licenseObjectPreppedForStorage["assigned"])
                                    }
                                    else{
                                        //else we got an update for this license but the agentIds are the same, check if it was only a license name change
                                        if(licenseObjectPreppedForStorage.name !== licenseFoundInSessionList.name){
                                            licenseFoundInSessionList.name = licenseObjectPreppedForStorage.name
                                            encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                            applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["name"], licenseObjectPreppedForStorage["licenseId"],
                                                false, licenseObjectPreppedForStorage["expiration"], licenseObjectPreppedForStorage["assigned"])
                                        }
                                        //else do nothing
                                    }
                                }
                                else{
                                    //remove agentId and update in session storage
                                    //licenseFoundInSessionList.agentId = null

                                    //Remove license from list since it no longer will have an agentId field
                                    licensesWithAgentIdListInSession = licensesWithAgentIdListInSession.filter(function (value, index, arr) {
                                        return value.licenseId !== licenseFoundInSessionList.licenseId;
                                    })
                                    encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                    applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, oldAgentId, " ", null, true, " ", " ")

                                }
                            }
                            else{
                                //License was not found in session storage. So if licenseObjectPreppedForStorage.agentId is not null then we have to update session storage, else we don't
                                if(licenseObjectPreppedForStorage.agentId){
                                    licensesWithAgentIdListInSession.push(licenseObjectPreppedForStorage)
                                    encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                    applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["name"], licenseObjectPreppedForStorage["licenseId"],
                                        false, licenseObjectPreppedForStorage["expiration"], licenseObjectPreppedForStorage["assigned"])

                                }
                            }

                        }
                        else if (operationType === "INSERT"){
                            if(licenseObjectPreppedForStorage.agentId){
                                licensesWithAgentIdListInSession.push(licenseObjectPreppedForStorage)
                                encryptAndStoreSessionVariable("licensesWithAgentIdList", JSON.stringify(licensesWithAgentIdListInSession))
                                applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, licenseObjectPreppedForStorage.agentId, licenseObjectPreppedForStorage["name"], licenseObjectPreppedForStorage["licenseId"],
                                    false, licenseObjectPreppedForStorage["expiration"], licenseObjectPreppedForStorage["assigned"])
                            }

                        }
                    }
                    else {
                        //TODO: how to handle this case when licensesWithAgentIdListInSession is null?
                    }
                },
                onclose() {
                    //console.debug("Connection closed by the server");
                },
                onerror(err) {
                    console.error("There was an error from server", err);
                },
            })
        })
    }
}

function applyTransactionToAgentForLicenseSessionStorageChangeStream(gridApi, agentIdForRowNode, newLicenseDisplayNameValue, newLicenseIdField,
                                                                     newLicenseManuallyRemovedByUserValue, expirationDate, assigned){
    if(gridApi && !gridApi.destroyCalled){
        //need to get row node of agent with matching agentId from license
        let agentRowNode = gridApi.getRowNode(agentIdForRowNode)
        if(agentRowNode){
            //if we found the agent, apply update
            agentRowNode.data.licenseManuallyRemovedByUser = newLicenseManuallyRemovedByUserValue
            agentRowNode.data.licenseDisplayName = newLicenseDisplayNameValue
            agentRowNode.data.licenseId = newLicenseIdField
            agentRowNode.data.expiration = expirationDate
            agentRowNode.data.assigned = assigned
            gridApi.applyTransactionAsync({
                update: [agentRowNode.data]
            })
        }
    }
}