import {findZenGroupByFriendlyName} from "./zenGroupSessionStorageManager";
import {decryptAndGetSessionVariable} from "./storageHelper";
import {dateFilter} from "./DTPicker";

export function setFilterHelper(filter, clazz){
    let f = {}
    if(filter[clazz].values) {
        f.key = clazz
        f.type = "inSet" //custom set for this filterType
        f.valueType = filter[clazz].filterType
        if(clazz === "zenGroupDisplayName" || clazz === "friendlyName" || clazz === "assignedDistributionGroupName"){
            let zenGroupFilters = []
            filter[clazz].values.forEach(friendlyName => {
                zenGroupFilters.push(findZenGroupByFriendlyName(friendlyName).id);
            });
            f.valsArray = zenGroupFilters;
        }else if(clazz === "licenseDisplayName"){
            //save this for next
            f.valsArray = filter[clazz].values
        }else if(clazz === "agentDisplayName"){
            //TODO: figure out how to filter this value - copied from licenses.js
            //save this for next
            //f.valsArray = filter[clazz].values
        }
        else if(clazz === "autoUpgrade" || clazz === "hiddenFromUI" || clazz === "sendOfflineAlertsForHiddenAgents" || clazz === "silent" || clazz === "disabled" || clazz === "sendRansomwareActivityAlertsForSilentIncidents"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        }
        else if(clazz === "specialStatusMessage"){
            let filterValues = filter[clazz].values
            if(filterValues.includes("none")){
                filterValues.push(null)
            }
            f.valsArray = filterValues;
        } else if(clazz === "channelPartner" || clazz === "distributionGroup" || clazz === "channelPartnerCustomer" || clazz === "collectAgentTelemetry" || clazz === "whcpOnly"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        } else if(clazz === "ransomwareActivity" || clazz === "offlineAgent" || clazz === "non_RansomwareIntrusionActivity"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        } else if(clazz === "dealCreated" || clazz === "dealDisabled" || clazz === "dealExpired" || clazz === "upcomingDealExpiration" || clazz === "invoiceCreated"){
            f.valueType = "booleanSet"
            f.valsArray = filter[clazz].values
        }
        else{
            f.valsArray = filter[clazz].values
        }
    }
    return f
}

export function dateFilterHelper(filter,clazz,forServerSideMultiCondition=false){
    let filterObj = null
    if(forServerSideMultiCondition){
        //multi condition filter is passed in
        filterObj = filter
    }
    else{
        //normal single filter condition
        filterObj = filter[clazz]
    }
    try{ //for extra precaution
        let f = {}
        let test = new Date()
        //Need to replace '-' delimiter with '/' because some browsers (Safari) do not recognize dates with '-' as valid
        let dateFrom = new Date(filterObj.dateFrom.replace(/-/g, "/"))
        let timezone = decryptAndGetSessionVariable("timezone")
        if(timezone === null || timezone === undefined){
            timezone = "Europe/London" //default to london gmt time if timezone is null
        }
        let testSessionTime = (new Date(Date.parse(test.toLocaleString('en-US', {
            timeZone: timezone
        }))));

        let sessionTZOffset = Math.ceil((testSessionTime - test) / 36e5) * 60 * 60* 1000 ;
        let filterLocalDate = (dateFrom.getTime() - sessionTZOffset)
        let dateFromWithOffset = new Date(filterLocalDate)


        let splitArray = dateFromWithOffset.toISOString().split("T")
        let splitArray2 = splitArray[1].split(".000Z")
        let finalDateFrom = splitArray[0] + " " + splitArray2[0]

        f.val = finalDateFrom

        if(filterObj.dateTo){
            let dateTo = new Date(filterObj.dateTo.replace(/-/g, "/"))
            let filterLocalDate2 = (dateTo.getTime() - sessionTZOffset)
            let dateToWithOffset = new Date(filterLocalDate2)
            let splitArray3 = dateToWithOffset.toISOString().split("T")
            let splitArray4 = splitArray3[1].split(".000Z")
            let finalDateTo = splitArray3[0] + " " + splitArray4[0]
            f.val = finalDateFrom+ "&" + finalDateTo
        }

        f.type = filterObj.type
        f.key = clazz
        f.valueType = filterObj.filterType
        return f
    }
    catch(error){
        console.error("error parsing dateFilterHelper contents")
    }
}

export function defaultFilterHelper(filter,clazz,forServerSideMultiCondition=false){
    if(!forServerSideMultiCondition){
        //Normal single condition filter
        let f={}
        f.val = filter[clazz].filter
        f.type = filter[clazz].type
        f.key = clazz
        if(clazz === "agentVersionFormatted"){
            //need to convert the agentVersionFormatted back to agentVersion before sending filter for server side data since we changed the field name to be agentVersionFormatted on frontend
            f.key = "agentVersion"
        }
        f.valueType = filter[clazz].filterType
        return f
    }
    else{
        //For server side multi condition filter, only need to prep the 'val' and 'type' fields for multi conditions, the rest should be prepped by caller
        let f={}
        f.val = filter.filter
        f.type = filter.type
        return f
    }
}

export function timeFilterHelper(filter,clazz){
    //have to customize this since we format the time interval from number to string
    let f = {}
    let value = 0;
    let filterTypedIn = filter[clazz].filter
    filterTypedIn = filterTypedIn.toLowerCase();
    switch(filterTypedIn){
        case "5 minutes":
            value = 300
            break;
        case "15 minutes":
            value = 900
            break;
        case "30 minutes":
            value = 1800
            break;
        case "1 hour":
            value = 3600
            break;
        case "12 hours":
            value = 43200
            break;
        case "24 hours":
            value = 86400
            break;
        default:
            value = 0;
            break;
    }
    f.val = value;
    f.type = filter[clazz].type
    f.key = clazz;
    f.valueType = "number";
    return f
}

export function masterFilterHelper(filter, skipMultiConditions=true){
    let filters = []
    for (let clazz in filter) {
        if (filter[clazz] == null) {
            continue;
        }
        if(skipMultiConditions){
            if(filter[clazz].conditions && filter[clazz].conditions.length > 0){
                continue
            }
        }
        //let f = {}
        if(filter[clazz].filterType === "set"){
            filters.push(setFilterHelper(filter,clazz))
        }
        else if(filter[clazz].filterType === "date"){
            filters.push(dateFilterHelper(filter,clazz))
        }
        else if(clazz === "timeIntervalInSeconds"){
            filters.push(timeFilterHelper(filter,clazz))
        }
        else if(filter[clazz].filterType === "multi"){
            if(clazz === "whitelistAppliedId"){
                try{
                    let filterModels = filter[clazz].filterModels
                    //Per ag grid docs, the filterModels indexes for multi filter types are in the same order as they are given in the column def. So for status column, index 0 is the
                    // status filter, and index 1 is the silent filter. If no filter is present for one of them, then the filter model for that index will be null, but the indexes do not change.
                    if(filterModels[0] != null){
                        //whitelistAppliedId filter
                        let f = {}
                        f.key = "whitelistAppliedId" //in the backend it accounts for this as the key and checking for Not Excluded and Excluded from the filter
                        f.type = "inSet" //custom set for this filterType
                        f.valueType = "set"
                        f.valsArray = filterModels[0].values
                        filters.push(f)
                    }
                    if(filterModels[1] != null){
                        //silent filter
                        let f = {}
                        f.key = "silent"
                        f.type = "inSet" //custom set for this filterType
                        f.valueType = "booleanSet" //boolean
                        //need to convert 'Silent', 'Not Silent' to true and false
                        let valsArrayToSend = []
                        filterModels[1].values.forEach(value => {
                            if(value === "Silent"){
                                valsArrayToSend.push(true)
                            }
                            else if(value === "Not Silent"){
                                valsArrayToSend.push(false)
                            }
                        })
                        f.valsArray = valsArrayToSend
                        filters.push(f)
                    }
                } catch (e){
                    console.log("Error grabbing filters from multi filter in filter helper ")
                }
            }
            else if(clazz === "hasNoTrustedCert"){
                try{
                    let filterModels = filter[clazz].filterModels
                    //Per ag grid docs, the filterModels indexes for multi filter types are in the same order as they are given in the column def
                    if(filterModels[0] != null){
                        //hasNoTrustedCert filter
                        let f = {}
                        f.key = "hasNoTrustedCert"
                        f.type = "inSet" //custom set for this filterType
                        f.valueType = "set"
                        f.valsArray = filterModels[0].values
                        filters.push(f)
                    }
                    if(filterModels[1] != null){
                        //windowsCode filter (Timeout/Disconnect) custom filter
                        let f = {}
                        f.key = "windowsCode"
                        f.type = "inSet" //custom set for this filterType
                        f.valueType = "set"
                        f.valsArray = filterModels[1].values //rest web accounts for the strings sent here
                        filters.push(f)
                    }
                } catch (e){
                    console.log("Error grabbing filters from multi filter in filter helper ")
                }
            }
            //else no other multi filters should be enabled
        }
        else{
            filters.push(defaultFilterHelper(filter,clazz))
        }
    }
    return filters
}

export function serverSideFilterHelperHandleMultiConditionChecks(filter){
    let filters = []
    for (let clazz in filter) {
        if (filter[clazz] == null) {
            continue;
        }
        try{
            let currentFilter = filter[clazz]
            if(currentFilter.conditions && currentFilter.conditions.length > 0){
                //Multi condition filter
                if(currentFilter.filterType === "date"){
                    let multiFilterToAdd = {}
                    multiFilterToAdd["multiConditionOperator"] = currentFilter.operator
                    multiFilterToAdd["key"] = clazz
                    multiFilterToAdd["valueType"] = currentFilter.filterType

                    let multiConditionFiltersFields = []

                    //default filter, for each condition add to multiConditionFilters
                    currentFilter.conditions.forEach(condition => {
                        multiConditionFiltersFields.push(dateFilterHelper(condition,clazz, true))
                    })
                    multiFilterToAdd["multiConditionFilters"] = multiConditionFiltersFields

                    filters.push(multiFilterToAdd)
                }
                else if(currentFilter.filterType === "multi"){
                    //For the only 2 'agMultiColumnFilter' filters we have they are set filters, so those will not have the conditions fields in their filter
                }
                else{
                    let multiFilterToAdd = {}
                    multiFilterToAdd["multiConditionOperator"] = currentFilter.operator
                    multiFilterToAdd["key"] = clazz
                    multiFilterToAdd["valueType"] = currentFilter.filterType

                    if(clazz === "agentVersionFormatted"){
                        //need to convert the agentVersionFormatted back to agentVersion before sending filter for server side data since we changed the field name to be agentVersionFormatted on frontend
                        multiFilterToAdd["key"] = "agentVersion"
                    }

                    let multiConditionFiltersFields = []

                    //default filter, for each condition add to multiConditionFilters
                    currentFilter.conditions.forEach(condition => {
                        multiConditionFiltersFields.push(defaultFilterHelper(condition,clazz, true))
                    })
                    multiFilterToAdd["multiConditionFilters"] = multiConditionFiltersFields

                    filters.push(multiFilterToAdd)
                }

            }
        } catch (e){
            console.log(e)
        }
    }
    return filters
}

export function defaultClientSideTextFilterParams () {
    //Keep all default filter options for text filter
    return {
        buttons: ["reset", "apply", "cancel"],
        maxNumConditions: 2,
    }
}
