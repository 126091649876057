import axios from "../../utils/axiosInstance";

export const findNotificationEventForDataIncidentAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForDataIncidentAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForIdentityIncidentAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForIdentityIncidentAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForOfflineAgentAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForOfflineAgentAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForFullyOfflineAgentAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForFullyOfflineAgentAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForPartiallyOfflineAgentAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForPartiallyOfflineAgentAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForDealExpiredAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForDealExpiredAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForSingleDealAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForSingleDealAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};
export const findNotificationEventForAutogeneratedWhitelistAutoFilter = async (notificationEventId) => {
    const response = await axios.post("/findNotificationEventForAutogeneratedWhitelistAutoFilter", {
        notificationEventId: notificationEventId
    });
    return response.data;
};