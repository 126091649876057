import React from "react";
import {Backdrop, CircularProgress} from "@mui/material";
import {muiLoadingCircleZIndex} from "../App";

/*
    Note: The parent div where this is placed inside of needs the 'relative' style in the className so this overlay is confined to the parent div!
    The color is for the color of the spinner, and backgroundColor is for the loading overlay color and opacity.
 */
export const BackDropChartLoadingOverlay = ({ opened}) => (
    <Backdrop transitionDuration={500} open={opened} sx={{ backgroundColor: 'rgba(0,0,0,0.1)', color: '#fff', position: 'absolute'}}>
        <CircularProgress color="inherit" />
    </Backdrop>
);

/*
    The BackDropPageLoadingOverlay is used for having a loading overlay that covers the entire page like the old LoadingOverlay we used to have did.
 */
export const BackDropPageLoadingOverlay = ({opened}) => (
    <Backdrop transitionDuration={500} open={opened} sx={{ color: '#fff', zIndex: muiLoadingCircleZIndex}}>
        <CircularProgress color="inherit" />
    </Backdrop>
)
