import {getAgentFriendlyNamesReactive} from "../pages/api/licensesApi";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import React from "react";
import {getEditIconComponent} from "./customCellEditor";
import {agentNamesListReactive} from "../pages/api/agentsApi";
import {encryptAndStoreSessionVariable} from "./storageHelper";
import {MuiIconButtonWithTooltip} from "../components/muiComponents";
import {defaultClientSideTextFilterParams} from "./filterHelper";
import {dateFilterParametersInHeaderClientSideGrid} from "./DTPicker";
import {dateValueFormatter} from "./gridDateFormatter";

/*
    This function assumes the agent id is 'agentId', the agent name column is 'agentDisplayName', and the agent machine
    name column is 'machineName'. I do not think we can add additional parameters to the function since it is directly
    tied to the ag-grid valueFormatter() function (https://www.ag-grid.com/react-data-grid/value-formatters/), so we
    have to work by assuming the info above.
 */

export const defaultAgentNameColumnInitWithOptions = (sortable=true, maxNumConditions=1) => {
    return {
        field: "agentDisplayName",
        headerName: "Agent Name",
        sortable: sortable,
        initialWidth: 375,
        editable: true,
        cellEditor: "customNameCellEditor",
        filter: 'agTextColumnFilter',
        filterParams: { //this is for server side agent name column, don't use filterParams: defaultClientSideTextFilterParams,
            suppressSorting: true,
            buttons: ["reset", "apply"],

            filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
            maxNumConditions: maxNumConditions,
        },
        valueFormatter: agentNameValueFormatter,
        cellRenderer: agentNameCellRendererFramework
    }
}

export const defaultAgentNameColumnAndMachineNameInitWithFilters = {
    field: "agentDisplayName",
    headerName: "Agent Name",
    sortable: true,
    width: 375,
    editable: true,
    cellEditor: "customNameCellEditor",
    filter: 'agTextColumnFilter',
    filterParams: { //this is for a server side agent name column, don't use filterParams: defaultClientSideTextFilterParams,
        suppressSorting: true,
        buttons: ["reset", "apply"],

        filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
        maxNumConditions: 1,
    },
    valueFormatter: agentNameAndMachineNameValueFormatter,
    cellRenderer: agentNameCellRendererFramework
}

export const defaultAgentNameColumnForLicensesGrid = {
    field: "agentDisplayName",
    headerName: "Agent Name",
    sortable: true,
    initialWidth: 375,
    editable: true,
    cellEditor: "customNameCellEditor",
    filter: 'agTextColumnFilter',
    filterParams: defaultClientSideTextFilterParams,
    valueGetter: agentNameValueGetterForLicensesGrid,
    cellRenderer: agentNameCellRendererForLicensesGrid
}

export const defaultMachineNameColDefWithFilters = {
    field: "machineName", headerName: "Machine Name", width: 250,
    filter: 'agTextColumnFilter',
    filterParams: { //this is for a server side agent name column, don't use filterParams: defaultClientSideTextFilterParams,
        suppressSorting: true,
        buttons: ["reset", "apply"],
        
        filterOptions: ['contains', 'notContains', 'equals', 'startsWith', 'endsWith'],
        maxNumConditions: 1,
    },
    cellRenderer: function(params){
        let agentLinkDiv = ""
        let spinnerDiv = ""
        if(params.node.data.agentId){
            const machineNameRenderToCell = params.node.data.machineName
            if(params.node.data.agentDisplayName && machineNameRenderToCell !== undefined && machineNameRenderToCell !== null && machineNameRenderToCell !== " "){
                agentLinkDiv =
                    <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon={"fa-duotone fa-user-gear"}
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This Agent"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </Link>
            }
            if(params.node.data.machineName === null || params.node.data.machineName === undefined){
                spinnerDiv =
                    <div className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        <FontAwesomeIcon
                            className="contain fa-pulse"
                            icon="fa-light fa-spinner"
                            size="lg"
                            name="AgentMachineNameLoading"
                        /> Loading
                    </div>
            }
            return(
                <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                    {agentLinkDiv}
                    {spinnerDiv}
                    {machineNameRenderToCell}
                </div>
            )
        }
        return (<div></div>)
    },
    sortable: true
}

export const defaultMachineNameColDefWithFiltersWithValueGetter = {
    field: "machineName", headerName: "Machine Name", initialWidth: 250,
    filter: 'agTextColumnFilter',
    filterParams: defaultClientSideTextFilterParams,
    valueGetter: function (params) {
        if(params.node.data.agentId){
            if(params.node.data.machineName){
                return params.node.data.machineName
            }
            else{
                return params.node.data.agentId
            }
        }
    },
    cellRenderer: function(params){
        let agentLinkDiv = ""
        let spinnerDiv = ""
        if(params.node.data.agentId){
            const machineNameRenderToCell = params.node.data.machineName
            if(params.node.data.agentDisplayName && machineNameRenderToCell !== undefined && machineNameRenderToCell !== null && machineNameRenderToCell !== " "){
                agentLinkDiv =
                    <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This Agent"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </Link>
            }
            if(params.node.data.machineName === null || params.node.data.machineName === undefined){
                spinnerDiv =
                    <div className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        <FontAwesomeIcon
                            className="contain fa-pulse"
                            icon="fa-light fa-spinner"
                            size="lg"
                            name="AgentMachineNameLoading"
                        /> Loading
                    </div>
            }
            return(
                <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                    {agentLinkDiv}
                    {spinnerDiv}
                    {machineNameRenderToCell}
                </div>
            )
        }
        return (<div></div>)
    },
    sortable: true
}

export const defaultAgentLastValidateColDefWithValueGetter = {
    field: "lastValidateDateTime", headerName: "Agent Latest Validation Check", initialWidth: 315,
    filter: 'agDateColumnFilter',
    filterParams: dateFilterParametersInHeaderClientSideGrid,
    valueFormatter: dateValueFormatter,
    cellRenderer: function(params){
        let spinnerDiv = ""
        let agentLinkDiv = ""
        if(params.node.data.agentId){
            const lastValidateDateTimeToCell = params.valueFormatted
            if(params.node.data.agentDisplayName && lastValidateDateTimeToCell !== undefined && lastValidateDateTimeToCell !== null && lastValidateDateTimeToCell.trim().length > 0) {
                agentLinkDiv =
                    <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                        agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                        <div className={"mb-1"}>
                            <MuiIconButtonWithTooltip
                                icon={
                                    <FontAwesomeIcon
                                        className="object-contain"
                                        icon="fa-duotone fa-user-gear"
                                        size="xs"
                                    />
                                }
                                tooltipTitle={"Click to Manage This Agent"}
                                tooltipPlacement={"bottom-start"}
                            />
                        </div>
                    </Link>
            }
            if(params.node.data.lastValidateDateTime === null || params.node.data.lastValidateDateTime === undefined){
                spinnerDiv =
                    <div className={"flex flex-nowrap items-center justify-start gap-x-2"}>
                        <FontAwesomeIcon
                            className="contain fa-pulse"
                            icon="fa-light fa-spinner"
                            size="lg"
                            name="AgentValidateLoading"
                        /> Loading
                    </div>
            }
            return(
                <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
                    {agentLinkDiv}
                    {spinnerDiv}
                    {lastValidateDateTimeToCell}
                </div>
            )
        }
        return (<div></div>)
    },
    sortable: true
}

export function agentNameAndMachineNameValueFormatter(params){
    if(params.node.data.agentDisplayName){
        return params.node.data.agentDisplayName
    }
    if(params.node.data.agentId){
        let agentIdList = []
        agentIdList.push(params.node.data.agentId);
        getAgentFriendlyNamesReactive(agentIdList).then(function(agentNameObjects){
            if(agentNameObjects && agentNameObjects.length>0) {
                if (agentNameObjects[0].name) {
                    if(agentNameObjects[0].name === params.node.data.agentDisplayName){
                        //console.log("same")
                    }else {
                        params.node.setDataValue("agentDisplayName", agentNameObjects[0].name)
                    }
                }
                else{
                    //in this case, stop showing spinning div since we have returned from api call
                    params.node.setDataValue("agentDisplayName", " ")
                }
                if (agentNameObjects[0].machineName) {
                    if(agentNameObjects[0].machineName === params.node.data.machineName){
                        //console.log("same")
                    }else {
                        params.node.setDataValue("machineName", agentNameObjects[0].machineName)
                        //make a new field to use for agent location link so the filter on agents grid for machine name will use correct agent machine name
                        params.node.data.machineNameForLocationLink = agentNameObjects[0].machineName
                    }
                }
                else{
                    params.node.setDataValue("machineName", " ")
                }
            }
            else{
                //in this case, stop showing spinning div since we have returned from api call
                params.node.setDataValue("agentDisplayName", " ")
                params.node.setDataValue("machineName", " ")
            }
        }).catch(function(error){
            //in case of error, stop showing spinning div since we have returned from api call
            params.node.setDataValue("agentDisplayName", " ")
            params.node.setDataValue("machineName", " ")
        })
        params.node.data.agentDisplayName = params.node.data.agentId
        return params.node.data.agentId
    }
    //return ""
}

export function agentNameValueFormatter(params){
    if(params.node.data.agentDisplayName){
        return params.node.data.agentDisplayName
    }
    if(params.node.data.agentId){
        let agentIdList = []
        agentIdList.push(params.node.data.agentId);
        getAgentFriendlyNamesReactive(agentIdList).then(function(agentNameObjects){
            if(agentNameObjects && agentNameObjects.length>0) {
                if (agentNameObjects[0].name) {
                    if(agentNameObjects[0].name === params.node.data.agentDisplayName){
                        //console.log("same")
                    }else {
                        params.node.data.agentDisplayName = agentNameObjects[0].name
                        params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [params.node], suppressFlash: false, force: false})
                        //params.node.setDataValue("agentDisplayName", agentNameObjects[0].name)
                    }
                }
                else{
                    //in this case, stop showing spinning div since we have returned from api call
                    params.node.data.agentDisplayName = " "
                    params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [params.node], suppressFlash: false, force: false})
                    //params.node.setDataValue("agentDisplayName", " ")
                }
                if (agentNameObjects[0].machineName) {
                    if(agentNameObjects[0].machineName === params.node.data.machineName){
                        //console.log("same")
                    }else {
                        //make a new field to use for agent location link so the filter on agents grid for machine name will use correct agent machine name
                        params.node.data.machineNameForLocationLink = agentNameObjects[0].machineName
                        //Mainly for incidents page, but force the incident machineName column to refresh so it will render the link icon now that we are in the getAgentNamesByIdList callback.
                        // Ag grid does not throw error if the column does not exist, so we are safe there
                        params.api.refreshCells({columns: ["machineName"], rowNodes: [params.node], suppressFlash: true, force: true})
                    }
                }
            }
            else{
                //in this case, stop showing spinning div since we have returned from api call
                params.node.data.agentDisplayName = " "
                params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [params.node], suppressFlash: false, force: false})
                //params.node.setDataValue("agentDisplayName", " ")
            }
        }).catch(function(error){
            //in case of error, stop showing spinning div since we have returned from api call
            params.node.data.agentDisplayName = " "
            params.api.refreshCells({columns: ["agentDisplayName"], rowNodes: [params.node], suppressFlash: false, force: false})
            //params.node.setDataValue("agentDisplayName", " ")
        })
        params.node.data.agentDisplayName = params.node.data.agentId
        return params.node.data.agentId
    }
    //return ""
}

export function agentNameCellRendererFramework(params){
    let spinnerDiv = ""
    let agentLinkDiv = ""
    let editNameIconDiv = ""
    if(params.data.agentId === params.valueFormatted){
            spinnerDiv = <FontAwesomeIcon
                className="contain fa-pulse"
                icon="fa-light fa-spinner"
                size="lg"
                name="AgentNameLoading"
            />
    }else{
        spinnerDiv = ""
    }

    //machineNameForLocationLink is a made up field in the above agentNameValueFormatters
    if(params.data.agentId && params.node.data.agentDisplayName){
        agentLinkDiv =
            <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                <div className={"mb-1"}>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-user-gear"
                                size="xs"
                            />
                        }
                        tooltipTitle={"Click to Manage This Agent"}
                        tooltipPlacement={"bottom-start"}
                    />
                </div>
            </Link>
        editNameIconDiv = getEditIconComponent(params, "Click to Edit this Agent's Name", "agentDisplayName")
    }

    return(
        <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
            {spinnerDiv}
            {agentLinkDiv}
            {editNameIconDiv}
            {params.valueFormatted}
        </div>
    )
}

export function agentNameValueGetterForLicensesGrid(params){
    if(params.node.data.agentDisplayName){
        return params.node.data.agentDisplayName
    }
    if(params.node.data.agentId){
        let agentIdList = []
        agentIdList.push(params.node.data.agentId);
        getAgentFriendlyNamesReactive(agentIdList).then(function(agentNameObjects){
            if(agentNameObjects && agentNameObjects.length>0) {
                if (agentNameObjects[0].name) {
                    if(agentNameObjects[0].name === params.node.data.agentDisplayName){
                        //console.log("same")
                    }else {
                        params.node.setDataValue("agentDisplayName", agentNameObjects[0].name)
                    }
                }
                else{
                    //in this case, stop showing spinning div since we have returned from api call
                    params.node.setDataValue("agentDisplayName", " ")
                }
                if (agentNameObjects[0].machineName) {
                    if(agentNameObjects[0].machineName === params.node.data.machineName){
                        //console.log("same")
                    }else {
                        params.node.setDataValue("machineName", agentNameObjects[0].machineName)
                        //make a new field to use for agent location link so the filter on agents grid for machine name will use correct agent machine name
                        params.node.data.machineNameForLocationLink = agentNameObjects[0].machineName
                    }
                }
                else{
                    params.node.setDataValue("machineName", " ")
                }

                if (agentNameObjects[0].validate) {
                    if(agentNameObjects[0].validate === params.node.data.validate){
                        //console.log("same")
                    }else {
                        params.node.setDataValue("lastValidateDateTime", agentNameObjects[0].validate)

                    }
                }
                else{
                    params.node.setDataValue("lastValidateDateTime", " ")
                }
            }
            else{
                //in this case, stop showing spinning div since we have returned from api call
                params.node.setDataValue("agentDisplayName", " ")
                params.node.setDataValue("machineName", " ")
                params.node.setDataValue("lastValidateDateTime", " ")
            }
        }).catch(function(error){
            //in case of error, stop showing spinning div since we have returned from api call
            params.node.setDataValue("agentDisplayName", " ")
            params.node.setDataValue("machineName", " ")
            params.node.setDataValue("lastValidateDateTime", " ")
        })
        params.node.data.agentDisplayName = params.node.data.agentId
        return params.node.data.agentId
    }
    //return ""
}

export function agentNameCellRendererForLicensesGrid(params){
    let spinnerDiv = ""
    let agentLinkDiv = ""
    let editNameIconDiv = ""
    if(params.data.agentId && ((params.data.agentId && !params.data.agentDisplayName) || (params.data.agentId === params.data.agentDisplayName))){
        spinnerDiv = <FontAwesomeIcon
            className="contain fa-pulse"
            icon="fa-light fa-spinner"
            size="lg"
            name="AgentNameLoading"
        />
    }else{
        spinnerDiv = ""
    }

    //machineNameForLocationLink is a made up field in the above agentNameValueFormatters
    if(params.data.agentId && params.node.data.agentDisplayName){
        agentLinkDiv =
            <Link to={{pathname:"/private/agents"}} state={{agentDisplayNameClicked: params.node.data.agentDisplayName, zenGroupIdClicked: params.node.data.zenGroupId,
                agentIdClicked : params.node.data.agentId, machineNameClicked: params.node.data.machineNameForLocationLink}} className="">
                <div className={"mb-1"}>
                    <MuiIconButtonWithTooltip
                        icon={
                            <FontAwesomeIcon
                                className="object-contain"
                                icon="fa-duotone fa-user-gear"
                                size="xs"
                            />
                        }
                        tooltipTitle={"Click to Manage This Agent"}
                        tooltipPlacement={"bottom-start"}
                    />
                </div>
            </Link>
        editNameIconDiv = getEditIconComponent(params, "Click to Edit this Agent's Name", "agentDisplayName")
    }

    return(
        <div className={"flex flex-nowrap items-center justify-start gap-x-1"}>
            {spinnerDiv}
            {agentLinkDiv}
            {editNameIconDiv}
            {params.data.agentDisplayName}
        </div>


    )
}

export function getAndStoreAgentNamesListInSession(){
    agentNamesListReactive().then(agents => {
        encryptAndStoreSessionVariable("agentNamesList", JSON.stringify(agents))
    }).catch(error => {})
}