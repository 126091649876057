import axios from "../../utils/axiosInstance";

export const getMinidumpsCCOnly = async (startYear, startMonth, startDayOfMonth, endYear, endMonth, endDayOfMonth) => {
    const response = await axios.post("/getMinidumpsCCOnly", {
        startYear,
        startMonth,
        startDayOfMonth,
        endYear,
        endMonth,
        endDayOfMonth
    });
    return response.data;
};

export const downloadMinidumpCCOnly = async (minidumpId) => {
    return await axios.post("/downloadMinidumpCCOnly", {
        minidumpId: minidumpId
    });
};