import axios from "../../utils/axiosInstance";

export const getWhitelistsReactive = async () => {
    const response = await axios.post("/getWhitelistsReactive", {
    });
    return response.data;
};

export const removeFromWhitelistReactive = async (id) => {
    return await axios.post("/removeFromWhitelistReactive", {
        id: id
    });
};

export const userAddWhitelistReactive = async (path, zenGroupId, programArguments, whitelistName, parentPath, parentArguments, onlyMatchIfTrustedCert, onlyMatchIfNoInjections, onlyMatchIfNoModifiedMemory, reportTypesToMatch, hoursUntilExpiration) => {
    const response = await axios.post("/userAddWhitelistReactive", {
        path: path,
        zenGroupId: zenGroupId,
        programArguments: programArguments,
        userSetFriendlyName: whitelistName,
        parentPath: parentPath,
        parentArguments: parentArguments,
        onlyMatchIfTrustedCert: onlyMatchIfTrustedCert,
        onlyMatchIfNoInjections: onlyMatchIfNoInjections,
        onlyMatchIfNoModifiedMemory: onlyMatchIfNoModifiedMemory,
        reportTypesToMatch: reportTypesToMatch,
        hoursUntilExpiration: hoursUntilExpiration
    })
    return response.data;
}
export const findByWhitelistIdListReactive = async (ids) => {
    const response = await axios.post("/findByWhitelistIdListReactive",{
        ids
    })
    return response.data
}
export const getUsersByIdsReactive = async (ids) => {
    const response = await axios.post("/getUsersByIdsReactive",{
        ids
    })
    return response.data
}
export const findBySingleWhitelistAppliedIdReactive = async (whitelistAppliedId) => {
    const response = await axios.post("/findBySingleWhitelistAppliedIdReactive",{
        whitelistAppliedId
    })
    return response.data
}
export const whitelistsPerGroupCountReactive = async () => {
    const response = await axios.post("/whitelistsPerGroupCountReactive",{})
    return response.data
}

export const changeWhitelistNameReactive = async (newWhitelistId, name) =>{
    return await axios.post("/changeWhitelistNameReactive", {
        whitelistId :newWhitelistId,
        newName : name,
    })
}

export const getWhitelistsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getWhitelistsGridFilterStateReactive", {});
    return response.data;
};

export const updateWhitelistsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateWhitelistsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateWhitelistsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateWhitelistsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getWhitelistsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getWhitelistsGridColumnStateReactive", {});
    return response.data;
};
export const updateWhitelistsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateWhitelistsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateWhitelistsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateWhitelistsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};

export const scanServerAVs = async () => {
    return await axios.post("/scanServerAVs", {})
};

export const updateWhitelistsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateWhitelistsGridColumnModeReactive", {
        columnMode: columnMode
    })
}
export const updateWhitelistChartVisibilityReactive = async (visible) => {
    return await axios.post("/updateWhitelistChartVisibilityReactive", {
        visible: visible
    });
};
export const copyAndReplaceWhitelistsReactive = async (zenGroupId, whitelistIdsToCopy) => {
    return await axios.post("/copyAndReplaceWhitelistsReactive", {
        zenGroupId: zenGroupId,
        whitelistIdsToCopy: whitelistIdsToCopy
    });
};
export const copySingleWhitelistReactive = async (whitelistId, newZenGroupId) => {
    return await axios.post("/copySingleWhitelistReactive", {
        whitelistId: whitelistId,
        newZenGroupId: newZenGroupId
    });
};
export const editWhitelistExpirationReactive = async (whitelistId, hoursUntilExpiration) => {
    return await axios.post("/editWhitelistExpirationReactive", {
        whitelistId: whitelistId,
        hoursUntilExpiration: hoursUntilExpiration
    });
};