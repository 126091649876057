import axios from "../../utils/axiosInstance";

export const getDecrytorsReactive = async () => {
    const response = await axios.post("/decryptorsListReactive", {
    });
    return response.data;
};

export const changeDecryptorZenGroupReactive = async (zenGroup, decryptorId) => {
    return await axios.post("/changeDecryptorZenGroupReactive", {
        newZenGroupId: zenGroup,
        idToUpdate: decryptorId,
    })
};

export const getDecryptorDownloadLinkReactive = async (decryptorId) => {
    const response = await axios.post("/getDecryptorDownloadLinkReactive", {
        id: decryptorId
    })
    return response.data
};
export const findByDecryptorIdListReactive = async (ids) => {
    const response = await axios.post("/findByDecryptorIdListReactive",{
        ids
    })
    return response.data
}

export const getDecryptorsGridFilterStateReactive = async () => {
    const response =  await axios.post("/getDecryptorsGridFilterStateReactive", {});
    return response.data;
};

export const updateDecryptorsGridFilterModelReactive = async (newFilterModel) => {
    return await axios.post("/updateDecryptorsGridFilterModelReactive", {
        newFilterModel: newFilterModel
    });
};

export const updateDecryptorsGridUseFilterStateReactive = async (newUseFilterState) => {
    return await axios.post("/updateDecryptorsGridUseFilterStateReactive", {
        newUseFilterState: newUseFilterState
    });
};

export const getDecryptorsGridColumnStateReactive = async () => {
    const response =  await axios.post("/getDecryptorsGridColumnStateReactive", {});
    return response.data;
};
export const updateDecryptorsGridColumnStateReactive = async (columnStatesList) => {
    return await axios.post("/updateDecryptorsGridColumnStateReactive", {
        columnStatesList: columnStatesList
    });
};

export const updateDecryptorsGridUseColumnStateReactive = async (newUseColumnState) => {
    return await axios.post("/updateDecryptorsGridUseColumnStateReactive", {
        newUseColumnState: newUseColumnState
    });
};
export const updateDecryptorsGridColumnModeReactive = async (columnMode) => {
    return await axios.post("/updateDecryptorsGridColumnModeReactive", {
        columnMode: columnMode
    });
};
