import axios from "../../utils/axiosInstance";
export const editPartnerOrganizationLeadNotesCCOnly = async (id, notes) => {
    return await axios.post("/editPartnerOrganizationLeadNotesCCOnly", {
        id: id,
        notes: notes
    });
};
export const editPartnerOrganizationLeadOwnerCCOnly = async (id, leadOwnerUsername) => {
    return await axios.post("/editPartnerOrganizationLeadOwnerCCOnly", {
        id: id,
        leadOwnerUsername: leadOwnerUsername
    });
};

export const getPartnerOrgLeadOwnerUserOptionsCCOnly = async () => {
    const response = await axios.post("/getPartnerOrgLeadOwnerUserOptionsCCOnly", {});
    return response.data;
};